import React from 'react';
import styled from "styled-components";
import { useState } from "react";
import { useEffect } from "react";
import { Chart } from "react-google-charts";
import moment from "moment";
var _ = require('lodash');
// Get a database reference
const database = window.firebase.database();
const Item = styled.div`

 position:relative;
 min-width:130px;
 width:400px;
height: 200px;
text-align:center;
display:flex;
flex-direction:column;
font-size:20px;
margin: 5px;
transition-property: box-shadow margin-top filter;
transition-duration: .2s;
border:1px solid;
border-color: lightgray; 
border-radius : 10px;
box-shadow : 0px 0px 2px 0px grey;
padding-bottom: 10x;
&:hover{
    margin-top: 0px;
    margin-bottom : 5px;
    box-shadow : 0px 0px 10px 0px grey;
}
`;
const Header = styled.div`
margin-left:auto;
margin-right:auto;
margin-top:5px;
margin-bottom:5px;
position:relative;
display:flex
border:1px solid;
border-color: lightgray; 
border-radius : 5px;
box-shadow : 0px 0px 2px 0px grey;
height:30px;
width: 200px;
`;
const ItemContainer = styled.div`
display:flex
flex-direction:row;

height:auto;
width: 98%;
margin-left:auto;
margin-right:auto;
margin-top: 15px;

`;
const InputContainer = styled.div`
display: flex;
flex-direction: row;
margin:5px;

`;
const Text = styled.div`
margin-left:10px;
`;

const db = window.firebase.firestore();
const ref = database.ref('orders');
function Items() {
  const[view, setView] = useState('today');

  const [orders, setOrders] = useState(null);
  const[mostSoldItem,setMostSoldItem] = useState();
  const[times,setTimes] = useState();

  const[numberOfItems ,setNumberOfItems] = useState();
 
  
  useEffect(()=>{ 
    db.collection('items').onSnapshot((data)=>{return  setNumberOfItems(data.size)});
   


    let FoodArr =[]
    ref.orderByChild('date').startAt(new Date(new Date().setHours(0,0,0,0))/1000).endAt(new Date(new Date().setHours(0,0,0,0)).getTime()/1000).on('value', (snapshot) => {          
      const data = _.toArray(snapshot.val());
      if (data.length>=1){
        data.forEach((element,index) => {
          element.order.filter(t=>t.category!=="ITALOK").forEach((item,key)=>{
            console.log('inside loop');
               FoodArr.push(item.name);
               setOrders(FoodArr);
               setMostSoldItem(FoodArr.lenght == 0 ? "nothing sold yet" : mostSold(FoodArr).food)
               setTimes(FoodArr.lenght == 0 ? 0 : mostSold(FoodArr).mf)
               console.log("most sold "+mostSoldItem)
          })
           })
      }else{
        setTimes('0')
        setOrders([]);
        setMostSoldItem('Nothing sold yet')
      }
     
      })

},[]);


function handlePeriodChange(e){
  setView(e.target.vlaue);
  switch(e.target.value){
  
    case "today" :
    
      let FoodArr =[]
      ref.orderByChild('date').startAt(new Date(new Date().setHours(0,0,0,0))/1000).endAt(new Date(new Date().setHours(0,0,0,0)).getTime()/1000).on('value', (snapshot) => {          
      
        if (snapshot.val().length>=1){
          snapshot.val().forEach((element,index) => {
            element.order.filter(t=>t.category!=="ITALOK").forEach((item,key)=>{
          
                 FoodArr.push(item.name);
                 setOrders(FoodArr);
                 setMostSoldItem(FoodArr.lenght == 0 ? "nothing sold yet" : mostSold(FoodArr).food)
                 setTimes(FoodArr.lenght == 0 ? 0 : mostSold(FoodArr).mf)
              
            })
             })
        }else{
          setTimes('0')
          setOrders([]);
          setMostSoldItem('Nothing sold yet')
        }
       
        })
        
      break;
      case "7day" :
   
        let FoodArr2 =[]
        ref.orderByChild('date').startAt(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).getTime()/1000).endAt(new Date(new Date().setHours(0,0,0,0)).getTime()/1000).on('value', (snapshot) => {          
          
          _.toArray(snapshot.val()).forEach((element,index) => {
              element.order.filter(t=>t.category!=="ITALOK").forEach((item,key)=>{
              
                   FoodArr2.push(item.name);
                   setOrders(FoodArr2);
                   setMostSoldItem(FoodArr2.lenght == 0 ? "nothing sold yet" : mostSold(FoodArr2).food)
                   setTimes(FoodArr2.lenght == 0 ? 0 : mostSold(FoodArr2).mf)
               
              })
               })
          })
          
        break;
        case "30days" :
         
          let FoodArr3 =[]
          ref.orderByChild('date').startAt(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).getTime()/1000).endAt(new Date(new Date().setHours(0,0,0,0)).getTime()/1000).on('value', (snapshot) => {          
            
            _.toArray(snapshot.val()).forEach((element,index) => {
                element.order.filter(t=>t.category!=="ITALOK").forEach((item,key)=>{
                 element.order.map(t=>console.log(t.name))
                     FoodArr3.push(item.name);
                     setOrders(FoodArr3);
                     setMostSoldItem(FoodArr3.lenght == 0 ? "nothing sold yet" : mostSold(FoodArr3).food)
                     setTimes(FoodArr3.lenght == 0 ? 0 : mostSold(FoodArr3).mf)
                  
                })
                 })
            })
          break;
          case "60days" :
         
            let FoodArr4 =[]
            ref.orderByChild('date').startAt(new Date(Date.now() - 60 * 24 * 60 * 60 * 1000).getTime()/1000).endAt(new Date(new Date().setHours(0,0,0,0)).getTime()/1000).on('value', (snapshot) => {          
              //const data = _.toArray(snapshot.val());
             
              snapshot.val().forEach((element,index) => {
                  element.order.filter(t=>t.category!=="ITALOK").forEach((item,key)=>{
                   
                       FoodArr4.push(item.name);
                       setOrders(FoodArr4);
                    
                       setMostSoldItem(FoodArr4.lenght == 0 ? "nothing sold yet" : mostSold(FoodArr4).food)
                       setTimes(FoodArr4.lenght == 0 ? 0 : mostSold(FoodArr4).mf)
                    
                  })
                   })
              })
            break;
            case "120days" :
         
              let FoodArr5 =[]
              ref.orderByChild('date').startAt(new Date(Date.now() - 120 * 24 * 60 * 60 * 1000).getTime()/1000).endAt(new Date(new Date().setHours(0,0,0,0)).getTime()/1000).on('value', (snapshot) => {          
                //const data = _.toArray(snapshot.val());
               
                snapshot.val().forEach((element,index) => {
                    element.order.filter(t=>t.category!=="ITALOK").forEach((item,key)=>{
                     
                         FoodArr5.push(item.name);
                         setOrders(FoodArr5);
                      
                         setMostSoldItem(FoodArr5.lenght == 0 ? "nothing sold yet" : mostSold(FoodArr5).food)
                         setTimes(FoodArr5.lenght == 0 ? 0 : mostSold(FoodArr5).mf)
                      
                    })
                     })
                })
              break;
      default:
       
    }
  }



function mostSold(arr){
  var mf = 1;
var m = 0;
var item;
for (var i=0; i<arr.length; i++)
{
        for (var j=i; j<arr.length; j++)
        {
                if (arr[i] == arr[j])
                 m++;
                if (mf<m)
                {
                  mf=m; 
                  item = arr[i];
                }
        }
        m=0;
}
  return {
   food:item,mf
  }
}

  return (
   <Item>
   
      <ItemContainer>
      <Header>Most sold Item</Header>
      <InputContainer className="form-group">
            <label for="tech" className="col-sm-3 control-label">Period</label>
            <div className="col-sm-4">
              <select className="form-control"
                name="status"
                value={view}
                onChange={(e)=>{ handlePeriodChange(e)}}
              >
                <option value="today">Today</option>
                <option value="7day">7 Days</option>
                <option value="30days">Month</option>
                <option value="60days">2 Months</option>
                <option value="120days">4 Months</option>
                <option value="year">Year</option>
              </select>
            </div>
         
          </InputContainer>
      {"Total items : "+numberOfItems}
     {orders&& mostSoldItem ? <div>{"Most sold : "+mostSoldItem}</div> :<div>Loading...</div>}
     {orders&& times ? <div>{times+" Times"}</div> :<div>Loading...</div>}
     </ItemContainer>
      </Item>
  )
}

export default Items
