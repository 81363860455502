import React from "react";
import styled from "styled-components";
import {T} from "../Language/translate";
const InputContainer = styled.div`
  width:260px;
  display: flex;
  flex-direction: row;
  margin-top:10px;
  margin-left:10px;
  @media(max-width: 700px){ 
    margin-top:10px;
    margin-left:-1px;
    width:100%;
   }
  
`;

const X = styled.div`
margin-right:10px;

width:100%;
`;

const Div = styled.div`
display:none;
`;
const TextArea = styled.textarea `
resize: none;

@media(max-width: 700px){ 
  
   
  }
`;
export function Remark({remark,setRemark,lang}){
    const handleChange = event => {
        setRemark(event.target.value);
        console.log(remark);
      };
    return(
        
        <InputContainer >
       
        <X>
          <TextArea type="text" className="form-control" name="ingredient" placeholder={T("69",lang)}
            onChange={handleChange} 
            value={remark}
            />
        </X>
       <Div>{remark}</Div>
      </InputContainer>

    )
}