import React from "react";
import ReactLoading from "react-loading";
import styled from 'styled-components';
 const Shadow = styled.div`
 position : fixed ;
 height : 100% ;
 width : 100%;
 background-color: black;
 opacity: 0.9;
 top : 0px;
 left:0px;
 z-index : 90;
 @media(max-width: 700px){ 
  width:100%;
}

`;
const Container =styled.div`
position:absolute;
display:flex;
align-items:center;
justify-content:center;
height:100%;
width:100%;
margin:auto;
z-index:95;
@media(max-width: 700px){ 
  
  }


`;
const Div = styled.div`
margin:auto;
width:60px;
height:60px;
`;
export default function ModelLoading() {
  return (
    <>
    <Shadow>
    <Container>
      <Div>
      <ReactLoading type="bars" color="#f44336"
        height={50} width={50} />
        </Div>
    </Container>
    </Shadow>
   
      
   

    </>
  );
}