import React from "react";
import styled from "styled-components";
import { useState,useEffect } from "react";
import { EditFees } from "./EditFees";
const Container = styled.div`
position:fixed;
top:100px;
right:0;
width:${(props) => props.width};
font-size : 20px;
@media screen and (max-width: 995px) {
    margin-left:10px;
}
`;
const ItemContainer = styled.div`
position:relative;
width:400px;
height: 420px;
text-align:center;
font-size:20px;
margin: 30px;
transition-property: box-shadow margin-top filter;
transition-duration: .2s;
border:1px solid;
border-color: lightgray; 
border-radius : 10px;
box-shadow : 0px 0px 2px 0px grey;
padding-bottom: 10x;
&:hover{
  
   box-shadow : 0px 0px 10px 0px grey;
}
@media screen and (max-width: 700px) {
 height: 210px;
  width:170px;
}
@media screen and (max-width: 350px) {
   height: 210px;
   width:120px;
}
`;
const Edit = styled.div`
height: 40px;
width: 40px;
display :flex;
align-items:center;
justify-element:center;
position:absolute;
background-image:url("/img/Edit.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border: none; 
top:0;
right:0;
font-size: 18px;
font-weight:bold;
 &:hover{
    cursor: pointer;
    opacity: 1.5;
    background-color:lightgray;
    border-radius : 7px;
 }

`;
const Div = styled.div`
font-weight:bold;
margin-top :auto;
margin-bottom:20px;
text-align:center;
@media screen and (max-width: 700px) {
  font-size:14px;
}

`;

function Fees({active}){
    const db = window.firebase.firestore();
    const [openFees, setOpenFees] = useState();
    const [fees,setFees] = useState();
   
    const canShowItems = fees && true;
    useEffect(()=>{ 
       
        db.collection('Fees').onSnapshot((data)=>{return  setFees(data.docs)});
    
        },[]);
    return (
        <Container width={ active ? "calc(100% - 220px)" : "calc(100% - 40px)"}>
            <EditFees openFees={openFees} setFees={setFees} setOpenFees={setOpenFees}/>
             { canShowItems &&
               <ItemContainer>
                <h2> Fees </h2>
                <Div>{"Minimum order price : "+fees[0].data().minOrder+" €"}</Div>
                <Div>{"Rate change : 1 Dmd =  "+fees[0].data().rate+" €"}</Div>
                <Div>{"Packaging Fee : "+fees[0].data().packagePrice +" €"}</Div>
               <Edit onClick={()=>setOpenFees(fees[0].data())} />
               </ItemContainer>
}
        </Container>
     
    )
}

export default Fees 