import React from 'react';
import styled from "styled-components";
import { useState ,useEffect } from 'react';
import ToggleButton from 'react-toggle-button'
import TimePicker from 'react-time-picker';
const Dialog = styled.div`
position: fixed;
top:100px;
  width: 800px;
  height:75%;
  background-color: white;
  margin-bottom:50px;
 overflow-y:scroll;
  z-index: 55;
margin:auto;
padding:10px;
  display: flex;
  flex-direction: column;
  border-radius:5px;
  // Add this part
  @media (max-width: 400px) {
    
    overflow-y: scroll;
    height:500px;
    width: 100%;
 
    
  }
`;
const DialogShadow = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  left:0px;
  background-color: black;
  opacity: 0.7;
  z-index: 11;
`;

const SchudelDiv = styled.div`

width:auto;
height:auto;
border: solid 1px;
border-color: gray; 
border-radius : 10px;
margin:10px;
@media screen and (max-width: 1200px) {
  width:80%;
}
@media screen and (max-width: 1050px) {
  width:90%;
}
@media screen and (max-width: 830px) {
 
  margin:10px;
}
`;

const ScheduleGrid = styled.div`
display: flex;
flex-direction:column;
gap: 15px 10px;
margin:10px;
@media screen and (max-width: 830px) {
  text-align:center;
  justify-items:center;
  margin:10px;
  grid-template-columns: 1fr ;
  grid-template-rows: 50px ;
}
`;
const ButtonStyled = styled.button`
max-height:40px;
text-decoration: none;
margin: 10px;
&:hover{
    opacity: 1.2;
    padding-top: 3px;
}
`;
export const ButtonContainer = styled.div`
padding-top:30px;
margin-right:20px;
margin-left: auto;
display:flex;
flex-direction:row;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: auto;
  margin: 20px;
`;
const Div = styled.div`
white-space: nowrap;
margin-top:auto;
margin-bottom:auto;
margin-left:5px;
margin-right:5px;
font-size:20px;

`;
const Wrapper = styled.div`
display:flex;
flex-direction:row;
justify-content: space-between;
@media screen and (max-width: 630px) {
  
  flex-direction:column;
}

`;
const Wrapper2 = styled.div`
z-index: 55;
position:fixed;
width:100%;
height:100%;
left:0;
bottom:0;
  display: flex;
  justify-content: center;
  align-items: center;

`;

function EditScheduleContainer({ openItem, prop,setData,setOpenItem}) {

  
  const db = window.firebase.firestore();
  //use state for toggle 
  const [toggle0, setToggle0] = useState(openItem[0].data().work);
  const [toggle1, setToggle1] = useState(openItem[1].data().work);
  const [toggle2, setToggle2] = useState(openItem[2].data().work);
  const [toggle3, setToggle3] = useState(openItem[3].data().work);
  const [toggle4, setToggle4] = useState(openItem[4].data().work);
  const [toggle5, setToggle5] = useState(openItem[5].data().work);
  const [toggle6, setToggle6] = useState(openItem[6].data().work);

  const [toggleInterval, setToggleInterval] = useState(openItem[7].data().inter);
  const canShowSchudel = openItem && true;
 

  //use state for times 
  const [sunStartTime, setSunStartTime] = useState(openItem[0].data().startTime);
  const [sunEndTime,setSunEndTime] = useState(openItem[0].data().endTime);
  const [sunStartTime2, setSunStartTime2] = useState(openItem[0].data().startTime2);
  const [sunEndTime2,setSunEndTime2] = useState(openItem[0].data().endTime2);

  
  const [monStartTime, setMonStartTime] = useState(openItem[1].data().startTime);
  const [monEndTime,setMonEndTime] = useState(openItem[1].data().endTime);
  const [monStartTime2, setMonStartTime2] = useState(openItem[1].data().startTime2);
  const [monEndTime2,setMonEndTime2] = useState(openItem[1].data().endTime2);


  const [tueStartTime, setTueStartTime] = useState(openItem[2].data().startTime);
  const [tueEndTime,setTueEndTime] = useState(openItem[2].data().endTime);
  const [tueStartTime2, setTueStartTime2] = useState(openItem[2].data().startTime2);
  const [tueEndTime2,setTueEndTime2] = useState(openItem[2].data().endTime2);

  const [wedStartTime, setWedStartTime] = useState(openItem[3].data().startTime);
  const [wedEndTime,setWedEndTime] = useState(openItem[3].data().endTime);
  const [wedStartTime2, setWedStartTime2] = useState(openItem[3].data().startTime2);
  const [wedEndTime2,setWedEndTime2] = useState(openItem[3].data().endTime2);

  const [thuStartTime, setThuStartTime] = useState(openItem[4].data().startTime);
  const [thuEndTime,setThuEndTime] = useState(openItem[4].data().endTime);
  const [thuStartTime2, setThuStartTime2] = useState(openItem[4].data().startTime2);
  const [thuEndTime2,setThuEndTime2] = useState(openItem[4].data().endTime2);

  const [friStartTime, setFriStartTime] = useState(openItem[5].data().startTime);
  const [friEndTime,setFriEndTime] = useState(openItem[5].data().endTime);
  const [friStartTime2, setFriStartTime2] = useState(openItem[5].data().startTime2);
  const [friEndTime2,setFriEndTime2] = useState(openItem[5].data().endTime2);

  const [satStartTime, setSatStartTime] = useState(openItem[6].data().startTime);
  const [satEndTime,setSatEndTime] = useState(openItem[6].data().endTime);
  const [satStartTime2, setSatStartTime2] = useState(openItem[6].data().startTime2);
  const [satEndTime2,setSatEndTime2] = useState(openItem[6].data().endTime2);


  



  function handleSubmit ()  {
    console.log('handel submit');

   
      
    const docSun = db.collection("schudel").doc("0");
    const docMon = db.collection("schudel").doc("1");
    const docTue = db.collection("schudel").doc("2");
    const docWed = db.collection("schudel").doc("3");
    const docThu = db.collection("schudel").doc("4");
    const docFri = db.collection("schudel").doc("5");
    const docSat = db.collection("schudel").doc("6");
    const docInter = db.collection("schudel").doc("7");
    try {
   
      docSun.set({
       name: "Sunday",
       work: toggle0,
       startTime: sunStartTime, 
       endTime: sunEndTime,
       startTime2: sunStartTime2, 
       endTime2: sunEndTime2,
       index:0
     
      });
      docMon.set({
        name: "Monday",
        work: toggle1,
        startTime: monStartTime, 
        endTime: monEndTime,
        startTime2: monStartTime2, 
        endTime2: monEndTime2,
        index:1
      
      
       });
       docTue.set({
        name: "Tuesday",
        work: toggle2,
        startTime: tueStartTime, 
        endTime: tueEndTime,
        startTime2: tueStartTime2, 
        endTime2: tueEndTime2,
        index:2
      
      
       });
       docWed.set({
        name: "Wednesday",
        work: toggle3,
        startTime: wedStartTime, 
        endTime: wedEndTime,
        startTime2: wedStartTime2, 
        endTime2: wedEndTime2,
        index:3
      
      
       });
       docWed.set({
        name: "Wednesday",
        work: toggle3,
        startTime: wedStartTime, 
        endTime: wedEndTime,
        startTime2: wedStartTime2, 
        endTime2: wedEndTime2,
        index:3
      
      
       });
       docThu.set({
        name: "Thursday",
        work: toggle4,
        startTime: thuStartTime, 
        endTime: thuEndTime,
        startTime2: thuStartTime2, 
        endTime2: thuEndTime2,
        index:4
      
      
       });
       docFri.set({
        name: "Friday",
        work: toggle5,
        startTime: friStartTime, 
        endTime: friEndTime,
        startTime2: friStartTime2, 
        endTime2: friEndTime2,
        index:5
      
      
       });
       docSat.set({
        name: "Saterday",
        work: toggle6,
        startTime: satStartTime, 
        endTime: satEndTime,
        startTime2: satStartTime2, 
        endTime2: satEndTime2,
        index:6
      
      
       });
       docInter.set({
        inter : toggleInterval
       })

      console.log("submited :=)")
    } catch (e) {
      console.log(e);
    }
   
  db.collection('schudel').get().then((data)=>{return  setData(data.docs)});
        
  
  setOpenItem();
  
  
  
  }



  return (
    <>
    <DialogShadow />
    <Wrapper2>
    <Dialog>
      <label>Deux intervale</label>
    <ToggleButton
      
      value={toggleInterval}
       onToggle={()=>!toggleInterval ? setToggleInterval(true):setToggleInterval(false)}
       colors={{
        active:{
          base:"#1578F1"
        },
        inactive: {
          base: 'gray'}
       }}
       />
    <ButtonContainer>
  
         <ButtonStyled className="btn btn-primary"  onClick={()=>handleSubmit()} >
           Save 
         </ButtonStyled>
         <ButtonStyled
           className="btn btn-light"
           onClick={() =>setOpenItem()}
         >
           Cancel
         </ButtonStyled>
       </ButtonContainer>
     <SchudelDiv>
       <ScheduleGrid>
       { canShowSchudel ? 


      <>
       <>
        <Div>{openItem[0].data().name}</Div>
        <ToggleButton
      
        value={toggle0}
         onToggle={()=>!toggle0 ? setToggle0(true):setToggle0(false)}
         colors={{
          active:{
            base:"#1578F1"
          },
          inactive: {
            base: 'gray'}
         }}
         />
 
       {toggle0 ? <Wrapper> <TimePicker
       className='TimePicker'
        value={sunStartTime} 
        onChange={(e)=>setSunStartTime(e)}
        disableClock='true'
      
        />
        <Div>To :</Div>
       
        <TimePicker
         className='TimePicker'
         type="text"
      name="SunEnd"
      onChange={e=>setSunEndTime(e)}
      value={sunEndTime} 
      disableClock='true'
    
      />
{toggleInterval ?<>
  <TimePicker
        className='TimePicker'
       value={sunStartTime2} 
       onChange={(e)=>setSunStartTime2(e)}
       disableClock='true'
     
       />
       <Div>To :</Div>
      
       <TimePicker
        className='TimePicker'
        type="text"
     name="SunEnd2"
     onChange={e=>setSunEndTime2(e)}
     value={sunEndTime2} 
     disableClock='true'
   
     />
</> : <></>}

      </Wrapper>
      : <><Div></Div></>}
      
      
      </>



<>
<Div>{openItem[1].data().name}</Div>
<ToggleButton

value={toggle1}
 onToggle={()=>!toggle1 ? setToggle1(true):setToggle1(false)}
 colors={{
  active:{
    base:"#1578F1"
  },
  inactive: {
    base: 'gray'}
 }}
 />
{toggle1 ? <Wrapper> 
  <TimePicker
 className='TimePicker'
 type="text"
      name="MonStart"
      onChange={e=>setMonStartTime(e)}
      value={monStartTime} 
disableClock='true'

/>
<Div>To :</Div>

<TimePicker
 className='TimePicker'
 type="text"
     name="MonEnd"
     onChange={e=>setMonEndTime(e)}
     value={monEndTime} 
disableClock='true'

/>
{toggleInterval ?<>
  <TimePicker
 className='TimePicker'
 type="text"
      name="MonStart2"
      onChange={e=>setMonStartTime2(e)}
      value={monStartTime2} 
disableClock='true'

/>
<Div>To :</Div>

<TimePicker
 className='TimePicker'
 type="text"
     name="MonEnd2"
     onChange={e=>setMonEndTime2(e)}
     value={monEndTime2} 
disableClock='true'

/>
</> : <></>}



</Wrapper>
: <><Div></Div></>}


</>


<>
<Div>{openItem[2].data().name}</Div>
<ToggleButton

value={toggle2}
 onToggle={()=>!toggle2 ? setToggle2(true):setToggle2(false)}
 colors={{
  active:{
    base:"#1578F1"
  },
  inactive: {
    base: 'gray'}
 }}
 />
{toggle2 ? <Wrapper> 
  
  <TimePicker
 className='TimePicker'
 type="text"
     name="TueStart"
     onChange={e=>setTueStartTime(e)}
        value={tueStartTime} 
disableClock='true'

/>
<Div>To :</Div>

<TimePicker
 className='TimePicker'
 type="text"
     name="TueEnd"
     onChange={e=>setTueEndTime(e)}
     value={tueEndTime} 
disableClock='true'

/>
{toggleInterval ?<>
  <TimePicker
 className='TimePicker'
 type="text"
     name="TueStart2"
     onChange={e=>setTueStartTime2(e)}
        value={tueStartTime2} 
disableClock='true'

/>
<Div>To :</Div>

<TimePicker
 className='TimePicker'
 type="text"
     name="TueEnd2"
     onChange={e=>setTueEndTime2(e)}
     value={tueEndTime2} 
disableClock='true'

/>
</> : <></>}



</Wrapper>
: <><Div></Div></>}


</>

<>
<Div>{openItem[3].data().name}</Div>
<ToggleButton

value={toggle3}
 onToggle={()=>!toggle3? setToggle3(true):setToggle3(false)}
 colors={{
  active:{
    base:"#1578F1"
  },
  inactive: {
    base: 'gray'}
 }}
 />
{toggle3 ? <Wrapper> 
  <TimePicker
 className='TimePicker'
 type="text"
     name="WedStart"
     onChange={e=>setWedStartTime(e)}
     value={wedStartTime} 
disableClock='true'

/>
<Div>To :</Div>

<TimePicker
 className='TimePicker'
 type="text"
     name="WedEnd"
     onChange={e=>setWedEndTime(e)}
     value={wedEndTime} 
disableClock='true'

/>
{toggleInterval ?<>
  <TimePicker
 className='TimePicker'
 type="text"
     name="WedStart2"
     onChange={e=>setWedStartTime2(e)}
     value={wedStartTime2} 
disableClock='true'

/>
<Div>To :</Div>

<TimePicker
 className='TimePicker'
 type="text"
     name="WedEnd2"
     onChange={e=>setWedEndTime2(e)}
     value={wedEndTime2} 
disableClock='true'

/>
</> : <></>}

</Wrapper>
: <><Div></Div></>}


</>


<>
<Div>{openItem[4].data().name}</Div>
<ToggleButton

value={toggle4}
 onToggle={()=>!toggle4? setToggle4(true):setToggle4(false)}
 colors={{
  active:{
    base:"#1578F1"
  },
  inactive: {
    base: 'gray'}
 }}
 />
{toggle4 ? <Wrapper>
   <TimePicker
 className='TimePicker'
 type="text"
     name="ThuStart"
     onChange={e=>setThuStartTime(e)}
     value={thuStartTime} 
disableClock='true'

/>
<Div>To :</Div>

<TimePicker
 className='TimePicker'
 type="text"
     name="ThuEnd"
     onChange={e=>setThuEndTime(e)}
     value={thuEndTime} 
disableClock='true'

/>
{toggleInterval ?<>
  <TimePicker
 className='TimePicker'
 type="text"
     name="ThuStart2"
     onChange={e=>setThuStartTime2(e)}
     value={thuStartTime2} 
disableClock='true'

/>
<Div>To :</Div>

<TimePicker
 className='TimePicker'
 type="text"
     name="ThuEnd2"
     onChange={e=>setThuEndTime2(e)}
     value={thuEndTime2} 
disableClock='true'

/>
</> : <></>}

</Wrapper>
: <><Div></Div></>}


</>

<>
<Div>{openItem[5].data().name}</Div>
<ToggleButton

value={toggle5}
 onToggle={()=>!toggle5? setToggle5(true):setToggle5(false)}
 colors={{
  active:{
    base:"#1578F1"
  },
  inactive: {
    base: 'gray'}
 }}
 />
{toggle5 ? <Wrapper> 
  <TimePicker
 className='TimePicker'
 type="text"
     name="FriStart"
     onChange={e=>setFriStartTime(e)}
     value={friStartTime} 
disableClock='true'

/>
<Div>To :</Div>

<TimePicker
 className='TimePicker'
 type="text"
     name="FriEnd"
     onChange={e=>setFriEndTime(e)}
     value={friEndTime} 
disableClock='true'

/>
{toggleInterval ?<>
  <TimePicker
 className='TimePicker'
 type="text"
     name="FriStart2"
     onChange={e=>setFriStartTime2(e)}
     value={friStartTime2} 
disableClock='true'

/>
<Div>To :</Div>

<TimePicker
 className='TimePicker'
 type="text"
     name="FriEnd2"
     onChange={e=>setFriEndTime2(e)}
     value={friEndTime2} 
disableClock='true'

/>
</> : <></>}

</Wrapper>
: <><Div></Div></>}


</>



<>
<Div>{openItem[6].data().name}</Div>
<ToggleButton

value={toggle6}
 onToggle={()=>!toggle6? setToggle6(true):setToggle6(false)}
 colors={{
  active:{
    base:"#1578F1"
  },
  inactive: {
    base: 'gray'}
 }}
 />
{toggle6 ? <Wrapper> 
  <TimePicker
 className='TimePicker'
 type="text"
     name="SatStart"
     onChange={e=>setSatStartTime(e)}
     value={satStartTime} 
disableClock='true'

/>
<Div>To :</Div>

<TimePicker
 className='TimePicker'
 type="text"
     name="SatEnd"
     onChange={e=>setSatEndTime(e)}
     value={satEndTime} 
disableClock='true'

/>
{toggleInterval ?<>
  <TimePicker
 className='TimePicker'
 type="text"
     name="SatStart2"
     onChange={e=>setSatStartTime2(e)}
     value={satStartTime2} 
disableClock='true'

/>
<Div>To :</Div>

<TimePicker
 className='TimePicker'
 type="text"
     name="SatEnd2"
     onChange={e=>setSatEndTime2(e)}
     value={satEndTime2} 
disableClock='true'

/>
</> : <></>}


</Wrapper>
: <><Div></Div></>}


</>

</>






     : "Loading ..."}
       </ScheduleGrid>
       </SchudelDiv>
    
       </Dialog>
       </Wrapper2>
       </>
  )
}

export function EditSchudle(props){
    if (!props.openItem) return null;
    return <EditScheduleContainer {...props}/>
}
