import { useState } from "react";

export function useQuantity(defaultQuantity,openFood){
    const [value, setValue] = useState(defaultQuantity || 1);

    function onChange (e){
        if(!(+e.target.value >= 1)){
        setValue(1);
        return;
         }
         if((+e.target.value > openFood.data().quantity)){
            setValue(openFood.data().quantity);
            return;
         }
         setValue(+e.target.value);
    }
    return {
        value,
        setValue,
        onChange
    };
}