import React from "react";
import styled from "styled-components";
import { useState } from "react";
import Autocomplete from "react-google-autocomplete";
import { OrderBtn } from "../Styles/colors";
import { useEffect } from "react";
import useForm from "../Hooks/useForm";
import {T} from "../Language/translate";
const db = window.firebase.firestore();
const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: auto;
  margin: 20px;
`;
export const UserFooter = styled.div`
  height: 60px;
  display: flex;

  margin-top: 10px;
  justify-content: center;
`;
export const ConfirmButton = styled.div`
  margin: 10px;
  color: white;
  height: 40px;
  width: 200px;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  background-color: ${OrderBtn};
  opacity: 0.7;
  z-index: 4;
  ${({ disabled }) =>
    disabled &&
    `
opacity: .5;
background-color: grey;
pointer-events: none;
`}
`;
const Notice = styled.span`
  color: red;
  font-size: 12px;
`;
const ExtraItem = styled.div`
position:relative;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
width:auto;
height: 40px;
text-align:center;
font-size:20px;
margin: 5px;
transition-property: box-shadow margin-top filter;
transition-duration: .2s;
border:1px solid;
border-color: lightgray; 
border-radius : 10px;
box-shadow : 0px 0px 2px 0px grey;
padding-bottom: 10x;

`;
const ExtraContent = styled.div`
display:flex;
flex-direction:row;
font-size:16px;

`;
const ExtraGrid = styled.div`
display: grid;
grid-template-columns: 1fr  ;
gap: 10px;

`;
const Div = styled.div`
margin:1px;
`;

const X = styled.div`
height: 30px;
width: 30px;
margin:5px;
 &:hover{
    cursor: pointer;
    opacity:0.7;
 }
`;
const AddressTxt = styled.div`
text-align:center;
width:300px;
margin:auto;
`; 
function phoneValid(value){
  return !isNaN(value) && (value != null && value.trim().length > 0)
}
function Required(value){
  return value != null && value.trim().length > 0
}
var session = window.sessionStorage;
export function UserForm({ setOpenUser ,loggedIn,prop,logout,setLogInPanel,lang,isGuest,setIsGuest,setOpenOrderDialog,setDeliveryFee,setMinOrderPrice,takeOrDelivery}) {
  

  const [zip,setZip] = useState();
  const [street,setStreet]= useState("");
  const [city,setCity]= useState("");
  const [selected,setSelected] = useState(false);
  const [phone,setPhone] = useState();
  const [hNumber, setHnumber] = useState();
  const [tags, setTags] = useState([]);
 //Form validation 
 const initialState = { name:'',address: '' ,floor: '',  door: '', };
 const validations = [

 ];

 const { values, isValid, errors, changeHandler} = useForm(initialState, validations, prop);


 

  





const [Err,setError] = useState("");
const [addressErr,setAddressErr] = useState("");
const [zipErr, setZipErr] = useState("");
const [phoneErr, setPhoneErr] = useState("");
const [HnumErr,setHnumErr] = useState("");

const [nameErr,setNameErr] = useState("");


function showError(){

  if(isNaN(zip)){
    setZipErr("Un code postal valide est requis");
  }
  if((errors.address || errors.zip || errors.phone)){
      setError(T("52",lang));
  }

  phoneValid(phone)? setPhoneErr(""): setPhoneErr(T("48",lang));
  
  Required(hNumber) ? setHnumErr(""): setHnumErr(T("139",lang));
 
   tags.length != 0 ? setAddressErr("") :setAddressErr(T("59",lang));
    
  
}

function handleDelete(i) {
  setTags(tags.filter((tag, index) => index !== i));
  setAddressErr(T("59",lang));
  setError('');
  setZip();

};

function handleAddition(e,place) {
  console.log(place);
   place.address_components.filter((item)=>item.types[0]=='postal_code' ? setZip(Number(item.long_name)):setZip());
   place.address_components.filter((item)=>item.types[0]=='route' ? setStreet(item.long_name):"");
   place.address_components.filter((item)=>item.types[0]=='locality' ? setCity(item.long_name):"");
   
  
   setTags([ e ]);
  setAddressErr("");
  setError('');

};

 


 function SignOUt(){
  logout();
  setTimeout(function() {
    setOpenUser();
    },500)
    setLogInPanel(false);

 }
 const [points , setPoints] = useState();
 useEffect(()=>{ 
 if(loggedIn) db.collection('users').doc(loggedIn.email).get().then((doc)=>{ doc.data() ? setPoints(doc.data().points): setPoints("10")})

},[]);

  const handleGuest = () => {
   if(takeOrDelivery !=="A importer" ) {
    session.setItem("name", values.name);
    session.setItem("houseNumber", hNumber);
    session.setItem("street", street);
    session.setItem("city", city);
    session.setItem("floor",values.floor);
    session.setItem("door",values.door);
    session.setItem("phone", phone);
    session.setItem("zipCode", zip);
    session.setItem("GuestOk",true);

if(city==="Thoury" || city ==="Massives"|| city ==="Saint-Dyé-sur-Loire") {
      setMinOrderPrice(20)
    }
if( city ==="Lailly-en-Val"  || city==="La Ferté-Saint-Cyr" ||city==="Mer"|| city==="Maslives"|| city==="Chambord") {
  setMinOrderPrice(30)
}
if(city ==="Dhuizon"|| city==="Montlivault"){
  setMinOrderPrice(40)
}

     db.collection('zip').doc(String(session.getItem("zipCode"))).get().then((X)=>{
      if(X.exists){
      setDeliveryFee(X.data().price?X.data().price:0);
      console.log(X.data().price);
setOpenUser();
      }
      else {
        setAddressErr(T("50",lang) ) ;
        console.log(zip);
    
        console.log(session.getItem("zipCode"))
       
     
      }
  })
} else{
  session.setItem("phone", phone);
  session.setItem("GuestOk",true);
  setOpenUser();
}
  
  }
  const handleSubmit = () => {

    db.collection('zip').doc(String(zip)).get().then((doc)=>{ 
      
      if(doc.exists){
      setDeliveryFee(doc.data().price?doc.data().price:0);
        const docRef = db.collection("users").doc(loggedIn.email);
        try {
        
          docRef.set({
            street: street,
            zipCode:zip,
            city:city,
            houseNumber:hNumber,
            email: loggedIn.email,
            name: loggedIn.displayName,
            phone: phone,
            floor: values.floor,
            door : values.door,
            points: points,
            date: new Date(),
            status:true
          });
        } catch (e) {
          console.log(e);
        }
      

      setOpenUser();
    }  else {

      setAddressErr(T("50",lang) ) ;
      
    } })
if(city==="Thoury" || city ==="Massives"|| city ==="Saint-Dyé-sur-Loire") {
      setMinOrderPrice(20)
    }
if( city ==="Lailly-en-Val"  || city==="La Ferté-Saint-Cyr" ||city==="Mer"|| city==="Maslives"|| city==="Chambord") {
  setMinOrderPrice(30)
}
if(city ==="Dhuizon"|| city==="Montlivault"){
  setMinOrderPrice(40)
}

  };

  return (
   
     <>
     {takeOrDelivery !=="A importer" ? <>
     <Notice>{!( errors.address||errors.zip || errors.phone) ? Err : addressErr}</Notice>
      <ExtraGrid >
              {tags.length != 0 ? tags.map((item, index) => {
                return (
                  <ExtraItem>
                    <ExtraContent>
                      <Div>{item}</Div>
                      <X onClick={() => { handleDelete(index);setZip() }} >❌</X>
                    </ExtraContent>

                  </ExtraItem>
                )

              }) : ""}

            </ExtraGrid>
           { console.log(tags)}
           <label className="label">
           <AddressTxt>{T("142",lang)}</AddressTxt>
           <Notice>{addressErr}</Notice>
        <Autocomplete
          className="form-control"
          value={values.address}
          name="address"
          onBlur={changeHandler}
          onChange={(e)=>{changeHandler(e);setSelected(false);}}
          placeholder={T("146",lang)}
          apiKey={"AIzaSyC_NsDrw_bCL2e5UQEiknoOFhwWdKadKtQ"}
          style={{ width: "fit-content(20em)" }}
          onPlaceSelected={(place) => {
           
          
            setSelected(true);
            handleAddition(place.formatted_address,place);
      
          }}
          options={{
            types: ["address"],
            componentRestrictions: { country: "fr"},
          
            strictBounds: false,
          }}
        
        />
      </label>
      <Notice>{zipErr}</Notice>
      <label>
        {T("37",lang)}
        <input
          className="form-control"
          name="zip"
          type="text"
          value={zip}
          disabled
        />
      </label>
      <Notice>{HnumErr}</Notice>
      <label>
       {T("138",lang)}
        <input
          className="form-control"
          name="Hnumber"
          type="text"
          value={hNumber}
          onChange={(e)=>setHnumber(e.target.value)}
        />
        </label>
    
      <label>
       {T("39",lang)}
        <input
          className="form-control"
          name="floor"
          type="text"
          value={values.floor}
          onChange={changeHandler}
        />
      </label>


     <Notice>{phoneErr}</Notice>
      <label>
       {T("54",lang)} 
        <input
          className="form-control"
          placeholder={T("147",lang)}
          name="phone"
          type="tel"
          value={phone}
          onChange={(e)=>setPhone(e.target.value)}
        />
      </label>  
      <UserFooter>
        

        <ConfirmButton onClick={()=>{!(tags.length != 0&& !isNaN(zip)&&phoneValid(phone)&&Required(hNumber))?showError():isGuest ? handleGuest(): handleSubmit()}}>{!isGuest ? T("55",lang) : T("130",lang)}</ConfirmButton>

        {!isGuest && <ConfirmButton onClick={SignOUt}>{T("32",lang)}</ConfirmButton>}
      </UserFooter>
     </> : <>
     <Notice>{phoneErr}</Notice>
      <label>
       {T("54",lang)} 
        <input
          className="form-control"
          placeholder={T("147",lang)}
          name="phone"
          type="tel"
          value={phone}
          onChange={(e)=>setPhone(e.target.value)}
        />
      </label>  
     <UserFooter>
        

        <ConfirmButton onClick={()=>{!(phoneValid(phone))?showError():isGuest ? handleGuest(): handleSubmit()}}>{!isGuest ? T("55",lang) : T("130",lang)}</ConfirmButton>

        {!isGuest && <ConfirmButton onClick={SignOUt}>{T("32",lang)}</ConfirmButton>}
      </UserFooter>
     
     </>

     }
   
      </> 
   
  );
}
