import React from "react";
import styled from "styled-components";
import { ButtonContainer } from "../Items/Items";
import { Bar } from "../Items/Items";
import { useState, useEffect } from "react";
import useForm from "../Hooks/useForm";
import {  DialogShadow,DialogHeader,H2,ButtonStyled,InputContainer,Dialog, Wrapper,Label,Notice,UserFooter } from "../Items/AddItem";
import { Close } from "../../../Orders/OrderDetails";
import { T } from "../../../../../Language/translate";



export const H4R = styled.div`
  font-size: 15px;
  font-weight: bold;
  color:red;

  white-space: nowrap;
`;
export const H4G = styled.div`
  font-size: 15px;
  font-weight: bold;
  color:green;

  white-space: nowrap;
`;

const ExtraGrid = styled.div`
display: grid;
grid-template-columns: 1fr 1fr   ;
gap: 10px;
overflow-y: scroll;
height:250px;
`;
const ExtraItem = styled.div`
position:relative;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
width:auto;
height: 40px;
text-align:center;
font-size:20px;
margin: 5px;
transition-property: box-shadow margin-top filter;
transition-duration: .2s;
border:1px solid;
border-color: lightgray; 
border-radius : 10px;
box-shadow : 0px 0px 2px 0px grey;
padding-bottom: 10x;

`;
const ExtraContent = styled.div`
display:flex;
flex-direction:row;

`;

const AddContainer = styled.div`
display:flex;
flex-direction:row;0.
`;

const Div = styled.div`
margin:1px;
`;
 const Close2 = styled.div`
height: 30px;
width: 30px;
background-image:url("/img/close.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
margin:5px;
 &:hover{
    cursor: pointer;
    opacity:0.7;
 }
`;
const Add = styled.div`
height: 38px;
width: 38px;
display :flex;
align-items:center;
justify-element:center;
background-image:url("/img/Add.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border: none; 
margin: auto;
font-size: 18px;
font-weight:bold;
 &:hover{
  cursor: pointer;
    opacity: 1.5;
    background-color:lightgray;
    border-radius : 7px;
 }
`;
// function for required fields 
function isRequired(value) {
    return value != null && value.trim().length > 0;
  }

function EditOptionContainer({ openItem, setOpenItem,prop,setData,lang}) {

   //extras tags
   const [tags, setTags] = useState(openItem.extras);
    //lang
  const [languages , setLanguages] = useState()
   //db
   const db = window.firebase.firestore();
   useEffect(()=>{ 
    db.collection('Language').get().then((data) => { return setLanguages(data.docs[0].data()) });
    
  },[]);
   function handleDelete (i) {
     setTags(tags.filter((tag, index) => index !== i));
    
   };
 
   function handleAddition() {
     const docRef2 = db.collection("Language").doc("lang");
     try{
      db.collection('Language').get().then((data) => { return setLanguages(data.docs[0].data()) });
      docRef2.set({
        "En":{
          [values.name]: values.enName,
          ...languages.En
         
        },
        "Hu":{
          [values.name]: values.name,
          ...languages.Hu
        }})
        setTags([...tags, {name:values.name,price:Number(values.price)}]);
        }catch(error){
          console.log(error);
        }
   };
 
 
 
   const initialState = { group: openItem.name,tags:openItem.extras,name:'',enName:'',price:''};
   const validations = [
     ({group}) => isRequired(group) || {group: 'Group is required'},
   ];

   const {values, isValid, errors, changeHandler} = useForm(initialState, validations, prop);
 

 
 
 
 
   const handleSubmit = (event) => {
     event.preventDefault();
    
       
     const docRef = db.collection("options").doc(values.group);
     try {
    
       docRef.set({
        name: values.group,
        extras: tags
      
       });
       console.log("submited :=)")
     } catch (e) {
       console.log(e);
     }
    
   db.collection('options').get().then((data)=>{return  setData(data.docs)});
         
   
   setOpenItem();
   
   
   
   }
   
  return (
    <>
    <DialogShadow />
    <Wrapper>
 <Dialog>
<Close      onClick={() =>setOpenItem()} />
     <DialogHeader>
       <H2>{T(207,lang)}</H2>

     </DialogHeader>
     <Bar />
     <InputContainer className="form-group">
 <Label  className="control-label">{T(208,lang)} </Label>
 
 <input type="text" className="form-control" name="group"  placeholder="Group name" 
 value={values.group}
 onBlur={changeHandler}
 onChange={changeHandler}/>
 
 { errors.group && <Notice>{errors.group}</Notice>}
</InputContainer> 
     <ExtraGrid >
       {tags.length!=0 ? tags.map((item,index)=>{ return(
          
             <ExtraItem>
               <ExtraContent>
               
             <Div>{item.name}</Div>
             <Div>:</Div>
             <Div>{item.price}</Div>
             <Close2 onClick={()=>{handleDelete(index)}}/>
               </ExtraContent>
             
            </ExtraItem>
       )
              
       }): <Notice>{T(209,lang)}</Notice>}
       
     </ExtraGrid>
     <AddContainer>
     <div>
     <InputContainer className="form-group">
 <Label  className="control-label">Extra  </Label>
 
 <input type="text" className="form-control" name="name" placeholder={T(210,lang)}
       value={values.name}
       onChange={changeHandler} />
 

</InputContainer> 
<InputContainer >
      <Label >Extra </Label>

      <input type="text" className="form-control" name="enName" placeholder={T(211,lang)}
            value={values.enName}
            onChange={changeHandler} />
     
  
   </InputContainer> 
{  !values.name != null && !values.name.trim().length > 0 ? <Notice>{T(212,lang)} </Notice> :""}
     <InputContainer className="form-group">
 <Label for="price" className="control-label">{T(213,lang)} </Label>
 
 <input type="number" className="form-control" name="price" placeholder={T(213,lang)}
       value={values.price}
       onChange={changeHandler} />
 

</InputContainer> 
{  !values.price != null && !values.price.trim().length > 0 ? <Notice>{T(214,lang)} </Notice> :""}
{ isNaN(values.price)? <Notice>{T(215,lang)} </Notice> : ""}
</div>
{!(!(values.name != null && values.name.trim().length > 0) ||
  isNaN(values.price)||!(values.price != null && values.price.trim().length > 0)) ? <Add  onClick={handleAddition} />: ""  } 
</AddContainer>
<Bar />
<ButtonContainer>
         <ButtonStyled className="btn btn-primary"  disabled={!isValid || !tags.length !=0} onClick={handleSubmit}>
         {T(194,lang)}
         </ButtonStyled>
         <ButtonStyled
           className="btn btn-light"
           onClick={() =>setOpenItem()}
         >
           {T(168,lang)}
         </ButtonStyled>
       </ButtonContainer>
 </Dialog>
 </Wrapper>
   </>
  );
}

export function EditOption(props){
    if (!props.openItem) return null;
    return <EditOptionContainer {...props}/>
}