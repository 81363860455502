import React from "react";
import styled from "styled-components";
import { Title } from "../Styles/title";
import {Link} from "react-router-dom";
import { useState, useEffect } from "react";
import { LogIn } from "./LogIn";
import { SignUp } from "./SignUp";
import { ResetPassWord } from "./ResetPassword";
import { OrderSent } from "../Order/OrderSent";
import { NavbarBackgroundColor } from "../Styles/colors";
import { NavLyric } from "../Styles/colors";
import {T} from "../Language/translate";
import { OrderBtn } from "../Styles/colors";
import '../app.css';


//firestore
const db = window.firebase.firestore();
//realTime
const database = window.firebase.database();

const NavbarStyled = styled.nav`
background-color: ${NavbarBackgroundColor};
box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
right:0px;
position: fixed;
padding-bottom:19px;
width: 340px;
z-index:20;
display: grid;
grid-template-columns: 1fr 3fr ;
justify-content: space-between;
@media(max-width: 700px){ 
    height:70px;
    width: 100%; 
    padding: 10px;
   
  }
`;

const Logo = styled(Title)`
font-size: 18px;
white-space: nowrap;
margin-left:2px;
color : ${NavLyric};
text-shadow: 1px 1px 1px 1px #380502;
@media(max-width: 1400px){ 
    font-size: 16px;
 }
@media(max-width: 400px){ 
    font-size: 14px;
 }
`

const UserStatus = styled.div`
display: grid;
grid-template-columns: auto auto auto;
color:white;
font-size: 16px;

@media(max-width: 700px){ 
    grid-template-columns: auto auto auto;
    margin-right: 50px;
  
  }

`;

const LoginButton = styled.span`
color:${NavLyric};
font-weight:800;
font-size:14px;
white-space: nowrap;
text-decoration:none;
min-width: 30px;
cursor:pointer;
border-radius:5px;
margin-left: 10px ; 
transition-property: box-shadow margin-bottom ;
transition-duration: .2s;
&:hover{
    cursor: pointer;
    transform: scale(1.005);
    color:white;
    font-weight:bold;
    opacity:0.9;
   
    

}
@media(max-width: 366px){ 
   font-size:12px;
   min-width: 15px;
}

`;
const Btn = styled.div`
margin:0px;
text-decoration:none;
cursor:pointer;
color: black;
padding: 3px;
&:hover{
   color : #42A5F5;

}

`;
const DropDown = styled.div`
 position : relative;
 display: inline-block;

`;
const DropDownContent = styled.div`
position: fixed;
margin-right:15px;
background-color: #f1f1f1;
right:1px;
z-index: 59;
min-width: 160px;
border-radius: 7px; 
box-shadow: 4px 0px 5px 4px grey;
display: flex;
flex-direction: column;
@media(max-width: 700px){ 
    top:60px;
    right:60px;
}

`;
const DropDownBtn = styled.div`
 height: 40px;
 width: 40px;
 
 margin-left:10px;
 background-image:url("/img/user.png");
 background-position: center;
 background-repeat: no-repeat;
 background-size: cover;
 
 border: none; 

 cursor: pointer;
 @media(max-width: 400px){ 
    height: 30px;
     width:30px;
 }

`;
const Orders = styled.div`
 height: 40px;
 width: 40px;
 margin-right:10px;
 background-image:url("/img/list.png");
 background-position: center;
 background-repeat: no-repeat;
 background-size: cover;
 margin-left:20px;
 border: none; 

 cursor: pointer;
 @media(max-width: 400px){ 
    height: 30px;
     width:30px;
 }
 
`;
const OrderNotif = styled.div`
height: 20px;
 width: 20px;
 border: 0.05rem solid white;
 background-color:${OrderBtn};
 border-radius:50%;
 justify-content:center;
 display: flex;
align-items: center;
 text-align: center;
 @media(max-width: 400px){ 
    height: 10px;
     width:10px;
 }
`;

const Div = styled.div`
@media(max-width: 700px){ 
    display:none
   

}
`;
const Num = styled.div`
position:fixed;
margin:auto;
@media(max-width: 550px){ 
 font-size:10px;
 }
`;
const DialogShadow = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
 top:0;
 left:0;
  background-color: black;
  opacity: 0.7;
  z-index: 58;
`;
const GuestContainer = styled.div`
display: flex; 
flex-direction:column;
`;
const ref = database.ref('orders');
var session = window.sessionStorage;

export function Navbar({loginWithGoogle,loginWithEmail,signUpWithEmail, loggedIn,
     logout,setOpenUser,setProfile,profile,orders,setOrders,signedIn,message,setMessage,
     resetPassWord,logInPanel,setLogInPanel,signUpPanel,setSignUpPanel,setDeliveryFee,deliveryFee,show,setShow,setBannerUser,lang,
    supportPanel,setSupportPanel,setIsGuest,admins}){
    var i=0;
    const[x, setX] = useState();  
   const[title,setTitle] = useState();
    const auth = window.firebase.auth();
    const isAdmin = loggedIn&& admins.some(u=>u.email ===loggedIn.email&&u.role==="admin");
    useEffect(()=>{
        
        db.collection('themes').doc("Standard").onSnapshot((doc)=>{return  setTitle(doc.data().resto)});
        auth.onAuthStateChanged(function(user){
            i=0;
            ref.orderByChild('email').on('child_added', (snapshot) => {
             
                if(user && user.email == snapshot.val().email)
                {
                    if(snapshot.val().status != "ready"){
                        setX(i=i+1) ; 
                    }
                     
            }
                else {
                   //disconnected
                }
          })
          ref.orderByChild('email').on('child_removed', (snapshot) => {
         
            if(user && user.email == snapshot.val().email)
            {
                setX(i=i-1) ; 
         
            }
            else {
                //disconnected
            }
      })

      if((session.getItem("GuestOk")&&loggedIn==null)){
        ref.orderByChild('phone').on('child_added', (snapshot) => {
             
            if(session.getItem("phone") == snapshot.val().phone)
            {
                if(snapshot.val().status != "ready"){
                    setX(i=i+1) ; 
                }
                 
        }
            else {
               //disconnected
            }
      })
      ref.orderByChild('phone').on('child_removed', (snapshot) => {
     
        if(session.getItem("phone") == snapshot.val().phone)
        {
            setX(i=i-1) ; 
     
        }
        else {
            //disconnected
        }
  })
      }

      db.collection('users').doc(user.email).get().then((X)=>{
        
        db.collection('zip').doc(String(X.data().zip?X.data().zip:X.data().zipCode)).get().then((Z)=>{  setDeliveryFee(Z.data().price?Z.data().price:0)
      
        })
    })
  
     }, function(error){

      console.log(error);
     });
    
   
     ref.off('value');
       
      
},[]
 )

    const[toggel,setToggel] = useState(false); 
    const[resetPanel,setResetPanel] = useState(false); 
    return<> 
    
    
{logInPanel ? <LogIn logInPanel={logInPanel} message={message} signedIn={signedIn} loginWithGoogle={loginWithGoogle} loginWithEmail={loginWithEmail} setResetPanel={setResetPanel} setLogInPanel={setLogInPanel} lang={lang}/> :""}

{signUpPanel ? <SignUp signedIn={signedIn}  message={message} loginWithGoogle={loginWithGoogle} signUpWithEmail={signUpWithEmail} setSignUpPanel={setSignUpPanel} lang={lang} /> : ""}
   
   
    <NavbarStyled className="navbar navbar-expand-lg navbar-light sticky-top">
        {show!=null ? <OrderSent show={show} setShow={setShow} loggedIn={loggedIn} setOrders={setOrders} lang={lang}/> : "" }
       
        {resetPanel ? <ResetPassWord setResetPanel={setResetPanel} resetPassWord={resetPassWord} lang={lang}/>: ""}
             <Logo>{title}</Logo>
             <UserStatus>
                {loggedIn !== 'loading' ? (
                 <>
                 {deliveryFee ? "" :""}
                {loggedIn  && <Div /> } 
                 {(loggedIn ||(session.getItem("GuestOk")&&loggedIn==null)) &&
                  <Orders onClick={()=>{loggedIn? setOrders(loggedIn.email):(session.getItem("GuestOk")&&loggedIn==null)?setOrders(String(session.getItem("phone"))):setOrders()}}>
                    <OrderNotif><Num>{!x?"0":x}</Num>
                    </OrderNotif>
                  </Orders> }
                 
                
                 {  (loggedIn  && (db.collection('users').doc(loggedIn.email).get().then((doc)=>{ return !doc.exists? setOpenUser(loggedIn.displayName): doc.data().status ? setOpenUser() : setBannerUser(loggedIn.displayName)  }))) &&<></> }
               
                 {loggedIn ? <DropDown>
                 {loggedIn ? <DropDownBtn onClick={()=>{!toggel? setToggel(true):setToggel(false);
                }}></DropDownBtn>:""}
                
                 {toggel ?<><DialogShadow onClick={()=>setToggel(false)}/><DropDownContent>

                    <Btn  onClick={()=>{!profile? setProfile(loggedIn.displayName):setProfile();setToggel(false);}}>{T("29",lang)}</Btn>

                    <Btn onClick={()=>{!supportPanel? setSupportPanel(loggedIn.displayName):setSupportPanel();setToggel(false);}}>{T("30",lang)}</Btn>

                 {loggedIn &&isAdmin && 
                 <Link onClick={()=>setToggel(false)} className="panelBtn" to='AdminPanel/orders' target="_blank">{T("31",lang)}</Link>}
                 <Btn> <span onClick={function(event){logout(); setToggel(false);}}>{T("32",lang)} </span></Btn>
                 </DropDownContent></>: ""}
                 </DropDown> : ""}

                      <>
                 {(session.getItem("GuestOk")&&loggedIn==null) ?<GuestContainer>
                 {!loggedIn  && <LoginButton style={lang ==="Hu"? {"marginRight":"1rem"}:{"":""}}>   <span onClick={()=>{!logInPanel? setLogInPanel(true):setLogInPanel(false);setIsGuest(false);setMessage("");}}>  {T("90",lang)}</span></LoginButton>  }
                {!loggedIn  && <div />}
                {!loggedIn  && <LoginButton style={{"marginRight":"1rem","marginTop":".1rem"}}>   <span onClick={()=>{!signUpPanel? setSignUpPanel(true):setSignUpPanel(false);setIsGuest(false);setMessage("");}}> {T("95",lang)}</span></LoginButton>  }
                 </GuestContainer> :
                 <>
                 {!loggedIn  &&<>
                   <LoginButton style={lang ==="Hu"? {"marginLeft":"auto","marginRight":"auto"}:{"marginLeft":"auto","marginRight":"auto"}}>   <span onClick={()=>{!logInPanel? setLogInPanel(true):setLogInPanel(false);setIsGuest(false);setMessage("");}}>  {T("90",lang)}</span></LoginButton>  
                 <div style={{"marginLeft":"auto","marginRight":"auto"}}>|</div>
                  <LoginButton style={{"marginLeft":"auto","marginRight":"auto"}}>   <span onClick={()=>{!signUpPanel? setSignUpPanel(true):setSignUpPanel(false);setIsGuest(false);setMessage("");}}> {T("95",lang)}</span></LoginButton>  
               </> }
                 </>}  
               
                </>
                 </>
                 ): ( "Loading...")
                 }
             </UserStatus>
    </NavbarStyled>
    </>;
}