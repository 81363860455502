import React from "react";


 function Ticket(){

    return(
        <div>
            Hello Ticket
        </div>
    )
}
export default Ticket