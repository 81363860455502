import { useState } from "react";

//initialize the hook 
export function useLimit(){
    const[limitCode,setLimitCode] = useState(); 
  
    return {
        limitCode,
        setLimitCode,
       
    }
}