import React from "react";
import styled from "styled-components";
import useForm from "../Hooks/useForm";
import { useState, useEffect } from "react";
import {T} from "../Language/translate";
const Dialog = styled.div`
position: fixed;
  width: auto;
  height:auto;
  background-color: white;
  margin-bottom:50px;
 
  z-index: 55;
margin:auto;
padding:10px;
  display: flex;
  flex-direction: column;
  border-radius:5px;
  // Add this part
  @media (max-width: 400px) {
    
    overflow-y: scroll;
    height:auto;
    width: auto;
 
    
  }
`;
const Wrapper = styled.div`
z-index: 57 !important;
position:fixed;
width:100%;
height:98%;
left:0;
bottom:0;
  display: flex;
  justify-content: center;
  align-items: center;

`;
const DialogShadow = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  left:0px;
  background-color: black;
  opacity: 0.7;
  z-index: 11;
`;
const Close = styled.div`
height: 30px;
width: 30px;
display :flex;
align-items:center;
justify-element:center;
position:absolute;
background-image:url("/img/close.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border: none; 
margin:10px;
top:0;
right:0;
font-size: 18px;
font-weight:bold;
 &:hover{
    cursor: pointer;
    opacity:0.7;
 }
`;
const Header = styled.div`
margin-top: 10px;
display:flex;
text-align:center;
align-items: center;
justify-content: center;

`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin:10px;
  
`;

const X = styled.div`
margin-left:35px;

`;
const Notice = styled.span`
  text-align:center;
  margin-left:20px;
  color: red;
  font-size: 14px;
`;
const Container = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
`;
const ButtonStyled = styled.button`
max-height:40px;
text-decoration: none;
margin: 10px;
&:hover{
    
  
}
`;
const Google = styled.div`
 height: 50px;
 width: 50px;
 margin-left:10px;
 margin-top:10px;
 background-image:url("/img/Google.png");
 background-position: center;
 background-repeat: no-repeat;
 background-size: cover;
 border: none; 
 cursor:pointer;
`;

// function for required fields 
function isRequired(value) {
    return value != null && value.toString().trim().length > 0;
  }
  function ValidateEmail(value){
    return String(value)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }
export function ResetPassWord({setResetPanel,message,resetPassWord,prop,lang}){
    const [emailErr,setEmailErr] = useState("");
    const [err,setError] = useState("");
    //Form validation 
 const initialState = { email:"",password:""};
 const validations = [
   ({email}) => isRequired(email) || {email: T("Email szükséges",lang)},
   ({email})=> ValidateEmail(email)!=null || {email:T("Hibás e-mail formátum",lang)}
 ];

 const {values, isValid, errors, changeHandler} = useForm(initialState, validations, prop);

 function Reset(){
    setEmailErr("");
    setError("");
    resetPassWord(values.email);
    setResetPanel(false);

 }

 function showError(){
    if(!(errors.email )){
        setError(T("Email szükséges",lang));
    }
    console.log('called show error')
       setEmailErr(errors.email);
       
    
 }

    return(
        <>
        <DialogShadow />
        <Wrapper>
        <Dialog >
          
        <Close onClick={()=>{setResetPanel(false)}}/>
         <Header>{T("99",lang)}</Header>
         <Container>
         <Notice>{!(errors.email || errors.password) ? err : emailErr}</Notice>
  <InputContainer className="form-group">
      
     
      <input type="email" className="form-control" name="email"  placeholder={T("35",lang)}    value={values.text} onChange={changeHandler} />
     
     
   </InputContainer> 
 
  
   <ButtonStyled  className="btn btn-primary"  onClick={()=>{!isValid ?showError():Reset()}} >
   {T("100",lang)}
         </ButtonStyled>
         { (isValid && message) && <Notice>{message}</Notice>} 
   </Container>
        </Dialog>
        </Wrapper>
        </>
    )
}