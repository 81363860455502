import React from 'react';
import styled from "styled-components";
import Users from './Components/Users';
import Items from './Components/Items';
import { useState } from "react";
import { useEffect } from "react";
import { Chart } from "react-google-charts";
import moment from "moment";
const Container = styled.div`
display : flex ;
position:fixed;
overflow-y:scroll;
flex-direction:row;
top:10px;
right:0;
width:${(props) => props.width};
font-size : 20px;
@media screen and (max-width: 600px) {
  top:80px;
  flex-direction:column;
  height:500px;
}
`;


function Performance({active}) {
 

  return (
    <Container width={ active ? "calc(100% - 220px)" : "calc(100% - 40px)"}>
    <Users />
    <Items />
    </Container>
  )
}

export default Performance
