import { useEffect, useState } from "react";

const auth = window.firebase.auth();
const provider = new window.firebase.auth.GoogleAuthProvider();
var session = window.sessionStorage;

export function useAuthentication(){
  const db = window.firebase.firestore();
    const [authenticated, setAuthenticated] = useState('loading');
    const [message ,setMessage] = useState("");
    const [signedIn,setSignedIn] = useState();
    function loginWithGoogle(){
        session.clear();
        const isWebView = /(android)|(iphone|ipod|ipad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent);

if (isWebView) {
  auth.signInWithRedirect(provider);
} else {
  auth.signInWithPopup(provider);
}
        
    }
    function loginWithEmail(email,password){
      session.clear();
        auth.signInWithEmailAndPassword(email, password).then((userCredential) => {
            // Signed in 
           setSignedIn(true);
            // ...
          })
          .catch((error) => {
            setSignedIn(false);
            setMessage(error.message);
           
          }
         
          );
    }

    function resetPassWord(email){
        auth.sendPasswordResetEmail(email)
        .then(() => {
          // Password reset email sent!
          
        })
        .catch((error) => {
          var errorMessage = error.message;
          setMessage(errorMessage);
      
          
          // ..
        });
    }
    function signUpWithEmail(email,password,displayName){
        session.clear();
        var user= null;
        auth.createUserWithEmailAndPassword(email, password)
        .then(function() {
            user = auth.currentUser;
             // Signed in 
             setSignedIn(true);
          // ...
        }).then(function () {
            user.updateProfile({
              displayName: displayName
           
            });
          })
        .catch((error) => {
      
          var errorMessage = error.message;
          setMessage(errorMessage);
        
          // ..
        });
    }
    function logout(){
        auth.signOut().then(function(){
            // Sign-out successful.
            setSignedIn(false);
        }).catch(function(error){
            //an error happened
        })
    }
      useEffect(()=>{
        auth.onAuthStateChanged(function(user){
               if(user){
                setAuthenticated(user);
               
           
               }
               else {
                setAuthenticated();
               }
        }, function(error){

         //error 
        });
      }, []);
        
    
    return {loginWithEmail,loginWithGoogle,logout,signUpWithEmail,resetPassWord, loggedIn:authenticated,message,signedIn,setMessage};
}