import React from "react";
import styled from "styled-components";
import { useState } from "react";
import { useEffect } from "react";
import ToggleButton from "react-toggle-button";
import OrdersSumWrapper from "./OrdersSumWrapper";
var _ = require('lodash');
const Container = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
  font-size: 26px;
  font-family: "Inter", sans-serif;
  margin:5px;
  position:fixed;
  overflow-y:scroll;
  width:${(props) => props.width};
  top:10px;
  right:0;
  height:80%;
  font-size: 20px;
  align-content: center;
`;
const AccountsDiv = styled.div`
  
  width: 90%;
  height: auto;
  border: solid 1px;
  border-color: gray;
  border-radius: 10px;
  margin: 30px auto 30px auto;
  @media screen and (max-width: 1200px) {
    width: 80%;
  }
  @media screen and (max-width: 1050px) {
    width: 90%;
    margin-top:40px;
    margin-left: 10px;
  }
`;
const AccountsRows = styled.div`
  
  display: flex;
  flex-direction: column;
`;
const AccountGrid = styled.div`

  border: solid 1px;
  border-color: gray;
  border-radius: 10px;
  display: grid;
  justify-content: center;
 
  height: 220px;
  grid-template-columns:auto;
  column-gap: 10%;
  margin: 10px;
  flex-wrap: wrap;
 
 
  

`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  width:300px;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  margin-left:10px;
  margin-right:10px;
  @media screen and (max-width: 470px) {
    text-align:left;
  }
  
`;
const Search = styled.input`
width: 300px;
margin-left:10px;
border-radius:7px;
border-color:gray;
`;
const SearchContainer = styled.div`
display:block;
margin:5px auto 5px auto;
`;
const CWrapper = styled.div`
width:50%;
display:flex;
flex-direction:column;
@media screen and (max-width: 840px) {
 
  flex-direction:column;
}

`;
const RWrapper = styled.div`
width:50%;
display:flex;
flex-direction:row;
@media screen and (max-width: 840px) {
 
  flex-direction:column;
}

`;
const db = window.firebase.firestore();

function Accounts({active}) {
  const [data, setData] = useState();
  const [search, setSearch] = useState();
  const isUsersEmpty = data && data.length === 0;
  const canShowUsers = data && true;
  useEffect(() => {
    
    db.collection("users")
      .onSnapshot((data) => {
        return setData(data.docs);
      });
    
    
      
    
  }, []);

   function searchUser(x){
    console.log(x.length)
   
    if(x.length!=0){
    db.collection('users').
    where("name", ">=", x.charAt(0).toUpperCase() + x.slice(1).toLowerCase()).
    where("name", "<=", x.charAt(0).toUpperCase() + x.slice(1).toLowerCase()+ "\uf8ff").get().then((data)=>{return  setData(data.docs)});
    
    }else{
      db.collection("users")
      .get().then((data) => {
        return setData(data.docs);
      });
    }
 
}
  function ToggleUser(id,state) {

    const docRef = db.collection("users").doc(id);
  
 
    docRef.update({
     status: !state
   
    });
    db.collection("users")
    .get()
    .then((data) => {
      return setData(data.docs);
    });

  console.log("updated :=)")
 
  }


  return (
    <Container  width={ active ? "calc(100% - 220px)" : "calc(100% - 40px)"}>
      <AccountsDiv>
      <SearchContainer>
          <label >Search</label>
          <Search type="text" name="search" 
          value={search}
         
          onChange={(e)=>{searchUser(e.target.value);setSearch(e.target.value);}}
          />
          </SearchContainer>
        <AccountsRows>
      
        
         
          {isUsersEmpty && "Loading ..."}
          {canShowUsers
            ? data.sort((a,b)=> b.data().points - a.data().points).map((item,index) => {
                return (
                  <>
                    <AccountGrid>
                    <Item>{"#"+Number(index+1)}</Item>
                  
                      <CWrapper>
                      <Item>{item.data().name}</Item>
                      <Item>{item.data().email}</Item>
                      <Item>{item.data().phone}</Item>
                       <Item>{Math.floor(item.data().points)+" 💎"}</Item>
                      <OrdersSumWrapper item={item} />

                      </CWrapper>
                     
                     <Item>
                      <ToggleButton
                        name="test"
                        value={item.data().status}
                        onToggle={()=>ToggleUser(item.data().email,item.data().status)}
                        
                        colors={{
                          active: {
                            base: "#1578F1",
                          },
                          inactive: {
                            base: "gray",
                          },
                        }}
                      />
                      </Item>
                    </AccountGrid>
                  </>
                );
              })
            : "Loading ..."}
        </AccountsRows>
      </AccountsDiv>
    </Container>
  );
}

export default Accounts;
