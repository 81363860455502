import React from "react";
import styled from "styled-components";
import { useState, useEffect } from "react";
import useForm from "../../../Hooks/useForm";
const Dialog = styled.div`
position: fixed;
  width: auto;
  height:auto;
  background-color: white;
  margin-bottom:50px;
 
  z-index: 55;
margin:auto;
padding:10px;
  display: flex;
  flex-direction: column;
  border-radius:5px;
  // Add this part
  @media (max-width: 400px) {
    
    overflow-y: scroll;
    height:auto;
    width: auto;
 
    
  }
`;

 const Wrapper = styled.div`
z-index: 55;
position:fixed;
width:100%;
height:100%;
left:0;
bottom:0;
  display: flex;
  justify-content: center;
  align-items: center;

`;


const ButtonStyled = styled.button`
max-height:40px;
text-decoration: none;
margin: 10px;
&:hover{
  opacity: 1.2;
  padding-top: 3px;
}
`;
const DialogShadow = styled.div`
position: fixed;
height: 100%;
width: 100%;
top: 0px;
left:0px;
background-color: black;
opacity: 0.7;
z-index: 11;
`;
const DialogHeader = styled.div`
padding: 10px;
display: flex;
flex-direction: row;
gap: 20%;
media screen and (max-width: 1000px) {
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 40%;
}
@media screen and (max-width: 800px) {
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 30%;
}
@media screen and (max-width: 400px) {
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 10%;
}
`;


const Header = styled.div`
display:flex;
flex-direction: column;`;
const H2 = styled.div`
font-size: 30px;
font-weight: bold;
display: flex;
flex-direction: row;
white-space: nowrap;
`;


const InputContainer = styled.div`
  display: grid;
  grid-template-columns: .8fr 1fr  ;
  margin:5px;
  
`;
const ButtonContainer = styled.div`
display:flex;
flex-direction:row;
@media screen and (max-width: 995px) {
 
  margin-top:20px;
}
@media screen and (max-width: 700px) {
  flex-direction:column;
      
  }
`;
const Bar = styled.hr`
border-top: 3px solid #bbb;
margin: 5px;
width:90%;
@media screen and (max-width: 500px) {
 
  width:300px;
}
`;

const Input = styled.textarea`
resize: none;
width:400px;
height:60px;
`;
const Percent = styled.div`
font-size:14px;
display:flex;
white-space:nowrap;
flex-direction:row;
justify-items:center;
align-content:center;
margin-left:15px;
`;

const Label = styled.label`
font-size:14px;
white-space:nowrap;
`;
const Notice = styled.span`
white-space: nowrap;
  margin-left:20px;
  color: red;
  font-size: 14px;
`;
const SimpInput = styled.input`
height:50px !important;`;
const UpButton = styled.button`
height:40px;`;
// function for required fields 
function isRequired(value) {
    return value != null && value.trim().length > 0;
  }
function EditInfoContainer({ openInfo, prop,setInfos,setOpenInfo}) {
    const storage = window.firebase.storage();
    const [name,setName]=useState(openInfo.name);  
    const [faceErr, setFaceErr] = useState();
    const [instaErr, setInstaErr] = useState();
    const [copy1, setCopy1] = useState(openInfo.copy1);
    const [copy2, setCopy2] = useState(openInfo.copy2);
    const [copy3, setCopy3] = useState(openInfo.copy3);
    const [file1,setFile1] = useState();
    const [percent1, setPercent1] = useState(0);

    const [file2,setFile2] = useState();
    const [percent2, setPercent2] = useState(0);

    const [file3,setFile3] = useState();
    const [percent3, setPercent3] = useState(0);


    function handleSubmit() {
      
    
        const db = window.firebase.firestore();
        const docRef = db.collection("footer").doc(openInfo.name.toLowerCase());
        try {
    
          docRef.update({
            name: values.name,
            facebook:values.facebook,
            instagram:values.instagram,
            copy1:copy1,
            copy2:copy2,
            copy3:copy3
          
          });
          console.log("submited :=) "+name)
        } catch (e) {
          console.log(e);
        }
    
        db.collection('footer').get().then((data) => { return setInfos(data.docs) });
    
    
        setOpenInfo();
    
    
    
      }


//upload picture 
const handleUpload1 = (event) => {
    event.preventDefault();
    if (!file1) {
      alert("Please upload an image first!");

    }
  
    else {

      console.log("after check")
      var storageRef = storage.ref(`/Copyright/${file1.name}`);

      console.log("after reference")

      // 'file' comes from the Blob or File API
      storageRef.put(file1).then((snapshot) => {
        console.log('inside upload');
        const percent1 = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        // update progress
        setPercent1(percent1);
      }, (err) => console.log(err)

      );
     setCopy1(file1.name);
    }
  };
// Handle file upload event and update state
function handleChange1(event) {
    setFile1(event.target.files[0]);
 
}




//upload picture 
const handleUpload2 = (event) => {
    event.preventDefault();
    if (!file2) {
      alert("Please upload an image first!");

    }
  
    else {

      console.log("after check")
      var storageRef = storage.ref(`/Copyright/${file2.name}`);

      console.log("after reference")

      // 'file' comes from the Blob or File API
      storageRef.put(file2).then((snapshot) => {
        console.log('inside upload');
        const percent2 = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        // update progress
        setPercent2(percent2);
      }, (err) => console.log(err)

      );
     setCopy2(file2.name);
    }
  };
// Handle file upload event and update state
function handleChange2(event) {
    setFile2(event.target.files[0]);
 
}


//upload picture 
const handleUpload3 = (event) => {
    event.preventDefault();
    if (!file3) {
      alert("Please upload an image first!");

    }
  
    else {

      console.log("after check")
      var storageRef = storage.ref(`/Copyright/${file3.name}`);

      console.log("after reference")

      // 'file' comes from the Blob or File API
      storageRef.put(file3).then((snapshot) => {
        console.log('inside upload');
        const percent3 = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        // update progress
        setPercent3(percent3);
      }, (err) => console.log(err)

      );
     setCopy3(file3.name);
    }
  };
// Handle file upload event and update state
function handleChange3(event) {
    setFile3(event.target.files[0]);
 
}



        //Form validation 
    const initialState = { name: openInfo.name , facebook:openInfo.facebook, instagram:openInfo.instagram };
    const validations = [
      ({ facebook }) => isRequired(facebook) || { facebook: 'Facebook link is required' },
      ({ instagram }) => isRequired(instagram) || { instagram: 'Instagram link is required' }
      
       
   
    ];
  
    const { values, isValid, errors, changeHandler } = useForm(initialState, validations, prop);

    function showError(){
      console.log('show error')
         setFaceErr(errors.facebook);
         setInstaErr(errors.instagram)
        
      
   }
   
    return(
        <>
         <DialogShadow />
         <Wrapper>
      <Dialog>
     
          <DialogHeader>
            <Header>
              <H2> {openInfo.name}</H2>
           
            </Header>
            <ButtonContainer>
              <ButtonStyled className="btn btn-primary" onClick={()=>{handleSubmit()}}>
                Save
              </ButtonStyled>
              <ButtonStyled
                className="btn btn-light"
                onClick={() =>setOpenInfo() }
              >
                Cancel
              </ButtonStyled>
         
       
            </ButtonContainer>
          </DialogHeader>
          <Bar />
          <InputContainer className="form-group">
            <Label for="name" className=" control-label">Name  </Label>
           
              <input disabled="true" type="text" className="form-control" name="name" placeholder="Name"
                value={values.name}
                onBlur={changeHandler}
                onChange={changeHandler}
              />
       

        
          </InputContainer>
          {<Notice>{faceErr}</Notice>}
          <InputContainer className="form-group">
            <Label  className="control-label">Facebook </Label>
           
              <Input type="text" className="form-control" name="facebook" placeholder="Facebook Link"
                value={values.facebook}
                onBlur={changeHandler}
                onChange={changeHandler}
              />
       

    

          </InputContainer>
          {<Notice>{instaErr}</Notice>}
          <InputContainer className="form-group">
            <Label  className=" control-label">Instagram</Label>
            
              <Input  type="text" className="form-control" name="instagram" placeholder="Instagram Link"
                value={values.instagram}
                onBlur={changeHandler}
                onChange={changeHandler}
              />
      

         

          </InputContainer>
          <InputContainer className="form-group">
            <Label  className=" control-label">File 1</Label>
            
            <SimpInput disabled="true" type="text" className="form-control" n placeholder=""
                value={copy1}
               
              />
      
  
              <input type="file" onChange={handleChange1} accept="" className="form-control" />
           
            <UpButton className="btn btn-primary" onClick={handleUpload1}>Upload </UpButton>
            <Percent>{percent1} "%"</Percent>
         

          </InputContainer>



        
          <InputContainer className="form-group">
            <Label  className=" control-label">File 2</Label>
            
            <SimpInput disabled="true" type="text" className="form-control"  placeholder=""
                value={copy2}
               
              />
      
     
              <input type="file" onChange={handleChange2} accept="" className="form-control" />
           
            <UpButton className="btn btn-primary" onClick={handleUpload2}>Upload </UpButton>
            <Percent>{percent2} "%"</Percent>
         

          </InputContainer>


          <InputContainer className="form-group">
            <Label  className="control-label">File 3</Label>
            
            <SimpInput disabled="true" type="text" className="form-control"  placeholder=""
                value={copy3}
               
              />
      
      
              <input type="file" onChange={handleChange3} accept="" className="form-control" />
          
            <UpButton className="btn btn-primary" onClick={handleUpload3}>Upload </UpButton>
            <Percent>{percent3} "%"</Percent>
         

          </InputContainer>

          </Dialog>
        
          </Wrapper>
        </>
    )

}

export function EditInfo(props){
    if (!props.openInfo) return null;
    return <EditInfoContainer {...props}/>
}