import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";

export default function CheckoutForm({sendOrder,orders,loggedIn,total,limitCode,updatePoints,updateLimit,setBasket}) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

   await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: "https://example.com",
    },
        redirect: 'if_required' 
 
    }).then((result)=> {
    
      if (result.paymentIntent) {
        if(result.paymentIntent.status =="succeeded"){
          console.log("successeful payment");
          sendOrder(orders, loggedIn,total);
     
          if(loggedIn)  updatePoints();
           
            if(limitCode) updateLimit(limitCode);
        
            setBasket("none");
   
        }
      
      }
      if (result.error){
            console.log(result.error.message);
            setMessage(result.error.message);
      }
    });;
    
 

    setIsProcessing(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      {message && <div style={{"color":"red"}}>{message}</div>}
      <button disabled={isProcessing || !stripe || !elements} id="submit" className="btn btn-primary" style={{"marginTop":"10px"}}>
        <span id="button-text">
          {isProcessing ? "Aut en cour ... " : "Payer " +total+" €"}
        </span>
      </button>
      {/* Show any error or success messages */}
     
    </form>
  );
}