import styled from "styled-components";
import { Ticker } from "./Ticker";
import { BannerImage } from "../Styles/images";
import { LogoImage } from "../Styles/images";
import Truck from "./Animation/Truck";
import { Id } from '../ProjectId';
import { Token } from '../StorageToken'; 
const Container = styled.div`
position: relative;
display : flex;
flex-direction:column;
top:0px;
height:278px;
width:calc(100% - 280px);
text-align: center;
margin-bottom:0px;
perspective: 2px; 
@media(max-width: 1115px){ 
  height:240px;
  width:calc(100% - 340px);
  
}
@media(max-width: 1000px){ 
  height:220px;
  width:calc(100% - 340px);
  

@media(max-width: 700px){ 
  height:220px;
  width:100% ;
  
}
@media(max-width: 850px){ 
  height:200px;
  width:100% ;
  
}
@media(max-width: 550px){ 
  height:170px;
  width:100% ;
  
}
@media(max-width: 340px){ 
  height:150px;
  width:100% ;
  
}
@media(max-width: 300px){ 
  height:140px;
  width:100% ;
  
}

`;

 const Banner = styled.div`
 position: relative;
 top:55px;
height: 100%;
width :100%;
background-image: url("https://firebasestorage.googleapis.com/v0/b/${Id}.appspot.com/o/${"Banner"}%2F${BannerImage}?alt=media&token=${Token}");
background-position:0px 0px;
background-repeat: no-repeat;
background-size: cover;
@media(max-width: 1200px){ 
  background-size: contain;
  
}
@media(max-width: 1000px){ 
  top:70px;
  
}

@media(max-width: 700px){ 
  top:70px;
  
}
@media(max-width: 550px){ 
  top:70px;
  background-size: contain;
}

`;

/*export const BannerLogo = styled.div`
position:fixed;
display:flex;
top:30%;
left:5%;
z-index: 19;
height: 120px;
width: 120px;
background-position: center;
background-size: cover;
background-image:  url("https://firebasestorage.googleapis.com/v0/b/${Id}.appspot.com/o/${"Logos"}%2F${LogoImage}?alt=media&token=${Token}");
@media(max-width: 1000px){ 
height: 90px;
width: 90px; 
  top: 100px;
}
@media(max-width: 850px){ 
  height: 70px;
width: 70px; 
  top: 100px;
}

@media(max-width: 700px){
  height: 70px;
width: 70px; 
  top: 120px;
}

`*/
const Div = styled.div`
width:100%;
position:fixed;
height:100px;
background-color:white;
bottom:0;
`;

export function BannerBar(){

    return (
    
        <Container>
        
        <Banner />
       {/* <BannerLogo />*/} 
            {/*<Truck/>*/}
          
      
      
         </Container>
      
        
      
    )
}