import React from "react";
import styled from "styled-components";
import { Bar } from "../Items/Items";
import { useState, useEffect } from "react";
import useForm from "../Hooks/useForm";
import {  DialogShadow,Label,DialogHeader,H2,ButtonStyled,InputContainer ,Dialog,Wrapper} from "../Items/AddItem";
import { T } from "../../../../../Language/translate";
import { Close } from "../../../Orders/OrderDetails";
 const ButtonContainer = styled.div`
 display:flex;
 flex-direction:row;
 margin:auto;
 @media screen and (max-width: 995px) {
    
     margin-top:20px;
     margin-left:auto;
     margin-right:auto;
 }
 @media screen and (max-width: 700px) {
     flex-direction:column;
         
     }
`;

const Notice = styled.span`
white-space: nowrap;
  margin-left:20px;
  color: red;
  font-size: 14px;
`;
const Message = styled.span`
white-space: nowrap;
  margin-left:20px;
  color: green;
  font-size: 14px;
`;
// function for required fields 
function isRequired(value) {
  return value != null && value.trim().length > 0;
}
function ValidatePath(value){
  return String(value)
    .toLowerCase()
    .match(
      /^[a-zA-Z]+$/
    );
}
export function CreateCategory({ openPanel, setOpenPanel,prop,setData,lang }) {
//use state for unique name 
const [nameUnique,setNameUnique] = useState(true);
const [languages , setLanguages] = useState()
const [index , setIndex] = useState();
//db
const db = window.firebase.firestore();
useEffect(()=>{ 
  db.collection('Category').get().then((data)=>{return  setIndex(data.docs.length.toString())});
  db.collection('Language').get().then((data) => { return setLanguages(data.docs[0].data()) });
  
},[]);
//Form validation 
const initialState = { huName: '',enName:'',path: ''};
const validations = [
  ({huName}) => isRequired(huName) || {huName: 'Name is required'},
  ()=> nameUnique || {huName: 'This Category already exist '},
  ({path}) => isRequired(path) || {path: 'Path is required'},
  ({path})=> ValidatePath(path)!=null || {path:'only english alphabet allowed'}
];
const {values, isValid, errors, changeHandler} = useForm(initialState, validations, prop);

if(values.huName !="") db.collection('Category').doc(values.huName).get().then((doc)=>{ return doc.exists? setNameUnique(false) : setNameUnique(true) })

function handleSubmit() {
  
  const docRef = db.collection("Category").doc(values.huName);
  try {
 
    docRef.set({
     name: values.huName,
     path: values.path,
     langIndex:Object. keys(languages.En).length,
     index
    });
    
  } catch (e) {
  //handel error
  }
  const docRef2 = db.collection("Language").doc("lang");
  docRef2.update({
    "En":{
      ...languages.En,
      [Object. keys(languages.En).length]: values.enName
    }})
    docRef2.update({
      "Hu":{
        ...languages.Hu,
        [Object. keys(languages.En).length]: values.huName
      }})
db.collection('Category').get().then((data)=>{return  setData(data.docs)});
setOpenPanel(false);

}
const [Err, setError] = useState("");
const [nameErr,setNameErr] = useState("");
const [pathErr, setPathErr] = useState("");
function showError(){
 if(!(errors.huName || errors.path )){
     setError("name  and path are required");
 }
    setNameErr(errors.huName);
  
    setPathErr(errors.path);
}
    return (
        <>
         <DialogShadow />
         <Wrapper>
      <Dialog>
       <Close    onClick={() =>
                  openPanel ? setOpenPanel(false) : setOpenPanel(true)
                }/>
          <DialogHeader>
            <H2>{T(204,lang)}</H2>
        
          </DialogHeader>
          { !(errors.name || errors.prath)  &&<Notice>{Err}</Notice>}
          <Bar />
      
          <InputContainer className="form-group">
      <Label className=" control-label">{"En "+T(168,lang)} </Label>
      <input type="text" className="form-control" name="enName"  placeholder="Nom en Anglais"
        value={values.name}
        onBlur={changeHandler}
        onChange={changeHandler}
      
      />
       <Notice>{nameErr}</Notice>
   </InputContainer> 
   <InputContainer className="form-group">
      <Label className=" control-label">{"Fr "+T(168,lang)} </Label>
      <input type="text" className="form-control" name="huName"  placeholder="Nom en francais"
        value={values.name}
        onBlur={changeHandler}
        onChange={changeHandler}
      
      />
       <Notice>{nameErr}</Notice>
   </InputContainer> 
   <Message>{"Fr "+T(205,lang)}</Message>
   <InputContainer className="form-group">
      <Label className="control-label">{T(206,lang)} </Label>
      <input type="text" className="form-control" name="path"  placeholder="path Name"
        value={values.path}
        onBlur={changeHandler}
        onChange={changeHandler} 
      />
   <Notice>{pathErr}</Notice>
   </InputContainer> 
   <Bar />
   <ButtonContainer>
              <ButtonStyled className="btn btn-primary"   onClick={()=>{!isValid  ?showError():handleSubmit()}}> 
              {T(55,lang)}
              </ButtonStyled>
              <ButtonStyled
                className="btn btn-light"
                onClick={() =>
                  openPanel ? setOpenPanel(false) : setOpenPanel(true)
                }
              >
                {T(168,lang)}
              </ButtonStyled>
            </ButtonContainer>
      </Dialog>
      </Wrapper>
        </>

    );

}