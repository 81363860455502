import React,{useEffect,useState} from 'react'
import styled from 'styled-components';
import { Container } from '../Products/pages/Items/Items';
import { Header } from '../Products/pages/Items/AddItem';
import { SchudelDiv} from '../Schedule/Schedule';

const Div = styled.div`
margin:10px;
`;
const Wrapper = styled.div`
display:grid;
grid-template-columns: auto 200px 50px 50px;
display:${props => props.display};  

`;
const Edit = styled.div`
height: 40px;
width: 40px;
display :flex;
align-items:center;
justify-element:center;
position:relative;
background-image:url("/img/Edit.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border: none; 

font-size: 18px;
font-weight:bold;
 &:hover{
    cursor: pointer;
    opacity: 1.5;
    background-color:lightgray;
    border-radius : 7px;
 }
`;
const Remove = styled.div`
height: 40px;
width: 40px;
display :flex;
align-items:center;
justify-element:center;
position:relative;
background-image:url("/img/Remove.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border: none; 
font-size: 18px;
font-weight:bold;
 &:hover{
    cursor: pointer;
    opacity: 1.5;
    background-color:lightgray;
    border-radius : 7px;
 }

`;
const ButtonStyled = styled.button`
position:relative;
top:0;
max-height:40px;
width:150px;
right:0;
text-decoration: none;
margin: 5px;
&:hover{ 
    opacity: 1.5;
    border:1px solid;
    border-color: lightgray; 
    border-radius : 3px;
}
@media screen and (max-width: 700px) {
      
    width:auto;
 }
`;
const Hcontainer = styled.div`
display:flex;
flex-direction: row;
margin:10px;

`;
const HContainer= styled.div`
display:grid;
grid-template-columns:auto 400px 2px;
gap:10px;
margin:10px;
`;
const CenterElement = styled.div`
align-text:center;
align-contents:center;
`;
const Notice = styled.span`
  color: red;
  font-size: 12px;
`;
function isRequired(value) {
  return value != null && value.toString().trim().length > 0;
}
function ValidateEmail(value){
  return String(value)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}
export default function ManageAdmins({active}) {
  const db = window.firebase.firestore();
  const [data , setData] = useState();
  const [email,setEmail] = useState();
  const [notice,setNotice] = useState();
  const canShowData = data && true;
  const [selectedValue, setSelectedValue] = useState('admin');
  const replaceElement = (array, index, newValue) => {
    if (index < 0 || index >= array.length) {
      // Invalid index, return the original array
      return array;
    }
  
    const newArray = [...array];
    newArray[index] = newValue;
    return newArray;
  };
  const removeElement = (array, index) => {
    if (index < 0 || index >= array.length) {
      // Invalid index, return the original array
      return array;
    }
  
    const newArray = array.slice(0, index).concat(array.slice(index + 1));
    return newArray;
  };
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(()=>{ 
    db.collection('Admins').doc("Standard").onSnapshot((doc)=>{return  setData(doc.data().admins)});
    
    },[]);
  return (
    <Container width={ active ? "calc(100% - 220px)" : "calc(100% - 40px)"}>
<Hcontainer>  
      <Header>Admins</Header>
      </Hcontainer>
  
      <SchudelDiv>
  
      {canShowData ? data.map((admin,index)=>{
      return(
     
       
         <Wrapper  display={admin.email==="supiangelo@gmail.com"||admin.email==="ali.jlidi85@gmail.com"||admin.email==="az.iz.miskolc@gmail.com" ? "none":""}  key={index}>
         <Div>{admin.email}</Div>
         <Div>{admin.role}</Div>
         <Edit onClick={()=>{
   if(ValidateEmail(email)!==null){
    const newArrayData = replaceElement(data, index, {email:email,role:selectedValue}); 
    db.collection('Admins').doc("Standard").set({admins:newArrayData})
    setNotice();
    setEmail("");
    setSelectedValue('admin');
    }
   else{
    setNotice("Erorr : Email format bad !")
   }

         }}/>
         <Remove onClick={()=>{
          if(email==="admin123456"){
            const newArrayData = removeElement(data, index);
            db.collection('Admins').doc("Standard").set({admins:newArrayData});
            setNotice("")
            setEmail("");
          }  else{
            setNotice("Password Incorrect , contact your Admin")
           }
         }}/>
         </Wrapper>
        
      )
     }):<div>Loading...</div>}
     <CenterElement>
     <Notice>{notice}</Notice>
     <HContainer>
      <label>
        Email
      
      </label>
 
    
        <input
        className="form-control"
       
        type="text"
        value={email}
        onChange={(e)=>{setEmail(e.target.value)}}
      />
        
      </HContainer>
      <HContainer>
      <label>
       Role
      
      </label>
 
    
      <select className="form-control"
                
                value={selectedValue} onChange={handleChange}
              >
                <option value="admin">Admin</option>
                <option value="pos">Pos Manager</option>
              </select>
        
      </HContainer>
    <ButtonStyled className='btn btn-primary' 
    onClick={()=>{
         if(ValidateEmail(email)!==null){
          const newObj = [...data,{email:email,role:selectedValue}];
          db.collection('Admins').doc("Standard").set({admins:newObj})
          setNotice();
          setEmail("");
          setSelectedValue('admin');
          }
         else{
          setNotice("Erorr : Email format bad !")
         }
         }}>Add</ButtonStyled>
     </CenterElement>
 
      </SchudelDiv>
   
    </Container>
  )
}
