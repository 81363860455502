import React from "react";
import styled from "styled-components";
import { useState,useEffect } from "react";
import Autocomplete from "react-google-autocomplete";
import { OrderBtn } from "../Styles/colors";
import useForm from "../Hooks/useForm";
import {T} from "../Language/translate";
const db = window.firebase.firestore();
const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: auto;
  margin: 20px;
`;
const DialogFooter = styled.div`
box-shadow : 0px -2px 10px 0px grey;
height: 55px;
display: flex;
justify-content: center;
border-radius:5px;
margin-top:5px;
`;
export const UserFooter = styled.div`
  
  display: flex;

  justify-content: center;
`;
export const ConfirmButton = styled.div`
  margin: 5px;
  color: white;
  height: 40px;
  width: 200px;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  background-color: ${OrderBtn};
  opacity: 0.7;
  z-index: 4;
  ${({ disabled }) =>
    disabled &&
    `
opacity: .5;
background-color: grey;
pointer-events: none;
`}
`;
const Notice = styled.span`
  color: red;
  font-size: 14px;
`;
const ExtraItem = styled.div`
position:relative;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
width:auto;
height: 40px;
text-align:center;
font-size:20px;
margin: 5px;
transition-property: box-shadow margin-top filter;
transition-duration: .2s;
border:1px solid;
border-color: lightgray; 
border-radius : 10px;
box-shadow : 0px 0px 2px 0px grey;
padding-bottom: 10x;

`;
const ExtraContent = styled.div`
display:flex;
flex-direction:row;
font-size:16px;

`;
const ExtraGrid = styled.div`
display: grid;
grid-template-columns: 1fr  ;
gap: 10px;

`;
const Div = styled.div`
margin:1px;
`;

const X = styled.div`
height: 30px;
width: 30px;
margin:1px;
 &:hover{
    cursor: pointer;
    opacity:0.7;
 }
`;
const AddressTxt = styled.div`
text-align:center;
width:400px;
margin:auto;
`; 
function phoneValid(value){
  return !isNaN(value) && (value != null && value.trim().length > 0)
}

var session = window.sessionStorage;
export function EditForm({ setOpenUser ,loggedIn,prop,profile,lang,isGuest}) {
  
 
  const [zip,setZip] = useState(profile.zip);
  const [phone,setPhone] = useState(profile.phone);
  const [street,setStreet]= useState("");
  const [city,setCity]= useState("");
  const [selected,setSelected] = useState(false);
  const [tags, setTags] = useState([profile.address]);
 //Form validation 
 const initialState = {name:profile.name, address: profile.address  ,floor: profile.floor,  door: profile.door, houseNumber:profile.houseNumber ? profile.houseNumber:""};
 const validations = [

 ];

 const { values, isValid, errors, changeHandler } = useForm(initialState, validations, prop);


 

  





const [Err,setError] = useState("");
const [addressErr,setAddressErr] = useState("");
const [zipErr, setZipErr] = useState("");
const [phoneErr, setPhoneErr] = useState("");

function showError(){
  
  if(!(errors.address || errors.zip || errors.phone)){
      setError(T("52",lang));
  }
  phoneValid(phone)? setPhoneErr(""): setPhoneErr(T("48",lang));
  
  
  
   tags.length != 0 ? setAddressErr("") :setAddressErr(T("59",lang));
    
   
  
}

function handleDelete(i) {
  setTags(tags.filter((tag, index) => index !== i));
  setAddressErr(T("59",lang));

};

function handleAddition(e,place) {
  console.log(place);
   place.address_components.filter((item)=>item.types[0]=='postal_code' ? setZip(Number(item.long_name)):setZip(T("58",lang)));
   place.address_components.filter((item)=>item.types[0]=='route' ? setStreet(item.long_name):"");
   place.address_components.filter((item)=>item.types[0]=='locality' ? setCity(item.long_name):"");
  
   
  
   setTags([ e ]);
  setAddressErr("");

};

const handleGuest = () => {
    
  session.setItem("name", values.name);
  session.setItem("houseNumber", values.houseNumber);
  session.setItem("street", street);
  session.setItem("city", city);
  session.setItem("floor",values.floor);
  session.setItem("door",values.door);
  session.setItem("phone", phone);
  session.setItem("zipCode", zip);
  session.setItem("GuestOk",true);
   
  
       
       setOpenUser();
       
    }

  const handleSubmit = () => {
  
    db.collection('zip').doc(String(zip)).get().then((doc)=>{ if(doc.exists){

      const docRef = db.collection("users").doc(loggedIn.email);

       
      try {
     
        docRef.update({
          street: street,
          zipCode:zip,
          city:city,
          houseNumber:values.houseNumber,
          email: loggedIn.email,
          name: loggedIn.displayName,
          phone: phone,
          floor: values.floor,
          door : values.door,
          
          
        });
      } catch (e) {
        console.log(e);
      }
    

    setOpenUser();

    }  else {

      setAddressErr(T("50",lang) ) ;

    } })
    

   
    
    
   
  };

  return (
   <>
        
       <ExtraGrid >
              {tags.length != 0 ? tags.map((item, index) => {
                return (

                  <ExtraItem>
                    <ExtraContent>
                      <Div>{item}</Div>
                      <X onClick={() => { handleDelete(index) }} >❌</X>
                    </ExtraContent>

                  </ExtraItem>
                )

              }) : ""}

            </ExtraGrid>
           {tags ? "":""} 
      <label className="label">
      <AddressTxt>{T("142",lang)}</AddressTxt>
       <Notice>{addressErr}</Notice>
        <Autocomplete
          className="form-control"
          value={values.address}
          name="address"
          onBlur={changeHandler}
          onChange={(e)=>{changeHandler(e);setSelected(false);}}
          placeholder={T("146",lang)}
          apiKey={"AIzaSyC_NsDrw_bCL2e5UQEiknoOFhwWdKadKtQ"}
          style={{ width: "fit-content(20em)" }}
          onPlaceSelected={(place) => {
           
            
            setSelected(true);
            handleAddition(place.formatted_address,place);
      
          }} 
          options={{
            types: ["address"],
            componentRestrictions: { country: "fr" },
          
            strictBounds: false,
          }}
   
        />
      </label>
    <Notice>{zipErr}</Notice>
      <label>
        {T("37",lang)}
        <input
          className="form-control"
          name="zip"
          type="text"
          value={zip}
          disabled
        />
      </label>
      <label>
       {T("138",lang)}
        <input
          className="form-control"
          name="houseNumber"
          type="text"
          value={values.houseNumber}
          onChange={changeHandler}
        />
      </label>
    
      <label>
       {T("39",lang)}
        <input
          className="form-control"
          name="floor"
          type="text"
          value={values.floor}
          onChange={changeHandler}
        />
      </label>
      <label>
       {T("40",lang)}
        <input
          className="form-control"
          type="text"
          name="door"
          value={values.door}
          onChange={changeHandler}
        />
      </label>
      {(isGuest||(session.getItem("GuestOk")&&loggedIn==null))  &&  <label>
       {T("34",lang)}
        <input
          className="form-control"
          name="name"
          type="text"
          value={values.name}
          onChange={changeHandler}
        />
      </label> }
     <Notice>{phoneErr}</Notice>
      <label>
       {T("54",lang)} 
        <input
          className="form-control"
          placeholder={T("147",lang)}
          name="phone"
          type="tel"
          value={phone}
          onChange={(e)=>setPhone(e.target.value)}
        />
      </label>  
      <DialogFooter>
        <ConfirmButton  onClick={()=>{!(tags.length != 0&& !isNaN(zip)&&phoneValid(phone))?showError():(isGuest||(session.getItem("GuestOk")&&loggedIn==null)) ? handleGuest(): handleSubmit()}}>{T("55",lang)}</ConfirmButton>
      </DialogFooter>
      </>
  );
}
