import { useState } from "react";

export function useDeliveryFee(){
    const [deliveryFee, setDeliveryFee] = useState(0);

    return {
        deliveryFee,
        setDeliveryFee
      };
    }
