import React from "react";
import styled from "styled-components";
import { ButtonContainer } from "../Items/Items";
import { Dialog,Wrapper, DialogShadow,DialogHeader,H2,ButtonStyled,InputContainer,Notice,Label} from "../Items/AddItem";
import { Bar } from "../Items/Items";
import { useState, useEffect } from "react";
import useForm from "../Hooks/useForm";
import { Close } from "../../../Orders/OrderDetails";





// function for required fields 
function isRequired(value) {
    return value != null && value.toString().trim().length > 0;
  }

function ZipFormat(value){
    return value.length === 5 ;
}
function EditZipContainer({ openItem, setOpenItem,prop,setData}) {


   //db
   const db = window.firebase.firestore();
 

 
 
 
   const initialState = { zipCode: openItem.code,price:openItem.price };
   const validations = [
     ({zipCode}) => isRequired(zipCode) || {zipCode: 'Zip is required'},
     ({price}) => isRequired(price) || {price: 'Price is required'},
    
     ({zipCode})=> ZipFormat(zipCode) || {zipCode: 'zip Code is 5 numbers'}
   ];
 
   
   const {values, isValid, errors, changeHandler, submitHandler} = useForm(initialState, validations, prop);
 
   const [Err, setError] = useState("");
   const [codeErr,setCodeErr] = useState("");
   const [priceErr, setPriceErr] = useState("");
  
  function showError(){
    if(!(errors.code || errors.price )){
        setError("Code  and price are required");
    }
    console.log('called show error after')
       setCodeErr(errors.zipCode);
       console.log('called show error after' +errors.zipCode)
       setPriceErr(errors.price);
    
    
 }

 
 
 
 
   const handleSubmit = () => {
    
       
     const docRef = db.collection("zip").doc(String(values.zipCode));
     try {
    
       docRef.update({
        code: Number(values.zipCode),
        price: Number(values.price)
      
       });
       console.log("submited :=)")
     } catch (e) {
       console.log(e);
     }
    
   db.collection('zip').get().then((data)=>{return  setData(data.docs)});
         
   
   setOpenItem();
   
   
   
   }
   
  return (
    <>
    <DialogShadow />
    <Wrapper>
 <Dialog>
   <Close     onClick={() =>setOpenItem()} />
     <DialogHeader>
       <H2>Zip Edit </H2>
    
     </DialogHeader>
     {!(errors.code || errors.price)  &&<Notice>{Err}</Notice>}
     <Bar />
     <Notice>{codeErr}</Notice>
     
     <InputContainer className="form-group">
      <Label  className=" control-label">Code </Label>
      
      <input type="number" className="form-control" name="zipCode"  placeholder="Zip Code" 
      value={values.zipCode}
      onBlur={changeHandler}
      onChange={changeHandler}/>
 
    
   </InputContainer> 
   <Notice>{priceErr}</Notice>
   <InputContainer className="form-group">
      <Label  className=" control-label">Price </Label>
  
      <input type="number" className="form-control" name="price"  placeholder="Price" 
      value={values.price}
      onBlur={changeHandler}
      onChange={changeHandler}/>

  
   </InputContainer> 
  <Bar />
  <ButtonContainer>
         <ButtonStyled className="btn btn-primary"  onClick={()=>{!isValid  ?showError():handleSubmit()}}>
           Edit
         </ButtonStyled>
         <ButtonStyled
           className="btn btn-light"
           onClick={() =>setOpenItem()}
         >
           Cancel
         </ButtonStyled>
       </ButtonContainer>

 </Dialog>
 </Wrapper>
   </>
  );
}

export function EditZip(props){
    if (!props.openItem) return null;
    return <EditZipContainer {...props}/>
}