import React from "react";
import styled from "styled-components";

const CursorPointer = 'cursor: pointer';

const RadioInput = styled.input`
${(CursorPointer)}
margin-left:3px;
margin-right:10px;
`;
const Div = styled.div`
display:flex;
flex-direction:column;
`;

const Label = styled.label`
${(CursorPointer)}
`;
export function Choices({toppings, choiceRadio}){
    return (
        <>
       
        {toppings.map(choice => 
            (
                <Div>
                    <div>
                <RadioInput
                type="radio"
                id={choice.name}
                name="choice"
                value={choice.name}
                checked = {choiceRadio.value===choice.name}
                onChange={choiceRadio.onChange}
                />
                       <Label >{choice.name}</Label>{" "}
                    </div>
                </Div>
            ))}
        </>
    );
}