import React, { useState } from 'react';
import {T} from "../Language/translate";
import styled , { keyframes } from "styled-components";
import { OrderBtn } from "../Styles/colors";

const Dialog = styled.div`
position: fixed;
  width:600px;
  height:auto;
  opacity:0.9;
  border: 2px solid white;
  background-color: ${OrderBtn};;
  animation: ${slideDown} 1s ease-out forwards;
  opacity: 0;
  z-index: 100;
padding:5px;
  display: flex;
  flex-direction: column;
  border-radius:5px;
  // Add this part
  @media (max-width: 700px) {
    
    overflow-y: scroll;
    height:auto;
    width: 90%;
 
    z-index: 100;
  }

`;

function slideDown(){
  return keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}`;
}
 const DialogShadow = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
 top:0;
 left:0;
  background-color: black;
  opacity: 0.3;
  z-index: 50;
`;
const Wrapper = styled.div`
z-index: 55;
position:fixed;
width:100%;
height:100%;
left:0; 
display: flex;
justify-content: center;

`;

const Container = styled.div`
display:flex;
flex-direction:row;

@media (max-width: 700px) {
    
    flex-direction:column;
  }
`;

const Paragraph = styled.div`
font-size:16px;
text-align:center;
color:white;
`;

const Btn = styled.button`
background-color: black;
margin:0px 5px;
color:white;
height:50px;
@media (max-width: 700px) {
    
   margin:5px;
  }
`;

function CookieNotice({lang,setLang}) {
   
  const [showNotice, setShowNotice] = useState(localStorage.getItem("cookieAccepted")==null?true:false);
  console.log(showNotice)
  const acceptCookies = () => {
    // Set cookie
 
    localStorage.setItem('cookieAccepted', true);
    document.cookie = "cookieConsent=true; max-age=31536000";
    setShowNotice(false);
 
  };

  const rejectCookies = () => {
    // Remove cookie
    localStorage.removeItem('cookieAccepted');
    setShowNotice(false);
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (!cookie.startsWith('essentialCookie=')) {
        document.cookie = `${cookie}; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      }
    }
  };

  return (
    showNotice && (
        <>
        <DialogShadow />
        <Wrapper>
        <Dialog>
          
           <Container>
          
            <Paragraph>{T(217,lang)}</Paragraph>
           
            <Btn className='btn' onClick={acceptCookies}>{T(161,lang)}</Btn>
            <Btn className='btn' onClick={rejectCookies}>{T(218,lang)}</Btn>
    
            </Container>
        </Dialog>
        </Wrapper>
        </>
    )
    
 
  );
}

export default CookieNotice;