import React from 'react';
import axios from "axios";
import styled, { keyframes } from "styled-components";
import {useState, useEffect} from 'react';
import { OrderDetails } from './OrderDetails';
import moment from "moment";
var _ = require('lodash');

// Get a database reference
const database = window.firebase.database();

const OrdersContainer = styled.div`
position:fixed;
top:100px;
right:0;
display:flex;
flex:direction:row;
width:${(props) => props.width};
height:80%;
border-color: lightgray; 
border-radius : 10px;
`;
const SubOrdersInc = styled.div`
overflow-y: scroll;
align-items: center;
display: flex;
flex-direction: column;
height:90%;
width : 90%;
margin:5px;
border:1px solid;
border-color: lightgray; 
border-radius : 10px;
@media(max-width: 995px){ 
  display:${props => props.Incdisplay}; 
}

`;
const SubOrdersPrep = styled.div`
overflow-y: scroll;
align-items: center;
display: flex;
flex-direction: column;
height:90%;
width : 90%;
margin:5px;
border:1px solid;
border-color: lightgray; 
border-radius : 10px;
@media(max-width: 995px){ 
  display:${props => props.Prepdisplay}; 
} `;
const SubOrdersFin = styled.div`
overflow-y: scroll;
align-items: center;
display: flex;
flex-direction: column;
height:90%;
width : 90%;
margin:5px;
border:1px solid;
border-color: lightgray; 
border-radius : 10px;
@media(max-width: 995px){ 
  display:${props => props.Findisplay}; 
}
`;
export const OrderContainer = styled.div`
@import url('https://fonts.googleapis.com/css2?family=Maven+Pro&display=swap');
font-family: 'Maven Pro', sans-serif;
position: relative;
display:grid;
grid-template-columns: auto 1fr auto;
height: auto;
width: 95%;
font-size:20px;
 
margin: 5px;
border:2px solid;
border-color: lightgray; 
border-radius:10px;
transition-property: box-shadow margin-top ;
transition-duration: .2s;
box-shadow : 0px 0px 2px 0px grey;
padding-bottom: 10x;
&:hover{
    opacity:0.7;
    margin-top: 0px;
    margin-bottom : 5px;
    box-shadow : 0px 0px 10px 0px grey;
} 
`;
function blinkingEffect(){
  return keyframes`
  50% {
    border-color:white;
    background-color:white;
  }`;
}
export const OrderContainerBlink = styled.div`
@import url('https://fonts.googleapis.com/css2?family=Maven+Pro&display=swap');
font-family: 'Maven Pro', sans-serif;
position: relative;
display:grid;
grid-template-columns: auto 1fr auto;
height: auto;
width: 95%;
font-size:20px;
 
margin: 5px;
border:2px solid;
animation: ${blinkingEffect} 1.2s linear infinite;
border-color: lightgray; 
border-radius:10px;
transition-property: box-shadow margin-top ;
transition-duration: .2s;
box-shadow : 0px 0px 2px 0px grey;
padding-bottom: 10x;
&:hover{
    opacity:0.7;
    margin-top: 0px;
    margin-bottom : 5px;
    box-shadow : 0px 0px 10px 0px grey;
} 
`;
 export const OrdersLabel = styled.div`
background-color: rgba(255, 255, 255, .8);
padding: 5px;
@media(max-width: 400px){ 
    font-size 8px; 
  } `;
 export const FoodContainer = styled.div`
 display:grid;
 grid-template-columns: 10px 20px 270px 120px;
 justify-content: space-between;
margin:5px;
`;

 export const Food = styled.div`
margin-left:20px;
font-weight: bold;
`;
export  const Quantity = styled.div`
margin-left:5px;
font-weight: bold;
`;
 export const ToppingContainer = styled.div`
margin-left:5px;
font-weight: bold;
color: gray;
font-size: 14px;
`;
export const Toppings = styled.div`

`;
const OkButton = styled.span`
position:relative;
width: 70px;
text-align:center;
padding:3px;
color:white;
cursor:pointer;
background-color: ${props => props.Color};
transition-property: box-shadow margin-top filter;
transition-duration: .2s;
border-radius : 7px;

z-index: 10;
&:hover{
    cursor: pointer;
    z-index: 10;
    opacity:0.7;
    margin-top: 0px;
    margin-bottom : 5px;
    box-shadow : 0px 0px 10px 0px grey;
}

`;
export const Div = styled.div`
     
    margin:auto;
    font-size:16px;
    font-weight:600;
     text-align:center;
    
   
`;
 const ID = styled.div`
     font-weight:800;
     margin-top:auto;
     margin-bottom:auto;
     font-size:28px;
     text-align:center;
     margin:1 px;
`;
const Fiv = styled.div`
width:100% ;
`;
const OrdersWrapper = styled.div`
height:100%;
width:100%;
`;
const PhoneNav = styled.div`
position:relative;
top:58px;
margin-left:auto;
margin-right:auto;


display:flex;
flex-direction:row;
@media(min-width: 995px){ 
  display:none; 
}

`;
const NavButton= styled.button`
background-color:#060b26;
color:white;
margin-lef:5px;
margin-right:5px;
&:hover{
  cursor: pointer;
  z-index: 10;
  opacity:0.7;
 
  box-shadow : 0px 0px 10px 0px grey;
}
@media screen and (max-width: 350px) {
      
  font-size:14px !important;
}
`;
const NavBtnContainer = styled.div`
margin-left:auto;
margin-right:auto;

`;
var session = window.sessionStorage;
session.setItem("play", true);
function Orders({sound, open,active}) {



const db = window.firebase.firestore();

const ref = database.ref('orders');
//const pandaRef = database.ref('PandaOr')
const[toggel,setToggel] = useState(false); 
const[orderItem,setItem] = useState(); 
const[orders, setOrders] = useState(null);
const[ordersInPrep, setOrdersInPrep] = useState(null);
const[ordersReady, setOrdersReady] = useState(null);


  const isOrdersEmpty = orders && orders.length ===0;
  const canShowOrders = orders && orders.length >0;

  const isOrdersInPrepEmpty = ordersInPrep && ordersInPrep.length ===0;
  const canShowOrdersInPrep = ordersInPrep && ordersInPrep.length >0;

  const isOrdersReadyEmpty = ordersReady && ordersReady.length ===0;
  const canShowOrdersReady = ordersReady && ordersReady.length >0;

  







  






 

  
   useEffect(()=>{
    var data;
    var Q;
    //need to be changed in production
    ref.orderByChild('status').equalTo('sent').on('value', (snapshot) => {          
           data = _.toArray(snapshot.val());
          if(!Q) Q = data.length;
      
          if(data.length > Q ){
            console.log("condition to play music Child Added ");
           
            sound.play();
            session.setItem("play", false);
            Q = data.length;
          }
           setOrders(data);
          
       
    });

    //need to be changed in production
    ref.orderByChild('status').equalTo('preparing').on('value', (snap) => {          
      const data2 = _.toArray(snap.val());
      setOrdersInPrep(data2);
      
});
 //need to be changed in production
ref.orderByChild('status').equalTo('ready').on('value', (snap) => {          
  const data3 = _.toArray(snap.val());
  setOrdersReady(data3);

});




    return ()=> {
      ref.off('value');
    }
   

  },[])


 
   



   function Incoming(){
    setInc("flex");
    setPrep("none");
    setFin("none");

   }
   function Preparation(){
     setInc("none");
    setPrep("flex");
    setFin("none");

   }
   function Finished(){
     setInc("none");
    setPrep("none");
    setFin("flex");

   }
   function stopSound(){
    sound.stop();
    console.log('sound stoped')
    session.setItem("play", true);
    console.log("is it time to play song ? "+session.getItem("play"))   ;
   }
const [Inc, setInc] = useState("flex");
const [Prep, setPrep] = useState("none");
const [Fin, setFin] = useState("none");
  return (
    <>
    {toggel ?<OrderDetails sound={sound} setToggel={setToggel} orderItem={orderItem} type="order"/> : ""}
   <OrdersWrapper>
    <PhoneNav>
    
      <NavBtnContainer>
      <NavButton className='btn btn-primary' onClick={()=>Incoming()}>Incoming</NavButton>
      <NavButton className='btn btn-primary' onClick={()=>Preparation()}>In Preparation</NavButton>
      <NavButton className='btn btn-primary' onClick={()=>Finished()}>Finished</NavButton>
      </NavBtnContainer>
      
    </PhoneNav>
    <OrdersContainer width={ active ? "calc(100% - 220px)" : "calc(100% - 40px)"}>
       
    <SubOrdersInc Incdisplay={Inc}>
     <OrdersLabel>Commandes entrantes</OrdersLabel>
 {isOrdersEmpty  &&<OrdersLabel>Pas en cours .. </OrdersLabel>}
 {canShowOrders && orders.map((item, index)=> {
  return(
    <Fiv key={index}>
    {item ? 
    <OrderContainerBlink key={index}  style={item.email==="wolt"?{"borderColor": "blue","backgroundColor":"lightblue" }:item.email==="foodpanda"?{"borderColor": "hotpink","backgroundColor":"lightpink" }:item.email==="falatozz"?{"borderColor": "black","backgroundColor":"lightgray" }:{"borderColor": "lightblue" }} >
    {item.falId ? <ID>{"#F"+item.Id }</ID>:item.woltId ?<ID>{"Wolt"}</ID>:<ID>{"#"+item.Id }</ID>} 
     <div>
      <Div>{item.displayName }</Div>
      <Div>{item.address}</Div>
      <Div>{isNaN(item.date) ?moment(item.date).format("MM-DD HH:mm") : moment.unix(item.date).format("MM-DD HH:mm")}</Div>
      </div>
      <OkButton Color={item.email==="wolt"?"blue":item.email==="foodpanda"?"#E32636":item.email==="falatozz"?"black":"#5F9EA0"} onClick={()=>{!toggel? setToggel(true):setToggel(false); setItem(item);stopSound()}}>Ouvrir</OkButton>
      
      </OrderContainerBlink>: "" }
      </Fiv>
  )} 
 )}
    </SubOrdersInc>

    
    <SubOrdersPrep Prepdisplay={Prep}>
    <OrdersLabel>Commandes en preparation</OrdersLabel>
    {isOrdersInPrepEmpty && <OrdersLabel>Pas en cours .. </OrdersLabel>}
 {canShowOrdersInPrep && ordersInPrep.map((item, index)=> {
  return(
    <Fiv key={index}>
    {item ? 
    <OrderContainer style={item.email==="wolt"?{"borderColor": "blue","backgroundColor":"lightblue" }:item.email==="foodpanda"?{"borderColor": "hotpink","backgroundColor":"lightpink" }:item.email==="falatozz"?{"borderColor": "black","backgroundColor":"lightgray" }:{"borderColor": "lightblue" }}  key={index} >
    {item.falId ? <ID>{"#F"+item.Id }</ID>:item.woltId ?<ID>{"Wolt"}</ID>:<ID>{"#"+item.Id }</ID>} 
     <div>
      <Div>{item.displayName }</Div>
      <Div>{item.address}</Div>
      <Div>{isNaN(item.date) ?moment(item.date).format("MM-DD HH:mm") : moment.unix(item.date).format("MM-DD HH:mm")}</Div>
      </div>
      <OkButton Color={item.email==="wolt"?"blue":item.email==="foodpanda"?"#E32636":item.email==="falatozz"?"black":"#5F9EA0"} onClick={()=>{!toggel? setToggel(true):setToggel(false);sound.stop(); setItem(item);}}>Ouvrir</OkButton>
   
      </OrderContainer>: ""}
      </Fiv>
  )} 
 )}

    </SubOrdersPrep>





    <SubOrdersFin Findisplay={Fin}>
    <OrdersLabel>Commandes Finis</OrdersLabel>
    {isOrdersReadyEmpty && <OrdersLabel>Pas en cours </OrdersLabel>}
 {canShowOrdersReady && ordersReady.map((item, index)=> {
  return(
    <Fiv key={index}>
    {item ? 
    <OrderContainer style={item.email==="wolt"?{"borderColor": "blue","backgroundColor":"lightblue" }:item.email==="foodpanda"?{"borderColor": "hotpink","backgroundColor":"lightpink" }:item.email==="falatozz"?{"borderColor": "black","backgroundColor":"lightgray" }:{"borderColor": "lightblue" }}  key={index} >
    {item.falId ? <ID>{"#F"+item.Id }</ID>:item.woltId ?<ID>{"Wolt"}</ID>:<ID>{"#"+item.Id }</ID>} 
     <div>
      <Div>{item.displayName }</Div>
      <Div>{item.address}</Div>
      <Div>{isNaN(item.date) ?moment(item.date).format("MM-DD HH:mm") : moment.unix(item.date).format("MM-DD HH:mm")}</Div>
      </div>
      <OkButton Color={item.email==="wolt"?"blue":item.email==="foodpanda"?"#E32636":item.email==="falatozz"?"black":"#5F9EA0"} onClick={()=>{!toggel? setToggel(true):setToggel(false); setItem(item);}}>Ouvrir</OkButton>
   
      </OrderContainer>: ""}
      </Fiv>
  )} 
 )}

    </SubOrdersFin>
   </OrdersContainer>
   </OrdersWrapper>
   </>)
}

export default Orders
