import React from "react";
import styled from "styled-components";
import ToggleButton from "react-toggle-button";
import { useState, useEffect } from "react";
import useForm from "../../../Products/pages/Hooks/useForm";
import moment from "moment";
import { Bar , ButtonContainer } from "../../../Products/pages/Items/Items";
import { Dialog ,DialogShadow, Wrapper,Label,DialogHeader,Header,ButtonStyled,H2 ,H4R,H4G ,Notice 
  ,InputContainer } from "../../../Products/pages/Items/AddItem";
  const ExtraGrid = styled.div`
  display: grid;
 grid-template-columns: 1fr 1fr 1fr   ;
 gap: 10px;
 
 `;
 const ExtraItem = styled.div`
 position:relative;
 display:flex;
 flex-direction:column;
 align-items:center;
 justify-content:center;
 width:auto;
 height: 40px;
 text-align:center;
 font-size:20px;
 margin: 5px;
 transition-property: box-shadow margin-top filter;
 transition-duration: .2s;
 border:1px solid;
 border-color: lightgray; 
 border-radius : 10px;
 box-shadow : 0px 0px 2px 0px grey;
 padding-bottom: 10x;
 
 `;
 const ExtraContent = styled.div`
 display:flex;
 flex-direction:row;
 
 `;
 
 const AddContainer = styled.div`
 display:flex;
 flex-direction:row;0.
 `;
 const Div = styled.div`
margin:1px;
`;
 const Close = styled.div`
height: 30px;
width: 30px;
background-image:url("/img/close.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
margin:5px;
 &:hover{
    cursor: pointer;
    opacity:0.7;
 }
`;
const SearchContainer = styled.div`
display: flex;
flex-direction: column;
height:100px;
margin-left:100px;
overflow-y:scroll;
cursor:pointer;
&:hover{
    cursor: pointer;
    opacity:0.7;
 }
`;
const Input = styled.input`
cursor:pointer;
&:hover{
    cursor: pointer;
    
 }
`;
// function for required fields 
function isRequired(value) {
  return value != null && value.trim().length > 0;
}
function DateisRequired(value) {
  return value != null ;
}
function NumericalValue(value) {
  return !isNaN(value);
}

function ValueInterval(value) {
   
    return !(value <0 || value>100);

  } 




export function CreatePerItem({ openPerItem, setOpenPerItem, prop, setData }) {

  


  //db
  const db = window.firebase.firestore();
  //use state for categories 
  const [items, setItems] = useState([]);
  const [checked, setChecked] = useState(false);
//use state for toggle 
const [toggle, setToggle] = useState(false);
 //use state for categories 
 const [categories, setCategory] = useState();
 const canShowCategories = categories && true;  
 useEffect(() => {
    db.collection('Category').get().then((data) => { return setCategory(data.docs) });
  
  }, []);

 

  const [codeUnique, setCodeUnique] = useState(true);
  const [nameUnique, setNameUnique] = useState(true);
  const [search, setSearch] = useState("");
  const [tags, setTags] = useState([]);
 
  //Form validation 
  const initialState = {name: '',code: toggle? '':'global', category: 'DESSZERTEK', startDate: moment().format('YYYY-MM-DDTHH:MM:SS'), endDate: moment().format('YYYY-MM-DDTHH:MM:SS'), value: '' ,quantity:'',limit:''};
  const validations = [
    ({ name }) => isRequired(name) || { name: 'Name is required' },
    ({ category }) => isRequired(category) || { category: 'Category is required' },
    ({ code }) => toggle ? isRequired(code) || { code: 'Code is required' } : true,
    ({ startDate }) => DateisRequired(startDate) || { startDate: 'Date is required' },
    ({ endDate }) => DateisRequired(endDate) || { endDate: 'Date is required' },
    ({ limit }) => isRequired(limit) || { limit: 'Limit is required' },
    ({ limit }) => NumericalValue(limit) || { limit: 'Limit is a number' },
    ({ quantity }) => isRequired(quantity) || { quantity: 'Quantity is required' },
    ({ quantity}) => NumericalValue(quantity) || { quantity: 'Quantity is a number' },
    () => tags.length != 0 || { products: 'At least one tage selected' },
    () => toggle ? codeUnique || { code: 'This code already exist ' } : true ,
    () => nameUnique || {name: 'This name already exist '}
  ];

  const { values, isValid, errors, changeHandler} = useForm(initialState, validations, prop);

/// check if name is already exist 
if (values.name != "") db.collection('offer').where("name", "==", values.name).get().then((doc) => { return doc.size ? setNameUnique(false) : setNameUnique(true) })
// check if code is already exist 
if (values.code != "" || toggle) db.collection('offer').where("code", "==", values.code).get().then((doc) => { return doc.size ? setCodeUnique(false) : setCodeUnique(true) })

function searchitems(x){

  setSearch(x);
  
  db.collection('items').
  where("name", ">=", search.charAt(0).toUpperCase() + search.slice(1)).
  where("name", "<=", search.charAt(0).toUpperCase() + search.slice(1)+ "\uf8ff").get().then((data)=>{return  setItems(data.docs)});


}
function handleDelete(i) {
 setTags(tags.filter((tag, index) => index !== i));

};

function handleAddition(e) {
  if (!tags.includes(e)&& tags.length <1) setTags([...tags,  e ]);

};



 


  const handleSubmit = (event) => {
    event.preventDefault();
  console.log(values.code)

    const docRef = db.collection("offer").doc(values.name);
    
   
      docRef.set({
        name: values.name,
        code: values.code,
        category: values.category,
        limit: values.limit,
        type: 'peritem',
        items: tags,
        quantity: values.quantity,
        startDate: values.startDate,
        endDate: values.endDate,
        recrusive: checked,
        proType:'per'
      });
    
    
    


    setOpenPerItem(false);

  }


  return (
    <>
    {console.log(values.code)}
      <DialogShadow />
      <Wrapper>
      <Dialog>
       
          <DialogHeader>
            <Header>
              <H2>Item for itmes</H2>
              {(!isValid ) ? <H4R>Fill required fields to add</H4R> : <H4G>You can save the product now</H4G>}
            </Header>
            <ButtonContainer>
              <ButtonStyled disabled={!isValid  } className="btn btn-primary" onClick={handleSubmit}>
                Save
              </ButtonStyled>
              <ButtonStyled
                className="btn btn-light"
                onClick={() =>
                  openPerItem ? setOpenPerItem(false) : setOpenPerItem(true)
                }
              >
                Cancel
              </ButtonStyled>
            </ButtonContainer>
          </DialogHeader>
          <Bar />

          {errors.name && <Notice>{errors.name}</Notice>}
          <InputContainer className="form-group">
            <label for="name" className="col-sm-2 control-label">Name  </label>
          
              <input type="text" className="form-control" name="name" placeholder="Name"
                value={values.name}
                onBlur={changeHandler}
                onChange={changeHandler}
              />
        

           
          </InputContainer>
          {errors.code && toggle && <Notice>{errors.code}</Notice>}
          <InputContainer className="form-group">
           
            <label for="code" className="col-sm-2 control-label">Code  </label>
            {toggle ?  
              <input type="text" className="form-control" name="code" placeholder="Code"
                value={values.code}
                onBlur={changeHandler}
                onChange={changeHandler}
              />
            : " " } 
            <ToggleButton
                          name="global"
                          value={toggle}
                          onToggle={()=>!toggle ? setToggle(true):setToggle(false)}
                          
                          colors={{
                            active: {
                              base: "#1578F1",
                            },
                            inactive: {
                              base: "gray",
                            },
                          }}
                        />
           
          </InputContainer>

          
          <InputContainer className="form-group">
            <label className="col-sm-3 control-label">Category</label>
            <div >
              <select className="form-control"
              name="category"
                value={values.category}
                onChange={changeHandler}
              >
                {canShowCategories && categories.map((item) => {
                  return (
                    <>
                      <option value={item.data().name}>

                        {item.data().name}
                      </option>
                    </>
                  )
                }
                )}


              </select>
            </div>
          </InputContainer>


        
            <ExtraGrid >
              {tags.length != 0 ? tags.map((item, index) => {
                return (

                  <ExtraItem>
                    <ExtraContent>
                      {console.log(item)}
                      <Div>{item}</Div>
                      <Close onClick={() => { handleDelete(index) }} />
                    </ExtraContent>

                  </ExtraItem>
                )

              }) :""}

            </ExtraGrid>

          <InputContainer className="form-group">
              <Label className=" control-label">Offer  </Label>
           
                <input type="text" className="form-control" name="item" placeholder="Item"
                   value={search}
         
                   onChange={(e)=>{searchitems(e.target.value);}} />
           
            </InputContainer>

            <SearchContainer>
            {items.map((item) => {
                  return (
                    <>
           
                      <Input className="form-control" 
                       readonly="readonly" value={item.data().name} onClick={(e)=>{handleAddition(e.target.value); setItems([])}}
                       />
                 
                    </>
                  )
                }
                )}
            
            </SearchContainer>
    
    {errors.startDate && <Notice>{errors.startDate}</Notice>}
    <InputContainer className="form-group">
          <Label  className="control-label">Limit  </Label>
              <input type="number" className="form-control" name="limit"  placeholder="Limit"
               value={values.limit} 
               onChange={changeHandler} />
           
            {errors.limit && <Notice>{errors.limit}</Notice>}
          </InputContainer>
          <InputContainer className="form-group">
            <label for="price" className="col-sm-2 control-label">Quantity</label>
     
              <input type="number" className="form-control" name="quantity"  placeholder="Quantity"
               value={values.quantity} 
               onChange={changeHandler} />
   
            {errors.quantity && <Notice>{errors.quantity}</Notice>}
          </InputContainer>
          <InputContainer className="form-group">
            <label for="date" className="col-sm-2 control-label">Start </label>
       
              {console.log(values.startDate)}
            <input type="datetime-local" 
              name="startDate"               
              value={values.startDate} 
               onChange={changeHandler}
               min={moment().format('YYYY-MM-DDTHH:MM:SS')} max="2024-06-14T00:00"/>
          
       
          </InputContainer>
          {errors.endDate && <Notice>{errors.endDate}</Notice>}
          <InputContainer className="form-group">
            <label for="date" className="col-sm-2 control-label">End </label>
       
            <input type="datetime-local" 
              name="endDate"               
              value={values.endDate} 
               onChange={changeHandler}
               min={values.startDate} max="2024-06-14T00:00"/>
            
          
          </InputContainer>
          <InputContainer className="form-group">
            <Label  className=" control-label">Recrusive ? </Label>
           
            <input
             type="checkbox"
             checked={checked}
             onChange={e => setChecked(e.target.checked)}
               />
        
          
          </InputContainer>
      </Dialog>
      </Wrapper>
    </>

  );
}