
import React from "react";
import styled from "styled-components";
import { useState, useEffect } from "react";
import useForm from "../../../Hooks/useForm";
import { ChromePicker } from 'react-color';

const Dialog = styled.div`
position: fixed;
  width: 500px;
  height:auto;
  background-color: white;
  margin-bottom:50px;
 
  z-index: 55;
margin:auto;
padding:10px;
  display: flex;
  flex-direction: column;
  border-radius:5px;
  // Add this part
  @media (max-width: 400px) {
    
    overflow-y: scroll;
    height:auto;
    width: auto;
 
    
  }
`;

 const Wrapper = styled.div`
z-index: 55;
position:fixed;
width:100%;
height:100%;
left:0;
bottom:0;
  display: flex;
  justify-content: center;
  align-items: center;

`;


const ButtonStyled = styled.button`
max-height:40px;
text-decoration: none;
margin: 10px;
&:hover{
  opacity: 1.2;
  padding-top: 3px;
}
`;
const DialogShadow = styled.div`
position: fixed;
height: 100%;
width: 100%;
top: 0px;
left:0px;
background-color: black;
opacity: 0.7;
z-index: 11;
`;

 const DialogHeader = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 20%;
  media screen and (max-width: 1000px) {
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 40%;
  }
  @media screen and (max-width: 800px) {
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 30%;
  }
  @media screen and (max-width: 400px) {
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 10%;
  }
`;

const Notice = styled.span`
white-space: nowrap;
  margin-left:20px;
  color: red;
  font-size: 14px;
`;
const Header = styled.div`
display:flex;
flex-direction: column;`;
 const H2 = styled.div`
  font-size: 30px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
`;

 const H4G = styled.div`
  font-size: 15px;
  font-weight: bold;
  color:green;

  white-space: nowrap;
`;


const InputContainer = styled.div`
  display: grid;
  grid-template-columns: .5fr 1fr ;
  margin:5px;
  
`;


const ButtonContainer = styled.div`
display:flex;
flex-direction:row;
@media screen and (max-width: 995px) {
   
    margin-top:20px;
}
@media screen and (max-width: 700px) {
    flex-direction:column;
        
    }
`;
const Bar = styled.hr`
border-top: 3px solid #bbb;
margin: 5px;
width:90%;
@media screen and (max-width: 500px) {
   
    width:300px;
}
`;
const Swatch = styled.div`
padding: 5px;
background: #fff;
border-radius: 1px;
margin-left:50px;
display: inline-block;
cursor: pointer;
`;
const Color = styled.div`
width: 36px;
height: 14px;
border: solid 2px lightgray;
border-radius:2px;
background:${props=>props.color};
`;
const Popover= styled.div`
position: absolute;
z-index: 2;
`;
const Cover = styled.div`
background-color: black;
opacity: 0.5;
position: fixed;
top: 0px;
right: 0px;
bottom: 0px;
left: 0px;
`;
const Label = styled.label`
font-size:14px;
white-space:nowrap;
margin-right:5px;
`;
const Div2 = styled.div`
display:flex;
flex-direction:row;
margin-left:80px;
`;
const Div3 = styled.div`
display: grid;
grid-template-columns: 1fr 1fr   ;
font-size:14px;
white-space:nowrap;
margin-left:auto;
margin-right:auto;
margin-top:10px;
`;
const Div4 = styled.div`
margin-right:10px;
margin-top:-5px;
`;
// function for required fields 
function isRequired(value) {
    return value != null && value.trim().length > 0;
  }
  function EditThemeContainer({ openTheme, prop,setThemes,setOpenTheme}) {

    const db = window.firebase.firestore();

   
    const [restoErr,setRestoErr] = useState("");
    const [navColor, setNavColor] = useState(openTheme.navBarBackground)  //#f44336
    const [tickerColor, setTickerColor] = useState(openTheme.tickerBackground)
    const [lyricsColor, setLyricsColor] = useState(openTheme.navLyric)
    const [lyricsTickerColor, setLyricsTickerColor] = useState(openTheme.tickerLyric)
    const [orderBtnColor, setOrderBtnColor] = useState(openTheme.orderBtn)
    const [paraType, setParaType] = useState(openTheme.paraType)
    const [click1, setClick1] = useState(false);
    const [click2, setClick2] = useState(false);
    const [click3, setClick3] = useState(false);
    const [click4, setClick4] = useState(false);
    const [click5, setClick5] = useState(false);
    const handleNavClick = () => {
        !click1 ? setClick1(true):setClick1(false);
      };
    const  handleTickerClick = () => {
        !click2 ? setClick2(true):setClick2(false);

      };
      const  handleLyricsClick = () => {
        !click3 ? setClick3(true):setClick3(false);

      };
      const  handleTickerLyricsClick = () => {
        !click4 ? setClick4(true):setClick4(false);

      };
      const  handleOrderBtnClick = () => {
        !click5 ? setClick5(true):setClick5(false);

      };
      function NumericalValue(value){
        return !isNaN(value);
    }
    
    
    //Form validation 
    const initialState = { name: openTheme.name , resto:openTheme.resto};
    const validations = [
      ({ resto }) => isRequired(resto) || { resto: 'Restaurant is required' },
   
      
   
    ];
  
    const { values, isValid, errors, changeHandler } = useForm(initialState, validations, prop);

    function showError(){
      console.log('show error')
         setRestoErr(errors.resto);
        
      
   }
   
   function DefaultPara(){
    setNavColor("#F5564A"); // #f44336
    setTickerColor("#F5564A");
    setLyricsColor("#fff");
    setLyricsTickerColor("#fff");
    setOrderBtnColor("#CB853D"); //#CB853D
    

   }


    function handleSubmit() {
      
    
        setRestoErr("");
        const docRef = db.collection("themes").doc(values.name);
        try {
    
          docRef.set({
            name: values.name,
            resto:values.resto,
            navBarBackground: navColor,
            tickerBackground: tickerColor,
            navLyric:lyricsColor,
            tickerLyric:lyricsTickerColor,
            paraType:paraType,
            orderBtn:orderBtnColor
          
          });
          console.log("submited :=)")
        } catch (e) {
          console.log(e);
        }
    
        db.collection('themes').get().then((data) => { return setThemes(data.docs) });
    
    
        setOpenTheme();
    
    
    
      }
    
    return (
        <>
          <DialogShadow />
          <Wrapper>
      <Dialog>
       
          <DialogHeader>
            <Header>
              <H2> Theme</H2>
              {(isValid)  && <H4G>You can save the Theme now</H4G>}
            </Header>
            <ButtonContainer>
              <ButtonStyled className="btn btn-primary" onClick={()=>{!isValid ?showError():handleSubmit()}}>
                Save
              </ButtonStyled>
              <ButtonStyled
                className="btn btn-light"
                onClick={() =>setOpenTheme()
                }
              >
                Cancel
              </ButtonStyled>
            </ButtonContainer>
          </DialogHeader>
          <Bar />

          <InputContainer className="form-group">
            <Label for="name" className=" control-label">Name  </Label>
          
              <input disabled="true" type="text" className="form-control" name="name" placeholder="Name"
                value={values.name}
                onBlur={changeHandler}
                onChange={changeHandler}
              />
          

        
          </InputContainer>
          
          <InputContainer className="form-group">
            <Label  className=" control-label">Restuarant </Label>
       
              <input  type="text" className="form-control" name="resto" placeholder="Name"
                value={values.resto}
                onBlur={changeHandler}
                onChange={changeHandler}
              />
           

            {<Notice>{restoErr}</Notice>}

          </InputContainer>
       

          <InputContainer>
            <Div2 className="form-check">
            <Label className="form-check-label" > Customized </Label>
            
            <input className="form-check-input" 
            type="radio" 
            value='custom'
            name="discount"
            onChange={(e)=>setParaType(e.target.value)}
            checked={paraType == "custom" ? "checked":""}
             />
             </Div2>
             <Div2 className="form-check">
             <Label className="form-check-label" >default</Label>
            <input className="form-check-input"
             onChange={(e)=>{setParaType(e.target.value);DefaultPara();}}
             type="radio" 
             name="discount" 
             value='default'
             checked={paraType == "default" ? "checked":""}/>
             </Div2>
            
            </InputContainer>
     {paraType == "custom" ?   <InputContainer>
       <Label for="name" className="col-sm-2 control-label">Navbar Color </Label>
        <Swatch onClick={handleNavClick}>
          <Color color={navColor}/>
        </Swatch>
        { click1 ? <Popover>
          <Cover onClick={ handleNavClick }/>
          <ChromePicker
           color={ navColor}
           name="navColor"
           value={navColor}
            onChange={ e=>setNavColor(e.hex) }
            onBlur={changeHandler}
          
           />
        </Popover> :"" }
        </InputContainer>
                       : <Div3><Div4>Navbar Color</Div4>  <Color color={navColor}/></Div3> }


        {paraType == "custom" ?  <InputContainer>
       <Label for="name" className="col-sm-2 control-label">Ticker Color </Label>
        <Swatch onClick={handleTickerClick}>
          <Color color={tickerColor}/>
        </Swatch>
        { click2 ? <Popover>
          <Cover onClick={ handleTickerClick }/>
          <ChromePicker
            name="tickerColor"
            value={tickerColor}
           color={tickerColor}
            onChange={ e=>{setTickerColor(e.hex);}}
            onBlur={changeHandler}
             />
        </Popover> :"" }
        </InputContainer> : <Div3><Div4>Ticker Color</Div4><Color color={tickerColor}/></Div3> }
      
        {paraType == "custom" ?     <InputContainer>
       <Label for="name" className="col-sm-2 control-label">Nav Lyrics Color </Label>
        <Swatch onClick={handleLyricsClick}>
          <Color color={lyricsColor}/>
        </Swatch>
        { click3 ? <Popover>
          <Cover onClick={ handleLyricsClick }/>
          <ChromePicker
            name="lyricsColor"
            value={lyricsColor}
           color={lyricsColor}
            onChange={ e=>{setLyricsColor(e.hex); }}
           
             />
        </Popover> :"" }
        </InputContainer> : <Div3><Div4>Nav Lyrics Color </Div4><Color color={lyricsColor}/></Div3> }

        {paraType == "custom" ?    <InputContainer>
       <Label for="name" className="col-sm-2 control-label">Ticker Lyrics Color </Label>
        <Swatch onClick={handleTickerLyricsClick}>
          <Color color={lyricsTickerColor}/>
        </Swatch>
        { click4 ? <Popover>
          <Cover onClick={ handleTickerLyricsClick }/>
          <ChromePicker
            name="lyricsTickerColor"
            value={lyricsTickerColor}
           color={lyricsTickerColor}
            onChange={ e=>{setLyricsTickerColor(e.hex); }}
           
             />
        </Popover> :"" }
        </InputContainer> : <Div3><Div4>Ticker Lyrics Color</Div4><Color color={lyricsTickerColor}/></Div3> }

        {paraType == "custom" ?     <InputContainer>
       <Label for="name" className="col-sm-2 control-label">Order Button Color </Label>
        <Swatch onClick={handleOrderBtnClick}>
          <Color color={orderBtnColor}/>
        </Swatch>
        { click5 ? <Popover>
          <Cover onClick={handleOrderBtnClick }/>
          <ChromePicker
            name="orderBtnColor"
            value={orderBtnColor}
           color={orderBtnColor}
            onChange={ e=>{setOrderBtnColor(e.hex); }}
           
             />
        </Popover> :"" }
        </InputContainer> : <Div3> <Div4>Order Button Color </Div4><Color color={orderBtnColor}/></Div3> }
          </Dialog>
          </Wrapper>
          </>
    )
}

export function EditTheme(props){
    if (!props.openTheme) return null;
    return <EditThemeContainer {...props}/>
}