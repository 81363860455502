import React from "react";
import styled from "styled-components";
import LangData from "../../../translation.json"
import { useState, useEffect } from "react";
var _ = require('lodash');
// Get a database reference
const Container = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
  font-size: 26px;
  font-family: "Inter", sans-serif;
  margin-top:30px;
  position:fixed;
  width:${(props) => props.width};
  top:10px;
  right:0;
  height:70%;
  margin-left:auto;
  margin-right:auto;
  font-size: 20px;
  align-content: center;
`;
const AccountsDiv = styled.div`
  position:relative;
  display:flex;
  flex-direction:row;
  overflow-y: scroll;
  width: 85%;
  height: 490px;
  border: solid 1px;
  border-color: gray;
  border-radius: 10px;
  margin-left:auto;
  margin-right:auto;
  @media screen and (max-width: 1200px) {
    width: 85%;
  }
  @media screen and (max-width: 1050px) {
    width: 85%;
  }
`;
const AccountGrid = styled.div`
white-space: nowrap;
  height: auto;
  width:auto;
  border-color: gray;
  display:flex;
  flex-direction:row;
  gap: 15px 15px;
  margin: 10px;
  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr 1fr ;
  }
`;
const AccountGrid2 = styled.div`
white-space: nowrap;
  height: auto;
  width:auto;
  border-color: gray;
  display:flex;
  flex-direction:row;
  gap: 25px 25px;
  margin: 14px;
  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr 1fr ;
  }
`;
const BtnGrid = styled.div`
display:grid;
grid-template-columns: 1fr 1fr ;
margin: 10px;
`;
const Item = styled.input`
 width:100%;
`;
const Div0 = styled.div`

width:min-content;
`;
const Div1 = styled.div`
width:50%;
`;
const Div2 = styled.div`
position:relative;
top:0;
right:0;
width:50%;
`;


const Wrapper = styled.div``;

export function LanguagePanel({active}){
 
  const db = window.firebase.firestore();
 /*
  db.collection("Language")
      .doc("lang")
      .get()
      .then((Snapshot) => {
         
          console.log(JSON.stringify(Snapshot.data()));
      });*/

/*

 const docRef = db.collection("Language").doc("lang");
  try {

    docRef.set({
      "En": {
    "1": "Privacy Notice 💻",
    "2": "General terms and conditions 📝",
    "3": "WE ARE OPENED",
    "4": "Open From ",
    "5": " To ",
    "6": "Write Code Please",
    "7": "Loading ...",
    "8": "Delivery Fee 🛵💨",
    "9": "Minimum order is",
    "10": "You didn't order anything yet",
    "11": "Expired 😕",
    "12": "Order do not contain ",
    "13": "Category",
    "14": "Quantity not enough",
    "15": "Promotion code applied",
    "16": "Code already Used",
    "17": "Wrong Code",
    "18": "Promotion code canceled",
    "19": "Your Order ",
    "20": "Total ",
    "21": "Checkout",
    "22": "Apply",
    "23": "Promotion Code",
    "24": "PIZZAS",
    "25": "PREMUIM PIZZAS",
    "26": "DESSERTS",
    "27": "SAUCES",
    "28": "DRINKS",
    "29": "Profile",
    "30": "Messages",
    "31": "Admin Panel",
    "32": "Log out",
    "33": "Hello ",
    "34": "Name: ",
    "35": "Email: ",
    "36": "Address: ",
    "37": "Zip Code: ",
    "38": "Phone:  ",
    "39": "Floor: ",
    "40": "Door: ",
    "41": "Points: ",
    "42": "You can edit your profile",
    "43": "Edit",
    "44": "Edit profile details",
    "45": "Address is required",
    "46": "zip Code is 4 numbers",
    "47": "Zip is required",
    "48": "Phone is required",
    "49": "Phone is a number",
    "50": "Sorry we don't deliver to this address",
    "51": "Zip Code is a number",
    "52": "Address ,Zip and Phone are required",
    "53": "Enter your Address:",
    "54": "Enter your phone number:",
    "55": "Save",
    "56": "It's your first time please fill your details",
    "57": "and get 10 points 🎉",
    "58": "Write Building Number Please",
    "59": "Select Adress please",
    "60": "You orders",
    "61": "You do not have any order yet ",
    "62": "Sent",
    "63": "Preparing",
    "64": "Ready",
    "65": "Cancelled",
    "66": "Extras",
    "67": "Update order : ",
    "68": "Add to order : ",
    "69": "Remark",
    "70": "Quantity: ",
    "71": "You don't have enougth points ☹",
    "72": "Payment",
    "73": "Are you sure about your information ?",
    "74": "Check now",
    "75": "Which payment you choose ? ",
    "76": "Cash 💸",
    "77": "By Card online  💳",
    "78": "Card reader 📇",
    "79": "Points 💎",
    "80": "Choose a payment type",
    "81": "Pay ",
    "82": " Cash",
    "83": " Online",
    "84": " By Card",
    "85": "💎 by points",
    "86": "Email is required",
    "87": "Password is required",
    "88": "Email incorrect format",
    "89": "Email and password are required",
    "90": "Log In",
    "91": "Email",
    "92": "Password",
    "93": "Forget password ?",
    "94": "Have a google account ? use it to sign In 😊",
    "95": "Sign Up",
    "96": "Name is required",
    "97": "Password does not match",
    "98": "Confirm password",
    "99": "Reset Password",
    "100": "Send Email",
    "101": "WE ARE CLOSED",
    "102": "Menu",
    "103": "Name , Email and password required",
    "114": "Supi",
    "120": "Test",
    "121": "APERITIFE",
    "122": "Connect to see delivery Fee",
    "123": "testEn",
    "124": "Have comment about the delivery?",
    "125": "Accept terms and conditions",
    "126": "Don't you forget something 😃",
    "127": "Have a google account ? it will be easier 😊",
    "128": "Continue as guest 😎",
    "129": "Guest 😎",
    "130": "Next ✔",
    "131": "Packaging Fee 📦",
    "137": "ACTIONS",
    "138": "Building Number",
    "139": "Building Number is required ",
    "140": "Under delivery",
    "141": " Fast google sign in 🤩 ",
    "142": "Write your address and choose the right one from the suggesstion list please.",
    "143": "Would you like to add something 😊",
    "144": "Add to order",
    "145": "Continue to payment",
    "146": "Enter the City and the street!",
    "147": "Phone number",
    "Bab": "Beans",
    "Bacon Csík": "Bacon Slice",
    "Bacon Kocka": "Bacon Kocka",
    "Bacon csík": "Bacon strips",
    "Bacon kocka": "Bacon cubes",
    "Brokkoli": "Broccoli",
    "Cheddar": "Cheddar",
    "Csirke": "Chicken",
    "Csirkemell": "Chicken ",
    "Csokiöntet": "Chocolate ",
    "Csípős alap": "Spicy sauce",
    "Darálthús": "Minced meat",
    "Eperöntet": "Strawberry",
    "Erős paprika": "Spicy peppers",
    "Fokhagymás Alap": "Garlic Base",
    "Fokhagymás alap": "Garlic base",
    "Füstölt Sajt": "Smoked cheese",
    "Fütyi": "Fütyi",
    "Gomba": "Mushrooms",
    "Jalapeno": "Jalapeno",
    "Jégsaláta": "Iceberg lettuce",
    "Kakaós": "Cocoa",
    "Karamellöntet": "Caramel ",
    "Kolbász": "Sausage",
    "Kukorica": "Maize",
    "Lekváros": "Jam",
    "Lilahagyma": "Cauliflower",
    "Mandula": "Mandula",
    "Mozarella": "Mozarella",
    "Málnaöntet": "Raspberry sauce",
    "Mézes Alap": "Honey Fund",
    "Mézes-mustár alap": "Honey-mustard",
    "Nutella": "Nutella",
    "Olivabogyó": "Olives",
    "Paprika": "Peppers",
    "Paradicsom": "Tomatoes",
    "Paradicsom szósz": "Tomato sauce",
    "Paradicsomos alap": "Tomato base sauce",
    "148": "Incoming",
    "149": "In Preparation",
    "150": "Finished",
    "151": "Incoming Orders",
    "152": "No orders yet",
    "153": "Check ",
    "154": "Orders In preparation",
    "155": "Finished Orders",
    "156": "Details",
    "157": "Thank you for your loyalty",
    "158": "Enjoy your meal 😋",
    "159": "Accept Wolt!",
    "160": "Ready Wolt!",
    "161": "Accept!",
    "162": "Print again",
    "163": "Set it ready!",
    "164": "Delivery",
    "165": "Pick Up",
    "166": "Reason To cancel",
    "167": "Cancel wolt!",
    "168": "Cancel!",
    "169": "House number: ",
    "170": "Restaurant: ",
    "171": "Products",
    "172": "Add new",
    "173": "Loading ...",
    "174": "Categories",
    "175": "Extras",
    "176": "ZIP",
    "177": "Fees",
    "178": "C-Sales",
    "179": "Add Product",
    "180": "Category: ",
    "181": "Extra type: ",
    "182": "Status: ",
    "183": "Active",
    "184": "Passive",
    "185": "Quantity: ",
    "186": "Points: ",
    "187": "Ingredient: ",
    "188": "Price: ",
    "189": "Action: ",
    "190": "Upload",
    "191": "Sizes ?",
    "192": "Product Edit",
    "193": "You can save the product now",
    "194": "Edit",
    "195": "Orders",
    "196": "Schedule ",
    "197": "Performance",
    "198": "Frontend",
    "199": "Accounts",
    "200": "Coupons",
    "201": "History",
    "202": "Language",
    "203": "Support",
    "204": "Category Add",
    "205": "Path is same as name but without spcial charachters",
    "206": "Path",
    "207": "Options Add",
    "208": "Group ",
    "209": "At least one topping is required",
    "210": "Name in Hungarain",
    "211": "Name in English",
    "212": "Extra can not be empty",
    "213": "Price",
    "214": "Price can not be empty",
    "215": "Price is a number",
    "216": "Options Add ",
      },
      "Hu": {
    "1": "Adatvédelmi Tájékoztató 💻",
    "2": "Általános Szerződési feltételek 📝",
    "3": "NYITVA VAGYUNK",
    "4": "Ma Nyitva:",
    "5": "-",
    "6": "Írj kódot, kérlek",
    "7": "Töltés ...",
    "8": "Szállítási díj 🛵💨",
    "9": "Minimális rendelés",
    "10": "Még nem rendeltél semmit",
    "11": "Lejárt 😕",
    "12": "A rendelés nem tartalmaz ",
    "13": "Kategória",
    "14": "A mennyiség nem elég",
    "15": "Alkalmazott promóciós kód",
    "16": "Már használt kód",
    "17": "Rossz kód",
    "18": "Promóciós kód törölve",
    "19": "Az Ön rendelése ",
    "20": "Összesen",
    "21": "Pénztár",
    "22": "Alkalmazás",
    "23": "Promóciós kód",
    "24": "PIZZÁK",
    "25": "PRÉMIUM PIZZÁK",
    "26": "DESSZERTEK",
    "27": "SZÓSZOK",
    "28": "ITALOK",
    "29": "A profilja",
    "30": "Csevegés",
    "31": "Admin felület",
    "32": "Kijelentkezés",
    "33": "Szia ",
    "34": "Név: ",
    "35": "Email: ",
    "36": "Cím: ",
    "37": "Irányítószám: ",
    "38": "Telefon: ",
    "39": "Emelet: ",
    "40": "Ajtó: ",
    "41": "Pontok: ",
    "42": "Szerkesztheti profilját",
    "43": "Szerkesztés",
    "44": "Profil adatok szerkesztése",
    "45": "Cím szükséges",
    "46": "Az irányítószám 4 szám",
    "47": "Zip szükséges",
    "48": "Telefonszám szükséges",
    "49": "A telefon egy szám",
    "50": "Sajnáljuk, nem szállítunk erre a címre",
    "51": "Az irányítószám egy szám",
    "52": "Cím ,Irányítószám és telefonszám szükséges",
    "53": "Adja meg a címét:",
    "54": "Telefonszám:",
    "55": "Mentés",
    "56": "Ez az első alkalom, kérjük, töltse ki adatait",
    "57": "és kapsz 10 pontot 🎉",
    "58": "Írja az épület számát, kérem",
    "59": "Válassza ki a címet, kérem",
    "60": "Megrendelések",
    "61": "Még nincs megrendelése ",
    "62": "Elküldve",
    "63": "Készítés alatt",
    "64": "Kiszállítva",
    "65": "Törölve",
    "66": "Extrák",
    "67": "Frissítési megbízás : ",
    "68": "Hozzáadás a megrendeléshez : ",
    "69": "Megjegyzés",
    "70": "Mennyiség: ",
    "71": "Nincs elég pontod ☹",
    "72": "Fizetés",
    "73": "Biztos vagy az információidban ?",
    "74": "Ellenőrizze most",
    "75": "Melyik fizetési módot választja ? ",
    "76": "Készpénz 💸",
    "77": "Kártyával online 💳",
    "78": "Kártyával helyszínen (OTP Szépkártya is!) 📇",
    "79": "Pontok 💎",
    "80": "Fizetési típus kiválasztása",
    "81": "Fizetés ",
    "82": " Készpénz",
    "83": " Online",
    "84": " Kártyával",
    "85": "💎 pontok szerint",
    "86": "Email szükséges",
    "87": "Jelszó szükséges",
    "88": "Hibás e-mail formátum",
    "89": "Email cím és jelszó szükséges",
    "90": "Fiókom",
    "91": "E-mail",
    "92": "Jelszó",
    "93": "Elfelejtetted a jelszavad ?",
    "94": "Van google fiókod ? használd a bejelentkezéshez 😊",
    "95": "Regisztráció",
    "96": "Név kötelező",
    "97": "A jelszó nem egyezik",
    "98": "Jelszó megerősítése",
    "99": "Jelszó visszaállítása",
    "100": "E-mail küldése",
    "101": "ZÁRVA VAGYUNK",
    "102": "Menü",
    "103": "Név ,Email és jelszó szükséges",
    "114": "Supi",
    "120": "TestHu",
    "121": "Aperitiffe",
    "122": "Csatlakozás a szállítási díjért",
    "123": "testHu",
    "124": "Van üzeneted az étteremnek ?",
    "125": "Elfogadja a feltételeket",
    "126": "Ne felejts el valamit 😃",
    "127": "Van google fiókod ? Így könnyebb lesz 😊",
    "128": "Tovább vendégként 😎",
    "129": "Vendég 😎",
    "130": "Következő ✔",
    "131": "Csomagolási díj 📦",
    "137": "Akciók",
    "138": "Épület száma",
    "139": "Az épület száma szükséges ",
    "140": "Szállítás alatt",
    "141": " Gyors google bejelentkezés 🤩",
    "142": "Kérlek írd be a várost és az utca nevét majd válaszd ki a listából.",
    "143": "Szeretne hozzáadni valamit 😊",
    "144": "Hozzáadás a megrendeléshez",
    "145": "Folytassa a fizetést",
    "146": "Adja meg a Várost és az utcát!",
    "147": "Telefonszám",
    "Ananász": "Ananász",
    "Bab": "Bab",
    "Bacon Csík": "Bacon Csík",
    "Bacon Kocka": "Bacon Kocka",
    "Bacon csík": "Bacon csík",
    "Bacon kocka": "Bacon kocka",
    "Brokkoli": "Brokkoli",
    "Cheddar": "Cheddar",
    "Csirke": "Csirke",
    "Csirkemell": "Csirkemell",
    "Csokiöntet": "Csokiöntet",
    "Csípős alap": "Csípős alap",
    "Darálthús": "Darálthús",
    "Eperöntet": "Eperöntet",
    "Erős paprika": "Erős paprika",
    "Fokhagymás Alap": "Fokhagymás Alap",
    "Fokhagymás alap": "Fokhagymás alap",
    "Füstölt Sajt": "Füstölt Sajt",
    "Fütyi": "Fütyi",
    "Gomba": "Gomba",
    "Jalapeno": "Jalapeno",
    "Jégsaláta": "Jégsaláta",
    "Kakaós": "Kakaós",
    "Karamellöntet": "Karamellöntet",
    "Kolbász": "Kolbász",
    "Kukorica": "Kukorica",
    "Lekváros": "Lekváros",
    "Lilahagyma": "Lilahagyma",
    "Mandula": "Mandula",
    "Mozarella": "Mozarella",
    "Málnaöntet": "Málnaöntet",
    "Mézes Alap": "Mézes Alap",
    "Mézes-mustár alap": "Mézes-mustár alap",
    "Nutella": "Nutella",
    "Olivabogyó": "Olivabogyó",
    "Paprika": "Paprika",
    "Paradicsom": "Paradicsom",
    "Paradicsom szósz": "Paradicsom szósz",
    "Paradicsomos alap": "Paradicsomos alap",
    "Pizzaszósz": "Pizzaszósz",
    "Ruccola": "Ruccola",
    "Rukkola": "Rukkola",
    "Sajt": "Sajt",
    "Saláta": "Saláta",
    "Sonka": "Sonka",
    "Szalámi": "Szalámi",
    "Tejfölös alap": "Tejfölös alap",
    "Túrós": "Túrós",
    "Vöröshagyma": "Vöröshagyma",
    "148": "Bejövő",
    "149": "Előkészületben",
    "150": "Kész",
    "151": "Beérkező megrendelések",
    "152": "Még nincs megrendelés",
    "153": "Nyílt",
    "154": "Megrendelések Előkészítés alatt",
    "155": "Befejezett megrendelések",
    "156": "Részletek",
    "157": "Köszönjük hűségét",
    "158": "Jó étvágyat 😋",
    "159": "Fogadjátok el Woltot!",
    "160": "Kész Wolt!",
    "161": "Elfogadom!",
    "162": "Újra nyomtatni",
    "163": "Készítsd elő!",
    "164": "Szállítás",
    "165": "Vedd fel",
    "166": "A lemondás oka",
    "167": "Törölje wolt!",
    "168": "Törölje!",
    "169": "Házszám: ",
    "170": "Étterem: ",
    "171": "Termékek",
    "172": "Új hozzáadása",
    "173": "Betöltés ...",
    "174": "Kategóriák",
    "175": "Extrák",
    "176": "ZIP CÍM",
    "177": "Díjak",
    "178": "Marketing",
    "179": "Termék hozzáadása",
    "180": "Kategóriák: ",
    "181": "Extra típus: ",
    "182": "Állapot: ",
    "183": "Aktív",
    "184": "Passzív",
    "185": "Mennyiség: ",
    "186": "Pontok: ",
    "187": "Összetevők: ",
    "188": "Ár: ",
    "189": "Akció: ",
    "190": "Feltöltés",
    "191": "Méretek ?",
    "192": "Termék szerkesztése",
    "193": "Most elmentheti a terméket",
    "194": "Szerkesztés",
    "195": "Rendelések",
    "196": "Ütemterv ",
    "197": "Teljesítmény",
    "198": "Frontend",
    "199": "Számlák",
    "200": "Kuponok",
    "201": "Történelem",
    "202": "Nyelv",
    "203": "Támogatás",
    "204": "Kategória hozzáadása",
    "205": "Az elérési út megegyezik a névvel, de különleges karakterek nélkül.",
    "206": "Elérési",
    "207": "Opciók hozzáadása",
    "208": "Csoport ",
    "209": "Legalább egy feltét szükséges",
    "210": "Név magyarul",
    "211": "Név angolul",
    "212": "Extra nem lehet üres",
    "213": "Ár",
    "214": "Az ár nem lehet üres",
    "215": "Az ár egy szám",
    "216": "Opciók hozzáadása",
      }
    });
    console.log("submited :=)")
  } catch (e) {
    console.log(e);
  }


*/


const [languages , setLanguages] = useState()
const canShowLanguages = languages && true;
  //useEffect to set categories and options

  useEffect(() => {
    db.collection('Language').get().then((data) => { return setLanguages(data.docs[0].data()) });
  }, []);









     function ChangeEn(val,itm,ind){
    const docRef = db.collection("Language").doc("lang");
  

    docRef.update({
      "En":{
        ...languages.En,
        [ind]: val
      }})

         return val ;

     }

     function ChangeHu(val,itm,ind){
      const docRef = db.collection("Language").doc("lang");
  

      docRef.update({
        "Hu":{
          ...languages.Hu,
          [ind]: val
        }})
      return val ;

  }

  
    return (
        <Container  width={ active ? "calc(100% - 220px)" : "calc(100% - 40px)"}>
       
         <AccountsDiv>
            <Div0>
            {canShowLanguages &&  Object.keys(languages.En).filter((x)=>!isNaN(x)).map((item,index)=>{
            return(
                <Wrapper key={index}>
                      <AccountGrid2 >
                      {item}
                      </AccountGrid2>
                    
          
                </Wrapper>
            ) 
           
           })}
            </Div0>
            <Div1>
           {canShowLanguages && _.toArray(languages.En).slice(0,Object.keys(languages.En).filter((x)=>!isNaN(x)).length).map((item,index)=>{
            return(
                <Wrapper key={index}>
             
                <AccountGrid >

                <Item defaultValue={item}  onChange={(e)=>{ChangeEn(e.target.value,item,Object.keys(languages.En).find(key => languages.En[key] === item))}} />
              
                   
                
                </AccountGrid>
                </Wrapper>
            ) 
           
           })}
      </Div1>
      <Div2>
      {canShowLanguages && _.toArray(languages.Hu).slice(0,Object.keys(languages.En).filter((x)=>!isNaN(x)).length).map((item,index)=>{
            return(
                <Wrapper key={index}>
             
                <AccountGrid >

                <Item defaultValue={item}  onChange={(e)=>{ChangeHu(e.target.value,item,Object.keys(languages.Hu).find(key => languages.Hu[key] === item))}}  />
              
                   
                
                </AccountGrid>
                </Wrapper>
            ) 
           
           })}
      </Div2>
         </AccountsDiv>
   
        </Container>
    )
}