import React from "react";
import styled from "styled-components";
import useForm from "../Hooks/useForm";
import { useState} from "react";
import {T} from "../Language/translate";
const Dialog = styled.div`
position: fixed;
  width: auto;
  height:auto;
  background-color: white;
  margin-bottom:50px;
 
  z-index: 55;
margin:auto;
padding:10px;
  display: flex;
  flex-direction: column;
  border-radius:5px;
  // Add this part
  @media (max-width: 400px) {
    
    overflow-y: scroll;
    height:auto;
    width: 100%;
 
    
  }
`;
const InputContainer = styled.div`
  display: grid;
  grid-template-columns:  1fr ;
  margin:5px;
  width:100%;
  
`;
 const Wrapper = styled.div`
z-index: 55;
position:fixed;
width:100%;
height:100%;
left:0;
bottom:0;
  display: flex;
  justify-content: center;
  align-items: center;

`;

const DialogShadow = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  left:0px;
  background-color: black;
  opacity: 0.7;
  z-index: 51 !important;
`;
const Close = styled.div`
height: 30px;
width: 30px;
display :flex;
align-items:center;
justify-element:center;
position:absolute;
background-image:url("/img/close.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border: none; 
margin:10px;
top:0;
right:0;
font-size: 18px;
font-weight:bold;
 &:hover{
    cursor: pointer;
    opacity:0.7;
 }
`;
const Header = styled.div`
margin-top: 10px;
display:flex;
text-align:center;
align-items: center;
justify-content: center;

`;



const X = styled.div`
margin-left:35px;

`;
const Notice = styled.span`
width:400px;
white-space: nowrap;
  margin-left:20px;
  color: red;
  font-size: 14px;
`;
const Container = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
`;
const ButtonStyled = styled.button`
max-height:40px;
text-decoration: none;
margin: 10px;
&:hover{
    
  
}
`;
const Google = styled.div`
 height: 50px;
 width: 50px;
 margin-left:10px;
 margin-top:10px;
 background-image:url("/img/Google.png");
 background-position: center;
 background-repeat: no-repeat;
 background-size: cover;
 border: none; 
 cursor:pointer;
`;
const Text = styled.div`
text-align:center;
`;
const B = styled.button`
margin:15px;
`;
// function for required fields 
function isRequired(value) {
    return value != null && value.toString().trim().length > 0;
  }
  function ValidateEmail(value){
    return String(value)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }
  function PassConfirm(val1,val2){
    return val1===val2 ;
  }
export function SignUp({setSignUpPanel,signUpWithEmail,loginWithGoogle,prop,message,signedIn,lang}){
    const [nameErr, setNameErr] = useState("");
    const [emailErr,setEmailErr] = useState("");
    const [passErr,setPassErr] = useState("");
     const [err,setError] = useState("");

    //Form validation 
    const initialState = {name:"", email:"",password:"",passConfirm:""};
    const validations = [
      ({name}) => isRequired(name) || {name: T("96",lang)},
      ({email}) => isRequired(email) || {email: T("86",lang)},
      ({password}) => isRequired(password) || {password: T("87",lang)},
      ({password,passConfirm}) => PassConfirm(password,passConfirm) || {password: T("97",lang)},
      ({email})=> ValidateEmail(email)!=null  || {email:T("88",lang)}
    ];
   
    const {values, isValid, errors, changeHandler} = useForm(initialState, validations, prop);
   
   function SignupEmail(){
    setEmailErr("");
    setPassErr("");
    setError("");
    signUpWithEmail(values.email,values.password,values.name);

   }
   function showError(){
    if(!(errors.email || errors.password || errors.name)){
        setError(T("103",lang));
    }
       setNameErr(errors.name);
       setEmailErr(errors.email);
       setPassErr(errors.password);
    
 }


    return(
        <>
        <DialogShadow />
        <Wrapper>
        <Dialog >
        {signedIn ? setSignUpPanel(false):""}
        <Close onClick={()=>{setSignUpPanel(false)}}/>
         <Header>{T("95",lang)}</Header>
         <Container>
         <Notice>{!( errors.name||errors.email || errors.password) ? err : nameErr}</Notice>
       <InputContainer className="form-group">
      
      
      <input type="text" className="form-control" name="name"  placeholder={T("34",lang)}   value={values.text} onChange={changeHandler} />
     
   </InputContainer>
  <Notice>{emailErr}</Notice>
  <InputContainer className="form-group">
      
    
      <input type="email" className="form-control" name="email"  placeholder={T("35",lang)}   value={values.text} onChange={changeHandler} />
      
   </InputContainer> 
   <Notice>{passErr}</Notice>
   <InputContainer className="form-group">
     
     
      <input type="password" className="form-control" name="password"  placeholder={T("92",lang)}    value={values.text} onChange={changeHandler} />
    
     
   </InputContainer> 
   <InputContainer className="form-group">
     
     <input type="password" className="form-control" name="passConfirm"  placeholder={T("98",lang)}  value={values.text} onChange={changeHandler} />
    
    
  </InputContainer> 
 
  
   <ButtonStyled className="btn btn-primary"   onClick={()=>{!isValid ?showError():SignupEmail()}} >
   {T("95",lang)}
         </ButtonStyled>
         { (isValid && message) && <Notice>{message}</Notice>}
         <Text  >{T("94",lang)}</Text>
         <B className="btn btn-primary" onClick={()=>{loginWithGoogle();setSignUpPanel(false)}}>{T("141",lang)} </B>
         
   </Container>
        </Dialog>
        </Wrapper>
        </>
    )
}