import React, {useState,useEffect,useRef} from 'react'
import styled from "styled-components";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { Link } from 'react-router-dom';
import { SidebarData } from './Data';
import { IconContext } from 'react-icons/lib';
import { T } from '../Language/translate';
import HamButton from './Pages/HamButton';
import './navbar.css'
import { NotifyPanelWindow } from './Pages/Support/NotifyPanelWindow';
const SideBarCover = styled.div`
height:100% ;
bottom:0;
position:fixed;
width: ${(props) => props.SideBarCoverWidth};
transition: width 1s;
flex-shrink: 0;
background-color:rgba(0,0,0,.7);
z-index:0;
`;
const SideBarStyled = styled.div`
display:${(props) => props.SideBarDisplay};
position:fixed;
bottom:0;
height:calc(100% - 40px);
width: 200px;
flex-shrink: 0;
background-color:#393D42;
transition: display 2s;
z-index:6;
`;
const Title = styled.div`
margin-top:5px;
position:relative;
margin-bottom:5px;
margin-left:10px;
height:40px;
width: ${(props) => props.width};
text-align:center;
z-index:5;
`;
const MenuStyled = styled.div`
position:fixed;
display:flex;
bottom:0;
flex-direction:column;
left:0;
height:calc(100% - 40px);
width: 53px;
flex-shrink: 0;
background-color:#2A2F33;
z-index:6;
`;
const MenuItemsWrapper = styled.div`
display:grid;
grid-template-columns:5px 45px ;
margin-right:.3em;
`;
const MenuItemLogo = styled.div`
height: 35px;
width: 35px;
display :flex;
margin-left:auto;
margin-right:auto;
margin-top:.35em;
margin-bottom:.35em;
background-image: url(${(props) => props.img});
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border-radius: 5px; 
font-size: 18px;
font-weight:bold;
cursor:pointer;
${({ disabled }) => disabled && `
opacity: .5;
background-color: grey;
`}
`;
const MenuItemPointer = styled.div`
margin-top:auto;
margin-bottom:auto;
border-radius: 0px 5px 5px 0px;
height: 29px;
width: 5px;
background-color:#E5F5DB;
`;

const SideBarItem = styled.div`
position:relative;
z-index:3;
  color: white;
  margin:0;
  height:40px;
  padding-top:.5em;
  padding-bottom:.5em;
  padding-left:0.1em;
  font-size:1.1em;
  text-decoration:none;
  display:block;
  background-color:${(props) => props.BackgroundColor};
  &:hover{
    cursor: pointer;     
 } 
`;
const ItemTicker = styled.div`
  position:relative ;
  display:${(props) => props.display};
  left:30px;
  bottom:-5px;
  width: 0; 
  height: 0; 
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #E5F5DB;
`;
const SideBarItemTitle = styled.span`
display:grid;
grid-template-columns:50px 110px auto ;
`;
const Div = styled.div`
 font-size:32px;
 font-weight:300;
 color:white;
 margin:-10px auto 0px auto;
 width:100%;
 display:flex;
justify-items:center;
align-content:center;
text-align:center;
 `;
const Div2 = styled.div`
display:flex;
justify-items:center;
align-content:center;
text-align:center
 `;
const Wrapper = styled.div`
 display:flex;
justify-items:center;
align-content:center;
 margin:auto;
 width:auto;
 `;
const ItemContent = styled.div`
padding-top:${(props) => props.padding};
padding-bottom:${(props) => props.paddingBottom};
padding-left:1.2em;
height:${(props) => props.height};
overflow:hidden;
background-color:#32363A;
`;
const Li = styled.li`
color:${(props) => props.color};
&:hover{
    cursor: pointer;  
 }
`;
const Ul = styled.ul`
margin:0;
padding-top:.5em;
padding-bottom:.5em;
&:hover{
    cursor: pointer;
    background-color:rgba(255,255,255,.1);
 }
`;
const I = styled.i`
color:${(props) => props.color};
`;
const MenuItems = styled.ul`
width: 100%;

`;
const Container = styled.div`
width:${(props) => props.width};
position:fixed;
z-index:1;
`;
const HC = styled.div`
display:flex;
flex-direction:row;`;
const OrderNotif = styled.div`
position:relative;
font-size:12px;
height: 20px;
width: 20px;
background-color:green;
border-radius:50%;
justify-content:center;
align-items: center;
text-align: center;`;

var _ = require('lodash');
const database = window.firebase.database();
const ref = database.ref('orders');
const db = window.firebase.firestore();

function MenuBar({lang, open, setOpen, active, setActive,  NotifyPanel, setNotifyPanel}) {
  const[category,setCategory] = useState("orders");
  const [rooms , setRooms] = useState([]);
  const [orders,setOrders]= useState(null);
  const [ind, setInd] = useState(0);
  const [ind2, setInd2] = useState(0);
  const [indChild, setChildInd] = useState(1);
  const [tabIndice, setTabIndice] = useState(3);
  const intervalRef = useRef(null);
  useEffect(()=>{ 
    db.collection('support').onSnapshot((data)=>{return  setRooms(data.docs)});
    ref.orderByChild('status').equalTo('sent').on('value', (snapshot) => {          
      const data = _.toArray(snapshot.val());
      setOrders(data);
      })
      const executeFunction = () => {
        // Function to be executed every 5 minutes
        console.log('Executing function every 5 minutes');
        
        db.collection('support').get().then((data)=>{
          return  data.docs.map((room)=>{return room.data().messages ? room.data().messages[room.data().messages.length-1].sender =="user":[]}).filter((t)=>t==true)!=0&&
        setNotifyPanel('yes')});
        setTimeout(executeFunction, 5*60*1000); // Recursive call after 5 minutes
      };
      const timeoutId = setTimeout(executeFunction, 5*60*1000); // Initial call after 5 minutes
      return () => {
        clearTimeout(timeoutId);
      };
    
},[]);

function TabClick(x, y) {
  setInd2(x);
  setOpen(false);
  setInd(0);
  setTabIndice(y)
  setChildInd();
}
  return (
    <Container width={!active ? "40px" : "200px"}>
      <NotifyPanelWindow NotifyPanel={NotifyPanel} setNotifyPanel={setNotifyPanel} lang={lang}/>
      <HamButton active={active}set={setActive}></HamButton>
 <SideBarCover SideBarCoverWidth={!active ? "40px" : "200px"} />
      <Title width={!active ? "0px" : "160px"}><b style={{ "fontWeight": "bolder", "fontSize": "22px","color":"#0045F5" }}>
        {active ? '🍕':''}</b></Title>
    
   
      <SideBarStyled SideBarDisplay={!active ? "none" : "block"}>

        {SidebarData.map((item, index) => {
          return (
            <div key={index}>
               
              <Link to={item.path} style={{ "textDecoration": "none" }} >
                <SideBarItem
                  BackgroundColor={active && ind == index ? "#0045F5" : "inherit"}
                  key={index}
                  onClick={() => { setInd(index); !open || ind == index ? setOpen(!open) : setOpen(open);setChildInd(0); }}>
                  <SideBarItemTitle>
                    <IconContext.Provider
                      value={active && ind == index ? { color: '#fff' } : { color: '#fff' }
                      }>
                      <Div2><Wrapper>{item.icon}</Wrapper></Div2>
                    </IconContext.Provider>
          
                    <Div2><Wrapper>{T(item.title,lang)}</Wrapper></Div2>

                    {rooms &&index === 9 && rooms.map((room)=>{return room.data().messages ? room.data().messages[room.data().messages.length-1].sender =="user":[]}).filter((t)=>t==true)!=0 &&
                 <OrderNotif>{rooms.map((room)=>{return room.data().messages[room.data().messages.length-1].sender =="user"}).filter((t)=>t==true).length}
                 </OrderNotif>}

                


                    {orders &&index === 0 && orders.length!=0 &&
                 <OrderNotif>{orders.length}
                 </OrderNotif>}
                    <Div2> {item.childrens ? <Wrapper><Div>{open && ind == index && item ? "-" : "+"}</Div></Wrapper> : <div />}</Div2>
                  
                  </SideBarItemTitle>
                 
                </SideBarItem>
              </Link>
              {item.childrens ? <ItemContent height={open && ind == index && item ? "auto" : "0"}
                padding={open && ind == index && item ? ".25em" : "0"}
                paddingBottom={open && ind == index && item ? ".25em" : "0"}>
                {
                  item.childrens.map((child, ChildIndex) => {
                    return (
                      <Ul key={ChildIndex}>
                        <Link to={child.path} style={{ "textDecoration": "none" }}>
                          <Li
                            color={indChild == ChildIndex ? "#0045F5" : "#fff"}
                            onClick={() => { setChildInd(ChildIndex); }}>
                            {T(Number(child.title),lang)}
                          </Li>
                        </Link>

                      </Ul>
                    )
                  })
                }
              </ItemContent> : ""}
            </div>
          );
        })}
      </SideBarStyled>
   
    </Container>
  )
}

export default MenuBar
