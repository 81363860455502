import React, { useState } from "react";
import { ImageSent } from "../Styles/images";
import styled from "styled-components";
import { Close } from "../Profile/profile";
import { T } from "../Language/translate";

const Dialog = styled.div`
  width: 350px;
  height:350px;
  text-align:center;
  position: fixed;
  background-color: #f5f5f5;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
 margin:auto;
  border-radius:10px;
  z-index: 60;
  transition: opacity 0.5s;
  display: flex;
  flex-direction: column;
  justify-items:center;
  align-content:center;
  // Add this part
  @media(max-width: 700px){ 
  
    width: 200px;
    height:180px;  
}
  @media(max-width: 400px){ 
 
  
    z-index: 55; 
  }
`;
const Wrapper = styled.div`
z-index: 55;
position:fixed;
width:100%;
height:100%;
left:0;
bottom:0;
  display: flex;
  justify-content: center;
  align-items: center;

`;

const DialogShadow = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  left:0px;
  background-color: black;
  opacity: 0.7;
  z-index: 51 !important;
`;
const Container = styled.div`
width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
 background-color:inherit;
  color: #000;
  text-align: center;
  border-radius: 20px;
  margin:auto;
`;
const Img = styled.img`
width: 82px;
margin-bottom: 15px;
@media(max-width: 700px){ 
  margin-top:40px;
  margin-left:auto;
  margin-right:auto;
  width: 50px;

}
@media(max-width: 400px){ 



}
`;
const P = styled.p`
margin-bottom: 20px;
font-size: 18px;
`;
const Btn = styled.button`
position:relative;
background-color: #ed6755;
margin-bottom:5px;
    border: none;
    border-radius: 5px;
    width: 200px;
    padding: 14px;
    font-size: 16px;
    color: white;
    box-shadow: 0px 6px 18px -5px rgba(237, 103, 85, 1);
    @media(max-width: 700px){ 
   
      width: 180px;
    
    }
    @media(max-width: 400px){ 
    
    
    
    }
    
`;
const session = window.sessionStorage;
export function OrderSent({show,setShow,loggedIn,setOrders,lang}){
      

     
          
    return(
        <>
       <DialogShadow  />
       <Wrapper>
        <Dialog >
          <Container>
        <Close onClick={()=>{setShow()}}/>
        <Img src="img/orderSent.png" alt="Success" />
    {show=="success" ? <><P>{T(223,lang)}</P> <p>Quand le restaurant accepte la commande, il attribue un temps de préparation.</p></>:<><P>{T(224,lang)}  </P><span>0254334699</span> </> }
   {show=="success"&&<Btn className="btn" onClick={()=>{((session.getItem("GuestOk")&&loggedIn==null) ? setOrders(String(session.getItem("phone"))):setOrders(loggedIn.email));setShow();}}>{T(225,lang)}</Btn>} 
    </Container>
        </Dialog>
        </Wrapper>
        </>
    )
}