import React from 'react';
import { useOpenFood } from './Hooks/useOpenFood';
import { useMessages } from './Hooks/useMessages';
import { useAuthentication } from './Hooks/useAuthentication';
import {useState, useEffect} from 'react'
import ReactDOM from 'react-dom/client';
import App from './App';
import { AdminPage } from './AdminPanel/AdminPanel';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Model } from './Menu/MainMenu/pages/Model';
import NavLoading from './Loading/NavLoading';
import { useLanguage } from './Hooks/useLanguage';
import Ticket from './AAdminDelivery/Ticket';
import { useAdmin } from './Hooks/useAdmin';
import { useNotifySupport } from './Hooks/useNotifySupport';
import { useOrderType } from './Hooks/useOrderType';
const storage = window.firebase.storage();
const Routing = () => {
  const OrderType = useOrderType();
  const NotifyMsg = useNotifySupport();
  const dashAdmins = useAdmin();
  const openFood = useOpenFood();
  const Language = useLanguage();
  const auth = useAuthentication();
  const messages = useMessages();
  const db = window.firebase.firestore();
  const [data , setData] = useState();
  const canShowCategory = data && true;

  const [link1, setLink1] = useState("");
  const [link2, setLink2] = useState("");
  const [link3, setLink3] = useState("");
  useEffect(()=>{ 
      db.collection('Category').get().then((data)=>{return  setData(data.docs)});
     

      db.collection('footer').doc('information').onSnapshot((doc)=>{return  setLink1(doc.data().copy1)});
      db.collection('footer').doc('information').onSnapshot((doc)=>{return  setLink2(doc.data().copy2)});
      db.collection('footer').doc('information').onSnapshot((doc)=>{return  setLink3(doc.data().copy3)});
    
      },[]);
    
  return(
    <BrowserRouter>
  
 
      <Routes>
        <Route path="/" element={<App {...openFood} {...auth}Language={Language} messages={messages} dashAdmins={dashAdmins} OrderType={OrderType}/>}>
        <Route index  element={<Model category="Pizza" {...openFood} {...Language}messages={messages} {...dashAdmins}{...auth} OrderType={OrderType}/>}  />
        {  canShowCategory ? data.map((item, index)=>{
          return (
        <Route key={index} path={item.data().path}  element={<Model {...openFood} category={item.data().name}{...Language} {...dashAdmins} {...auth}/>}  />
     
          )  }) : <Route element =  {<NavLoading />} /> }

        </Route>
      
      <Route path="AdminPanel//*" element={<AdminPage {...auth}{...dashAdmins}messages={messages}Language={Language}NotifyMsg={NotifyMsg} OrderType={OrderType}/>} />
      <Route  path='/ticket'  element={<Ticket />}  />
      <Route path="/insta" element={<Instagram />} />
      <Route path="/face" element={<Facebook />} />
      <Route path="/link1" element={<Link1 />} />
      <Route path="/link2" element={<Link2 />} />
      <Route path="/link3" element={<Link3 />} />
      <Route path="/support" element={<Support/>} />
      </Routes>
    </BrowserRouter>

 
      
  )
  function Instagram() {
    // 👇️ using window.location.href 👇️
  
         // 👇️ using window.location.href 👇️
    db.collection('footer').doc('information').get().then((doc)=>{
      window.location.href = doc.data().instagram
    });
  
   
    return null;
  }
  function Facebook() {
    // 👇️ using window.location.href 👇️
    db.collection('footer').doc('information').get().then((doc)=>{
      window.location.href = doc.data().facebook
    });

    return null;
  }

  
function Link1() {
  // 👇️ using window.location.href 👇️
  storage.ref("Copyright/"+link1).getDownloadURL()
  .then((url) => {
  
    window.location.href = url;
   
  });

  return null;
}
function Link2() {
   // 👇️ using window.location.href 👇️
   storage.ref("Copyright/"+link2).getDownloadURL()
   .then((url) => {
    
     window.location.href = url;
    
   });
  return null;
}
function Link3() {
  // 👇️ using window.location.href 👇️
  storage.ref("Copyright/"+link3).getDownloadURL()
  .then((url) => {
   
    window.location.href = url;
   
  });
  return null;
}
function Support() {
  
    window.location.href = "https://www.google.com"
    return null;
  
}
}




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Routing/>);
