import React from 'react';
import styled from "styled-components";
import { useState ,useEffect } from 'react';
import { EditSchudle } from './EditScedule';
import ToggleButton from 'react-toggle-button';
import axios from "axios";
const Container = styled.div`
position:fixed;
right:0;
overflow-y: scroll;
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
font-size:26px;
font-family: 'Inter', sans-serif;
width:${(props) => props.width};
height:90%;


`;

const OpenLogo = styled.div`
 height: 40px;
 width: 40px;
 margin-left:10px;
 margin-top:20px;
 background-image:url("/img/opened.png");
 background-position: center;
 background-repeat: no-repeat;
 background-size: cover;
 border: none; 
 @media screen and (max-width: 620px) {
  margin-top:0px;
  height: 30px;
 width: 30px;
}
@media screen and (max-width: 410px) {
  margin-top:0px;
  height: 20px;
 width: 20px;
}
`;
const CloseLogo = styled.div`
 height: 40px;
 width: 40px;
 margin-left:10px;
 margin-top:20px;
 background-image:url("/img/closed.png");
 background-position: center;
 background-repeat: no-repeat;
 background-size: cover;
 border: none; 
 @media screen and (max-width: 620px) {
  margin-top:0px;
  height: 30px;
 width: 30px;
}
@media screen and (max-width: 410px) {
  margin-top:0px;
  height: 20px;
 width: 20px;
}
`;
export const SchudelDiv = styled.div`

width:auto;
height:auto;
border: solid 1px;
border-color: gray; 
border-radius : 10px;
margin-top:10px;
margin-left:auto;
margin-right:auto;
@media screen and (max-width: 1200px) {
  width:auto;
}
@media screen and (max-width: 1050px) {
  width:auto;
}
@media screen and (max-width: 620px) {
  margin-left:auto;
  margin-right:auto;
  width:80%;
}
`;

export const ScheduleGrid = styled.div`
display: grid;
grid-template-columns: 1fr 1fr 1fr ;
gap: 15px 10px;
margin:40px;
@media screen and (max-width: 620px) {
      
  margin:5px;
}

`;
 const ButtonStyled = styled.button`
max-height:40px;
min-width:150px;
text-decoration: none;
margin: 10px;
&:hover{
    opacity: 1.5;
    border:1px solid;
    border-color: lightgray; 
    border-radius : 3px;
}
`;
const X = styled.div`
display:flex;
flex-direction:row
align-items:center;
justify-content:center;
`;
const Div = styled.div`
display:flex;
flex-direction:row;
margin-left:5px;
margin-right:5px;
justify-items:center;
align-content:center;
text-align:center;
white-space: nowrap;
@media screen and (max-width: 620px) {
 
  font-size:14px !important;
}
`;
export const Wrapper = styled.div`
display:flex;
flex-direction:row;
@media screen and (max-width: 360px) {
 
  flex-direction:column;
}`;
const InputContainer = styled.div`
  display: grid;
  grid-template-columns: .5fr 1fr ;
  margin:5px;
  width:100px;
  
`;
const Label = styled.div`
height: 60px;
width: 200px;
margin-left: 10px;
margin-top: 5px;
background-image: url(/img/wolt.png);
background-position: center;
background-repeat: no-repeat;
background-size: contain;
border: none;
`;
function Schedule({active}) {

  
  const db = window.firebase.firestore();
  const [data , setData] = useState();
  const [woltState ,setWoltState]=useState();
  const [interval ,setInterval]=useState();
 //use state to edit data 
 const [openItem, setOpenItem] = useState();
  const canShowSchudel = data && true;
  const canShowState = woltState!=null&&true;
 



  useEffect(()=>{ 
    db.collection('schudel').onSnapshot((data)=>{return  setData(data.docs)});
    db.collection("schudel").doc("wolt").onSnapshot((doc) => {return setWoltState(doc.data().state);});
    db.collection("schudel").doc("7").onSnapshot((doc) => {return setInterval(doc.data().inter);});
    },[]);


    function WoltState(state) {

    
  
      axios.request('https://us-central1-aziz-adf05.cloudfunctions.net/app/woltStatus',{params:{status:!state===false?"OFFLINE":"ONLINE",until:null},headers:{
        'Access-Control-Allow-Origin': '*'
      }}).then(function (response) {
        const docRef = db.collection("schudel").doc("wolt");
    
   
      docRef.update({
       state: !state
     
      });
      }).catch(function (error) {
        console.error(error);
      });
      db.collection("schudel").doc("wolt")
      .get()
      .then((doc) => {
        return setWoltState(doc.data().state);
      });
    console.log("updated :=)")
   
    }
 
  return (
    <>
    
    <Container width={ active ? "calc(100% - 220px)" : "calc(100% - 40px)"}>
      <EditSchudle openItem={openItem} setData={setData} setOpenItem={setOpenItem}/>
      {canShowState &&
        <InputContainer>
        <Label />
        <ToggleButton
        
        value={woltState}
        onToggle={()=>WoltState(woltState)}
         colors={{
          active:{
            base:"#1578F1"
          },
          inactive: {
            base: 'gray'}
         }}
         />
         </InputContainer>}
    
    <SchudelDiv>
   
      <ScheduleGrid>
      { canShowSchudel ? data.slice(0,-2).map((item)=>{
        return(
          <>
          <Div>{item.data().name}</Div>
          <Div>{item.data().work ? <OpenLogo />: <CloseLogo />}</Div>
          {item.data().work ? <Wrapper>
          <Div>{item.data().startTime}</Div>
          <Div>To : </Div>
          <Div>{item.data().endTime}</Div>
          {interval ? <>
            <Div>{item.data().startTime2}</Div>
          <Div>To : </Div>
          <Div>{item.data().endTime2}</Div>
          </>: <></>}
          </Wrapper> : <><Div>---</Div></>}
          </>
        )
       
        
      })


: "Loading ..."}
</ScheduleGrid>
</SchudelDiv>
<X>
<ButtonStyled className='btn btn-primary' onClick={()=>setOpenItem(data)} >Edit</ButtonStyled>
</X>
</Container>

</>
  )
}

export default Schedule