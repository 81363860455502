import React from "react";
import styled from "styled-components";
import {T} from "../Language/translate";
import { OrderBtn } from "../Styles/colors";
const Dialog = styled.div`
position: fixed;
  width: auto;
  height:auto;
  background-color: white;
  margin-bottom:50px;
 
  z-index: 55;
margin:auto;
padding:10px;
  display: flex;
  flex-direction: column;
  border-radius:5px;
  // Add this part
  @media (max-width: 400px) {
    
    overflow-y: scroll;
    height:auto;
    width: auto;
 
    
  }
`;

 const Wrapper = styled.div`
z-index: 55;
position:fixed;
width:100%;
height:100%;
left:0;
bottom:0;
  display: flex;
  justify-content: center;
  align-items: center;

`;

const DialogShadow = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  left:0px;
  background-color: black;
  opacity: 0.7;
  z-index: 51 !important;
`;
const Close = styled.div`
height: 30px;
width: 30px;
display :flex;
align-items:center;
justify-element:center;
position:absolute;
background-image:url("/img/close.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border: none; 
margin:10px;
top:0;
right:0;
font-size: 18px;
font-weight:bold;
 &:hover{
    cursor: pointer;
    opacity:0.7;
 }
`;
const Header = styled.div`
margin-top: 10px;
display:flex;
text-align:center;
align-items: center;
justify-content: center;

`;
const Container = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
`;
const A = styled.button`
color:white;
background-color:${OrderBtn};
margin:15px;
`;
const HContainer = styled.div`
display:flex;
flex-direction:row;
justify-content:center;
align-items:center;
justify-content:space-between;
`;
const Google = styled.div`
 height: 50px;
 width: 50px;
 background-color:gray;
 border-radius:8px;
 margin-left:10px;
 margin-top:10px;
 background-image:url("/img/Google.png");
 background-position: center;
 background-repeat: no-repeat;
 background-size: cover;
 border: none; 
 cursor:pointer;
`;
const Text = styled.div`
text-align:center;
`;
export function SignIn_Up({setSignIn_UpPanel,setLogInPanel,loginWithGoogle,setSignUpPanel,lang,setIsGuest,setOpenUser}){
 
    return(
         <>
         <DialogShadow />
         <Wrapper>
         <Dialog>
            <Close onClick={()=>{setSignIn_UpPanel(false)}}/>
            <Header>{T("126",lang)}</Header>
            <Container>
           <HContainer> 
           <A className="btn" onClick={()=>{setLogInPanel(true);setSignIn_UpPanel(false)}}>{T("90",lang)}</A>
           <div />
           <A className="btn" onClick={()=>{setSignUpPanel(true);setSignIn_UpPanel(false)}}>{T("95",lang)}</A>
           </HContainer>
           <Text  >{T("127",lang)}</Text>
           <A  className="btn" onClick={()=>{loginWithGoogle();setSignIn_UpPanel(false)}}>{T("141",lang)} </A>
           <A className="btn" onClick={()=>{setIsGuest(true);setSignIn_UpPanel(false);setOpenUser(T("129",lang))}}>{T("128",lang)}</A>
         </Container>
         </Dialog>
         </Wrapper>
     
         </>


    )
}