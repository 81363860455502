import styled from "styled-components";
import React from "react";
import {T} from "../Language/translate";
const ToppingGrid = styled.div`
height:85px;
display: grid;
grid-template-columns: repeat(3, 1fr);
text-size: 16px;
@media(max-width: 400px){ 
  grid-template-columns: 1fr 1fr;
}
`;
const ToppingCheckbox = styled.input`
margin-right: 10px;
cursor: pointer;
`;
const CheckboxLabel = styled.label`
white-space: nowrap;
cursor: pointer;
margin-right:7px; 
@media(max-width: 700px){ 
  font-size:15px;
  
  
 }
`;
export function Toppings({toppings,checkTopping,lang}){
 return (<ToppingGrid>
     {toppings.map((topping,i) => (<CheckboxLabel key={i} >
       <ToppingCheckbox type="Checkbox"
       checked={topping.checked}
       onChange={()=>{checkTopping(i);}}/>
       {T(topping.name,lang)}
      </CheckboxLabel>))}

 </ToppingGrid>)
}