import {useState, useEffect} from "react";
const db = window.firebase.firestore();
export function useTitle({openFood, orders}){
    const [title,setTitle] = useState("");
useEffect(()=>{
    db.collection('themes').doc("Standard").onSnapshot((doc)=>{return  setTitle(doc.data().resto)});
if(openFood){
    document.title = openFood.name;
}else
{
    document.title = orders.length === 0 ? title: `[${orders.length}] items in your order`;
}

});
}