import React from 'react';
import styled from "styled-components";
import { useState } from "react";
import { useEffect } from "react";
import {CreateOption} from "./AddOption"
import { AddHeader, ButtonContainer,ButtonStyled,Bar} from '../Items/Items';
import { ConfirmDelete } from '../ConfirmDelete';
import { EditOption } from './EditOption';
import { T } from '../../../../../Language/translate';
const Container = styled.div`
position:fixed;
top:100px;
right:0;
width:${(props) => props.width};
font-size : 20px;
@media screen and (max-width: 995px) {
    margin-left:10px;
}
`;
const ItemGrid = styled.div`

display: flex;
overflow-y: scroll;
flex-flow:wrap;
gap:10px;

@media screen and (max-width: 1700px) {

}
@media screen and (max-width: 1380px) {

    }


@media screen and (max-width: 1060px) {
;

gap: 8px;
}
@media screen and (max-width: 800px) {

  }



`;
const Item = styled.div`
display: grid;
grid-template-columns: 1fr 1fr 1fr;
height: 70px;
text-align:center;
font-size:20px;
background-position: center;
background-size: cover ;
padding: 10px;
margin: 5px;
transition-property: box-shadow margin-top filter;
transition-duration: .2s;
border:1px solid;
border-color: lightgray; 
border-radius : 10px;
box-shadow : 0px 0px 2px 0px grey;
padding-bottom: 10x;
&:hover{
   opacity:0.7;
   margin-top: 0px;
   margin-bottom : 5px;
   box-shadow : 0px 0px 10px 0px grey;
}
@media screen and (max-width: 700px) {
   width:150px;
       
   }
   @media screen and (max-width: 400px) {
    grid-template-columns: 1fr;
    
    height: 170px;
    width:130px;
 }
`;

const Edit = styled.div`
height: 38px;
width: 38px;
display :flex;
align-items:center;
justify-element:center;
background-image:url("/img/Edit.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border: none; 
margin: auto;
font-size: 18px;
font-weight:bold;
 &:hover{
    cursor: pointer;
    opacity: 1.5;
    background-color:lightgray;
    border-radius : 7px;
 }
`;
const Remove = styled.div`
height: 40px;
width: 40px;
display :flex;
align-items:center;
justify-element:center;
background-image:url("/img/Remove.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border: none; 
margin: auto;
font-size: 18px;
font-weight:bold;
 &:hover{
    cursor: pointer;
    opacity: 1.5;
    background-color:lightgray;
    border-radius : 7px;
 }
`;
const Div = styled.div`
     padding-top:2px;
     display:flex;
     text-align:center;
     
`;
const H2 = styled.h2`
@media screen and (max-width: 995px) {
   
    margin-top:20px;
}
@media screen and (max-width: 700px) {
   font-size:14px;
}

`;
const db = window.firebase.firestore();
function Options({lang,active}) {
  //use state to edit data 
  const [openItem, setOpenItem] = useState();
//use sate to open panel for adding item 
const [openPanel, setOpenPanel ] = useState(false);
//use state to read data 
const [data , setData] = useState();
///
const [openDelete,setOpenDelete]=useState();
const isOptionsEmpty = data && data.length ===0;
const canShowOptions = data && true;
useEffect(()=>{ 
db.collection('options').onSnapshot((data)=>{return  setData(data.docs)});

},[]);



    return (
        <Container width={ active ? "calc(100% - 220px)" : "calc(100% - 40px)"}>
            <EditOption openItem={openItem} setData={setData} setOpenItem={setOpenItem} lang={lang} />
            {openPanel && <CreateOption openPanel={openPanel} setOpenPanel={setOpenPanel}  setData={setData}lang={lang}/>}
            <ConfirmDelete openDelete={openDelete} setData={setData} setOpenDelete={setOpenDelete} collection="options" />
            <AddHeader>
            <H2>{T(175,lang)}</H2>  
            <ButtonContainer>   
        <ButtonStyled className='btn btn-primary' onClick={()=>openPanel? setOpenPanel(false): setOpenPanel(true)} >{T(172,lang)}</ButtonStyled>
    </ButtonContainer>
            </AddHeader>
            <Bar/>
            <ItemGrid>
            {isOptionsEmpty  && <Div>No category to display</Div>}
            { canShowOptions ? data.map((item)=>{
                return (
               <>
                    <Item>
                   {item.data().name == "No Extra" ? "" : <Remove onClick={()=>setOpenDelete(item.data())}/>} 
                        <Div>{item.data().name}</Div>
                        {item.data().name == "No Extra" ? "" : <Edit onClick={()=>setOpenItem(item.data())} />} 
                   
                    </Item>
                    </>
                )} 
                ) : <Item><div>Loading ...</div></Item>}
            
            </ItemGrid>
      
        </Container>
    );
}

export default Options;