import { useState } from "react";

//initialize the hook 
export function useFindCheapest(){
    const[indexOfCheap,setIndexOfCheap]=useState();
    return {
        indexOfCheap,
        setIndexOfCheap
    }
}
