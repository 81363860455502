import React from 'react'
import * as AiIcons from "react-icons/ai";


export const SidebarData =[
    //195
    { title: 195,
     path: 'orders',
     icon:<AiIcons.AiFillFire/>,
    cName: 'nav-text'
},
//171
    { title: 171, 
    path: 'products/items',
    icon: <AiIcons.AiFillAppstore/>,
    cName: 'nav-text' ,
    childrens:[
        { title: '171',
         path: 'items',
        cName: 'menu-text'
    },
        { title: '174', 
        path: 'categories',
        cName: 'menu-text' 
    },
    { title: "175", 
    path: 'options',
    cName: 'menu-text' 
    }
    ,
    { title: "176", 
    path: 'zip',
    cName: 'menu-text' 
    },
    {
        title:"177",
        path:'fees',
        cName: 'menu-text'
    },
    {
        title:"178",
        path:'sales',
        cName: 'menu-text'
    }
    ]
},
//196
{ title: 196, 
path: 'schedule',
icon: <AiIcons.AiFillCalendar/>,
cName: 'nav-text' 
},
//197
{ title: 197, 
path: 'performance',
icon: <AiIcons.AiFillDashboard/>,
cName: 'nav-text' 
},
//198
{ title: 198, 
path: 'frontend',
icon: <AiIcons.AiFillControl/>,
cName: 'nav-text' 
},
//199
{ title: 199, 
path: 'accounts',
icon: <AiIcons.AiFillRobot/>,
cName: 'nav-text' 
},
//200
{ title: 200, 
path: 'coupons/percentage',
icon: <AiIcons.AiFillGift/>,
cName: 'nav-text',
childrens:[
    { title: 219,
     path: 'percentage',
    cName: 'menu-text'
},
    { title: 220, 
    path: 'deliveries',
    cName: 'menu-text' 
},
{ title: 221, 
path: 'xy',
cName: 'menu-text' 
}
 
]
},
//201
{ title: 201, 
path: 'history',
icon: <AiIcons.AiFillRead/>,
cName: 'nav-text' 
},
//202
{ title: 202, 
path: 'language',
icon: <AiIcons.AiOutlineTaobaoCircle/>,
cName: 'nav-text' 
},
//203
{ title: 203, 
path: 'support',
icon: <AiIcons.AiFillMessage/>,
cName: 'nav-text' 
},
{ title: 222, 
    path: 'admins',
    icon: <AiIcons.AiFillSetting/>,
    cName: 'nav-text' 
    }
 
]