import React from "react";
import styled from 'styled-components';
import {T} from "../Language/translate";
import { EditForm } from "./editForm";
import { Close } from "./profile";
const Dialog = styled.div`
position: fixed;
  width: 500px;
  height:calc(100% - 50px);
  background-color: white;
  margin-bottom:50px;
 overflow-y:scroll;
  z-index: 57;
margin:auto;
padding:10px;
  display: flex;
  flex-direction: column;
  border-radius:5px;
  // Add this part
  @media (max-width: 600px) {
    
    overflow-y: scroll;
    height:auto;
    width: 100%;
 
    
  }
`;

 const Wrapper = styled.div`
z-index: 57;
position:fixed;
width:100%;
height:100%;
left:0;
bottom:0;
  display: flex;
  justify-content: center;
  align-items: center;

`;
  
const DialogShadow = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  left:0px;
  background-color: black;
  opacity: 0.7;
  z-index: 56 !important;
`;
export const UserHeader = styled.div`
margin-top: 10px;
display:flex;
text-align:center;
align-items: center;
justify-content: center;

`;




const db = window.firebase.firestore();
function EditProfileContainer({edit, setEdit, loggedIn,lang,isGuest}){

    return (
        <>
        <DialogShadow  />
        <Wrapper>
        <Dialog>
        <UserHeader>{T("33",lang)+edit.name}</UserHeader>
        <Close onClick={()=>{setEdit()}}/>
        <UserHeader>{T("44",lang)}</UserHeader>
        <EditForm setOpenUser={setEdit} loggedIn={loggedIn} profile={edit} lang={lang}isGuest={isGuest}></EditForm>
       
        </Dialog>
        </Wrapper>
        </>
    )

}



export function EditProfile(props){
    if (!props.edit) return null;
    return <EditProfileContainer {...props}/>
}