import React, { useRef } from "react";
import moment from "moment";
import styled, { keyframes } from "styled-components";
import { DialogFooter, Wrapper } from "../FoodDialog/FoodDialog";
import { useState, useEffect } from "react";
import axios from "axios";
import { Remark } from "./Remark";
import { OrderBtn } from "../Styles/colors";
import { loadStripe } from "@stripe/stripe-js";
import { useReactToPrint } from "react-to-print";
import { FoodContainer } from "../AdminPanelComponents/Pages/Orders/Orders";
import { UserFooter } from "../userDialog/userForm";
import { T } from "../Language/translate";
import { UserInfoPanel } from "../userInfo/userInfoPanel";
import { Elements, useStripe, useElements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import ModelLoading from "../Loading/ModelLoading";
const html2canvas = require('html2canvas'); // Include html2canvas library
var _ = require("lodash");
const botToken = '6301276076:AAEgxzwzi8s1gwhD4gwozgCLRuv8IuPLGRE';
const chatId = '-1002080777660';
// Set up the Telegram API endpoint
const telegramApiEndpoint = `https://api.telegram.org/bot${botToken}/sendMessage`;
//const stripe = require('stripe')('pk_test_51ORk7MDTZJCnUGSTByYGZjc3nJhHSdwxY1nh9bunVCWTbMUt2pwT74gh0L8gVVsC2zwcmHVrqjb5NDlfZrM8mUyz00cej4dpEh');
//const stripe = require('stripe')('pk_test_51ORk7MDTZJCnUGSTByYGZjc3nJhHSdwxY1nh9bunVCWTbMUt2pwT74gh0L8gVVsC2zwcmHVrqjb5NDlfZrM8mUyz00cej4dpEh');
const database = window.firebase.database();

const H2 = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;
const Dialog = styled.div`
position: fixed;
  width: 600px;
  height:auto;
  background-color: white;
  margin-bottom:auto;
  margin-top:auto;
  z-index: 55;
 margin-left:auto;
 margin-right:auto;

padding:5px;
  display: flex;
  flex-direction: column;
  border-radius:5px;
  // Add this part
  @media (max-width: 600px) {
    
    overflow-y: scroll;
    height:auto;
    width: 95%;
 
    z-index: 55;
  }
  @media (max-height: 600px) {
    overflow-y: scroll;
    height:400px;
 
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px;
`;
const ConfirmButton = styled.button`
  font-family: "Righteous", cursive;
  margin: 10px;
  color: white;
  height: 40px;
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  background-color: ${OrderBtn};
  opacity: 0.8;
  z-index: 4;
  ${({ disabled }) =>
    disabled &&
    `
opacity: .5;
background-color: grey;
pointer-events: none;
`}
`;
const DialogShadow = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  background-color: black;
  opacity: 0.7;
  z-index: 50;
`;
const Div = styled.div`
  margin: auto;
  margin-left: 5px;
  font-size: 16px;
  font-weight: 700;
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;
const Div2 = styled.div`
  width: fit-content;
  margin-left: 10px;
  margin-bottom: 10px;
`;
const DialogContent = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  min-height: 100px;
  padding: 0px 10px;
  padding-bottom: 20px;
`;
const Label = styled.label`
  margin-left: 10px;
`;

const Close = styled.div`
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-element: center;
  position: absolute;
  background-image: url("/img/close.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  margin: 10px;
  top: 0;
  right: 0;
  font-size: 18px;
  font-weight: bold;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;
const Info = styled.button`
  margin-right: 20px;
  font-size: 16px;
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;
const Info2 = styled.button`
  width: 90px;
  font-size: 16px;
`;
const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 0.1fr 0.1fr;
`;
function blinkingEffect() {
  return keyframes`
  30% {
    background-image:url("/img/arrowWhite.png");
  
  }`;
}
const Arrow = styled.div`
  height: 30px;
  width: 60px;
  margin: auto;
  background-image: url("/img/arrow.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: stretch;
  animation: ${blinkingEffect} 3s linear infinite;
  border: none;

  cursor: pointer;
  @media (max-width: 600px) {
    height: 20px;
    width: 60px;
  }
`;
const Limit = styled.span`
  white-space: nowrap;
  margin-left: 20px;
  color: gray;
  font-size: 12px;
`;
const Hide = styled.div`
  color: white;
`;

const ProfileContainer2 = styled.div`
  padding: 3px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;
const Food = styled.div`
  font-family: "Helvetica", "Arial", sans-serif !important;
  color: black;
  margin-left: 20px;
  font-weight: bolder;
  font-size: 20px;
`;
const ExtraPrice = styled.div`
  margin-left: 20px;
`;

const Header = styled.div`
  font-family: "Helvetica", "Arial", sans-serif !important;
  text-align: center;
  color: black;
  margin-left: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 26px;
  font-weight: bolder;
`;
const Quantity = styled.div`
  font-family: "Helvetica", "Arial", sans-serif !important;
  color: black;
  margin-left: 5px;
  font-weight: bolder;
  font-size: 20px;
`;
const ToppingContainer = styled.div`
  font-family: "Helvetica", "Arial", sans-serif !important;
  display: grid;
  margin-left: 20px;
  margin-right: 10px;
  grid-template-columns: 270px 20px 10px 120px;
  justify-content: space-between;
  font-weight: bold;
  font-size: 16px;
  color: black;
  min-width: 50px;
`;

const Topping = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: -5px;
  white-space: normal;
`;
const Remark1 = styled.div`
  display: flex;
  white-space: normal;
  font-weight: bold;
  font-size: 16px;
  margin-left: 15px;
`;
const Remark2 = styled.div`
  display: flex;
  white-space: normal;
  font-weight: bold;
  font-size: 16px;
  margin-left: 18px;
`;

const H1 = styled.div`
  font-family: "Helvetica", "Arial", sans-serif !important;
  font-size: 22px;
  font-weight: bolder;
  margin-left: 15px;
  color: black;
`;

const HR = styled.hr`
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2px !important;
  color: black;
  background-color: black;
  width: 92%;
  height: 3px;
`;
const Print = styled.div`
  font-family: "Helvetica", "Arial", sans-serif !important;
  height: auto;
  margin: 150px 10px 150px 10px;
  width: 400px;
`;
const DivPay = styled.div`
  margin-right: auto;
  margin-left: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 400px;
  height: 320px;
  background-color: white;
  z-index: 56;
  @media (max-width: 600px) {
    width: 280px;
  }
`;
const DivPay2 = styled.div`
  margin-right: auto;
  margin-left: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 400px;
  height: auto;
  background-color: white;
  z-index: 56;
  @media (max-width: 600px) {
    width: 280px;
  }
`;
const useCurrentDateTime = () => {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  useEffect(() => {
    const fetchCurrentDateTimeFrance = async () => {
      try {
        const apiKey = '348M8JPBF5B1'; // Replace with your TimezoneDb API key
        const response = await fetch(`http://api.timezonedb.com/v2.1/get-time-zone?key=${apiKey}&format=json&by=zone&zone=Europe/Paris`);
        const data = await response.json();

        if (data && data.formatted) {
          const dateTimeString = data.formatted;
          const dateTime = new Date(dateTimeString);
          setCurrentDateTime(dateTime);
        } else {
          throw new Error('Unable to fetch current date and time in France.');
        }
      } catch (error) {
        console.error('Error:', error.message);
        // Handle error accordingly
      }
    };

    fetchCurrentDateTimeFrance();
  }, []);

  return currentDateTime;
};

var session = window.sessionStorage;
export function OrderDialogContainer({
  openOrderDialog,
  setOpenOrderDialog,
  setOrders,
  total,
  orders,
  loggedIn,
  deliveryFee,
  setShow,
  setBasket,
  setProfile,
  lang,
  limitCode,
  orderType,
  openUser,
  setOpenUser,
  userInfo,
  setUserInfo,
  userDetails,
  setUserDetails,
  setDeliveryFee,
  showPay,
  setShowPay,
  takeOrDelivery
}) {
  const [paymentType, setPaymentType] = useState(null);

  const [remark, setRemark] = useState("");
  const [points, setPoints] = useState(0);
  const [errMessage, setErrMessage] = useState("");
  //stripe online payment
  const [stripePromise, setStripePromise] = useState(null);
  const [orderItem, setOrderItem] = useState();
  const [price, setPrice] = useState();
  const[changeRate,setChangeRate]=useState(0);
  const [clientSecret, setClientSecret] = useState("");

  const componentRef = useRef(null);
  const currentDateTime = useCurrentDateTime();

  useEffect(() => {
    db.collection('Fees').where('name','==',"Standard").onSnapshot((data)=>{return  setChangeRate(data.docs[0].data().rate)});
    if (loggedIn && loggedIn.email && loggedIn != "loading")
      db.collection("users")
        .where("email", "==", loggedIn.email)
        .onSnapshot((data) => {
          return setPoints(data.docs[0].data().points);
        });
  }, []);

  //production :
  //https://us-central1-starpizza-f114e.cloudfunctions.net/app/config
  useEffect(() => {
    const options = { method: "GET", headers: {} };
    fetch(
      "https://us-central1-starpizza-f114e.cloudfunctions.net/app/config",
      options
    )
      .then(async (response) => {
        const { publishableKey } = await response.json();
        setStripePromise(loadStripe(publishableKey, { locale: "fr" }));
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    const options = {
      method: "POST",
      body: JSON.stringify({ total }), // Assuming total is a number on the client side
      headers: {
        "Content-Type": "application/json",
      },
    };

    fetch(
      "https://us-central1-starpizza-f114e.cloudfunctions.net/app/create-payment-intent",
      options
    )
      .then(async (response) => {
        const obj = await response.json();
      
        setClientSecret(obj.clientSecret);
      })
      .catch((err) => console.error(err));
  }, []);

  const newOrderRef = database.ref("orders");
  function getId() {
    var i = 0;
    newOrderRef
      .orderByChild("Id")
      .limitToLast(1)
      .on("value", (snapshot) => {
        if (snapshot.exists()) {
          let arr = _.toArray(snapshot.val()).filter((x) => x != null);
          i = arr[0].Id + 1;
        } else {
          i = 0;
        }
      });
    return i;
  }
  function PayOnline(){
     //send order
 
     //update points 
     const docRef = db.collection("users").doc(loggedIn.email);
    db.collection('users').doc(loggedIn.email).get().then((doc)=>{ 
      docRef.update({
        points :Number(doc.data().points)-Math.round(total/changeRate)
      })
      console.log(points)
       })
       

      
       setPaymentType(null);
      setBasket("none");
      sendOrder(orders, loggedIn,total);
  }
  
  function sendOrder(orders, loggedIn, total) {
    var i = 0;
    newOrderRef
      .orderByChild("Id")
      .limitToLast(1)
      .on("value", (snapshot) => {
        if (snapshot.exists()) {
          let arr = _.toArray(snapshot.val()).filter((x) => x != null);
          i = arr[0].Id + 1;
        } else {
          i = 0;
        }
      });
    //reduce to data structure
    const newOrders = orders.map((order) => {
      return Object.keys(order).reduce((acc, orderKey) => {
        if (!order[orderKey]) {
          //undifiend value
          return acc;
        }
        if (orderKey === "toppings") {
          return {
            ...acc,
            [orderKey]: order[orderKey]
              .filter(({ checked }) => checked)
              .map(({ name }) => name),
          };
        }
        return {
          ...acc,
          [orderKey]: order[orderKey],
        };
      }, {});
    });

    const db = window.firebase.firestore();

    try {
      // Block of code that may throw an error
      if (loggedIn) {
        db.collection("users")
          .doc(loggedIn.email)
          .get()
          .then((doc) => {
            const obj = {
              Id: i,
              order: newOrders,
              status: "sent",
              email: loggedIn.email ? loggedIn.email : "---",
              deliveryType: takeOrDelivery,
              displayName: loggedIn.displayName? loggedIn.displayName: "---",
              total: total !== null ? total : "---",
              res: "Star Pizza",
              date: Math.floor(currentDateTime.getTime() / 1000.0)? Math.floor(currentDateTime.getTime() / 1000.0): "---",
              street: doc.data().street ? doc.data().street : "",
              city: doc.data().city ? doc.data().city : "",
              zipCode: doc.data().zipCode? doc.data().zipCode: doc.data().zip? doc.data().zip: "",
              houseNumber: doc.data().houseNumber? doc.data().houseNumber: doc.data().buildingNumber? doc.data().buildingNumber: "",
              floor: doc.data().floor ? doc.data().floor : "",
              door: doc.data().door ? doc.data().door : "",
              address: doc.data().address ? doc.data().address : "",
              phone: doc.data().phone ? doc.data().phone : "---",
              payment: "paiement en ligne",
              deliveryFee: deliveryFee ? deliveryFee : 0,
              paymentRemark: remark ? remark : "---",
            }
            setOrderItem(obj)
            newOrderRef
              .push(obj)
              .then(() => {
                setOrders([]);
                setShow("success");
                sendNotification("Envoyée");
                // call function
                setOpenOrderDialog();
                // Perform any additional actions on success
              })
              .catch((error) => {
                sendNotification("Echec!!");
                setShow("fail");
                db.collection("error")
                .doc(error.name)
                .set({ errorMessage: error.message });
                setOpenOrderDialog();
                // Handle the error and perform any necessary actions
              });
          });
      }
      else
       {
        const obj ={
          Id: i,
          order: newOrders,
          status: "sent",
          email: "Guest",
          deliveryType: takeOrDelivery,
          displayName: session.getItem("name")? session.getItem("name"): "---",
          total: total !== null ? total : "---",
          res: "Star Pizza",
          date: Math.floor(currentDateTime.getTime() / 1000.0)? Math.floor(currentDateTime.getTime() / 1000.0): "---",
          street: session.getItem("street")? session.getItem("street"): "---",
          city: session.getItem("city") ? session.getItem("city") : "---",
          zipCode: session.getItem("zipCode")? session.getItem("zipCode"): "---",
          houseNumber: session.getItem("houseNumber")? session.getItem("houseNumber"): "---",
          floor: session.getItem("floor") ? session.getItem("floor") : "---",
          door: session.getItem("door") ? session.getItem("door") : "---",
          phone: session.getItem("phone") ? session.getItem("phone") : "---",
          payment: "paiement en ligne",
          deliveryFee: deliveryFee ? deliveryFee : 0,
          paymentRemark: remark ? remark : "---",
        }
        setOrderItem(obj)
        newOrderRef
          .push(obj)
          .then(() => {
            setOrders([]);
            setShow("success");
            sendNotification("Envoyée")
            setOpenOrderDialog();
          })
          .catch((error) => {
            setShow("fail");
            sendNotification("Echec!!");
            db.collection("error")
            .doc(error.name)
            .set({ errorMessage: error.message });
            setOpenOrderDialog();
          });
      }
   
    } catch (error) {
      db.collection("error")
        .doc(error.name)
        .set({ errorMessage: error.message });
    
    }
  }


  function sendNotification(state){
    html2canvas(componentRef.current).then(canvas => {
      console.log(canvas);
      canvas.toBlob(blob => {
          // Ensure the blob is properly created
          if (blob) {
              // Create a FormData object
              const formData = new FormData();
              formData.append('chat_id', chatId);
  
              // Append the blob data with the appropriate filename
              formData.append('photo', blob, 'image.png');
  
              formData.append('caption', state);
  
              // Make a POST request to the Telegram API
              fetch(`https://api.telegram.org/bot${botToken}/sendPhoto`, {
                  method: 'POST',
                  body: formData
              })
              .then(response => {
                  // Handle response
                  console.log('Photo sent successfully:', response);
              })
              .catch(error => {
                  // Handle error
                  console.error('Error sending photo:', error);
              });
          } else {
              console.error('Failed to create blob from canvas.');
                          // Make a POST request to the Telegram API
                          fetch(`https://api.telegram.org/bot${botToken}/sendMessage`, {
                            method: 'POST',
                            body: JSON.stringify({chat_id:chatId,text:"Commande Envoyée"})
                        })
                        .then(response => {
                            // Handle response
                            console.log('Text sent successfully:', response);
                        })
                        .catch(error => {
                            // Handle error
                            console.error('Error sending Text:', error);
                        });
          }
      }, 'image/png');
  });
  }

  const db = window.firebase.firestore();

  function updatePoints() {
    const totalPoints = orders.reduce((total, order) => {
      return total + Number(order.points);
    }, 0);

    const db = window.firebase.firestore();
    const docRef = db.collection("users").doc(loggedIn.email);
    db.collection("users")
      .doc(loggedIn.email)
      .get()
      .then((doc) => {
        docRef.update({
          points: Number(doc.data().points) + totalPoints,
        });
      });
  }

  function updateLimit(code) {
    db.collection("offer")
      .where("code", "==", code)
      .get()
      .then((data) => {
        //update the doc
        const docRef = db.collection("offer").doc(data.docs[0].data().name);
        docRef.update({
          limit: String(Number(data.docs[0].data().limit) - 1),
        });
      });
  }

  function getToppingPrice(topping, category) {
    category =
      category === "Pizza"
        ? "Supp Pizza"
        : category === "Burger" || category === "Paninis"
          ? "Supp Burger"
          : "Pizza Extra's";

    db.collection("options")
      .doc(category)
      .get()
      .then((doc) => {
        return setPrice(
          doc.data().extras.filter((n) => n.name === topping)[0].price
        );
      });
    return price;
  }

  // setShowPay(true)
  //  updatePoints();
  //  sendOrder(orders, loggedIn,total);
  //setPaymentType(null);
  // setBasket("none");
  //  setOpenOrderDialog();

  return (
    <>
      <DialogShadow />
      <Wrapper>
        <Dialog>
          <UserInfoPanel
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            userDetails={userDetails}
            setUserDetails={setUserDetails}
            setDeliveryFee={setDeliveryFee}
          />
          <DialogContent>
            <Hide>{total}</Hide>
            <H2> {T("81", lang)}</H2>

            <Close
              onClick={() => {
                setOpenOrderDialog();
                setPaymentType(null);
                setRemark("");
                setErrMessage("");
              }}
            />

            {orderType === "dine" ? (
              <Div>{"Order number :    #" + getId()}</Div>
            ) : orderType === "phone" ? (
              <InfoContainer>
                <Div></Div>

                <Info2
                  className="btn btn-primary"
                  onClick={() => setUserInfo("🖊️")}
                >
                  {"🖊️ Info"}
                </Info2>
              </InfoContainer>
            ) : takeOrDelivery !=="A importer" ? (
              <InfoContainer>
                <Div>{T("73", lang)}</Div>
                <Arrow />
                <Info
                  className="btn btn-primary"
                  onClick={() =>
                    (session.getItem("GuestOk")&&loggedIn==null)
                      ? setProfile(session.getItem("name"))
                      : setProfile(loggedIn.email)
                  }
                >
                  {T("74", lang)}
                </Info>
              </InfoContainer>
            ) : <></>}

     

            <DivPay>
              {clientSecret && stripePromise ? (
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                  <CheckoutForm
                    sendOrder={sendOrder}
                    orders={orders}
                    loggedIn={loggedIn}
                    total={total}
                    limitCode={limitCode}
                    updatePoints={updatePoints}
                    updateLimit={updateLimit}
                    setBasket={setBasket}
                    setOpenOrderDialog={setOpenOrderDialog}
                  />
                </Elements>
              ) : (
                <ModelLoading />
              )}
            </DivPay>

           <DivPay2>
            {points>=total/changeRate && <p>{"Vous pouvez payer avec votre points 😊 : "+points}</p>}
            {points>=total/changeRate && <button onClick={()=>PayOnline()} className="btn btn-primary" style={{"marginTop":"10px"}}>{"Payer " +total/changeRate+" 💎"}</button> }
           </DivPay2>


           {/**<button onClick={()=>sendOrder(orders,loggedIn,total)}> Test</button> */} 
          
            {/*<Div> {T("124",lang)}</Div>
            <InputContainer>
           <Remark remark={remark.substring(0,99)} setRemark={setRemark} /> 
           <Limit>{remark.length != 0 ?(100-remark.length) : "100"}</Limit>
            </InputContainer>*/}
          </DialogContent>
          {/*
         <DialogFooter>
          <ConfirmButton className="btn"
           
           disabled={ !stripe || !elements} id="submit">{ T("72",lang)+" "+total+" €"+T("83",lang)}
          </ConfirmButton> 
       </DialogFooter>
      */}
        </Dialog>

        {orderItem && (
          <UserFooter>
            <div>
              <div  >
                <Print ref={componentRef} >
                  <ProfileContainer2>
                   
                      <Header>PIZZA</Header>
                    
                    <Header>
                      {isNaN(orderItem.date)
                        ? moment(orderItem.date).format("MM-DD HH:mm")
                        : moment.unix(orderItem.date).format("MM-DD HH:mm")}
                    </Header>
                    <Remark1>49 Rue Nationale</Remark1>
                    <Remark1>41220 Saint-Laurent-Nouan </Remark1>
                    <Remark1>SIRET: 90923296900016</Remark1>
                    <Remark1>TVA intracom: FR40909232969</Remark1>
                    <Remark1>NAF: 56.10A</Remark1>
                    <Remark1>Tél: 0254334699</Remark1>
                    {(orderItem.deliveryType &&orderItem.deliveryType!=='')&&<H2>{orderItem.deliveryType}</H2>} 
                    <H1>{T(34, lang) + orderItem.displayName}</H1>
                    {orderItem.phone && <H1>{T(38, lang) + orderItem.phone}</H1>}

                
                    {(!orderItem.deliveryType ||orderItem.deliveryType!=="A importer")&&<>
                  
                  
           
                    {(orderItem.city||orderItem.street) && <H1>{T(36, lang)+(orderItem.houseNumber ?orderItem.houseNumber:"-")+" "+orderItem.street+" , "+(orderItem.zipCode ?orderItem.zipCode:"-")  +" "+ orderItem.city}</H1>}
           {orderItem.floor && <H1>{T(39, lang) + orderItem.floor + "    " + T(40, lang) + orderItem.door}</H1>}
         

                    </>}

                    <HR />
                    {orderItem.order.map((food, index) => {
                      return (

                        <div key={index}>
                          <FoodContainer>
                            <Quantity>{food.Qu}</Quantity>
                            <Quantity>x</Quantity>
                            <Food>{food.name}</Food>
                            <Food>{food.price ? (food.price * food.Qu) + " €" : "0 €"}</Food>
                          </FoodContainer>
                          <Topping>
                            {food.toppings && food.toppings.map(x => {
                              return (
                                <ToppingContainer><div>{x}</div> <div /><div /> {!(orderItem.email === "wolt" || orderItem.email === "foodpanda" || orderItem.email === "falatozz") ? <ExtraPrice>{getToppingPrice(x, food.category) ? (getToppingPrice(x, food.category) + " €") : ""}</ExtraPrice> : <div />}{ }</ToppingContainer>
                              )
                            })
                            }

                          </Topping>
                          {food.foodType &&<ToppingContainer>{food.foodType}</ToppingContainer>} 
                          {food.category === "Tacos" && <ToppingContainer>{food.viande }</ToppingContainer>} 
                          {food.selectedChoice !=="Choix : " &&<ToppingContainer>{food.selectedChoice}</ToppingContainer>} 
                          {food.drinks !=="Boissons :   " &&<ToppingContainer>{food.drinks}</ToppingContainer>} 
                          {food.sauces!=="Sauces :   " &&<ToppingContainer>{food.sauces}</ToppingContainer>} 
                          <Remark1>{food.remark && T(69, lang) + ": " + food.remark}</Remark1>

                        </div>

                      )
                    })
                    }
                    <HR />
                    <Remark2>{orderItem.paymentRemark && T(69, lang) + ": " + orderItem.paymentRemark}</Remark2>

                    <Food>{T(72, lang) + ": " + (orderItem.payment === "cash" ? T(76, lang) : orderItem.payment === "card" ? T(77, lang) : orderItem.payment === "terminal" ? T(78, lang) : orderItem.payment === "points" ? T(79, lang) : orderItem.payment)}</Food>
                   
                    <FoodContainer>
                      <div />
                      <div />

                      <Food>{T(20, lang) + ": " + Number(orderItem.total) + " €"}</Food>
                      <div />
                    </FoodContainer>
                    <Header>{T(157, lang)}</Header>
                    <Header>{T(158, lang)}</Header>
                  </ProfileContainer2>



                </Print>
              </div>
            </div>
          </UserFooter>
        )}
      </Wrapper>
    </>
  );
}

export function OrderDialog(props) {
  if (!props.openOrderDialog) return null;
  return <OrderDialogContainer {...props} />;
}
