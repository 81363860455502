import { useState,useEffect } from "react";

//initialize the hook 
export function useWelcomePanel(){
    const db = window.firebase.firestore();
    const [welcomePanel, setWelcomePanel] = useState(false);
    useEffect(()=>{
        db.collection('welcome').onSnapshot((data)=>{return  setWelcomePanel((data.docs[0].data().Toggel))});
  
      }, []);
    return {
        welcomePanel,
        setWelcomePanel
    }
}