import React from "react";
import styled from "styled-components";
import { ButtonContainer } from "./Items";
import { Bar } from "./Items";
import ToggleButton from 'react-toggle-button';
import { useState, useEffect } from "react";
import useForm from "../Hooks/useForm";
import { T } from "../../../../../Language/translate";
export const Dialog = styled.div`
position: fixed;
  width: auto;
  height:75vh;
  overflow-y:scroll;
  background-color: white;
  margin-bottom:50px;
 
  z-index:200;
 margin-left:auto;
 margin-right:auto;
margin-top:-80px;
padding:10px;
  display: flex;
  flex-direction: column;
  border-radius:5px;
  // Add this part
  @media (max-width: 500px) {
    
    overflow-y: scroll;
    height:500px;
    width: 100%;
    margin-bottom:100px;
    z-index: 12;
  }
`;
export const InputContainer = styled.div`
  display: grid;
  grid-template-columns: .5fr 1fr ;
  margin:5px;
  
`;
export const PricesContainer = styled.div`
display:flex;
flex-direction:row;
`;
export const Input = styled.input`
width:100px;
margin:5px;
`;
export const Wrapper = styled.div`
z-index: 12;
position:fixed;
width:100%;
height:100%;
left:0;
  display: flex;
  justify-content: center;
  align-items: center;

`;
export const Label = styled.label`
margin-right:10px;
`;
export const ButtonStyled = styled.button`
max-height:40px;
text-decoration: none;
margin: 10px;
&:hover{
    opacity: 1.2;
    padding-top: 3px;
}
`;
export const DialogShadow = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
 top:0;
 left:0;
  background-color: black;
  opacity: 0.7;
  z-index: 11;
`;
export const DialogHeader = styled.div`
  padding: 10px;
 margin:5px;
 grid-template-rows: 120px;
 justify-items:center;
align-content:center;
  media screen and (max-width: 1000px) {
    padding: 10px;
   
  }
  @media screen and (max-width: 800px) {
    padding: 10px;
   
  }
  @media screen and (max-width: 500px) {
   
    padding: 10px;
    
  }
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: auto;
  margin: 20px;
`;
export const UserFooter = styled.div`
  height: 60px;
  display: flex;
  margin-top: 50px;
  justify-content: center;
`;

export const Notice = styled.span`
white-space: nowrap;
  margin-left:20px;
  color: red;
  font-size: 14px;
`;
export const Header = styled.div`
display:flex;
flex-direction: column;
justify-items:center;
align-content:center;F
margin-top:30px;
`;

export const H2 = styled.div`
  font-size: 30px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  @media screen and (max-width: 500px) {
   
    font-size: 14px;
    
  }
`;
export const H4R = styled.div`
  font-size: 15px;
  font-weight: bold;
  color:red;

  white-space: nowrap;
`;
export const H4G = styled.div`
  font-size: 15px;
  font-weight: bold;
  color:green;

  white-space: nowrap;
`;



export const UpContainer = styled.div`
display:flex;
flex-direction:row;
`;

export const Percent = styled.div`
display:flex;
flex-direction:row;
justify-items:center;
align-content:center;
margin-left:15px;
white-space:nowrap;
`;
export const InputContainer2 = styled.div`
  display: grid;
  grid-template-columns: .5fr .6fr 1fr ;
  margin:5px;
  
`;

// function for required fields 
function isRequired(value) {
  return value != null && value.trim().length > 0;
}
function NumericalValue(value) {
  return !isNaN(value);
}





export function CreateProduct({ openPanel, setOpenPanel, prop,lang }) {

  //Storage for picture
  const storage = window.firebase.storage();
  // Create a root reference

  //upload picture 
  const handleUpload = (event) => {
    event.preventDefault();
    if (!file) {
      alert("Please upload an image first!");

    }
    else {

      console.log("after check")
      var storageRef = storage.ref(`/files/${file.name}`);

      console.log("after reference")

      // 'file' comes from the Blob or File API
      storageRef.put(file).then((snapshot) => {
        console.log('inside upload');
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        // update progress
        setPercent(percent);
      }, (err) => console.log(err)

      );
      setUplaodErr("");
    }
  };

  //db
  const db = window.firebase.firestore();
  //use state for categories 
  const [categories, setCategory] = useState();
  const [toggle, setToggle] = useState(false);
  const [toggle2,setToggle2] = useState(false);
  const canShowCategories = categories && true;
  //use state for categories 
  const [options, setOptions] = useState();
  //use state for upload percent 
  const [percent, setPercent] = useState(0);
  // State to store uploaded file
  const [file, setFile] = useState("");
  // Handle file upload event and update state
  function handleChange(event) {
    setFile(event.target.files[0]);
  }
  const [index , setIndex] = useState();

  
  //useEffect to set categories and options
  const canShowOptions = options && true;
  useEffect(() => {
    db.collection('Category').get().then((data) => { return setCategory(data.docs) });
    db.collection('options').get().then((data) => { return setOptions(data.docs) });
    db.collection('items').get().then((data)=>{return  setIndex(data.docs.length.toString())});
  }, []);


  
  const [nameUnique, setNameUnique] = useState(true);
  //Form validation 
  const initialState = { name: '', price: '', category: 'Boissons', status: 'Active', option: "Pizza Extra's", 
  ingredient: "",quantity:0 ,points:0,ingredientEn:"",action:toggle,actionPrice:0,size:toggle2,price1:'',price2:''};
  const validations = [
    ({ name }) => isRequired(name) || { name: 'Name is required' },
    ({ ingredient }) => isRequired(ingredient) || { ingredient: 'ingredient are required' },
    ({ price }) => isRequired(price) || { price: 'Price is required' },
    ({ price }) => NumericalValue(price) || { price: 'Price is a number' },
   
    () => nameUnique || { name: 'This name already exist ' },
    ({quantity})=>NumericalValue(quantity) || {quantity: 'Quantity is a number'},
    ({quantity}) => isRequired(String(quantity)) || {quantity: 'Quantity is required'},
    ({points})=>NumericalValue(points) || {points: 'Points are a number'},
    ({points}) => isRequired(String(points)) || {points: 'points are required'}
  ];

  const { values, isValid, errors, changeHandler } = useForm(initialState, validations, prop);

  if (values.name != "") db.collection('items').doc(values.name).get().then((doc) => { return doc.exists ? setNameUnique(false) : setNameUnique(true) })

   const [Err, setError] = useState("");
   const [nameErr,setNameErr] = useState("");
   const [ingredientErr, setIngredientErr] = useState("");
   const [priceErr, setPriceErr] = useState("");
   const[uploadErr,setUplaodErr] = useState("");

  function showError(){
    if(!(errors.name || errors.ingredient || errors.price)){
        setError("Name ,Ingredient and price are required");
    }
    console.log('called show error')
       setNameErr(errors.name);
       setIngredientErr(errors.ingredient);
       setPriceErr(errors.price);
       setUplaodErr(percent !=100 ?"Upload Picture please" :"");
       console.log(errors.name +" " +errors.ingredient +" "+ errors.price)
    
 }

 



  const handleSubmit = () => {
   


    const docRef = db.collection("items").doc(values.name);
    try {

      docRef.set({
        name: values.name,
        category: values.category,
        options: values.option,
        status: values.status,
        ingredient:values.ingredient,
        ingredientEn: values.ingredientEn,
        price: Number(values.price),
        price1:Number(values.price1),
        price2:Number(values.price2),
        size:toggle2,
        imgUrl: file.name,
        index,
        quantity:values.quantity,
        points:values.points,
        action:toggle,
        actionPrice:Number(values.actionPrice)
      });
      console.log("submited :=)")
    } catch (e) {
      console.log(e);
    }

  

    setNameErr("");
    setIngredientErr("");
    setPriceErr("");
    setError("");
    setOpenPanel(false);



  }

  return (
    <>
      <DialogShadow />
      <Wrapper>
      <Dialog>
       
          <DialogHeader>
            <Header>
              <H2>{T(179,lang)}</H2>
             
            </Header>
            
            <ButtonContainer>
              <ButtonStyled className="btn btn-primary" onClick={()=>{!isValid || percent !=100 ?showError():handleSubmit()}}>
              {T(55,lang)}
              </ButtonStyled>
              <ButtonStyled
                className="btn btn-light"
                onClick={() =>
                  openPanel ? setOpenPanel(false) : setOpenPanel(true)
                }
              >
               {T(168,lang)}
              </ButtonStyled>
            </ButtonContainer>
          </DialogHeader>
          <Bar />

          
          <Notice>{( errors.name&&errors.ingredient&& errors.price) ? Err : ""}</Notice>
          <InputContainer className="form-group">
            <Label for="name" className=" control-label">{T(34,lang)} </Label>
        
              <input type="text" className="form-control" name="name" placeholder="Name"
                value={values.name}
                onBlur={changeHandler}
                onChange={changeHandler}
              />
            

          
          </InputContainer>


          <InputContainer className="form-group">
            <Label className="control-label">{T(180,lang)} </Label>
            <div >
              <select className="form-control"
              name="category"
                value={values.category}
                onChange={changeHandler}
              >
                {canShowCategories && categories.map((item) => {
                  return (
                    <>
                      <option value={item.data().name}>

                        {item.data().name}
                      </option>
                    </>
                  )
                }
                )}


              </select>
            </div>
          </InputContainer>

          <InputContainer className="form-group">
            <Label  className=" control-label">{T(181,lang)}</Label>
            <div >
              <select className="form-control"
              name="option"
                value={values.option}
                onChange={changeHandler}
              >
                {canShowOptions && options.map((item) => {
                  return (
                    <>
                      <option value={item.data().name}>

                        {item.data().name}
                      </option>
                    </>
                  )
                }
                )}
              </select>
            </div>
          </InputContainer>
          <InputContainer className="form-group">
            <Label className="control-label">{T(182,lang)}</Label>
            <div >
              <select className="form-control"
                name="status"
                value={values.status}
                onChange={changeHandler}
              >
                <option value="active">{T(183,lang)}</option>
                <option value="passive">{T(184,lang)}</option>
              </select>
            </div>
          </InputContainer>

          
        <InputContainer>

          <label  className="col-sm-2 control-label">{T(185,lang)}</label>
      
      <input type="text" className="form-control" name="quantity"  placeholder="Quantity" value={values.quantity} onChange={changeHandler} />
      
      { errors.quantity && <Notice>{errors.quantity}</Notice>}
   </InputContainer> 
   <InputContainer>

<label  className="col-sm-2 control-label">{T(186,lang)}</label>

<input type="number" className="form-control" name="points"  placeholder="Points" value={values.points} onChange={changeHandler} />

{ errors.points && <Notice>{errors.points}</Notice>}
</InputContainer> 
  

         <Notice>{ingredientErr}</Notice>
            <InputContainer className="form-group">
              <Label className=" control-label">{T(187,lang)}</Label>
            
                <textarea style={{resize:"none"}} type="text" className="form-control" name="ingredient" placeholder="ingredients"
                  value={values.ingredient}
                  onBlur={changeHandler}
                  onChange={changeHandler} />
              
            
            </InputContainer>

            <InputContainer className="form-group">
              <Label className=" control-label">{T(187,lang)+"EN: "} </Label>
            
                <textarea style={{resize:"none"}} type="text" className="form-control" name="ingredientEn" placeholder="ingredients En"
                  value={values.ingredientEn}
                  onBlur={changeHandler}
                  onChange={changeHandler} />
              
            
            </InputContainer>

   
            <Notice>{priceErr}</Notice>

<InputContainer2>
<label className="control-label">{T(191,lang)}</label>
            <ToggleButton
      
      value={toggle2}
       onToggle={()=>!toggle2? setToggle2(true):setToggle2(false)}
       colors={{
        active:{
          base:"#1578F1"
        },
        inactive: {
          base: 'gray'}
       }}
       />
 </InputContainer2>
          {toggle2? <PricesContainer>
            <InputContainer className="form-group">
        
            <Label  className=" control-label">{T(188,lang)}</Label>

            <InputContainer2>
           
              <Input type="text" className="form-control" name="price" id="category" placeholder="Junior" value={values.price} onChange={changeHandler} />
              <Input type="text" className="form-control" name="price1" id="category" placeholder="Sénior" value={values.price1} onChange={changeHandler} />
              <Input type="text" className="form-control" name="price2" id="category" placeholder="Méga" value={values.price2} onChange={changeHandler} />
          



          
          
              </InputContainer2>
              </InputContainer>
          



          </PricesContainer>:
          <InputContainer className="form-group">
            <Label  className=" control-label">{T(188,lang)}</Label>
           
              <input type="text" className="form-control" name="price" id="category" placeholder="Price" value={values.price} onChange={changeHandler} />
          
          
          </InputContainer>  }

         

          <InputContainer2 className="form-group">
      <label className="col-sm-2 control-label">{T(189,lang)}</label>
      <ToggleButton
      
      value={toggle}
       onToggle={()=>!toggle? setToggle(true):setToggle(false)}
       colors={{
        active:{
          base:"#1578F1"
        },
        inactive: {
          base: 'gray'}
       }}
       />
      {toggle &&<input type="text" className="form-control" name="actionPrice"  placeholder="Action Price" value={values.actionPrice} onChange={changeHandler} />
}
      { errors.actionPrice && <Notice>{errors.actionPrice}</Notice>}
   </InputContainer2> 


          <Notice>{uploadErr}</Notice>
          <UpContainer>
            
              <input type="file" onChange={handleChange} accept="" className="form-control" />
          
            <button className="btn btn-primary" onClick={handleUpload}>{T(190,lang)}</button>
            <Percent>{percent} %</Percent>
          </UpContainer>
      
      </Dialog>
      </Wrapper>
    </>
  );
}