import React from "react";
import styled from "styled-components";

const InputContainer = styled.div`
  display: flex;
  width:90%;
  flex-direction: row;
  margin-top:10px;
  margin-left:auto;
  margin-right:auto;
  @media(max-width: 700px){ 
    margin-top:10px;
    margin-left:-1px;
    
   }
  
`;




const Div = styled.div`
display:none;
`;
const TextArea = styled.textarea `
resize: none;
width:100%;
@media(max-width: 700px){ 
  
   
  }
`;
export function Remark({remark,setRemark}){
    const handleChange = event => {
        setRemark(event.target.value);
      
      };
    return(
        
        <InputContainer>
       
   
          <TextArea type="text" className="form-control" name="ingredient" placeholder="Remark"
            onChange={handleChange} 
            value={remark}
            />
     
       <Div>{remark}</Div>
      </InputContainer>

    )
}