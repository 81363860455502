import React, { useRef } from "react";
import styled from 'styled-components';


const Dialog = styled.div`
position: fixed;
  width: auto;
  height:auto;
  background-color: white;
  margin-bottom:50px;
 
  z-index: 12;
 margin:auto;
padding:10px;
  display: flex;
  flex-direction: column;
  border-radius:5px;
  // Add this part
  @media (max-width: 400px) {
    
    overflow-y: scroll;
    height:auto;
    width: auto;
 
    z-index: 12;
  }
`;

 const Wrapper = styled.div`
z-index: 12;
position:fixed;
width:100%;
height:100%;
left:0;
top:0;
  display: flex;
  justify-content: center;
  align-items: center;

`;


 const DialogShadow = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
 top:0;
 left:0;
  background-color: black;
  opacity: 0.7;
  z-index: 11;
`;
const Button = styled.button`
  margin: 10px;
  height: 40px;
  width: 150px;
  text-align: center;

`;
const Container = styled.div `
display:flex;
flex-direction:row
margin-left:auto;
margin-right:auto;
`;
const H5 = styled.h5`
margin-top:10px;
text-align:center;
`;

// Get a database reference
const database = window.firebase.database();
const db = window.firebase.firestore();
export function ConfirmPanel({setConfirmPanel,orderItem,id}){
  function generateRandomString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }
  
  // Example usage
  const randomString = generateRandomString(10); // Generates a random string of length 10
  
    function Delete(){
        const ref = database.ref('orders');
        console.log(String(id)+randomString)
        console.log(orderItem)
         db.collection("deleted").doc(String(id)+randomString).set({orderItem});
        

         ref.once("value", function(snapshot) {
          snapshot.forEach(function(childSnapshot) {
              var childData = childSnapshot.val();
              if (childData.Id === id) {
                  // If the ID matches, remove the document
                  childSnapshot.ref.remove()
                  .then(function() {
                      console.log("Document with ID", id, "successfully removed.");
                  })
                  .catch(function(error) {
                      console.error("Error removing document: ", error);
                  });
              }
          });
      });
      }
    return (
        <>
        <DialogShadow />
        <Wrapper>
        <Dialog >
            <H5>Are you sure to Delete order Id {"#"+id}?</H5>
        <Container>
            <Button className="btn btn-info" onClick={()=>{Delete();setConfirmPanel(false)}}>Yes</Button>
            <Button className="btn btn-danger" onClick={()=>setConfirmPanel(false)}>No</Button>
        </Container>
        </Dialog>
        </Wrapper>
        </>
    )
}