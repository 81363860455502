import React from "react";
import styled from "styled-components";
import { useState } from "react";
import { useEffect } from "react";
import { CreatePerQuantity } from "./CreatePerQuantity";
import { CreatePerItem } from "./CreatePerItem";
import { CreatePerDelivery } from "./CreatePerDelivery";
import { ConfirmDelete } from "./ConfirmDelete";
const Container = styled.div`
margin-left:150px;
position:fixed;
height:80%;
font-size : 20px;
top:10px;
right:0;
width:${(props) => props.width};
@media screen and (max-width: 995px) {
    margin-left:10px;
}
`;
export const AddHeader = styled.div`
padding: 10px;
display: flex;
flex-direction: row;
gap: 40%;

media screen and (max-width: 1000px) {
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 20%;
}
media screen and (max-width: 1200px) {
      padding: 10px;
      display: flex;
      flex-direction: row;
      gap: 20%;
  }
  @media screen and (max-width: 800px) {
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 10%;
    }
    @media screen and (max-width: 400px) {
  
          padding: 10px;
          display: flex;
          flex-direction: column;
          gap: 5%;
      }
`;
export const ButtonContainer = styled.div`
display:flex;
flex-direction:row;
@media screen and (max-width: 1400px) {
  
    flex-direction: column;
  
}
@media screen and (max-width: 995px) {
    margin-top:15px;
      flex-direction: column;

  }
  @media screen and (max-width: 400px) {

       
        flex-direction: column;
 
    }
`;

export const ButtonStyled = styled.button`
white-space: nowrap;
max-height:40px;
min-width:150px;
text-decoration: none;
margin: 10px;
&:hover{
    opacity: 1.5;
    border:1px solid;
    border-color: lightgray; 
    border-radius : 3px;
}
`;
const Bar = styled.hr`
border-top: 3px solid #bbb;
margin: 5px;
width:80%;
`;
const H2 = styled.h2`
@media screen and (max-width: 995px) {
   white-space:nowrap;
    margin-top:20px;
}

`;
const Div = styled.div`
    
     text-align:center;
     margin:1 px;
`;
const SubDiv = styled.div`
     margin-top:5px;
     text-align:center;
     white-space: nowrap;
    
`;
const SubDiv2 = styled.div`
     margin-top:5px;
     text-align:center;

    
`;
 const ItemGrid = styled.div`
 width:80%;
 display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr ;
gap: 10px;


media screen and (max-width: 1000px) {
    display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr 1fr  ;
gap: 8px;
}
@media screen and (max-width: 800px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr   ;
    gap: 5px;
  }
  @media screen and (max-width: 750px) {
    display: grid;
    grid-template-columns: 230px 230px ;
    gap: 5px;
        
    }
    @media screen and (max-width: 460px) {
        display: grid;
        grid-template-columns: 230px  ;
        gap: 5px;
            
        }
    


`;

 const ItemContainer = styled.div`
 position:relative;
 width:auto;
height: 330px;
text-align:center;
font-size:20px;
margin: 5px;
transition-property: box-shadow margin-top filter;
transition-duration: .2s;
border:2px solid;
border-color: ${props => props.color} ;

border-radius : 10px;
box-shadow : 0px 0px 2px 0px grey;
padding-bottom: 10x;
&:hover{
    margin-top: 0px;
    margin-bottom : 5px;
    box-shadow : 0px 0px 10px 0px grey;
}
`;
  const Item = styled.div`
 width:200px;
 display:flex;
 flex-direction:column;
 justify-items:center;
 align-content:center;
 text-align:center;
 margin-top:5px;
 margin-left:2px;
 margin-right:2px;
 background-color: ${props => props.color} ;
height: auto;
text-align:center;
font-size:18px;
transition-property: box-shadow margin-top filter;
transition-duration: .2s;
border:1px solid;
border-color: lightgray; 
border-radius : 10px;
box-shadow : 0px 0px 2px 0px grey;
padding-bottom: 10x;

`;
const Remove = styled.div`
height: 40px;
width: 40px;
display :flex;
align-items:center;
justify-element:center;
position:absolute;
background-image:url("/img/Remove.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border: none; 
bottom:0;
left:40%;
font-size: 18px;
font-weight:bold;
 &:hover{
    cursor: pointer;
    opacity: 1.5;
    background-color:lightgray;
    border-radius : 7px;
 }
`;

const db = window.firebase.firestore();
export function XY({active}){
  //use sate to open perQuantity panel
  const [openPerQuantity, setOpenPerQuantity ] = useState(false);
   //use sate to open perDelivery panel
   const [openPerDelivery, setOpenPerDelivery ] = useState(false);
    //use sate to open perItem panel
  const [openPerItem, setOpenPerItem ] = useState(false);

    //state for reading data from the database 
    const [data , setData] = useState();
    const isOfferEmpty = data && data.length ===0;
    const canShowOffer = data && true;
    const [openDelete,setOpenDelete]=useState();

    useEffect(()=>{ 
        db.collection("offer").where("proType","==","per")
        .onSnapshot((data) => {
          return setData(data.docs);
        });
      
        
      },[]);
        
    return (
        <Container width={ active ? "calc(100% - 220px)" : "calc(100% - 40px)"}>
    <ConfirmDelete openDelete={openDelete} setData={setData} setOpenDelete={setOpenDelete} collection="offer" />
     {openPerQuantity && <CreatePerQuantity openPerQuantity={openPerQuantity} setOpenPerQuantity={setOpenPerQuantity} setData={setData}/>}
     {openPerDelivery&& <CreatePerDelivery openPerDelivery={openPerDelivery} setOpenPerDelivery={setOpenPerDelivery} setData={setData}/>}
     {openPerItem && <CreatePerItem openPerItem={openPerItem} setOpenPerItem ={setOpenPerItem } setData={setData}/>}
            <AddHeader>
            <H2> Buy X Get Y</H2>  
            <ButtonContainer>   
        <ButtonStyled className='btn btn-primary' onClick={()=>openPerQuantity? setOpenPerQuantity(false): setOpenPerQuantity(true)}>Offer % on quantity</ButtonStyled>
        <ButtonStyled className='btn btn-secondary' onClick={()=>openPerDelivery? setOpenPerDelivery(false): setOpenPerDelivery(true)}>offer delivery on items</ButtonStyled>
        <ButtonStyled className='btn btn-success' onClick={()=>openPerQuantity? setOpenPerItem(false): setOpenPerItem(true)}>offer item on items</ButtonStyled>
    </ButtonContainer>
            </AddHeader>
            <Bar/>
            <ItemGrid>
            {isOfferEmpty  && <Item><div>Loading ...</div></Item>}
            { canShowOffer ? data.map((item)=>{
                return (
               <>   
               <ItemContainer  color ={ item.data().type == "perquantity" ? '#0B5ED7' : item.data().type == "perdelivery" ? '#5C636A' : item.data().type == "peritem" ? '#157347' : '#666666'}   >
               <Div>🎁</Div>
                    <Item  color ={ item.data().type == "perquantity" ? '#0B5ED7' : item.data().type == "perdelivery" ? '#5C636A' : item.data().type == "peritem" ? '#157347' : '#666666'}>
                    <SubDiv>{item.data().name}</SubDiv>
                    </Item>
                    <Item >
                    <SubDiv>{item.data().code}</SubDiv>
                    {item.data().type == "perquantity" ?<SubDiv2>{"Buy "+item.data().quantity+" items from "+item.data().category+" get "+item.data().value+"% discount on the "+(Number(item.data().quantity)+1)+"d"+"\nRecrusive : "+item.data().recrusive}</SubDiv2>
                     :item.data().type == "perdelivery" ? <SubDiv2>{"Buy "+item.data().quantity+" "+item.data().category+" get "+item.data().value+"% discount on the delivery"+"\nRecrusive : "+item.data().recrusive} </SubDiv2>
                    :  item.data().type == "peritem" ? <SubDiv2>{"Buy "+item.data().quantity+" items from "+item.data().category+" get free "+item.data().items[0]+"  \nRecrusive : "+item.data().recrusive} </SubDiv2> :"" }
                    <SubDiv>{item.data().startDate.substring(0, 10)+' '+item.data().startDate.slice(-5)}</SubDiv>
                    <SubDiv>{item.data().endDate.substring(0, 10)+' '+item.data().endDate.slice(-5)}</SubDiv>
                    </Item>
                    <Remove  onClick={()=>setOpenDelete(item.data())}/>
                    </ItemContainer>
                    </>
                )} 
                ) : <Item><div>Loading ...</div></Item>}
            
            </ItemGrid>
        </Container>
    
    )
}