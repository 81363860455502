import React ,{useState,useEffect} from "react";
export const pizzaRed="#f44336";

const db = window.firebase.firestore();

export function NavbarBackgroundColor(){
    const [NavbarBackground, setNavbarBackground] = useState("#f44336");
    useEffect(()=>{
        db.collection('themes').doc('Standard').onSnapshot((doc)=>{return  setNavbarBackground(doc.data().navBarBackground)});
  
      }, []);
        
    return NavbarBackground ;
}

export function TickerBackgroundColor(){
    const [TickerBackground, setTickerBackground] = useState("#f44336");
    useEffect(()=>{
        db.collection('themes').doc('Standard').onSnapshot((doc)=>{return  setTickerBackground(doc.data().tickerBackground)});
     
      }, []);
        
    return TickerBackground ;
} 

export const  NavLyric =  ()=> {

    const [navLyric, setNavLyric] = useState("#fff");
    useEffect(()=>{
        db.collection('themes').doc('Standard').onSnapshot((doc)=>{return  setNavLyric(doc.data().navLyric)});
      
      }, []);
        
    return navLyric ;
}

export const  TickerLyric =  ()=> {

    const [tickerLyric, setTickerLyric] = useState("#fff");
    useEffect(()=>{
        db.collection('themes').doc('Standard').onSnapshot((doc)=>{return  setTickerLyric(doc.data().tickerLyric)});
      
      }, []);
        
    return tickerLyric ;
}
export const  OrderBtn =  ()=> {

    const [orderBtn, setOrderBtn] = useState("#CB853D");
    useEffect(()=>{
        db.collection('themes').doc('Standard').onSnapshot((doc)=>{return  setOrderBtn(doc.data().orderBtn)});
      
      }, []);
        
    return orderBtn ;
}