import React from 'react';
import styled from 'styled-components';
import ToggleButton from 'react-toggle-button';
import { useState ,useEffect} from "react"

const Container = styled.div`
position:fixed;
top:10px;
height:560px;
right:0;
width:${(props) => props.width};
overflow-y: scroll;
font-size : 20px;
@media screen and (max-width: 995px) {
  margin-top:50px;
    margin-left:10px;
    width:100%;
}
`;
const InputContainer = styled.div`
  display:flex;
  flex-direction:row;
  gap:5px;
  margin-top:2px;

  @media(max-width: 700px){ 
    margin-top:10px;
    margin-left:-1px;
    
   }
  
`;
const Div = styled.div`

`;
const ConfirmButton = styled.div`
margin: 10px;
color : white;
height:40px;
width:100px;
border-radius: 5px;
padding: 10px;
text-align: center;
cursor: pointer;
background-color: blue;
opacity:0.8;
z-index : 4;
${({disabled})=>disabled && `
opacity: .5;
background-color: grey;
pointer-events: none;
`}
`;
const AddContainer = styled.div`
height:700px;
display: flex;
flex-direction: column;
margin:5px;
`;
const ExtraItem = styled.div`
position:relative;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
width:auto;
height: 50px;
text-align:center;
font-size:20px;
margin: 5px;
transition-property: box-shadow margin-top filter;
transition-duration: .2s;
border:1px solid;
border-color: lightgray; 
border-radius : 10px;
box-shadow : 0px 0px 2px 0px grey;
padding-bottom: 10x;

`;
const ExtraContent = styled.div`
display:flex;
flex-direction:row;
height:auto;
`;
const ExtraGrid = styled.div`
display: grid;
grid-template-columns: 1fr 1fr 1fr   ;
gap: 10px;

`;
const Close = styled.div`
height: 30px;
width: 30px;
background-image:url("/img/close.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
margin:5px;
 &:hover{
    cursor: pointer;
    opacity:0.7;
 }
`;
const SearchContainer = styled.div`
display: flex;
flex-direction: column;
overflow-y: scroll;
height:200px;

margin-left:100px;
cursor:pointer;
&:hover{
    cursor: pointer;
    opacity:0.7;
 }
`;
const Input = styled.input`
cursor:pointer;
&:hover{
    cursor: pointer;
    
 }
`;
const Input2 = styled.textarea`

height:100px;
cursor:pointer;
resize: none;
&:hover{
    cursor: pointer;
    
 }
`;
const Bar = styled.hr`
border-top: 3px solid #bbb;
margin: auto;
padding-bottom:20px;
width:80%;
@media screen and (max-width: 500px) {
   
    width:300px;
}
`;

const Notice = styled.span`
white-space: nowrap;
  margin-left:20px;
  color: red;
  font-size: 14px;
`;
const Okay = styled.span`
white-space: nowrap;
font-weight:bold;
  margin-left:20px;
  color: green;
  font-size: 16px;
`;
const Label = styled.label`
margin-right:10px;
`;
const db = window.firebase.firestore();
const ButtonContainer = styled.div`
padding-top:30px;
margin-right:20px;
margin-left: auto;
margin-bottom:30px;
display:flex;
flex-direction:row;
`;

const ButtonStyled = styled.button`
max-height:40px;
text-decoration: none;
margin: 10px;
&:hover{
    opacity: 1.2;
    padding-top: 3px;
}
`;

function Sales({active}) {

    const [tags, setTags] = useState([]);
    const [search, setSearch] = useState("");
    const [items, setItems] = useState([]);
    const [welcomeTitle,setWelcomeTitle] = useState(" ");
    const [welcomeText,setWelcomeText] = useState(" ");
    const [welcomeGreeting,setWelcomeGreeting] = useState(" ");
    const [toggle, setToggle] = useState();

  

    function searchitems(x){
   
        if(x.length!=0){
        db.collection('items').
        where("name", ">=", x.charAt(0).toUpperCase() + x.slice(1).toLowerCase()).
    where("name", "<=", x.charAt(0).toUpperCase() + x.slice(1).toLowerCase()+ "\uf8ff").get().then((data)=>{return  setItems(data.docs)});
        }
        if(x.length==0){
            db.collection("items")
      .get().then((data) => {
        return setItems(data.docs);
      });
        }
        if(tags >= 6 ){
            setItems();
        }
     
    }
    function handleDelete(i) {
        setTags(tags.filter((tag, index) => index !== i));
    
      };
    
      function handleAddition(e) {
        if (!tags.includes(e)&& tags.length <6) setTags([...tags,  e ]);
    
      };
      function Save(){
        const docRef = db.collection("sales").doc("sales1");
        docRef.set({
                 items : tags
        })
      }
    
  
    useEffect(()=>{ 
      setTags([])
      db.collection('sales').doc('sales1').get().then((doc)=>{
        return doc.data().items.map((t)=>{
          setTags(tags=>[...tags,t])
        }) 
      })
      db.collection('welcome').onSnapshot((data)=>{return  setWelcomeTitle(data.docs[0].data().Title)});
      db.collection('welcome').onSnapshot((data)=>{return  setWelcomeText(data.docs[0].data().Paragraph)});
      db.collection('welcome').onSnapshot((data)=>{return  setWelcomeGreeting(data.docs[0].data().Greeting)});
      db.collection('welcome').onSnapshot((data)=>{return  setToggle(data.docs[0].data().Toggel)});
   },[]);
  
  function handleSubmit(){
    const docRef = db.collection("welcome").doc("0");
       
    try {
   
      docRef.update({
       Title: welcomeTitle,
       Paragraph:welcomeText,
       Greeting:welcomeGreeting,
       Toggel:toggle
      
      });
      console.log("submited :=)")
    } catch (e) {
      console.log(e);
    }
  }

    return (
     
   
          <Container width={ active ? "calc(100% - 220px)" : "calc(100% - 40px)"}>
              <AddContainer>
                <ButtonContainer>
                  <Okay>Don't forget to click save after changes </Okay>
              <ButtonStyled className="btn btn-primary"  disabled={tags.length<5} onClick={()=>{Save()}} >Save</ButtonStyled>
              </ButtonContainer>
            <ExtraGrid >
              {tags.length != 0 ? tags.map((item, index) => {
                return (

                  <ExtraItem>
                    <ExtraContent>
                      {console.log(item)}
                      <Div>{item}</Div>
                      <Close onClick={() => { handleDelete(index) }} />
                    </ExtraContent>

                  </ExtraItem>
                )

              }) : <Notice>At least 1 item is required</Notice>}

            </ExtraGrid>
            
           
      
            <InputContainer className="form-group">
            <Label  className="control-label">Items </Label>
            
                <input type="text" className="form-control" name="item" placeholder="Item"
                   value={search}
         
                   onChange={(e)=>{searchitems(e.target.value);setSearch(e.target.value)}} />
             
            </InputContainer>

            <SearchContainer>
            {items.map((item) => {
                  return (
                    <>
               
                      <Input className="form-control" 
                       readonly="readonly" value={item.data().name} onClick={(e)=>{handleAddition(e.target.value); setItems([])}}
                       />
                  
                    </>
                  )
                }
                )}
            
            </SearchContainer>
        

       
        <Bar />

        <ToggleButton
      
      value={toggle}
       onToggle={()=>!toggle? setToggle(true):setToggle(false)}
       colors={{
        active:{
          base:"#1578F1"
        },
        inactive: {
          base: 'gray'}
       }}
       />

   <InputContainer className="form-group">
     <label>Title</label>
     <Input type="text" className="form-control" name="text1"  placeholder="Title"   value={welcomeTitle} onChange={e => setWelcomeTitle(e.target.value)} />
  </InputContainer>
  
  <InputContainer className="form-group">
     <label>Text</label>
     <Input2 type="text" className="form-control" name="text2"  placeholder="Text"   value={welcomeText} onChange={e => setWelcomeText(e.target.value)} ></Input2>
  </InputContainer> 

  <InputContainer className="form-group">
     <label>Greeting</label>
     <Input type="text" className="form-control" name="text3"  placeholder="Paragraph"   value={welcomeGreeting} onChange={e => setWelcomeGreeting(e.target.value)} />
  </InputContainer> 


  <ButtonContainer>
  <Okay>Don't forget to click save after changes !</Okay>
        <ButtonStyled className="btn btn-primary"  onClick={()=>handleSubmit()} >
          Save 
        </ButtonStyled>
        
      </ButtonContainer>
      </AddContainer>   
      </Container>
    );
}

export default Sales;