import React from "react";
import styled from "styled-components";
import {useState, useEffect} from 'react';
import moment from "moment";
import { OrderDetails } from "../Orders/OrderDetails";
import { ConfirmPanel } from "./ConfirmPanel";
var _ = require('lodash');
// Get a database reference
const database = window.firebase.database();
const Container = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
  font-size: 26px;
  font-family: "Inter", sans-serif;
  position:fixed;
  width:${(props) => props.width};
  top:20px;
  right:0;
  margin-left:auto;
  margin-right:auto;
  height:90%;
  font-size: 20px;
  align-content: center;
`;
const AccountsDiv = styled.div`
  overflow-y: scroll;
  width: 100%;
  height: 400px;
  border: solid 1px;
  border-color: gray;
  border-radius: 10px;
  margin-left:auto;
  margin-right:auto;
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
  @media screen and (max-width: 1050px) {
    width: 100%;
  }
`;
const AccountGrid = styled.div`
  background-color:${props => props.color}; 
  opacity : ${props => props.opacity}; 
  height: auto;
  width:auto;
  border: solid 1px;
  border-color: gray;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 0.7fr 0.1fr 0.1fr 0.1fr;
  gap: 15px 15px;
  margin: 10px;
  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
`;
const Button = styled.button`
margin-top:7px;
  margin-bottom: 5px;
  height: 40px;
  width: 120px;
  text-align: center;

`;
const AccountGridHeader = styled.div`
  border: solid 1px;
  border-color: gray;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr 0.1fr 0.1fr 0.1fr  2fr;
  gap: 15px 10px;
  margin: 40px;
  margin:auto;
  margin-top:10px;
  width:80%;

  @media screen and (max-width: 700px) {
    white-space: nowrap;
    grid-template-columns: 1fr 0.01fr 0.01fr 0.01fr 1fr ;
  }
`;
const Header = styled.div`
white-space: nowrap;
  display: flex;
  font-weight: bold;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
const Wrapper = styled.div``;
const ButtonContainer = styled.div`
display:flex;
flex-direction:column;
margin-left:5px;
margin-right:5px;
`;
const Hide = styled.div`
display:none;
`;
const Filter = styled.div`
display:flex;
flex-direction:row;

@media screen and (max-width: 995px) {
   
    font-size:15px;
}
@media screen and (max-width: 700px) {
   
    flex-direction:column;
   
}
`;
const FilterItem = styled.div`
display:flex;
flex-direction:row;
align-items:center;
justify-element:center;
margin-right:10px;
`;
const Radio = styled.input`
margin-right:5px;
margin-left:5px;
margin-top:auto;
margin-bottom:auto;
`;
const RadioLabel = styled.label`
white-space:nowrap;
margin-right:5px;
margin-left:5px;
margin-top:auto;
margin-bottom:auto;
`;
export function History({active}){
    const [width, setWidth]=useState(window.screen.width);
    const [id,setId]= useState();
    const [confirmPanel, setConfirmPanel] = useState(false);
    const[orderItem,setItem] = useState(); 
    const[toggel,setToggel] = useState(false); 
    const[filter,setFilter] = useState("all");
    var data;
    const ref = database.ref('orders');
    const [orders, setOrders] = useState(null);
    const canShowOrders = orders && orders.length >0;
    const today = Math.floor(new Date().setHours(0, 0, 0, 0) / 1000);
    const yesterday = Math.floor(new Date(new Date().setDate(new Date().getDate() - 2)).setHours(0, 0, 0, 0) / 1000);
    const lastWeek = Math.floor(new Date(new Date().setDate(new Date().getDate() - 7)).setHours(0, 0, 0, 0) / 1000);
    const lastMonth = Math.floor(new Date(new Date().setDate(new Date().getDate() - 30)).setHours(0, 0, 0, 0) / 1000);
    useEffect(()=>{
        ref.on('value', (snapshot) => {          
            data = _.toArray(snapshot.val());
            if(filter ==="all"){
            setOrders(data.sort((a,b)=>a.Id -b.Id).filter(t=>t!=null));}
             if (filter ==="day"){
              console.log(today)
              console.log(data.filter(t=>t!=null && t.date>today))
              setOrders(data.filter(t=>t!=null && t.date>today));
            }
             if (filter==="yesterday"){
              setOrders(data.filter(t=>t!=null && t.date>yesterday));
            }
           if (filter ==="week"){
              setOrders(data.filter(t=>t!=null && t.date>lastWeek));
            }
            if (filter ==="month"){
              setOrders(data.filter(t=>t!=null && t.date>lastMonth));
            }
          
           
           
          
           
 
          
           
     });
    },[filter]
    )
  
    return (
        <Container  width={ active ? "calc(100% - 220px)" : "calc(100% - 40px)"}>
           <Filter> {<FilterItem><div>{"filtrer : "}</div></FilterItem>}
          
             <FilterItem >
                
                <Radio   
                 type="radio" 
                 value={"day"}
                
                 checked={filter==="day" ? "checked":""}
               onChange={(e)=>{setFilter(e.target.value);}}  
               />
               <RadioLabel>{"Aujourd'hui"}</RadioLabel>

               <Radio   
                 type="radio" 
                 value={"yesterday"}
                
                 checked={filter === "yesterday"? "checked":""}
               onChange={(e)=>{setFilter(e.target.value);}}  
               />
               <RadioLabel>{"Hier"}</RadioLabel>

               <Radio   
                 type="radio" 
                 value={"week"}
                
                 checked={filter==="week" ? "checked":""}
               onChange={(e)=>{setFilter(e.target.value);}}  
               />
               <RadioLabel>{"Last Week"}</RadioLabel>

               <Radio   
                 type="radio" 
                 value={"month"}
                
                 checked={filter==="month" ? "checked":""}
               onChange={(e)=>{setFilter(e.target.value);}}  
               />
               <RadioLabel>{"Le mois dernier"}</RadioLabel>

               <Radio   
                 type="radio" 
                 value={"all"}
                
                 checked={filter==="all" ? "checked":""}
               onChange={(e)=>{setFilter(e.target.value);}}  
               />
               <RadioLabel>{"Tout"}</RadioLabel>

               

             </FilterItem>
                
             
           
            </Filter>
     {confirmPanel? <ConfirmPanel id={id} orderItem={orderItem} setConfirmPanel={setConfirmPanel}/> : ""}
       {toggel ?<OrderDetails  setToggel={setToggel} orderItem={orderItem} type="history"/> : ""}
         <AccountsDiv>
           {canShowOrders && orders.map((item,index)=>{
            return(
                <Wrapper key={index}>
            
                <AccountGrid color={item.status == "canceled" ? "lightgray" : ""}>
                    <Item>{item.Id}</Item>
                   {width >=700 ?<Item>{item.status}</Item>: ""} 
                   {width >=700 ?<Item>{isNaN(item.date) ?moment(item.date).format("MM-DD HH:mm") : moment.unix(item.date).format("MM-DD HH:mm")}</Item>: ""} 
                    <ButtonContainer>
                    <Item>{item.futar ? item.futar : ""}</Item>
                    <Item>{item.total+" €"}</Item>
                    </ButtonContainer>
                   
                    <ButtonContainer>
                    <Button className="btn btn-info" onClick={()=>{!toggel? setToggel(true):setToggel(false); setItem(item);}}>Open</Button>
                    <Button className="btn btn-danger" onClick={()=>{!toggel? setConfirmPanel(true):setConfirmPanel(false);setId(item.Id);setItem(item);}}>Delete</Button>
                    </ButtonContainer>
                   
                </AccountGrid>
                </Wrapper>
            ) 
           
           })}
         </AccountsDiv>
         <AccountGridHeader>
            <Header>Total</Header>
            <Header></Header>
            <Header></Header>
            <Header></Header>
         <Header> {canShowOrders && orders.filter((t)=> t.status !="canceled").reduce((total, order)=>{
               return total +(parseInt(order.total)) },0)+ " €"}</Header>
              
               </AccountGridHeader>
        </Container>
    )
}