import React from "react";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { DeletePhoto } from "./DeletePhoto";
import { Id } from '../../../ProjectId';
import { Token } from '../../../StorageToken'; 
const Dialog = styled.div`
position: fixed;
  width: auto;
  height:auto;
  background-color: white;
  margin-bottom:50px;
 
  z-index: 55;
margin:auto;
padding:10px;
  display: flex;
  flex-direction: column;
  border-radius:5px;
  // Add this part
  @media (max-width: 400px) {
    
    overflow-y: scroll;
    height:auto;
    width: auto;
 
    
  }
`;

 const Wrapper = styled.div`
z-index: 55;
position:fixed;
width:100%;
height:100%;
left:0;
bottom:0;
  display: flex;
  justify-content: center;
  align-items: center;

`;

const DialogShadow = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  left:0px;
  background-color: black;
  opacity: 0.8;
  z-index: 51 !important;
`;
const ButtonStyled = styled.button`
max-height:40px;
text-decoration: none;
margin: 10px;
&:hover{
  opacity: 1.2;
  padding-top: 3px;
}
`;

const DialogHeader = styled.div`
padding: 10px;
display: flex;
flex-direction: row;
gap: 20%;
media screen and (max-width: 1000px) {
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 40%;
}
@media screen and (max-width: 800px) {
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 30%;
}
@media screen and (max-width: 400px) {
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 10%;
}
`;


const Header = styled.div`
display:flex;
flex-direction: column;`;
const H2 = styled.div`
font-size: 30px;
font-weight: bold;
display: flex;
flex-direction: row;
white-space: nowrap;
`;


const InputContainer = styled.div`
  display: grid;
  grid-template-columns: .5fr 1fr ;
  margin:5px;
  
`;
const ButtonContainer = styled.div`
display:flex;
flex-direction:row;
@media screen and (max-width: 995px) {
 
  margin-top:20px;
}
@media screen and (max-width: 700px) {
  flex-direction:column;
      
  }
`;
const Bar = styled.hr`
border-top: 3px solid #bbb;
margin: 5px;
width:90%;
@media screen and (max-width: 500px) {
 
  width:300px;
}
`;
const ItemGrid = styled.div`
height:auto;
 display: grid;

grid-template-columns: 1fr 1fr 1fr ;
gap:10px;

media screen and (max-width: 1500px) {
display: grid;
grid-template-columns: 1fr 1fr 1fr   ;
}
media screen and (max-width: 1300px) {
    display: grid;
    grid-template-columns: 1fr 1fr ;
    gap: 8px;
    }


media screen and (max-width: 1000px) {
    display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr  ;
gap: 8px;
}
@media screen and (max-width: 800px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr   ;
    gap: 5px;
  }
  @media screen and (max-width: 663px) {
    display: grid;
    grid-template-columns: 1fr 1fr ;
    gap: 5px;
        
    }


`;

 const ItemContainer = styled.div`
 position:relative;
width:200px;
height: 180px;
text-align:center;
font-size:20px;
margin: 5px;
transition-property: box-shadow margin-top filter;
transition-duration: .2s;
border:1px solid;
border-color: lightgray; 
border-radius : 10px;
box-shadow : 0px 0px 2px 0px grey;
padding-bottom: 10x;
&:hover{
    margin-top: 0px;
    margin-bottom : 5px;
    box-shadow : 0px 0px 10px 0px grey;
}
@media screen and (max-width: 500px) {
   
   width:150px;
}
@media screen and (max-width: 350px) {
    height: 210px;
    width:120px;
 }
`;

const Div = styled.div`


font-weight:bold;
margin-top :auto;
margin-bottom:auto;
text-align:center;
@media screen and (max-width: 700px) {
  font-size:14px;
}

`;
const Item = styled.div`
margin-left:auto;
margin-right:auto;
position:relative;
margin-top:5px;
width:100px;
height: 100px;
text-align:center;
font-size:20px;
background-image: ${({img})=> `url(${img});`}
background-position: center;
background-size: cover ;

transition-property: box-shadow margin-top filter;
transition-duration: .2s;
border:1px solid;
border-color: lightgray; 
border-radius : 10px;
box-shadow : 0px 0px 2px 0px grey;
padding-bottom: 10x;

`;
const Input = styled.input`
`;
const Percent = styled.div`
display:flex;
flex-direction:row;
justify-items:center;
align-content:center;
margin-left:15px;
`;
const Remove = styled.div`
height: 40px;
width: 40px;
display :flex;
align-items:center;
justify-element:center;
position:absolute;
background-image:url("/img/Remove.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border: none; 
bottom:0;
left:0;
font-size: 18px;
font-weight:bold;
 &:hover{
    cursor: pointer;
    opacity: 1.5;
    background-color:lightgray;
    border-radius : 7px;
 }

`;
function EditPictueContainer({ openPicture, prop,setPictures,setOpenPicture}) {
    const storage = window.firebase.storage();
var listRef = storage.ref(`/${openPicture.name}`);
    listFiles();
const [arr, setArr]= useState([]);
const [name,setName]=useState(openPicture.imgUrl);
const [percent, setPercent] = useState(0);
const [openDelete,setOpenDelete]=useState();
// State to store uploaded file
const [file, setFile] = useState("");
const reader = new FileReader();
 //upload picture 
 const handleUpload = (event) => {
    event.preventDefault();
    if (!file) {
      alert("Please upload an image first!");

    }
  
    else {

      console.log("after check")
      var storageRef = storage.ref(`/${openPicture.name}/${file.name}`);

      console.log("after reference")

      // 'file' comes from the Blob or File API
      storageRef.put(file).then((snapshot) => {
        console.log('inside upload');
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        // update progress
        setPercent(percent);
      }, (err) => console.log(err)

      );
      listFiles();
    }
  };
// Handle file upload event and update state
function handleChange(event) {
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (e) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (e) => {
          const height = e.target.height;
          const width = e.target.width;
          if(openPicture.Width){
          if ((width != openPicture.Width  || height != openPicture.Height)) {
            setFile("");
            alert("size must be "+openPicture.Width+" x "+openPicture.Height);
            return false;
          }}
          console.log(width + "__"+height)
          console.log("good size")
          setFile(event.target.files[0]);
          return true;
        };
      };
 
}
//Storage for picture

const isItemsEmpty = arr && arr.length ===0;
const canShowItems = arr && true;
// Find all the prefixes and items.

function listFiles(){
    listRef.listAll()
  .then((res) => {
   
   
   setArr(res.items) ;
  }).catch((error) => {
    console.log(error);
  });
}

function handleSubmit() {
      
    
    const db = window.firebase.firestore();
    const docRef = db.collection("pictures").doc(openPicture.name.toLowerCase());
    try {

      docRef.update({
        name: openPicture.name,
        imgUrl:name
      
      });
      console.log("submited :=) "+name)
    } catch (e) {
      console.log(e);
    }

    db.collection('pictures').get().then((data) => { return setPictures(data.docs) });


    setOpenPicture();



  }

return (
    <>
 <DialogShadow />
 <Wrapper>
      <Dialog>
      <DeletePhoto openDelete={openDelete}  setOpenDelete={setOpenDelete} collection={openPicture.name} />
          <DialogHeader>
            <Header>
              <H2> {openPicture.name}</H2>
           
            </Header>
            <ButtonContainer>
              <ButtonStyled className="btn btn-primary" onClick={()=>{handleSubmit()}}>
                Save
              </ButtonStyled>
              <ButtonStyled
                className="btn btn-light"
                onClick={() =>setOpenPicture()
                }
              >
                Cancel
              </ButtonStyled>
         
       
            </ButtonContainer>
          </DialogHeader>
          <Bar />
          <ItemGrid>
          {isItemsEmpty  && <div>Loading ...</div>}
            { canShowItems ?arr.map((item)=>{
                return (
               <>   
               
               <ItemContainer>
              
               <Div>{item.name}</Div>
               <Item img ={`https://firebasestorage.googleapis.com/v0/b/${Id}.appspot.com/o/${openPicture.name}%2F${item.name}?alt=media&token=${Token}`}>
                      
              
                    </Item>
                    <Remove  onClick={()=>setOpenDelete(item)}/>
                    <Input 
                      type="radio" 
                      value={item.name}
                     
                      checked={item.name ==name ? "checked":""}
                    onChange={(e)=>setName(e.target.value)} />
                   
                    </ItemContainer>
                    </>
                )} 
                ) : <div>Loading ...</div>}
            
            </ItemGrid>
            <Bar />
            <InputContainer className="form-group">
         
              <input type="file" onChange={handleChange} accept="" className="form-control" />
            
            <button className="btn btn-primary" onClick={handleUpload}>Upload </button>
            <Percent>{percent} "%"</Percent>
          </InputContainer>
          </Dialog >
          </Wrapper>
    </>
)



}

export function EditPicture(props){
    if (!props.openPicture) return null;
    return <EditPictueContainer {...props}/>
}