import React ,{useState,useEffect} from "react";

const db = window.firebase.firestore();

export function BannerImage(){
    const [NavbarBackground, setNavbarBackground] = useState("header.jpg");
    useEffect(()=>{
        db.collection('pictures').doc('banner').onSnapshot((doc)=>{return  setNavbarBackground(doc.data().imgUrl)});
  
      }, []);
        
    return NavbarBackground ;
}


export function LogoImage(){
    const [logo, setLogo] = useState("azizLogo.png");
    useEffect(()=>{
        db.collection('pictures').doc('logos').onSnapshot((doc)=>{return  setLogo(doc.data().imgUrl)});
  
      }, []);
        
    return logo ;
}
export function ImageSent(){
  const [logo, setLogo] = useState("azizLogo.png");
  useEffect(()=>{
      db.collection('pictures').doc('sent').onSnapshot((doc)=>{return  setLogo(doc.data().imgUrl)});

    }, []);
      
  return logo ;
}

export function BodyImage(){
    const [body, setBody] = useState("background.jpg");
    useEffect(()=>{
        db.collection('pictures').doc('body').onSnapshot((doc)=>{return  setBody(doc.data().imgUrl)});
  
      }, []);
        
    return body ;
}

export function FooterImage(){
    const [footer, setFooter] = useState("header.jpg");
    useEffect(()=>{
        db.collection('pictures').doc('footer').onSnapshot((doc)=>{return  setFooter(doc.data().imgUrl)});
  
      }, []);
        
    return footer ;
}