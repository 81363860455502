import React from 'react';
import styled from "styled-components";
import { useState } from "react";
import { useEffect } from "react";
import {CreateCategory} from "./AddCategory"
import { ConfirmDelete } from '../ConfirmDelete';
import { AddHeader, ButtonContainer,ButtonStyled,Bar} from '../Items/Items';
import { T } from '../../../../../Language/translate';
const ItemGrid = styled.div`
height:600px;
 display: grid;
 overflow-y: scroll;
grid-template-columns: 200px 200px 200px 200px 200px ;
grid-template-rows:90px 90px 90px 90px auto auto auto ;
gap:10px;
@media screen and (max-width: 1500px) {
display: grid;
grid-template-columns: 1fr 1fr 1fr   ;
}
@media screen and (max-width: 1300px) {
    display: grid;
    grid-template-columns: 1fr 1fr ;
    gap: 8px;
    }


@media screen and (max-width: 1000px) {
    display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr  ;

gap: 8px;
}
@media screen and (max-width: 800px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr   ;
    gap: 5px;
  }
  @media screen and (max-width: 663px) {
    display: grid;
    grid-template-columns: 1fr 1fr ;
    gap: 5px;
        
    }


`;
const Container = styled.div`
position:fixed;
right:0;
width:${(props) => props.width};
top:100px;
font-size : 20px;
@media screen and (max-width: 995px) {
    margin-left:10px;
}
`;
 const Item = styled.div`
 display: flex;
flex-direction:row ;
height: 80px;
text-align:center;
font-size:20px;
background-position: center;
background-size: cover ;
padding: 10px;
margin: 5px;
transition-property: box-shadow margin-top filter;
transition-duration: .2s;
border:1px solid;
border-color: lightgray; 
border-radius : 10px;
box-shadow : 0px 0px 2px 0px grey;
padding-bottom: 10x;
&:hover{
    opacity:0.7;
    margin-top: 0px;
    margin-bottom : 5px;
    box-shadow : 0px 0px 10px 0px grey;
}
@media screen and (max-width: 700px) {
    width:150px;
        
    }
    @media screen and (max-width: 350px) {
      
        width:130px;
     }
`;

const Remove = styled.div`
height: 40px;
width: 40px;
display :flex;
align-items:center;
justify-element:center;
background-image:url("/img/Remove.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border: none; 
font-size: 18px;
font-weight:bold;
 &:hover{
    cursor: pointer;
    opacity: 1.5;
    background-color:lightgray;
    border-radius : 7px;
 }
`;
const Div = styled.div`
     display:flex;
     text-align:center;
     @media screen and (max-width: 663px) {
        font-size:16px;
            
        }
     
`;
const H2 = styled.h2`
@media screen and (max-width: 995px) {
   
    margin-top:20px;
}

`;
const db = window.firebase.firestore();
function Categories({lang,active}) {

//use sate to open panel for adding item 
const [openPanel, setOpenPanel ] = useState(false);
//use state to read data 
const [data , setData] = useState();
const [openDelete,setOpenDelete]=useState();
const isCategoryEmpty = data && data.length ===0;
const canShowCategory = data && true;
useEffect(()=>{ 
db.collection('Category').onSnapshot((data)=>{return  setData(data.docs)});

},[]);



return (
    <Container width={ active ? "calc(100% - 220px)" : "calc(100% - 40px)"}>
        {openPanel && <CreateCategory openPanel={openPanel} setOpenPanel={setOpenPanel} setData={setData} lang={lang}/>}
        <ConfirmDelete openDelete={openDelete} setData={setData} setOpenDelete={setOpenDelete} collection="Category" />
        <AddHeader>
        <H2>{T(174,lang)}</H2>  
        <ButtonContainer>   
    <ButtonStyled className='btn btn-primary' onClick={()=>openPanel? setOpenPanel(false): setOpenPanel(true)} >{T(172,lang)}</ButtonStyled>
   
</ButtonContainer>
        </AddHeader>
        <Bar/>
        <ItemGrid>
        {isCategoryEmpty  && "Loading ..."}
        { canShowCategory ? data.map((item)=>{
            return (
           <>
                <Item>
                <Remove onClick={()=>setOpenDelete(item.data())}/>
                    <Div>{item.data().name}</Div>
                </Item>
                </>
            )} 
            ) : "Loading ..."}
        
        </ItemGrid>
  
    </Container>
);
}

export default Categories;