import React from "react";
import useForm from "../../../../../Hooks/useForm";
import styled from "styled-components";



const Dialog = styled.div`
position: fixed;
  width: 500px;
  height:auto;
  background-color: white;
  margin-bottom:50px;
 
  z-index: 55;
margin:auto;
padding:10px;
  display: flex;
  flex-direction: column;
  border-radius:5px;
  // Add this part
  @media (max-width: 400px) {
    
    overflow-y: scroll;
    height:auto;
    width: auto;
 
    
  }
`;

 const Wrapper = styled.div`
z-index: 55;
position:fixed;
width:100%;
height:100%;
left:0;
bottom:0;
  display: flex;
  justify-content: center;
  align-items: center;

`;


const ButtonStyled = styled.button`
max-height:40px;
text-decoration: none;
margin: 10px;
&:hover{
  opacity: 1.2;
  padding-top: 3px;
}
`;
const DialogShadow = styled.div`
position: fixed;
height: 100%;
width: 100%;
top: 0px;
left:0px;
background-color: black;
opacity: 0.7;
z-index: 11;
`;

 const DialogHeader = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 20%;
  media screen and (max-width: 1000px) {
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 40%;
  }
  @media screen and (max-width: 800px) {
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 30%;
  }
  @media screen and (max-width: 400px) {
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 10%;
  }
`;

const Notice = styled.span`
white-space: nowrap;
  color: red;
  font-size: 14px;
`;
const Header = styled.div`
display:flex;
flex-direction: column;`;
 const H2 = styled.div`
  font-size: 30px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
`;

 const H4G = styled.div`
  font-size: 15px;
  font-weight: bold;
  color:green;

  white-space: nowrap;
`;


const InputContainer = styled.div`
  display: grid;
  grid-template-columns: .5fr 1fr ;
  margin:5px;
  
`;


const ButtonContainer = styled.div`
display:flex;
flex-direction:row;
@media screen and (max-width: 995px) {
   
    margin-top:20px;
}
@media screen and (max-width: 700px) {
    flex-direction:column;
        
    }
`;
const Bar = styled.hr`
border-top: 3px solid #bbb;
margin: 5px;
width:90%;
@media screen and (max-width: 500px) {
   
    width:300px;
}
`;

const Label = styled.label`
font-size:14px;
white-space:nowrap;
margin-right:5px;
`;

// function for required fields 
function isRequired(value) {
    return value != null && value.trim().length > 0;
  }
  function NumericalValue(value){
    return !isNaN(value);
}
const db = window.firebase.firestore();
function EditFeesContainer({ openFees, prop,setThemes,setOpenFees}){

    function showError(){

    }
    
    function handleSubmit() {
      
    
      
        const docRef = db.collection("Fees").doc("Standard");
        try {
    
          docRef.update({
            name:"Standard",
            minOrder:values.minOrder,
            packagePrice : values.packaging,
            rate :values.rate,
          
          });
          console.log("submited :=)")
        } catch (e) {
          console.log(e);
        }
    
        db.collection('Fees').get().then((data) => { return setThemes(data.docs) });
    
    
        setOpenFees();
    
    
    
      }


     
    //Form validation 
    const initialState = {  minOrder:openFees.minOrder,rate:openFees.rate,packaging:openFees.packagePrice};
    const validations = [
      ({minOrder})=>NumericalValue(minOrder) || {minOrder: 'Min order are a number'},
      ({minOrder}) => isRequired(String(minOrder)) || {minOrder: 'Min order are required'},
      ({rate}) => isRequired(String(rate)) || {rate: 'change rate is required'},
      ({packaging}) => isRequired(String(packaging)) || {packaging: 'packaging is required'}
      
   
    ];
  
    const { values, isValid, errors, changeHandler } = useForm(initialState, validations, prop);





    return(
        <>
        <DialogShadow />
        <Wrapper>
    <Dialog>
     
        <DialogHeader>
          <Header>
            <H2> Theme</H2>
            {(isValid)  && <H4G>You can save the Theme now</H4G>}
          </Header>
          <ButtonContainer>
            <ButtonStyled className="btn btn-primary" onClick={()=>{!isValid ?showError():handleSubmit()}}>
              Save
            </ButtonStyled>
            <ButtonStyled
              className="btn btn-light"
              onClick={() =>setOpenFees()
              }
            >
              Cancel
            </ButtonStyled>
          </ButtonContainer>
        </DialogHeader>
        <Bar />
        {errors.minOrder && <Notice>{errors.minOrder}</Notice>}
        <InputContainer>
<Label className=" control-label">Min Order</Label>
<input type="number" className="form-control" name="minOrder" placeholder="Min order " value={values.minOrder} onChange={changeHandler} />
</InputContainer>
{errors.rate && <Notice>{errors.rate}</Notice>}
<InputContainer>
<Label className=" control-label">Exchange rate</Label>
<input type="number" className="form-control" name="rate" placeholder="Exchange rate " value={values.rate} onChange={changeHandler} />
</InputContainer> 
{errors.packaging && <Notice>{errors.packaging}</Notice>}
<InputContainer>
            <Label className=" control-label">Packaging Fee</Label>
            <input type="number" className="form-control" name="packaging" placeholder="Packaging Fee" value={values.packaging} onChange={changeHandler} />
          </InputContainer> 

        </Dialog>
        </Wrapper>
        </>
    )
}

export function EditFees(props){
    if (!props.openFees) return null;
    return <EditFeesContainer {...props}/>
}