import {useState, useEffect} from "react";
const db = window.firebase.firestore();
export function useAdmin(){
    const [admins,setAdmins] = useState([]);
useEffect(()=>{
    db.collection('Admins').doc("Standard").get().then((doc)=>{return  setAdmins(doc.data().admins)});

},[]);
return {
    admins,
    setAdmins
}
}