import React from 'react'
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { DialogShadow,Wrapper } from '../Products/pages/Items/AddItem';
import { Close } from '../Orders/OrderDetails';
import { Header } from '../Products/pages/Items/AddItem';
import { Title } from '../../../Styles/title';
import { T } from '../../../Language/translate';
const Dialog = styled.div`
position: fixed;
  width: 200px;
  height:200px;
  background-color: white;
 margin:auto;
  z-index:200;
padding:10px;
  display: flex;
  flex-direction: column;
  border-radius:5px;
  // Add this part
  @media (max-width: 400px) {
    
    overflow-y: scroll;
    height:auto;
    width: auto;
 
    z-index: 12;
  }
`;
const Div = styled.div`
margin:15px;
text-align:center;

`;
 function NotifyPanelContainer({setNotifyPanel,NotifyPanel,lang}) {

  return (
   <>
   <DialogShadow />
   <Wrapper>
<Dialog>
<Close onClick={()=>{setNotifyPanel()}}/>
<Header><Title>{T(226,lang)}</Title></Header>
<Div>{T(227,lang)}</Div>
<button className='btn btn-primary' onClick={()=>{setNotifyPanel()}}><Link className='link-mod' to={"support"}>{T(228,lang)}</Link> </button>
</Dialog>
   </Wrapper>
   </>
  )
}

export function NotifyPanelWindow(props){
    if (!props.NotifyPanel) return null;
    return <NotifyPanelContainer {...props}/>
}