import React from "react";
import styled from "styled-components";
import { ButtonContainer } from "./Items";
import { Bar } from "./Items";
import { useState, useEffect } from "react";
import useForm from "../Hooks/useForm";
import ToggleButton from 'react-toggle-button';
import { Wrapper,UpContainer,Label ,Dialog, ButtonStyled, DialogShadow ,DialogHeader,Notice 
,Header,H2, InputContainer ,H4R ,H4G ,Percent,InputContainer2,PricesContainer,Input} from "./AddItem";
import { T } from "../../../../../Language/translate";







// function for required fields 
function isRequired(value) {
    return value != null && value.toString().trim().length > 0;
  }
function NumericalValue(value){
    return !isNaN(value);
}







function EditProductContainer({ openItem, prop,setOpenItem,lang}) {

    //use state for upload percent 
  const [percent, setPercent] = useState(0);
  const [toggle, setToggle] = useState(openItem.action);
  const [toggle2,setToggle2] = useState(openItem.size);
  // State to store uploaded file
  const [file, setFile] = useState("");
 // Handle file upload event and update state
 function handleChange(event) {
   setFile(event.target.files[0]);
}




 //Storage for picture
 const storage = window.firebase.storage();
 // Create a root reference
 








 const handleUpload = (event) => {
     event.preventDefault();
     if (!file) {
         alert("Please upload an image first!");
         
     }
     else{
   
         console.log("after check")
         var storageRef = storage.ref(`/files/${file.name}`);
         
         console.log("after reference")
  
        // 'file' comes from the Blob or File API
     storageRef.put(file).then((snapshot) => {
         console.log('inside upload');
         const percent = Math.round(
             (snapshot.bytesTransferred / snapshot.totalBytes) * 100
         );
 
         // update progress
         setPercent(percent);
   }, (err) => console.log(err) 
  
          );
     
         }
 };













  const db = window.firebase.firestore();
// add to all items .
/*
  db.collection("items").get().then(function(querySnapshot) {
    querySnapshot.forEach(function(doc) {
        doc.ref.update({
            actionPrice: 0,
            action: false
        });
    });
});
*/
  //use state for categories 
  const [categories, setCategory] = useState();

  const canShowCategories = categories && true;
   //use state for categories 
   const [options, setOptions] = useState();

   const canShowOptions = options && true;
    useEffect(()=>{ 
     db.collection('Category').get().then((data)=>{return  setCategory(data.docs)});
     db.collection('options').get().then((data)=>{return  setOptions(data.docs)});
},[]); 

  
  
  //Form validation 
  var Ing = openItem.ingredientEn ? openItem.ingredientEn : "" ;
  const initialState = { name: openItem.name, index:openItem.index, price: openItem.price ,
  ingredient:openItem.ingredient,category:openItem.category,status:openItem.status,option:openItem.options,quantity:openItem.quantity,
points:openItem.points,ingredientEn: Ing,action:openItem.action,actionPrice:openItem.actionPrice,price1:openItem.price1,price2:openItem.price2 };
  const validations = [
    ({name}) => isRequired(name) || {name: 'Name is required'},
    ({price}) => isRequired(price) || {price: 'Price is required'},
    ({quantity}) => isRequired(quantity) || {quantity: 'Quantity is required'},
    ({ ingredient }) => isRequired(ingredient) || { ingredient: 'Required' },
    ({price})=>NumericalValue(price) || {price: 'Price is a number'},
    ({quantity})=>NumericalValue(quantity) || {quantity: 'Quantity is a number'},
    ({points})=>NumericalValue(points) || {points: 'Points are a number'},
    ({points}) => isRequired(String(points)) || {points: 'points are required'},
  ];

  const {values, isValid, errors, changeHandler} = useForm(initialState, validations, prop);





  function handleSubmit () {
    const ural = file? file.name : openItem.imgUrl;
   
      
   db.collection("items").where("name","==",openItem.name).get().then((querySnapshot)=>{
    querySnapshot.forEach((doc)=>{
          doc.ref.update({
            name: values.name,
            category: values.category,
            options:values.option,
            status:values.status,
            ingredient:values.ingredient,
            ingredientEn: values.ingredientEn,
            size:toggle2,
            price: Number(values.price),
            price1:Number(values.price1),
            price2:Number(values.price2),
            imgUrl: ural ,
            index:values.index,
            quantity:values.quantity,
            points:values.points,
            action:toggle,
            actionPrice:Number(values.actionPrice)
           });
    })
   })
  
  
   
 
        

  setOpenItem();



  }

  return (
    <>
      <DialogShadow />
      <Wrapper>
      <Dialog>
     
          <DialogHeader>
            <Header>
            <H2>{T(192,lang)}</H2>
            {(!isValid )? "" :<H4G>{T(193,lang)}</H4G>  }
            </Header>
            <ButtonContainer>
              <ButtonStyled disabled={!isValid } className="btn btn-primary" onClick={handleSubmit}>
              {T(194,lang)}
              </ButtonStyled>
              <ButtonStyled
                className="btn btn-light"
                onClick={() => setOpenItem()}
              >
                {T(168,lang)}
              </ButtonStyled>
            </ButtonContainer>
          </DialogHeader>
          <Bar />
        
        
          
          <InputContainer className="form-group">
            <Label for="name" className=" control-label">{T(34,lang)} </Label>
        
              <input type="text" className="form-control" name="name" placeholder="Name"
                value={values.name}
                onBlur={changeHandler}
                onChange={changeHandler}
              />
            

          
          </InputContainer>


          <InputContainer className="form-group">
            <Label className="control-label">{T(180,lang)} </Label>
            <div >
              <select className="form-control"
              name="category"
                value={values.category}
                onChange={changeHandler}
              >
                {canShowCategories && categories.map((item) => {
                  return (
                    <>
                      <option value={item.data().name}>

                        {item.data().name}
                      </option>
                    </>
                  )
                }
                )}


              </select>
            </div>
          </InputContainer>

          <InputContainer className="form-group">
            <Label  className=" control-label">{T(181,lang)}</Label>
            <div >
              <select className="form-control"
              name="option"
                value={values.option}
                onChange={changeHandler}
              >
                {canShowOptions && options.map((item) => {
                  return (
                    <>
                      <option value={item.data().name}>

                        {item.data().name}
                      </option>
                    </>
                  )
                }
                )}
              </select>
            </div>
          </InputContainer>
          <InputContainer className="form-group">
            <Label className="control-label">{T(182,lang)}</Label>
            <div >
              <select className="form-control"
                name="status"
                value={values.status}
                onChange={changeHandler}
              >
                <option value="active">{T(183,lang)}</option>
                <option value="passive">{T(184,lang)}</option>
              </select>
            </div>
          </InputContainer>

          
        <InputContainer>

          <label  className="col-sm-2 control-label">{T(185,lang)}</label>
      
      <input type="text" className="form-control" name="quantity"  placeholder="Quantity" value={values.quantity} onChange={changeHandler} />
      
      { errors.quantity && <Notice>{errors.quantity}</Notice>}
   </InputContainer> 
   <InputContainer>

<label  className="col-sm-2 control-label">{T(186,lang)}</label>

<input type="number" className="form-control" name="points"  placeholder="Points" value={values.points} onChange={changeHandler} />

{ errors.points && <Notice>{errors.points}</Notice>}
</InputContainer> 
  

        
            <InputContainer className="form-group">
              <Label className=" control-label">{T(187,lang)}</Label>
            
                <textarea style={{resize:"none"}} type="text" className="form-control" name="ingredient" placeholder="ingredients"
                  value={values.ingredient}
                  onBlur={changeHandler}
                  onChange={changeHandler} />
              
            
            </InputContainer>

            <InputContainer className="form-group">
              <Label className=" control-label">{T(187,lang)+"EN: "} </Label>
            
                <textarea style={{resize:"none"}} type="text" className="form-control" name="ingredientEn" placeholder="ingredients En"
                  value={values.ingredientEn}
                  onBlur={changeHandler}
                  onChange={changeHandler} />
              
            
            </InputContainer>

   
           

<InputContainer2>
<label className="control-label">{T(191,lang)}</label>
            <ToggleButton
      
      value={toggle2}
       onToggle={()=>!toggle2? setToggle2(true):setToggle2(false)}
       colors={{
        active:{
          base:"#1578F1"
        },
        inactive: {
          base: 'gray'}
       }}
       />
 </InputContainer2>
          {toggle2? <PricesContainer>
            <InputContainer className="form-group">
        
            <Label  className=" control-label">{T(188,lang)}</Label>

            <InputContainer2>
           
              <Input type="text" className="form-control" name="price" id="category" placeholder="Junior" value={values.price} onChange={changeHandler} />
              <Input type="text" className="form-control" name="price1" id="category" placeholder="Sénior" value={values.price1} onChange={changeHandler} />
              <Input type="text" className="form-control" name="price2" id="category" placeholder="Méga" value={values.price2} onChange={changeHandler} />
          
          



          
          
              </InputContainer2>
              </InputContainer>
          



          </PricesContainer>:
          <InputContainer className="form-group">
            <Label  className=" control-label">{T(188,lang)}</Label>
           
              <input type="text" className="form-control" name="price" id="category" placeholder="Price" value={values.price} onChange={changeHandler} />
          
          
          </InputContainer>  }

         

          <InputContainer2 className="form-group">
      <label className="col-sm-2 control-label">{T(189,lang)}</label>
      <ToggleButton
      
      value={toggle}
       onToggle={()=>!toggle? setToggle(true):setToggle(false)}
       colors={{
        active:{
          base:"#1578F1"
        },
        inactive: {
          base: 'gray'}
       }}
       />
      {toggle &&<input type="text" className="form-control" name="actionPrice"  placeholder="Action Price" value={values.actionPrice} onChange={changeHandler} />
}
      { errors.actionPrice && <Notice>{errors.actionPrice}</Notice>}
   </InputContainer2> 

   <UpContainer className="form-group">
    
    <input type="file" onChange={handleChange}  accept=""  className="form-control"/>
    
            <button className="btn btn-primary"  onClick={handleUpload}>{T(190,lang)} </button>
            <Percent>{percent} %</Percent>
   </UpContainer> 
      
      </Dialog>
      </Wrapper>
    </>
  );
}

export function EditProduct(props){
    if (!props.openItem) return null;
    return <EditProductContainer {...props}/>
}