

import { useState } from "react";

//initialize the hook 
export function useLanguage(){
    const [lang,setLang]= useState('Hu');
    return {
        lang,
        setLang
    }
}