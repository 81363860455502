
import { Navbar } from './Navbar/Navbar';
import { BannerBar } from './Banner/Banner';
import { Menu } from './Menu/MainMenu/Menu';
import { FoodDialog } from './FoodDialog/FoodDialog';
import { GlobalStyle } from './Styles/GlobalStyle';
import { Order } from './Order/Order';
import { Welcome } from './Welcome/Welcome';
import {useOrders} from "./Hooks/useOrders";
import { useTitle } from './Hooks/useTitle';
import { useProfile } from './Hooks/useProfile';
import { OrderDialog } from './Order/OrderDialog';
import { useOrderDialog } from './Hooks/useOrderDialog';
import { useOpenUser } from './Hooks/useOpenUser';
import { UserDialog } from './userDialog/userDialog';
import { ProfileDialog } from './Profile/profile';
import { SupportDialog } from './Profile/support';
import { EditProfile } from './Profile/editProfile';
import { useEdit } from './Hooks/useEdit';
import { useCustomerOrder } from './Hooks/useCustomerOrder';
import { ProfileOrders } from './Profile/profileOrders';
import { useTotal } from './Hooks/useTotal';
import { Footer } from './Footer/Footer';
import { useDeliveryFee } from './Hooks/useDeliveryFee';
import { Ticker } from './Banner/Ticker';
import { useLoginPanel } from './Hooks/useLoginPanel';
import { useSignUpPanel } from './Hooks/useSignUpPanel';
import { useOrderSent } from './Hooks/useOrderSent';
import { useBasketPanel } from './Hooks/useBasketPanel';
import { usePromoPrice } from './Hooks/usePromoPrice';
import { usePromoCategory } from './Hooks/usePromoCategory';
import { useFindCheapest } from './Hooks/useFindCheapest';
import { BannerDialog } from './userDialog/BannerDialog';
import { useBannerUser } from './Hooks/useBannerUser';
import { useLimit } from './Hooks/useLimit';
import {useCodeApply} from './Hooks/useCodeApply';
import { useGuest } from './Hooks/useGuest';
import { useSupportPanel } from './Hooks/useSupportPanel';
import { CrossSell } from './CrossSell/CrossSell';
import { useCrossSell } from './Hooks/useCrossSell';
import { useWelcomePanel } from './Hooks/useWelcomePanel';
import CookieNotice from './Cookie/CookieNotice';
import { useUserInfo } from './Hooks/useUserInfo';
import { useUserDetails } from './Hooks/useUserDetails';
import { useOrderEdit } from './Hooks/useOrderEdit';
import { useShowPay } from './Hooks/useShowPay';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useMinOrderPrice } from './Hooks/useMinOrderPrice';
import { useTakeOrDelivery } from './Hooks/useTakeOrDelivery';

const stripePromise = loadStripe('pk_test_51ORk7MDTZJCnUGSTByYGZjc3nJhHSdwxY1nh9bunVCWTbMUt2pwT74gh0L8gVVsC2zwcmHVrqjb5NDlfZrM8mUyz00cej4dpEh');
function App({openFood, setOpenFood,Language,messages,dashAdmins,OrderType,...auth}) {

  const Delivery = useDeliveryFee();
   const orders = useOrders();
   const Limit = useLimit();
   const orderDialog = useOrderDialog();
   const openUser = useOpenUser();
   const bannerUser = useBannerUser();
   const profile =  useProfile();
   const total = useTotal();
   const edit = useEdit();
   const customerPanel = useUserInfo();
   const cusOrders = useCustomerOrder();
   const orderSent = useOrderSent();
   const LoginPanel = useLoginPanel();
   const SignupPanel = useSignUpPanel();
   const BasketPanel = useBasketPanel();
   const PromoPrice = usePromoPrice();
   const PromoCategory = usePromoCategory();
   const codeApplied = useCodeApply();
   const Guest = useGuest();
   const cheapestPizza = useFindCheapest()
   const supportPanel = useSupportPanel();
   const Sell = useCrossSell();
   const welcomePanel = useWelcomePanel();
   const UserDetailsInfo = useUserDetails();
   const isEditingOrNot = useOrderEdit()
   const showPayPanel = useShowPay();
   const MinOrderPriceState = useMinOrderPrice();
   const takeOrDeliveryState = useTakeOrDelivery();
   useTitle({...openFood, ...orders})

  const options = {
    style: {
      base: {
        marginTop:'12px',
        iconColor: '#c4f0ff',
        color: '#fff',
        fontWeight: '500',
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: '#fce883',
        },
        '::placeholder': {
          color: '#87BBFD',
        },
      },
      invalid: {
        iconColor: '#FFC7EE',
        color: '#FFC7EE',
      },
    },
    locale: 'fr'
  };
  
  
   const isAdmin = auth.loggedIn&& dashAdmins.admins.some(u=>u.email ===auth.loggedIn.email&&(u.role==="admin"||u.role==="pos"));
  return (
    <>
   
   

    <GlobalStyle/>
    <Elements  stripe={stripePromise} options={options} >
    <OrderDialog {...orders}{...total}{...orders}{...auth}{...Delivery} {...orderSent}
    {...BasketPanel}{...profile}{...Language}{...Limit}{...orderDialog}{...OrderType}
    {...openUser}{...customerPanel}{...UserDetailsInfo}{...showPayPanel}{...takeOrDeliveryState}/>
    </Elements>
    <FoodDialog {...orders} openFood={openFood} setOpenFood={setOpenFood}{...Delivery} {...auth} 
    {...PromoPrice}{...Language}{...codeApplied}{...cheapestPizza}{...PromoCategory}
     {...isEditingOrNot}{...MinOrderPriceState}/>
    <UserDialog {...openUser}{...auth} {...LoginPanel}{...Language}{...Guest} {...orderDialog}{...Delivery}{...MinOrderPriceState}
    {...takeOrDeliveryState}/>
    <BannerDialog  {...auth} {...bannerUser}/>
    <EditProfile {...edit}{...auth}{...Language}/>
    <ProfileDialog {...profile}{...auth}{...edit}{...Language}/>
    <Welcome  {...welcomePanel} {...takeOrDeliveryState}/>
    <CookieNotice {...Language}/>

   
    <CrossSell  {...Sell}{...Language}{...orders}{...orderDialog}{...total}/>
    <SupportDialog   {...supportPanel}{...auth}{...Language} {...messages}/>
    
    <ProfileOrders {...cusOrders}{...Language}/>
     <Navbar {...auth}{...openUser} {...profile}{...cusOrders} {...LoginPanel} {...SignupPanel}
     {...Delivery}{...orderSent} {...bannerUser}{...Language} {...supportPanel}{...Guest}{...dashAdmins} />
     
     <Order {...orders} openFood={openFood} setOpenFood={setOpenFood}{...auth} {...orderDialog}{...total}{...Delivery}
     {...LoginPanel}{...SignupPanel}{...BasketPanel} {...PromoPrice} {...Language}{...Limit}{...codeApplied}{...Guest}
     {...openUser}{...Sell}{...cheapestPizza}{...PromoCategory}{...dashAdmins}{...OrderType}
     {...isEditingOrNot}{...MinOrderPriceState}/>
  
    {!isAdmin ?<BannerBar/>: <div style={{height:"50px"}}></div>}
   {!isAdmin ? <Ticker /> : " "} 
     <Menu  {...Language} {...dashAdmins}{...auth}/>
     {!isAdmin ? <Footer {...Language} />: " "}  
   </>
  );
}

export default App;
