import React from 'react';
import styled from 'styled-components';
import { useState ,useEffect} from "react"
const SupportContainer = styled.div`
display:flex;
flex-direction:row;
position:fixed;


width:100%;

`;
const RoomsList = styled.div`
display : flex;
flex-direction:column;
width:240px;
overflow-y:scroll;
margin-left:200px;
padding-top:10px;
padding-left:5px;
padding-bottom:35px;
background-color:rgba(0,0,0,.6); 
height: 100vh;

`;
const RoomFrame = styled.div`
color:white;
margin:2px;
height:40px;
padding-left:5px;
&:hover{
    cursor: pointer;
    opacity: 0.9;
    transform: scale(1.02);
    background-color:gray;
    border-radius:3px;
}
`;
const Dialog = styled.div`

  width: 500px;
  height:auto;
  background-color: white;
  margin-bottom:50px;
  z-index: 57 !important;
  margin:auto;
  padding:10px;
  display: flex;
  flex-direction: column;
  border-radius:5px;
  // Add this part
  @media (max-width: 400px) {
    
    overflow-y: scroll;
    height:auto;
    width: auto;
 
    
  }
`;



const ProfileContainer = styled.div`

width: 80%;
height:400px;
overflow-y: scroll;
margin-left:auto;
margin-right:auto;
margin-top:20px;
border-radius:5px;
border:1px solid;
border-color:gray;
display:flex;
justify-content: space-between;
overflow:auto;
flex-direction:column; 

`;
 
const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top:10px;
  margin-left:auto;
  margin-right:auto;
  @media(max-width: 700px){ 
    margin-top:10px;
    margin-left:-1px;
    
   }
  
`;




const Div = styled.div`
display:none;
`;
const TextArea = styled.textarea `
resize: none;
border-radius:10px;

@media(max-width: 700px){ 
  
   
  }
`;
const ConfirmButton = styled.div`
margin: 10px;
color : white;
height:40px;
width:100%;
border-radius: 5px;
padding: 10px;
text-align: center;
cursor: pointer;
background-color: blue;
opacity:0.8;
z-index : 4;
${({disabled})=>disabled && `
opacity: .5;
background-color: grey;
pointer-events: none;
`}
`;
const Box = styled.div`

height:auto;
width:${props => props.width};
background-color: ${props => props.color};
border-radius:8px;
margin-left:5px;
margin-top:10px;
margin-right:5px;
padding:2px 5px;
`;
const OrderNotif = styled.div`
position:relative;
top:-30px;
right:-170px;
height: 20px;
 width: 20px;
 background-color:green;
 border-radius:50%;
 justify-content:center;
 display: flex;
align-items: center;
 text-align: center;
`;
const MsgContainer = styled.div`

display:flex;
justify-content: space-between;
margin-left: ${props => props.margin};
height: 50px;
flex-direction:column;

`;
const Time = styled.div`
margin-left:${props => props.margin};
font-size:12px;
`;
const db = window.firebase.firestore();
function Support() {
    const [text,setText] = useState("");
    const [Smessages,setSmessages] = useState();
    const [email,setEmail] = useState();
    const handleChange = event => {
        setText(event.target.value);
        console.log(text);
      };
      
    const [rooms , setRooms] = useState();
    const canShowRooms = rooms && true;
    useEffect(()=>{ 
        db.collection('support').onSnapshot((data)=>{return  setRooms(data.docs)});
       
   },[]);
   function LoadRoom(email){
    db.collection('support').where('email','==',email).onSnapshot((x)=>{ return x.docs[0] ?  setSmessages(x.docs[0].data().messages):setSmessages([])});
   }
   function sendMessage(){
    var x = [...Smessages,{value:text,date:new Date().getMonth()+"/"+new Date().getDate()+" "+new Date().getHours()+":"+new Date().getMinutes(),sender:"support"}];
  
   const docRef = db.collection("support").doc(email);
       docRef.set({
                email: email,
                messages : x
       })
       setText("");
   

 
 }
    return (
        <SupportContainer>
         
          <RoomsList>
          { canShowRooms ? rooms.map((room,index)=>{
            return(
              <RoomFrame onClick={()=>{LoadRoom(room.data().email);setEmail(room.data().email)}}>{room.data().email}
              {room.data().messages &&room.data().messages[room.data().messages.length-1].sender =="user" && <OrderNotif>1</OrderNotif>}
              </RoomFrame> 
            )
          }): <div>Loading...</div>}
          </RoomsList>
          <Dialog>
       
    
       { Smessages && <ProfileContainer>   
          {Smessages.map((t)=>{
            return (
                <MsgContainer margin= {t.sender ==="user" ? "10px":"30%"} >
                <Box color={t.sender ==="user" ? "lightgray":"lightblue"} width={t.sender ==="user" ? "70%":"95%"}>{t.value}</Box>
                 <Time margin={t.sender ==="user" ? "50%":"-15%"}>{t.date}</Time>
                </MsgContainer>
            )
          })}
         </ProfileContainer>} 
         { Smessages &&<><InputContainer>
       
   
       <TextArea type="text" className="form-control" name="ingredient" placeholder="Message"
         onChange={handleChange} 
         value={text}
         />
  
    <Div>{text}</Div>
   </InputContainer> 
   <InputContainer>
   <ConfirmButton disabled={text.length===0} onClick={()=>{sendMessage()}} >Send</ConfirmButton>
   </InputContainer></>}
        </Dialog>
        </SupportContainer>
    );
}

export default Support;