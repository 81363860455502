import React, { useRef } from "react";
import styled from 'styled-components';
import { UserHeader } from "../../../userDialog/userDialog";
import { useState } from "react";
import { ConfirmPanel } from "./ConfrimPanel";
import { FoodContainer } from "./Orders";
import axios from "axios";
import { Dialog, DialogShadow, Wrapper } from "../Products/pages/Items/AddItem";
import { useReactToPrint } from 'react-to-print';
import moment from "moment";
import { T } from "../../../Language/translate";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const database = window.firebase.database();
const InputContainer = styled.div`
  display: grid;
  grid-template-columns: .8fr 1fr ;
  margin:5px;
  
`;
const Label = styled.label`
font-size:14px;
white-space:nowrap;

`;
const UserFooter = styled.div`
  height: auto;
  display: flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
`;


const ProfileContainer = styled.div`
width: 100%;
padding:3px;
height:300px;
overflow-y: scroll;
margin-left:auto;
margin-right:auto;
margin-top:20px;
border-radius:5px;
border:1px solid;
border-color:gray;
display :flex;

flex-direction:column; 

`;
const ProfileContainer2 = styled.div`
padding:3px;
height:auto;
margin-left:auto;
margin-right:auto;
margin-top:20px;
margin-bottom:20px;
display :flex;
flex-direction:column; 

`;
const Food = styled.div`
font-family: 'Helvetica', 'Arial', sans-serif !important;
color: black;
margin-left:20px;
font-weight: bolder;
font-size:20px;
`;
const ExtraPrice = styled.div`
margin-left:20px;
`;

const Header = styled.div`
font-family: 'Helvetica', 'Arial', sans-serif !important;
text-align:center;
color: black;
margin-left:20px;
margin-top:5px;
margin-bottom:5px;
font-size:26px;
font-weight:bolder;
`;
const Quantity = styled.div`
font-family: 'Helvetica', 'Arial', sans-serif !important;
color: black;
margin-left:5px;
font-weight: bolder;
font-size:20px;
`;
const ToppingContainer = styled.div`
font-family: 'Helvetica', 'Arial', sans-serif !important;
display:grid;
margin-left:20px;
margin-right:10px;
grid-template-columns: 270px 20px 10px 120px;
justify-content: space-between;
font-weight: bold;
font-size:16px;
color: black;
min-width:50px;
`;

const ToppingPrice = styled.div`
font-family: 'Helvetica', 'Arial', sans-serif !important;
display:flex;
margin:auto;
flex-direction:row;
font-weight: bold;
font-size:16px;
color: black;
min-width:50px;
`;
const Topping = styled.div`
display:flex;
flex-direction:column;
margin-right:-5px;
white-space: normal;
`;
const Remark1 = styled.div`
display:flex;
white-space: normal;
font-weight: bold;
font-size:16px;
margin-left:15px;
`;
const Remark2 = styled.div`
display:flex;
white-space: normal;
font-weight: bold;
font-size:16px;
margin-left:18px;
`;
export const Close = styled.div`
height: 30px;
width: 30px;
display :flex;
align-items:center;
justify-element:center;
position:absolute;
background-image:url("/img/close.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border: none; 
margin:10px;
top:0;
right:0;
font-size: 18px;
font-weight:bold;
 &:hover{
    cursor: pointer;
    opacity:0.7;
 }
`;

const PrintButton = styled.button`
margin: 5px;
height: 40px;
width: 200px;
text-align: center;

`;
const DeleteButton = styled.button`
  margin: 5px;
  height: 40px;
  width: 200px;
  text-align: center;

`;

const H1 = styled.div`
font-family: 'Helvetica', 'Arial', sans-serif !important;
font-size:22px;
font-weight:bolder;
margin-left:15px;
color: black;
`;
const H2 = styled.div`
font-family: 'Helvetica', 'Arial', sans-serif !important;
font-size:24px;
font-weight:bolder;
text-align:start;
margin-left:15px;
margin-top:2px;
color: black;

`;
const HR = styled.hr`
  margin-right:auto;
  margin-left:auto;
  margin-bottom:2px !important;
  color: black;
  background-color:black; 
  width:92%;
  height: 3px;
`;
const Print = styled.div`
font-family: 'Helvetica', 'Arial', sans-serif !important;
height:auto;
margin:150px 10px 150px 10px ;
width:400px;
`;
const db = window.firebase.firestore();

export function OrderDetails({ setToggel, orderItem, sound, type, lang }) {

  const [confirmPanel, setConfirmPanel] = useState(false);
  const [Timer, setTimer] = useState(20);
  const [FoodpandaTimer, setFoodpandaTimer] = useState(40);
  const [Text, setText] = useState("");
  const [pandaText, setPandaText] = useState("");
  const [price, setPrice] = useState();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const TimeData = [
    { id: 1, name: '20 Min' },
    { id: 2, name: '30 Min' },
    { id: 3, name: '40 Min' },
    { id: 4, name: '50 Min' },
    { id: 5, name: '60 Min' },
    { id: 6, name: '70 Min' },
    { id: 7, name: '90 Min' },

    // Add more sauces as needed
  ];
  /******************* FALATOZZ MANAGEMENT BEGIN *******************/
  function acceptOrder(orderId) {


    axios.request('https://us-central1-res-2-2293f.cloudfunctions.net/app/api3', {
      params: { tx_id: orderId }, headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }).then(function (response) {
      console.log("Accepted");
      UpdateStatus('preparing');
      handlePrint()
      setToggel(false); 
    }).catch(function (error) {
      console.error(error);
      toast.error('An error occurred!', { autoClose: 2000 });
    });
  }
  function acceptOrder2(orderId) {


    axios.request('https://us-central1-res-2-2293f.cloudfunctions.net/app/api32', {
      params: { tx_id: orderId }, headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }).then(function (response) {
      console.log("Accepted");
      UpdateStatus('preparing');
      handlePrint();
      setToggel(false); 
    }).catch(function (error) {
      console.error(error);
    });
  }

  function acceptOrder3(id) {


    axios.request('https://us-central1-res-2-2293f.cloudfunctions.net/app/apiTest', {
      params: { tx_id: id }, headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }).then(function (response) {
      console.log("Accepted");
      UpdateStatus('preparing');
      handlePrint();
      setToggel(false); 
    }).catch(function (error) {
      console.error(error);
    });
  }
    /******************* FALATOZZ MANAGEMENT END *******************/



  /******************* WOLT MANAGEMENT BEGIN *******************/
  function acceptWolt(link) {
    var timeToAdd = moment(new Date()).add(Timer, 'm').toDate().toISOString();
    console.log(timeToAdd);
    axios.request('https://us-central1-res-2-2293f.cloudfunctions.net/app/acceptWolt', {
      params: { url: link, pickUp: timeToAdd }, headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }).then(function (response) {
      console.log("Accepted");
    }).catch(function (error) {
      console.error(error);
    });
  }

  function rejectWolt(link) {


    axios.request('https://us-central1-res-2-2293f.cloudfunctions.net/app/rejectWolt', {
      params: { url: link, reason: Text }, headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }).then(function (response) {
      console.log("Accepted");
    }).catch(function (error) {
      console.error(error);
    });
  }

  function readyWolt(link) {


    axios.request('https://us-central1-res-2-2293f.cloudfunctions.net/app/readyWolt', {
      params: { url: link }, headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }).then(function (response) {
      console.log("Ready");
    }).catch(function (error) {
      console.error(error);
    });


  }

/******************* WOLT MANAGEMENT END *******************/





  /******************* FOODPANDA MANAGEMENT BEGIN *******************/
  function acceptFoodPanda(link) {

    var timeToAdd = moment(new Date()).add(FoodpandaTimer, 'm').toDate().toISOString();
    axios.request('https://us-central1-res-2-2293f.cloudfunctions.net/app/acceptFoodpanda', {
      params: { url: link,time:timeToAdd}, headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }).then(function (response) {
      console.log("Accepted");
    }).catch(function (error) {
      console.error(error);
    });
  }

  function rejectFoodPanda(link) {


    axios.request('https://us-central1-res-2-2293f.cloudfunctions.net/app/rejectFoodpanda', {
      params: { url: link, reason: pandaText }, headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }).then(function (response) {
      console.log("Rejected");
    }).catch(function (error) {
      console.error(error);
    });
  }

  function PandaPickedUp(link){
    axios.request('https://us-central1-res-2-2293f.cloudfunctions.net/app/foodpandaPickedUp', {
      params: { url: link}, headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }).then(function (response) {
      console.log("picked");
    }).catch(function (error) {
      console.error(error);
    });
  }
  function ReadyFoodpanda(link,id) {


    axios.request('https://us-central1-res-2-2293f.cloudfunctions.net/app/readyFoodpanda', {
      params: { url: link, Id:id}, headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }).then(function (response) {
      console.log("Ready");
    }).catch(function (error) {
      console.error(error);
    });


  }

/******************* FOODPANDA MANAGEMENT END *******************/


  //End Function 
  function UpdateStatus(status) {
    database.ref('orders').orderByChild('Id').equalTo(orderItem.Id).once('value')
    .then((snapshot)=>{
      snapshot.forEach((child)=>{
        const childKey=child.key;
        const updates = {};
        updates['orders/' + childKey + '/status'] = status;
        updates['orders/' + childKey + '/timeToready'] = Timer;
        database.ref().update(updates)
        .then(() => {
          console.log('Update operation successful');
          // Perform any additional actions on success
        })
        .catch((error) => {
          console.error('Update operation failed:', error);
          // Handle the error and perform any necessary actions
        });
      })
    });
    //const pandaRef = database.ref('PandaOr')
    //change when it's production 
  }








  function getToppingPrice(topping, category) {
    category = category === "Pizza" ? "Supp Pizza" : category === "Burger" || category==="Paninis" ? "Supp Burger" : "Supp Pizza"

    db.collection('options').doc(category).get().then((doc) => { return setPrice(doc.data().extras.filter(n => n.name === topping)[0].price) })
    return price
  }
  return (
    <>
      <DialogShadow />
      <Wrapper>
        <Dialog>
          {confirmPanel ? <ConfirmPanel setConfirmPanel={setConfirmPanel} UpdateStatus={UpdateStatus} setToggel={setToggel} /> : ""}

          <Close onClick={() => setToggel(false)} />
          <UserHeader>{T(156, lang)}</UserHeader>
          <ProfileContainer>
          <Header>www.starpizza41.fr</Header>
            <Header>{isNaN(orderItem.date) ? moment(orderItem.date).format("MM-DD HH:mm") : moment.unix(orderItem.date).format("MM-DD HH:mm")}</Header>
            <Remark1>49 Rue Nationale</Remark1>
                    <Remark1>41220 Saint-Laurent-Nouan </Remark1>
                    <Remark1>SIRET: 90923296900016</Remark1>
                    <Remark1>TVA intracom: FR40909232969</Remark1>
                    <Remark1>NAF: 56.10A</Remark1>
                    <Remark1>Tél: 0254334699</Remark1>
                    {(orderItem.deliveryType &&orderItem.deliveryType!=='')&&<H2>{orderItem.deliveryType}</H2>} 
            <H1>{T(34, lang) + orderItem.displayName}</H1>
            {orderItem.phone && <H1>{T(38, lang) + orderItem.phone}</H1>}

            {(!orderItem.deliveryType ||orderItem.deliveryType!=="A importer")&&


              <>
           
                {(orderItem.city||orderItem.street) && <H1>{T(36, lang)+(orderItem.houseNumber ?orderItem.houseNumber:"-")+" "+orderItem.street+" , "+(orderItem.zipCode ?orderItem.zipCode:"-")  +" "+ orderItem.city}</H1>}
                {orderItem.floor && <H1>{T(39, lang) + orderItem.floor + "    " + T(40, lang) + orderItem.door}</H1>}
              </>
            


}


            <HR />
            {orderItem.order.map((food, index) => {
              return (

                <div key={index}>
                  <FoodContainer>
                    <Quantity>{food.Qu}</Quantity>
                    <Quantity>x</Quantity>
                    <Food>{food.name}</Food>
                    <Food>{food.price ? (food.price * food.Qu) + " €" : "0 €"}</Food>
                  </FoodContainer>
                  <Topping>
                    {food.toppings && food.toppings.map(x => {
                      return (
                        <ToppingContainer><div>{x}</div> <div /><div /> {!(orderItem.email === "wolt" || orderItem.email === "foodpanda" || orderItem.email === "falatozz") ? <ExtraPrice>{getToppingPrice(x, food.category) ? (getToppingPrice(x, food.category) + " €") : ""}</ExtraPrice> : <div />}{ }</ToppingContainer>
                      )
                    })
                    }

                  </Topping>
                  {food.foodType &&<ToppingContainer>{food.foodType}</ToppingContainer>} 
                  {food.category === "Tacos" && <ToppingContainer>{food.viande }</ToppingContainer>} 
                  {food.selectedChoice !=="Choix : "&&<ToppingContainer>{food.selectedChoice}</ToppingContainer>} 
                  {food.drinks !=="Boissons :    " &&<ToppingContainer>{food.drinks}</ToppingContainer>} 
                  {food.sauces !=="Sauces :   " &&<ToppingContainer>{food.sauces}</ToppingContainer>} 
                        
                  <Remark1>{food.remark && T(69, lang) + ": " + food.remark}</Remark1>

                </div>

              )
            })
            }
            <HR />
            <Remark2>{orderItem.paymentRemark && T(69, lang) + ": " + orderItem.paymentRemark}</Remark2>

            <Food>{T(72, lang) + ": " + (orderItem.payment === "cash" ? T(76, lang) : orderItem.payment === "card" ? T(77, lang) : orderItem.payment === "terminal" ? T(78, lang) : orderItem.payment === "points" ? T(79, lang) : orderItem.payment)}</Food>
          
            <FoodContainer>
              <div />
              <div />
              {orderItem.hasOwnProperty("discounts")&&orderItem.discounts && orderItem.discounts.length>0&&orderItem.map(x=>{
                return(
                  <Food>{"Discount : "+x.name+" "+x.amount}</Food>
                )
              }) }
              <Food>{T(20, lang) + ": " + Number(orderItem.total) + " €"}</Food>
              <div />
            </FoodContainer>
            <Header>{T(157, lang)}</Header>
            <Header>{T(158, lang)}</Header>
          </ProfileContainer>










          <UserFooter>
            <div>
              <div
                style={{ display: "none" }}>
                <Print ref={componentRef} >


                 
                <ProfileContainer2>
                  {orderItem.email === "falatozz" ? <Header>Falatozz</Header> : orderItem.email === "wolt" ? <Header>Wolt</Header> :orderItem.email==="foodpanda"?<Header>Foodora</Header> :<Header>PIZZA</Header>}
                    <Header>{isNaN(orderItem.date) ? moment(orderItem.date).format("MM-DD HH:mm") : moment.unix(orderItem.date).format("MM-DD HH:mm")}</Header>
                    <Remark1>49 Rue Nationale</Remark1>
                    <Remark1>41220 Saint-Laurent-Nouan </Remark1>
                    <Remark1>SIRET: 90923296900016</Remark1>
                    <Remark1>TVA intracom: FR40909232969</Remark1>
                    <Remark1>NAF: 56.10A</Remark1>
                    <Remark1>Tél: 0254334699</Remark1>
                    {(orderItem.deliveryType &&orderItem.deliveryType!=='')&&<H2>{orderItem.deliveryType}</H2>} 
                    <H1>{T(34, lang) + orderItem.displayName}</H1>
                    {orderItem.phone && <H1>{T(38, lang) + orderItem.phone}</H1>}

                
                    {(!orderItem.deliveryType ||orderItem.deliveryType!=="A importer")&&<>
                  
                  
           
                    {(orderItem.city||orderItem.street) && <H1>{T(36, lang)+(orderItem.houseNumber ?orderItem.houseNumber:"-")+" "+orderItem.street+" , "+(orderItem.zipCode ?orderItem.zipCode:"-")  +" "+ orderItem.city}</H1>}
           {orderItem.floor && <H1>{T(39, lang) + orderItem.floor + "    " + T(40, lang) + orderItem.door}</H1>}
         

                    </>}

                      
                    
                  


                    <HR />
                    {orderItem.order.map((food, index) => {
                      return (

                        <div key={index}>
                          <FoodContainer>
                            <Quantity>{food.Qu}</Quantity>
                            <Quantity>x</Quantity>
                            <Food>{food.name}</Food>
                            <Food>{food.price ? (food.price * food.Qu) + " €" : "0 €"}</Food>
                          </FoodContainer>
                          <Topping>
                            {food.toppings && food.toppings.map(x => {
                              return (
                                <ToppingContainer><div>{x}</div> <div /><div /> {!(orderItem.email === "wolt" || orderItem.email === "foodpanda" || orderItem.email === "falatozz") ? <ExtraPrice>{getToppingPrice(x, food.category) ? (getToppingPrice(x, food.category) + " €") : ""}</ExtraPrice> : <div />}{ }</ToppingContainer>
                              )
                            })
                            }

                          </Topping>
                          {food.foodType &&<ToppingContainer>{food.foodType}</ToppingContainer>} 
                          {food.category === "Tacos" && <ToppingContainer>{food.viande }</ToppingContainer>} 
                          {food.selectedChoice !=="Choix : " &&<ToppingContainer>{food.selectedChoice}</ToppingContainer>} 
                          {food.drinks !=="Boissons :   " &&<ToppingContainer>{food.drinks}</ToppingContainer>} 
                          {food.sauces!=="Sauces :   " &&<ToppingContainer>{food.sauces}</ToppingContainer>} 
                          <Remark1>{food.remark && T(69, lang) + ": " + food.remark}</Remark1>

                        </div>

                      )
                    })
                    }
                    <HR />
                    <Remark2>{orderItem.paymentRemark && T(69, lang) + ": " + orderItem.paymentRemark}</Remark2>

                    <Food>{T(72, lang) + ": " + (orderItem.payment === "cash" ? T(76, lang) : orderItem.payment === "card" ? T(77, lang) : orderItem.payment === "terminal" ? T(78, lang) : orderItem.payment === "points" ? T(79, lang) : orderItem.payment)}</Food>
                   
                    <FoodContainer>
                      <div />
                      <div />

                      <Food>{T(20, lang) + ": " + Number(orderItem.total) + " €"}</Food>
                      <div />
                    </FoodContainer>
                    <Header>{T(157, lang)}</Header>
                    <Header>{T(158, lang)}</Header>
                  </ProfileContainer2>



                </Print>
              </div>

            </div>

          </UserFooter>
          <UserFooter>
          <Label>
  Temps a preparer :
  <select value={Timer} onChange={(e) => setTimer(e.target.value)}>
    {TimeData.map((time) => (
      <option key={time.id} value={time.name}>
        {time.name}
      </option>
    ))}
  </select>
</Label>
          {/*Web Page LOGIC IS HERE*/}
          {type == "order" && orderItem.status == "sent" && (orderItem.email !== "wolt" &&  orderItem.email !== "foodpanda"&& orderItem.email!=="falatozz") && <PrintButton className="btn btn-info" onClick={() => { setToggel(false); UpdateStatus('preparing');sound.stop(); handlePrint() }}>Accepter !</PrintButton>}
            {type == "order" && orderItem.status == "preparing" && (orderItem.email !== "guest" && orderItem.email !== "wolt" && orderItem.email !== "foodpanda") && <PrintButton className="btn btn-info" onClick={() => { setToggel(false); UpdateStatus('ready') }}>Commande est prête </PrintButton>}
            {type == "order" && orderItem.status == "ready" && ( orderItem.email !== "wolt" &&  orderItem.email !== "foodpanda") &&<PrintButton className="btn btn-success" onClick={() => { setToggel(false); UpdateStatus('delivery') }}>En Livraison</PrintButton>}  



      
            {/*Falatozz LOGIC IS HERE*/}
            {type == "order" && orderItem.email == "falatozz" && orderItem.status == "sent"
              && (orderItem.res == "PIZZAKIRALY" ) && <PrintButton className="btn btn-info" onClick={() => { acceptOrder(orderItem.falId); sound.stop();  }}>Falatozz !</PrintButton>}
            {type == "order" && orderItem.email == "falatozz" && orderItem.status == "sent"
              && (orderItem.res == "PAZZA" || orderItem.res == "Pazza") && <PrintButton className="btn btn-info" onClick={() => {   acceptOrder2(orderItem.falId); sound.stop();  }}>Falatozz !</PrintButton>}

{type == "order" && orderItem.email == "falatozz" && orderItem.status == "sent"
              && (orderItem.res == "TEST" || orderItem.res == "test") && <PrintButton className="btn btn-info" onClick={() => {  acceptOrder3(orderItem.falId); sound.stop();  }}>Test !</PrintButton>}


             {/*WOLT LOGIC IS HERE*/}
             {type == "order" && orderItem.status == "sent" && orderItem.email == "wolt" && <InputContainer className="form-group">
              <Label for="name" className=" control-label">Pick Up</Label>

              <input type="number" className="form-control" placeholder=""
                value={Timer}
                onChange={t => setTimer(t.target.value)}
              />
            </InputContainer>
            }
            {(type == "order" && orderItem.status == "sent" && orderItem.email == "wolt") && <PrintButton disabled={Timer.length === 0 || Timer == 0 || Timer > 60} className="btn btn-info" onClick={() => { setToggel(false); UpdateStatus('preparing'); acceptWolt(orderItem.url); sound.stop(); handlePrint() }}>Accept wolt!</PrintButton>}

            {(type == "order" && orderItem.status == "preparing" && orderItem.email == "wolt") && <PrintButton className="btn btn-info" onClick={() => { setToggel(false); UpdateStatus('ready'); readyWolt(orderItem.url); sound.stop(); }}>Ready wolt!</PrintButton>}
            {type == "order" && orderItem.status == "sent" && orderItem.email == "wolt" && 
            <InputContainer className="form-group">
              <Label for="name" className=" control-label">Reason To cancel </Label>

              <input type="text" className="form-control" placeholder=""
                value={Text}
                onChange={t => setText(t.target.value)}
              />
            </InputContainer>
            }
            {type == "order" && (orderItem.status == "sent" && orderItem.email == "wolt") && <DeleteButton disabled={Text.length === 0 || Text == null} className="btn btn-danger" onClick={() => { rejectWolt(orderItem.url); setToggel(false); UpdateStatus('canceled') }}>Reject wolt!</DeleteButton>}
  

        

         {/*Foodpanda LOGIC IS HERE*/}
         {type == "order" && orderItem.status == "sent" && orderItem.email == "foodpanda" && <InputContainer className="form-group">
              <Label for="name" className=" control-label">Pick Up</Label>

              <input type="number" className="form-control" placeholder=""
                value={FoodpandaTimer}
                onChange={t => setFoodpandaTimer(t.target.value)}
              />
            </InputContainer>
            }
         {(type == "order" && orderItem.status == "sent" && orderItem.email == "foodpanda") && <PrintButton disabled={Timer.length === 0 || Timer == 0 || Timer > 60} className="btn btn-info" onClick={() => { setToggel(false); UpdateStatus('preparing'); acceptFoodPanda(orderItem.urls.orderAcceptedUrl); sound.stop(); handlePrint() }}>Accept FoodPanda!</PrintButton>}
          {(type == "order" && orderItem.status == "preparing"&&orderItem.vendorOrPanda=="panda" && orderItem.email == "foodpanda") && <PrintButton className="btn btn-info" onClick={() => { setToggel(false); UpdateStatus('ready'); ReadyFoodpanda(orderItem.urls.orderPreparedUrl,orderItem.pandaId); sound.stop(); }}>Ready foodpanda!</PrintButton>}   
          {(type == "order" && orderItem.status == "preparing"&&orderItem.vendorOrPanda=="vendor" && orderItem.email == "foodpanda") && <PrintButton className="btn btn-info" onClick={() => { setToggel(false); UpdateStatus('ready'); ; sound.stop(); }}>Ready !</PrintButton>}
          {(type == "order" && orderItem.status == "ready"&&(orderItem.vendorOrPanda=="vendor"||orderItem.expeditionType==="pickup") && orderItem.email == "foodpanda") && <PrintButton className="btn btn-info" onClick={() => { setToggel(false);PandaPickedUp(orderItem.urls.orderPickedUpUrl) ;UpdateStatus('delivery'); ; sound.stop(); }}>Picked Up !</PrintButton>}
          {type == "order" && orderItem.status == "sent" && orderItem.email == "foodpanda" && 
            <InputContainer className="form-group">
              <Label for="name" className=" control-label">Reason To cancel </Label>

              <input type="text" className="form-control" placeholder=""
                value={pandaText}
                onChange={t => setPandaText(t.target.value)}
              />
            </InputContainer>
            }
          {type == "order" && (orderItem.status == "sent" && orderItem.email == "foodpanda") && <DeleteButton disabled={pandaText.length === 0 || pandaText == null} className="btn btn-danger" onClick={() => { rejectFoodPanda(orderItem.urls.orderRejectedUrl); setToggel(false); UpdateStatus('canceled') }}>Reject FoodPanda!</DeleteButton>}
        







         {(type == "history"||type=="order") &&orderItem.status !== "sent"&& <PrintButton className="btn btn-info" onClick={() => { setToggel(false); handlePrint(); }}>Imprimer à nouveau</PrintButton>}
         {type == "order" && (orderItem.status == "sent") && (orderItem.email !== "foodpanda" && orderItem.email !== "wolt" && orderItem.email !== "falatozz") && <DeleteButton className="btn btn-danger" onClick={() => { !confirmPanel ? setConfirmPanel(true) : setConfirmPanel(false); }}>Annuler</DeleteButton>}
          </UserFooter>
  
        </Dialog>
      </Wrapper>
    </>
  )

}


