import React from "react";
import styled from "styled-components";
import { useState, useEffect } from "react";
import useForm from "../../../Products/pages/Hooks/useForm";
import { Dialog ,DialogShadow, Wrapper,Label,DialogHeader,Header,ButtonStyled,H2 ,H4R,H4G ,Notice 
  ,InputContainer } from "../../../Products/pages/Items/AddItem";
import moment from "moment";
import { ButtonContainer,Bar } from "../../../Products/pages/Items/Items";





const AddContainer = styled.div`
display: flex;
flex-direction: column;
margin:5px;
`;

const ExtraItem = styled.div`
position:relative;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
width:auto;
height: 40px;
text-align:center;
font-size:20px;
margin: 5px;
transition-property: box-shadow margin-top filter;
transition-duration: .2s;
border:1px solid;
border-color: lightgray; 
border-radius : 10px;
box-shadow : 0px 0px 2px 0px grey;
padding-bottom: 10x;

`;
const ExtraContent = styled.div`
display:flex;
flex-direction:row;

`;
const ExtraGrid = styled.div`
display: grid;
grid-template-columns: 1fr 1fr 1fr   ;
gap: 10px;

`;
const Div = styled.div`
margin:1px;
`;
const Div2 = styled.div`
margin-left:10px;
`;
const Close = styled.div`
height: 30px;
width: 30px;
background-image:url("/img/close.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
margin:5px;
 &:hover{
    cursor: pointer;
    opacity:0.7;
 }
`;
const SearchContainer = styled.div`
display: flex;
flex-direction: column;
overflow-y: scroll;
height:100px;

margin-left:100px;
cursor:pointer;
&:hover{
    cursor: pointer;
    opacity:0.7;
 }
`;
const Input = styled.input`
cursor:pointer;
&:hover{
    cursor: pointer;
    
 }
`;
const Label2 = styled.label`
margin-right:40px;
`;

// function for required fields 
function isRequired(value) {
  return value != null && value.trim().length > 0;
}
function DateisRequired(value) {
  return value != null ;
}
function NumericalValue(value) {
  return !isNaN(value);
}

  




export function CreatePercentage({ openPanel, setOpenPanel, prop, setData }) {

  


  //db
  const db = window.firebase.firestore();
  //use state for categories 
  const [items, setItems] = useState([]);
 
 function searchitems(x){
   
    if(searchType=='food'){
    db.collection('items').
    where("name", ">=", x.charAt(0).toUpperCase() + x.slice(1)).
    where("name", "<=", x.charAt(0).toUpperCase() + x.slice(1)+ "\uf8ff").get().then((data)=>{return  setItems(data.docs)});
}else if(searchType=='category')
{
    db.collection('Category').
    where("name", ">=", x.charAt(0).toUpperCase() + x.slice(1)).
    where("name", "<=", x.charAt(0).toUpperCase() + x.slice(1)+ "\uf8ff").get().then((data)=>{return  setItems(data.docs)});   
}else {
  setItems([])
}

}
  
 
  const [tags, setTags] = useState([]);
  const [search, setSearch] = useState("");
  const [codeUnique, setCodeUnique] = useState(true);
  const [checked, setChecked] = useState(false);
  const [searchType, setSearchType] = useState();
  const [discountType, setDiscountType] = useState();
  function ValueInterval(value) {
    if(discountType =='percentage'){
    return !(value <0 || value>100);
}else {
    return !(value<0 || value>5000)
}
  } 
  //Form validation 
  const initialState = { name: '', code: '', products: [], startDate: moment().format('YYYY-MM-DDTHH:MM:SS'), endDate: moment().format('YYYY-MM-DDTHH:MM:SS'), value: '' ,limit:''};
  const validations = [
    ({ name }) => isRequired(name) || { name: 'Name is required' },
    ({ code }) => isRequired(code) || { code: 'Code is required' },
    ({ startDate }) => DateisRequired(startDate) || { startDate: 'Date is required' },
    ({ endDate }) => DateisRequired(endDate) || { endDate: 'Date is required' },
    ({ limit }) => isRequired(limit) || { limit: 'Limit is required' },
    ({ limit }) => NumericalValue(limit) || { limit: 'Limit is a number' },
    () => tags.length != 0 || { products: 'At least one tage selected' },
    ({ value }) => NumericalValue(value) || { value: 'value is a number' },
    ({ value }) => isRequired(value) || { value: 'Value is required' },
    ({ value }) => ValueInterval(value) || (discountType == 'percentage' ?{ value: 'Value is between 0 -100' }:{ value: 'Value is between 0 -5000' }),
    () => codeUnique || { code: 'This code already exist ' }
  ];

  const { values, isValid, errors, changeHandler} = useForm(initialState, validations, prop);

// check if code is already exist 
  if (values.code != "") db.collection('discount').where("code", "==", values.code).get().then((doc) => { return doc.size ? setCodeUnique(false) : setCodeUnique(true) })




  function handleDelete(i) {
    setTags(tags.filter((tag, index) => index !== i));

  };

  function handleAddition(e) {
    if (!tags.includes(e)&& tags.length <1) setTags([...tags,  e ]);

  };



  function handleSubmit() {
   


    const docRef = db.collection("offer").doc(values.code);
    
   
      docRef.set({
        name: values.name,
        code: values.code,
        value: values.value,
        valueType: discountType,
        type: searchType,
        items: tags,
        limit: values.limit,
        startDate: values.startDate,
        endDate: values.endDate,
        recrusive: checked,
        proType:"discount"
      });
    
    
    db.collection('offer').where('proType','==','discount').get().then((data) => { return setData(data.docs) });


    setOpenPanel(false);

  }


  return (
    <>
      <DialogShadow />
      <Wrapper>
      <Dialog>
       
          <DialogHeader>
            <Header>
              <H2>Add a discount 🎁</H2>
              {(!isValid || !tags.length != 0 || !discountType|| !searchType) ? <H4R>Fill required fields to add</H4R> : <H4G>You can save the product now</H4G>}
            </Header>
            <ButtonContainer>
              <ButtonStyled disabled={!isValid || !tags.length != 0 || !discountType|| !searchType } className="btn btn-primary" onClick={handleSubmit}>
                Save
              </ButtonStyled>
              <ButtonStyled
                className="btn btn-light"
                onClick={() =>
                  openPanel ? setOpenPanel(false) : setOpenPanel(true)
                }
              >
                Cancel
              </ButtonStyled>
            </ButtonContainer>
          </DialogHeader>
          <Bar />

         

          {errors.name && <Notice>{errors.name}</Notice>}
          <InputContainer className="form-group">
          <Label  className="control-label">Name  </Label>
           
              <input type="text" className="form-control" name="name" placeholder="Name"
                value={values.name}
                onBlur={changeHandler}
                onChange={changeHandler}
              />
            

            
          </InputContainer>

          {errors.code && <Notice>{errors.code}</Notice>}
          <InputContainer className="form-group">
     
          <Label  className="control-label">Code  </Label>
              <input type="text" className="form-control" name="code" placeholder="Code"
                value={values.code}
                onBlur={changeHandler}
                onChange={changeHandler}
              />
         

            
          </InputContainer>
          {!discountType && <Notice>{'discount type is required'}</Notice>}
     <InputContainer>
            <Div2 className="form-check">
            <Label2 className="form-check-label" > Percentage </Label2>
            
            <input className="form-check-input" 
            type="radio" 
            value='percentage'
            name="discount"
            onClick={(e)=>discountType ? setDiscountType(e.target.value):setDiscountType(e.target.value)}
             />
             </Div2>
             <Div2 className="form-check">
             <Label2 className="form-check-label" >HUF</Label2>
            <input class="form-check-input"
             onClick={(e)=>discountType ? setDiscountType(e.target.value):setDiscountType(e.target.value)}
             type="radio" 
             name="discount" 
             value='huf'/>
             </Div2>
            
            </InputContainer>
            {errors.value && <Notice>{errors.value}</Notice>}
          <InputContainer className="form-group">
    
          <Label  className="control-label">Value  </Label>
              <input type="number" className="form-control" name="value" placeholder="Value"
                value={values.value}
                onBlur={changeHandler}
                onChange={changeHandler}
              />
    

           
          </InputContainer>


      

          <AddContainer>
            <ExtraGrid >
              {tags.length != 0 ? tags.map((item, index) => {
                return (

                  <ExtraItem>
                    <ExtraContent>
                      {console.log(item)}
                      <Div>{item}</Div>
                      <Close onClick={() => { handleDelete(index) }} />
                    </ExtraContent>

                  </ExtraItem>
                )

              }) : <Notice>At least one item is required</Notice>}

            </ExtraGrid>
            {!searchType && <Notice>{'Type is required'}</Notice>}
            <InputContainer>
            <Div2 className="form-check">
            <Label2 className="form-check-label" > Food </Label2>
            
            <input className="form-check-input" 
            type="radio" 
            value='food'
            name="searchType"
            onClick={(e)=>searchType ? setSearchType(e.target.value):setSearchType(e.target.value)}
             />
             </Div2>
             <Div2 className="form-check">
             <Label2 className="form-check-label" >Category</Label2>
            <input class="form-check-input"
             onClick={(e)=>searchType ? setSearchType(e.target.value):setSearchType(e.target.value)}
             type="radio" 
             name="searchType" 
             value='category'/>
             </Div2>
            
            </InputContainer>
      
            <InputContainer className="form-group">
            <Label  className="control-label">Item </Label>
            
                <input type="text" className="form-control" name="item" placeholder="Item"
                   value={search}
         
                   onChange={(e)=>{searchitems(e.target.value);setSearch(e.target.value)}} />
             
            </InputContainer>

            <SearchContainer>
            {items.map((item) => {
                  return (
                    <>
               
                      <Input className="form-control" 
                       readonly="readonly" value={item.data().name} onClick={(e)=>{handleAddition(e.target.value); setItems([])}}
                       />
                  
                    </>
                  )
                }
                )}
            
            </SearchContainer>
          </AddContainer>

          <InputContainer className="form-group">
          
          <Label  className="control-label">Limit  </Label>
              <input type="number" className="form-control" name="limit"  placeholder="Limit"
               value={values.limit} 
               onChange={changeHandler} />
           
            {errors.limit && <Notice>{errors.limit}</Notice>}
          </InputContainer>
          <InputContainer className="form-group">
            <Label  className="control-label">Start </Label>
       
              {console.log(values.startDate)}
            <input type="datetime-local" 
              name="startDate"               
              value={values.startDate} 
               onChange={changeHandler}
               min={moment().format('YYYY-MM-DDTHH:MM:SS')} max="2024-06-14T00:00"/>
            
            {errors.startDate && <Notice>{errors.startDate}</Notice>}
          </InputContainer>

          <InputContainer className="form-group">
            <Label  className=" control-label">End </Label>
           
            <input type="datetime-local" 
              name="endDate"               
              value={values.endDate} 
               onChange={changeHandler}
               min={values.startDate} max="2024-06-14T00:00"/>
        
            {errors.endDate && <Notice>{errors.endDate}</Notice>}
          </InputContainer>
          <InputContainer className="form-group">
            <Label  className=" control-label">Recrusive ? </Label>
           
            <input
             type="checkbox"
             checked={checked}
             onChange={e => setChecked(e.target.checked)}
               />
        
            {errors.endDate && <Notice>{errors.endDate}</Notice>}
          </InputContainer>
       
      </Dialog>
      </Wrapper>
    </>
  );
}