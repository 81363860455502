import { useState } from "react";

 function useForm(initialState = {}, validations=[], onSubmit = () => {}) {
    const {isValid: initialIsValid, errors: initialErrors} = validate(validations, initialState);
    //state for setting the value of input
    const [values, setValues] = useState(initialState);
    //state for correcting errors 
    const [errors, setErrors] = useState({});
    const [isValid, setValid] = useState(false);
    //don't show error messages before touching the form
    //const [touched, setTouched] = useState({});
    const changeHandler = event => {
       console.log("ites called");
        const newValues = {...values, [event.target.name]: event.target.value};
        const {isValid, errors} = validate(validations, newValues);
        setValues(newValues);
        setValid(isValid);
        setErrors(errors);
        //setTouched({...touched, [event.target.name]: true}); 
      }; 
      const submitHandler = event => {
        event.preventDefault();
        onSubmit(values);
      }
    return {values,changeHandler,isValid,errors, submitHandler}; //,touched
  }

  function validate(validations, values) {
    const errors = validations
      .map(validation => validation(values))
      .filter(validation => typeof validation === 'object');
    return {isValid: errors.length === 0, errors: errors.reduce((errors, error) => ({...errors, ...error}), {})};
  }
  export default useForm;