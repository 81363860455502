import React from 'react';
import styled from "styled-components";
import { useState ,useEffect } from 'react';
import useForm from "../../../Hooks/useForm"
import { Wrapper,UpContainer,Label ,Dialog, ButtonStyled, DialogShadow ,DialogHeader,Notice 
  ,Header,H2 ,H4R ,H4G ,Percent}  from "../Products/pages/Items/AddItem"



export const ButtonContainer = styled.div`
padding-top:30px;
margin-right:20px;
margin-left: auto;
display:flex;
flex-direction:row;
`;
const InputContainer = styled.div`
  display: grid;
  grid-template-columns:  1fr ;
  margin:5px;
  
`;

const Input = styled.textarea`
width:100%;
height:100px;
`;
const Remark = styled.span`
white-space: nowrap;
  margin-left:20px;
  color: gray;
  font-size: 12px;
`;

// function for required fields 
function isRequired(value) {
    return value != null && value.toString().trim().length > 0;
  }
function EditTextContainer({ openItem, prop,setData,setOpenItem}) {
    const db = window.firebase.firestore();
    console.log(openItem.data().index);

    function handleSubmit ()  {
      
   
      
        const docRef = db.collection("ticker").doc(openItem.data().index);
       
        try {
       
          docRef.update({
           text: values.text,
          
          });
          console.log("submited :=)")
        } catch (e) {
          console.log(e);
        }
       
     db.collection('ticker').get().then((data)=>{return  setData(data.docs)});


        setOpenItem();
    }



 //Form validation 
 const initialState = { text: openItem.data().text};
 const validations = [
   ({text}) => isRequired(text) || {text: 'Text is required'}
 ];

 const {values, isValid, errors, changeHandler} = useForm(initialState, validations, prop);



    return(
        <>
          <DialogShadow />
          <Wrapper>
    <Dialog>

         <Remark>{values.text.length != 0 ?"You have "+(100-values.text.length)+" remaining letters" : "100 letters are allowed"}</Remark>
         
          <InputContainer className="form-group">
      <div />
      <Input type="text" className="form-control" name="text"  placeholder="Name"   value={values.text.substring(0,99)} onChange={changeHandler} />
      
     
   </InputContainer> 
   { errors.text && <Notice>{errors.text}</Notice>}
   <ButtonContainer>
         <ButtonStyled className="btn btn-primary"  onClick={()=>handleSubmit()} >
           Save 
         </ButtonStyled>
         <ButtonStyled
           className="btn btn-light"
           onClick={() =>setOpenItem()}
         >
           Cancel
         </ButtonStyled>
       </ButtonContainer>
    </Dialog>
    </Wrapper>
        </>
    )


}

export function EditText(props){
    if (!props.openItem) return null;
    return <EditTextContainer {...props}/>
}
