//"PIZZÁK"
//`https://firebasestorage.googleapis.com/v0/b/aziz-adf05.appspot.com/o/files%2F${item.data().imgUrl}?alt=media&token=d02a0ad4-0554-4bc0-be12-d549339e4fdb`
import React from 'react';
import styled from "styled-components";
import { useState } from "react";
import { useEffect } from "react";
import { CreateProduct } from './AddItem';
import { EditProduct } from './EditItem';
import { ConfirmDelete } from '../ConfirmDelete';
import { T } from '../../../../../Language/translate';
import { Id } from '../../../../../ProjectId';
import { Token } from '../../../../../StorageToken';
export const Container = styled.div`
position:fixed;
right:0;
width:${(props) => props.width};
height:80%;
top:10px;
font-size : 20px;
@media screen and (max-width: 995px) {
    margin-left:10px;
}
`;
export const AddHeader = styled.div`
width:auto;
padding: 10px;
display: flex;
flex-direction: row;
gap: 60%;

media screen and (max-width: 1000px) {
      padding: 10px;
      display: flex;
      flex-direction: row;
      gap: 40%;
  }
  @media screen and (max-width: 995px) {
   
        padding:10px;
        display: flex;
        flex-direction: row;
        gap: 30%;
    }
    @media screen and (max-width: 400px) {
  
          padding: 10px;
          display: flex;
          flex-direction: row;
          gap: 10%;
      }
`;
export const ButtonContainer = styled.div`
display:flex;
flex-direction:row;
margin-left:auto;
margin-right:auto;
margin-top:10px;
@media screen and (max-width: 995px) {
   
    margin-top:20px;
    margin-left:auto;
    margin-right:auto;
}
@media screen and (max-width: 700px) {
    flex-direction:column;
        
    }
`;

export const ButtonStyled = styled.button`
max-height:40px;
width:150px;
text-decoration: none;
margin: 10px;
&:hover{ 
    opacity: 1.5;
    border:1px solid;
    border-color: lightgray; 
    border-radius : 3px;
}
@media screen and (max-width: 700px) {
      
    width:auto;
 }
`;
export const ItemGrid = styled.div`
height:78%;
 display: flex;
 overflow-y: scroll;
flex-direction:row ;
flex-wrap: wrap;
gap:10px;

media screen and (max-width: 1500px) {
display: grid;
grid-template-columns: 1fr 1fr 1fr   ;
}
media screen and (max-width: 1300px) {
    display: grid;
    grid-template-columns: 1fr 1fr ;
    gap: 8px;
    }


media screen and (max-width: 1000px) {
    display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr  ;
gap: 8px;
}
@media screen and (max-width: 800px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr   ;
    gap: 5px;
  }
  @media screen and (max-width: 663px) {
    display: grid;
    grid-template-columns: 1fr 1fr ;
    gap: 5px;
        
    }


`;

export const ItemContainer = styled.div`
 position:relative;
width:200px;
height: 210px;
text-align:center;
font-size:20px;
margin: 5px;
transition-property: box-shadow margin-top filter;
transition-duration: .2s;
border:1px solid;
border-color: lightgray; 
border-radius : 10px;
box-shadow : 0px 0px 2px 0px grey;
padding-bottom: 10x;
&:hover{
    margin-top: 0px;
    margin-bottom : 5px;
    box-shadow : 0px 0px 10px 0px grey;
}
@media screen and (max-width: 500px) {
   
   width:150px;
}
@media screen and (max-width: 350px) {
    height: 210px;
    width:120px;
 }
`;
 export const Item = styled.div`
 margin-left:auto;
 margin-right:auto;
 position:relative;
 margin-top:5px;
 width:100px;
height: 100px;
text-align:center;
font-size:20px;
background-image: ${({img})=> `url(${img});`}
background-position: center;
background-size: cover ;

transition-property: box-shadow margin-top filter;
transition-duration: .2s;
border:1px solid;
border-color: lightgray; 
border-radius : 10px;
box-shadow : 0px 0px 2px 0px grey;
padding-bottom: 10x;

`;
export const Edit = styled.div`
height: 40px;
width: 40px;
display :flex;
align-items:center;
justify-element:center;
position:absolute;
background-image:url("/img/Edit.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border: none; 
bottom:0;
right:0;
font-size: 18px;
font-weight:bold;
 &:hover{
    cursor: pointer;
    opacity: 1.5;
    background-color:lightgray;
    border-radius : 7px;
 }

`;
const Remove = styled.div`
height: 40px;
width: 40px;
display :flex;
align-items:center;
justify-element:center;
position:absolute;
background-image:url("/img/Remove.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border: none; 
bottom:0;
left:0;
font-size: 18px;
font-weight:bold;
 &:hover{
    cursor: pointer;
    opacity: 1.5;
    background-color:lightgray;
    border-radius : 7px;
 }

`;
export const Bar = styled.hr`
border-top: 3px solid #bbb;
margin: 5px;
@media screen and (max-width: 500px) {
   
    width:300px;
}
`;
const Div = styled.div`
     font-size:16px;
     text-align:center;
     margin:1 px;
     @media screen and (max-width: 350px) {
   
        white-space:normal;
     }
`;
const H2 = styled.h2`
@media screen and (max-width: 995px) {
   
    margin-top:20px;
}

`;
const Filter = styled.div`
display:flex;
flex-direction:row;
flex-wrap: wrap;
@media screen and (max-width: 995px) {
   
    font-size:15px;
}
@media screen and (max-width: 700px) {
   
    flex-direction:column;
   
}
`;
const FilterItem = styled.div`
display:flex;
flex-direction:row;
align-items:center;
justify-element:center;
margin-right:10px;
`;
const Radio = styled.input`
margin-right:5px;
margin-left:5px;
margin-top:auto;
margin-bottom:auto;
`;
const RadioLabel = styled.label`
font-size:16px;
white-space:nowrap;
margin-right:5px;
margin-left:5px;
margin-top:auto;
margin-bottom:auto;
`;
const db = window.firebase.firestore();

function Items({lang,active}) {
    //use sate to open panel for adding item 
    const [openPanel, setOpenPanel ] = useState(false);
    const [category, setCategory]= useState();
     //use state to read data 
    const [data , setData] = useState();
    const [name, setName] = useState("Pizza");
    const [openDelete,setOpenDelete]=useState();
    //use state to edit data 
    const [openItem, setOpenItem] = useState();
    const isItemsEmpty = data && data.length ===0;
  const canShowItems = data && true;
  const isCatEmpty = category && category.length ===0;
  const canShowCat = category && true;
    useEffect(()=>{ 
     db.collection('items').where('category','==',name).onSnapshot((data)=>{return  setData(data.docs)});
     db.collection('Category').onSnapshot((data)=>{return  setCategory(data.docs)});
     
},[]);

function FilterData(x){
    db.collection('items').where('category','==',x).onSnapshot((data)=>{return  setData(data.docs)});
}
    
    
    
    
  

    return (
        <Container width={ active ? "calc(100% - 220px)" : "calc(100% - 40px)"}>
         
            {openPanel && <CreateProduct openPanel={openPanel} setOpenPanel={setOpenPanel} setData={setData} lang={lang}/>}
             <EditProduct openItem={openItem} setData={setData} setOpenItem={setOpenItem}  lang={lang}/>
             <ConfirmDelete openDelete={openDelete} setData={setData} setOpenDelete={setOpenDelete} lang={lang} collection="items" />
            <AddHeader>
            <H2>{T(171,lang)}</H2>  
            <ButtonContainer>   
        <ButtonStyled className='btn btn-primary' onClick={()=>openPanel? setOpenPanel(false): setOpenPanel(true)} >{T(172,lang)}</ButtonStyled>
    </ButtonContainer>
            </AddHeader>
            <Filter> {isCatEmpty  && <FilterItem><div>{T(173,lang)}</div></FilterItem>}
            { canShowCat ? category.sort((a,b) => a.data().index - b.data().index).map((item,index)=>{
                return (
             <FilterItem key={index}>
                
                <Radio   
                 type="radio" 
                 value={item.data().name}
                
                 checked={item.data().name ==name ? "checked":""}
               onChange={(e)=>{setName(e.target.value);FilterData(e.target.value)}}  
               />
               <RadioLabel>{item.data().name}</RadioLabel>
             </FilterItem>
                )
               }):<FilterItem><div>{T(173,lang)}</div></FilterItem>
           }
        
            </Filter>
            <Bar/>
            <ItemGrid>
            {isItemsEmpty  && <Item><div>{T(173,lang)}</div></Item>}
            { canShowItems ? data.sort((a,b) => a.data().index - b.data().index).map((item)=>{
                return (
               <>   
               <ItemContainer>
               <Div>{item.data().name}</Div>
                    <Item  img={"https://firebasestorage.googleapis.com/v0/b/"+Id+".appspot.com/o/files%2F"+item.data().imgUrl+"?alt=media&token="+Token} >
                      
                  
                    </Item>
                    <Div>Qu : {String(item.data().quantity).slice(0,4)}</Div>
                    <Edit onClick={()=>setOpenItem(item.data())} />
                    <Remove  onClick={()=>setOpenDelete(item.data())}/>
                    </ItemContainer>
                    </>
                )} 
                ) : <div>{T(173,lang)}</div>}
            
            </ItemGrid>
            <Bar/>
        </Container>
    );
}

export default Items;