import React from 'react';
import styled from "styled-components";
import { useState ,useEffect } from 'react';
import { EditText } from './EditText';
import { EditTheme } from './EditTheme';
import { EditPicture } from './EditPicture';
import { EditInfo } from './EditInfo';
import { Id } from '../../../ProjectId';
import { Token } from '../../../StorageToken'; 
const Container = styled.div`
overflow-y: scroll !important;
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
font-size:26px;
font-family: 'Inter', sans-serif;
height:80%;
width:${(props) => props.width};
top:10px;
right:0;
margin-left:5px;
position:fixed;
overflow: hidden;

`;

const SchudelDiv = styled.div`

width:95%;
height:auto;
border: solid 1px;
border-color: gray; 
border-radius : 10px;
margin:20px;
@media screen and (max-width: 1200px) {
  width:80%;
}
@media screen and (max-width: 1050px) {
  width:90%;
}
`;

const ScheduleGrid = styled.div`
display: grid;
grid-template-columns: 2fr 10fr 1fr;
gap: 15px 15px;
margin:40px;
border: solid 1px;
border-color: gray; 
border-radius : 10px;
justify-items:center;
@media screen and (max-width: 700px) {
  grid-template-columns: 1fr;
}


`;
 const ButtonStyled = styled.button`
max-height:40px;
width:150px;
text-decoration: none;
margin-top: 10px;
margin-bottom: 10px;
margin-right:5px;
&:hover{
    opacity: 1.5;
    border:1px solid;
    border-color: lightgray; 
    border-radius : 3px;
}
@media screen and (max-width: 700px) {
  font-size:14px;
  width:100px;
}
`;
const X = styled.div`
display:flex;
flex-direction:row
align-items:center;
justify-content:center;
`;
const DivTitle = styled.div`
font-weight: bold ; 
margin-top :auto;
margin-bottom:auto;
text-align:center;
white-space: nowrap; 
@media screen and (max-width: 700px) {
  font-size:14px;
}
`;
const Div = styled.div`


font-weight:bold;
margin-top :auto;
margin-bottom:20px;
text-align:center;
@media screen and (max-width: 700px) {
  font-size:14px;
}

`;
const Div2 = styled.div`
display: grid;
grid-template-columns: 10fr 1fr;
font-size:14px;
margin-top :auto;
margin-bottom:auto;
text-align:center;
@media screen and (max-width: 700px) {
  font-size:14px;
}

`;
const Title = styled.div`
font-weight: bold ; 
margin-left: 10px;


`;
const AddHeader = styled.div`
padding: 10px;
display: flex;
flex-direction: row;
gap: 60%;

media screen and (max-width: 1000px) {
      padding: 10px;
      display: flex;
      flex-direction: row;
      gap: 40%;
  }
  @media screen and (max-width: 995px) {
   
        padding:10px;
        display: flex;
        flex-direction: row;
        gap: 30%;
    }
    @media screen and (max-width: 400px) {
  
          padding: 10px;
          display: flex;
          flex-direction: row;
          gap: 10%;
      }
`;

const Bar = styled.hr`
border-top: 3px solid #bbb;
margin: 5px;
width:90%;
@media screen and (max-width: 500px) {
   
    width:300px;
}
`;
 const ItemGrid = styled.div`
height:210px;
 display: grid;

grid-template-columns: 200px 200px 200px 200px 200px ;
gap:10px;

media screen and (max-width: 1500px) {
display: grid;
grid-template-columns: 1fr 1fr 1fr   ;
}
media screen and (max-width: 1300px) {
    display: grid;
    grid-template-columns: 1fr 1fr ;
    gap: 8px;
    }


media screen and (max-width: 1000px) {
    display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr  ;
gap: 8px;
}
@media screen and (max-width: 800px) {
  height:380px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr   ;
    gap: 5px;
  }
  @media screen and (max-width: 663px) {
    display: grid;
    grid-template-columns: 1fr 1fr ;
    gap: 5px;
        
    }


`;

 const ItemContainer = styled.div`
 position:relative;
width:200px;
height: 220px;
text-align:center;
font-size:20px;
margin: 5px;
transition-property: box-shadow margin-top filter;
transition-duration: .2s;
border:1px solid;
border-color: lightgray; 
border-radius : 10px;
box-shadow : 0px 0px 2px 0px grey;
padding-bottom: 10x;
&:hover{
    margin-top: 0px;
    margin-bottom : 5px;
    box-shadow : 0px 0px 10px 0px grey;
}
@media screen and (max-width: 700px) {
  height: 210px;
   width:170px;
}
@media screen and (max-width: 350px) {
    height: 210px;
    width:120px;
 }
`;
 const Item = styled.div`
 margin-left:auto;
 margin-right:auto;
 position:relative;
 margin-top:5px;
 width:100px;
height: 100px;
text-align:center;
font-size:20px;
background-image: ${({img})=> `url(${img});`}
background-position: center;
background-size: cover ;

transition-property: box-shadow margin-top filter;
transition-duration: .2s;
border:1px solid;
border-color: lightgray; 
border-radius : 10px;
box-shadow : 0px 0px 2px 0px grey;
padding-bottom: 10x;

`;
const Edit = styled.div`
height: 40px;
width: 40px;
display :flex;
align-items:center;
justify-element:center;
position:absolute;
background-image:url("/img/Edit.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border: none; 
top:0;
right:0;
font-size: 18px;
font-weight:bold;
 &:hover{
    cursor: pointer;
    opacity: 1.5;
    background-color:lightgray;
    border-radius : 7px;
 }

`;
const Color = styled.div`
position:relative;
right:2px;
margin-top:5px;

width: 36px;
height: 14px;
border: solid 2px lightgray;
border-radius:2px;
background:${props=>props.color};
`;
function Frontend({active}) {
 const db = window.firebase.firestore();
 const [data , setData] = useState();
 const [themes,setThemes] = useState();
 const [pictures,setPictures] = useState();
 const [info,setInfos]= useState();
 const [openItem, setOpenItem] = useState();
 const [openTheme, setOpenTheme] = useState();
 const [openPicture, setOpenPicture] = useState();
 const [openInfo,setOpenInfo] = useState();
 

  const canShowText = data && true;
  const isItemsEmpty = themes && themes.length ===0;
  const canShowItems = themes && true;
  const isPicturesEmpty = pictures && pictures.length ===0;
  const canShowPictures = pictures && true;
  const isInfoEmpty = info && info.length ===0;
  const canShowInfo = info && true;
  useEffect(()=>{ 
    db.collection('ticker').onSnapshot((data)=>{return  setData(data.docs)});
    db.collection('themes').onSnapshot((data)=>{return  setThemes(data.docs)});
    db.collection('pictures').onSnapshot((data)=>{return  setPictures(data.docs)});
    db.collection('footer').onSnapshot((data)=>{return  setInfos(data.docs)});
    },[]);
  return (
    <Container  width={ active ? "calc(100% - 220px)" : "calc(100% - 40px)"}>
    <EditText openItem={openItem} setData={setData} setOpenItem={setOpenItem} />
    <Title>Frontend</Title>
  <SchudelDiv>
  <Title>Ticker Text</Title>
    { canShowText ? data.map((item,index)=>{
      return(
        
        <ScheduleGrid key={index}>         
        <DivTitle> {"Text "+(index+1)}  </DivTitle>
        <Div>{item.data().text}</Div>
        <ButtonStyled className='btn btn-primary' onClick={()=>{setOpenItem(item)}} >Edit</ButtonStyled>
        </ScheduleGrid>
        
      )
     
      
    })


: "Loading ..."}

</SchudelDiv>

<EditTheme openTheme={openTheme} setThemes={setThemes} setOpenTheme={setOpenTheme}/>
<EditPicture openPicture={openPicture} setPictures={setPictures} setOpenPicture={setOpenPicture}/>
<EditInfo openInfo={openInfo} setInfos={setInfos} setOpenInfo={setOpenInfo}/>
<AddHeader>
            <Title>Theme</Title>  
  
            </AddHeader>
            <Bar />
            <ItemGrid>
            {isItemsEmpty  && <Item><div>Loading ...</div></Item>}
            { canShowItems ? themes.map((item,index)=>{
                return (
               <>   
               <ItemContainer key={index}>
               <Div>{item.data().name}</Div>
               <Div>{item.data().resto}</Div>
               <Div2> Navbar background <Color color={item.data().navBarBackground}/></Div2>
               <Div2> Ticker background<Color color={item.data().tickerBackground}/></Div2>
               <Div2> Navbar Lyrics<Color color={item.data().navLyric}/></Div2>
               <Div2> Ticker Lyrics<Color color={item.data().tickerLyric}/></Div2>
               <Div2> Order Button<Color color={item.data().orderBtn}/></Div2>
                    <Edit onClick={()=>setOpenTheme(item.data())} />
                   
                    </ItemContainer>
                    </>
                )} 
                ) : <div>Loading ...</div>}
            
            </ItemGrid>
            <AddHeader>
            <Title>Pictures</Title>  
  
            </AddHeader> 
            <Bar />
            <ItemGrid>
            {isPicturesEmpty  && <Item><div>Loading ...</div></Item>}
            { canShowPictures ? pictures.map((item,index)=>{
                return (
                 
               <ItemContainer key={index}>
               <Div>{item.data().name}</Div>
             
               <Item img ={`https://firebasestorage.googleapis.com/v0/b/${Id}.appspot.com/o/${item.data().name}%2F${item.data().imgUrl}?alt=media&token=${Token}`}>
                      
                 
                    </Item>
                    <Edit onClick={()=>setOpenPicture(item.data())} />
                   
                    </ItemContainer>
                
                )} 
                ) : <div>Loading ...</div>}
            
            </ItemGrid>
            <AddHeader>
            <Title>Footer</Title>  
  
            </AddHeader>
            <Bar />
            <ItemGrid>
            {isInfoEmpty  && <Item><div>Loading ...</div></Item>}
            { canShowInfo ? info.map((item,index)=>{
                return (
                
               <ItemContainer key={index}>
               <Div>{item.data().name}</Div>
              
               <Div2> facebook :{item.data().facebook.substring(25)} </Div2>
               <Div2> instagram :{item.data().instagram.substring(26)} </Div2>
               <Div2> file 1 :{item.data().copy1} </Div2>
               <Div2> file 2 :{item.data().copy2} </Div2>
               <Div2> file 3 :{item.data().copy3} </Div2>
                    <Edit onClick={()=>setOpenInfo(item.data())} />
                   
                    </ItemContainer>
                  
                )} 
                ) : <div>Loading ...</div>}
            
            </ItemGrid>
</Container>
  )
}

export default Frontend
