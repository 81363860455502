import { createGlobalStyle } from "styled-components";
// define a global style 
export const GlobalStyle = createGlobalStyle`
  body {
    margin:0;
    font-family: Open-Sans;
  }
  h1,h2,h3 {
    font-family: 'Righteous', cursive;
  }
`;