import React from "react";
import styled from 'styled-components';
import {T} from "../Language/translate";
import { UserInfoForm } from "./userInfoForm";
const Dialog = styled.div`
position: fixed;
  min-width:300px;
  width: auto;
  height:auto;
  background-color: white;
  margin-bottom:50px;
 
  z-index: 55;
margin:auto;
padding:10px;
  display: flex;
  flex-direction: column;
  border-radius:5px;
  // Add this part
  @media (max-width: 600px) {
    width: 100%;
    overflow-y: scroll;
    height:auto;
 
 
    
  }
  @media (max-height: 600px) {
    overflow-y: scroll;
    height:400px;
 
 
    
  }
`;

 const Wrapper = styled.div`
z-index: 55;
position:fixed;
width:100%;
height:100%;
left:0;
bottom:0;
  display: flex;
  justify-content: center;
  align-items: center;

`;

const DialogShadow = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  left:0px;
  background-color: black;
  opacity: 0.7;
  z-index: 51 !important;
`;
export const UserHeader = styled.div`
margin-top: 10px;
margin-bottom: 10px;
display:flex;
text-align:center;
align-items: center;
justify-content: center;


`;
const Close = styled.div`
height: 30px;
width: 30px;
display :flex;
align-items:center;
justify-element:center;
position:absolute;
background-image:url("/img/close.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border: none; 
margin:10px;
top:0;
right:0;
font-size: 18px;
font-weight:bold;
 &:hover{
    cursor: pointer;
    opacity:0.7;
 }
`;


var session = window.sessionStorage;
const db = window.firebase.firestore();
function UserInfoContainer({userDetails,setUserDetails,userInfo, setUserInfo,setLogInPanel,lang,setOpenOrderDialog,setDeliveryFee}){

    return (
        <>
        <DialogShadow  />
        <Wrapper>
        <Dialog>
           <Close onClick={()=>{setUserInfo();}}/>
        <UserHeader>{"Info"}</UserHeader>
     
        <UserInfoForm setUserDetails={setUserDetails} setUserInfo={setUserInfo}  setLogInPanel={setLogInPanel} 
        lang={lang} userDetails={userDetails}   setOpenOrderDialog={setOpenOrderDialog}setDeliveryFee={setDeliveryFee}></UserInfoForm>
       
        </Dialog>
        </Wrapper>
        </>
    )

}



export function UserInfoPanel(props){
    if (!props.userInfo) return null;
    return <UserInfoContainer {...props}/>
}