import React from "react";
import styled from "styled-components";
import { useState } from "react";
import { useEffect } from "react";
import { CreatePercentage } from "./AddPercentage";
import { ConfirmDelete } from "./ConfirmDelete";
import { EditPercentage } from "./EditPercentage";
const Container = styled.div`
margin-left:150px;
position:fixed;
height:80%;
font-size : 20px;
top:10px;
right:0;
width:${(props) => props.width};
@media screen and (max-width: 995px) {
    margin-left:10px;
}
`;
export const AddHeader = styled.div`
padding: 10px;
display: flex;
flex-direction: row;
gap: 60%;

media screen and (max-width: 1000px) {
      padding: 10px;
      display: flex;
      flex-direction: row;
      gap: 40%;
  }
  @media screen and (max-width: 800px) {
        padding: 10px;
        display: flex;
        flex-direction: row;
        gap: 30%;
    }
    @media screen and (max-width: 400px) {
  
          padding: 10px;
          display: flex;
          flex-direction: row;
          gap: 10%;
      }
`;
export const ButtonContainer = styled.div`
display:flex;
flex-direction:row;
@media screen and (max-width: 995px) {
   
    margin-top:20px;
}
`;

export const ButtonStyled = styled.button`
max-height:40px;
min-width:150px;
text-decoration: none;
margin: 10px;
&:hover{
    opacity: 1.5;
    border:1px solid;
    border-color: lightgray; 
    border-radius : 3px;
}
`;
export const ItemGrid = styled.div`
 display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr ;
gap: 10px;


media screen and (max-width: 1000px) {
    display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr 1fr  ;
gap: 8px;
}
@media screen and (max-width: 800px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr   ;
    gap: 5px;
  }
  @media screen and (max-width: 400px) {
    grid-template-columns: 1fr  ;
 
        
    }


`;

export const ItemContainer = styled.div`
 position:relative;
 width:auto;
height: 310px;
text-align:center;
font-size:20px;
margin: 5px;
transition-property: box-shadow margin-top filter;
transition-duration: .2s;
border:1px solid;
border-color: lightgray; 
border-radius : 10px;
box-shadow : 0px 0px 2px 0px grey;
padding-bottom: 10x;
&:hover{
    margin-top: 0px;
    margin-bottom : 5px;
    box-shadow : 0px 0px 10px 0px grey;
}
@media screen and (max-width: 400px) {
    width: 220px  ;
 
        
    }
`;
 export const Item = styled.div`
 width:200px;
 display:flex;
 flex-direction:column;
 justify-items:center;
 align-content:center;
 text-align:center;
 margin-top:5px;
 margin-left:2px;
 margin-right:2px;
height: auto;
text-align:center;
font-size:18px;
transition-property: box-shadow margin-top filter;
transition-duration: .2s;
border:1px solid;
border-color: lightgray; 
border-radius : 10px;
box-shadow : 0px 0px 2px 0px grey;
padding-bottom: 10x;

`;
const Edit = styled.div`
height: 40px;
width: 40px;
display :flex;
align-items:center;
justify-element:center;
position:absolute;
background-image:url("/img/Edit.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border: none; 
bottom:0;
right:0;
font-size: 18px;
font-weight:bold;
 &:hover{
    cursor: pointer;
    opacity: 1.5;
    background-color:lightgray;
    border-radius : 7px;
 }
`;
const Remove = styled.div`
height: 40px;
width: 40px;
display :flex;
align-items:center;
justify-element:center;
position:absolute;
background-image:url("/img/Remove.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border: none; 
bottom:0;
left:0;
font-size: 18px;
font-weight:bold;
 &:hover{
    cursor: pointer;
    opacity: 1.5;
    background-color:lightgray;
    border-radius : 7px;
 }
`;
export const Bar = styled.hr`
border-top: 3px solid #bbb;
margin: 5px;
@media screen and (max-width: 500px) {
   
    width:300px;
}
`;
const Div = styled.div`
    
     text-align:center;
     margin:1 px;
`;
const SubDiv = styled.div`
     margin-top:5px;
     text-align:center;
     white-space: nowrap;
    
`;
const H2 = styled.h2`
@media screen and (max-width: 995px) {
    white-space: nowrap;
    margin-top:20px;
}
@media screen and (max-width: 400px) {
     margin-top:30px;
    font-size:18px;
    font-weight:bold;
}

`;
const db = window.firebase.firestore();
export function Percentage({active}){
  //use sate to open panel for adding item 
  const [openPanel, setOpenPanel ] = useState(false);
  //use state to read data 
 const [data , setData] = useState();
 const [openDelete,setOpenDelete]=useState();
 //use state to edit data 
 const [openItem, setOpenItem] = useState();
 const isItemsEmpty = data && data.length ===0;
const canShowItems = data && true;
 useEffect(()=>{ 
  db.collection('offer').where("proType","==","discount").onSnapshot((data)=>{return  setData(data.docs)});
  
},[]);
  
    return (
        <Container  width={ active ? "calc(100% - 220px)" : "calc(100% - 40px)"}>
             {openPanel && <CreatePercentage openPanel={openPanel} setOpenPanel={setOpenPanel} setData={setData}/>}
             <EditPercentage openItem={openItem} setData={setData} setOpenItem={setOpenItem}/>
             <ConfirmDelete openDelete={openDelete} setData={setData} setOpenDelete={setOpenDelete} collection="offer" />
            <AddHeader>
            <H2> Discount %</H2>  
            <ButtonContainer>   
        <ButtonStyled className='btn btn-primary' onClick={()=>openPanel? setOpenPanel(false): setOpenPanel(true)} >ADD</ButtonStyled>
    </ButtonContainer>
            </AddHeader>
            <Bar/>
            <ItemGrid>
            {isItemsEmpty  && <Item><div>Loading ...</div></Item>}
            { canShowItems ? data.map((item,index)=>{
                return (
               <>   
               <ItemContainer key={index}>
               <Div>🎁</Div>
                    <Item >
                    <SubDiv> {item.data().code}</SubDiv>
                    <SubDiv>{item.data().valueType =="percentage" ? item.data().value+"%":item.data().value+" HUF"}</SubDiv>
                    <SubDiv>{item.data().startDate.substring(0, 10)+' '+item.data().startDate.slice(-5)}</SubDiv>
                    <SubDiv>{item.data().endDate.substring(0, 10)+' '+item.data().endDate.slice(-5)}</SubDiv>
                    {item.data().items.map((food)=>{
                        return (
                            <SubDiv>{food}</SubDiv>
                        )
                    })}
                    <SubDiv>{"limit :"+item.data().limit}</SubDiv>
                    </Item>
                    <Edit onClick={()=>setOpenItem(item.data())} />
                    <Remove  onClick={()=>setOpenDelete(item.data())}/>
                    </ItemContainer>
                    </>
                )} 
                ) : <Item><div>Loading ...</div></Item>}
            
            </ItemGrid>
      
        </Container>
    );
}