import React from 'react';
import styled from "styled-components";
import { useState } from "react";
import { useEffect } from "react";
import { Chart } from "react-google-charts";
import moment from "moment";

const Item = styled.div`

 position:relative;
 min-width:130px;
 width:400px;
height: 400px;
text-align:center;
display:flex;
flex-direction:column;
font-size:20px;
margin: 5px;
transition-property: box-shadow margin-top filter;
transition-duration: .2s;
border:1px solid;
border-color: lightgray; 
border-radius : 10px;
box-shadow : 0px 0px 2px 0px grey;
padding-bottom: 10x;
&:hover{
    margin-top: 0px;
    margin-bottom : 5px;
    box-shadow : 0px 0px 10px 0px grey;
}
`;
const Header = styled.div`
margin-left:auto;
margin-right:auto;
margin-top:5px;
margin-bottom:5px;
position:relative;
display:flex
border:1px solid;
border-color: lightgray; 
border-radius : 5px;
box-shadow : 0px 0px 2px 0px grey;
height:30px;
width: 200px;
`;
const NewUserContainer = styled.div`
display:flex
flex-direction:row;
height:auto;
width: 98%;
margin-left:auto;
margin-right:auto;
margin-top: 15px;


`;
 const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin:5px;
  
`;
const Text = styled.div`
margin-left:10px;
`;
const Div =styled.div`
hieght:200px;
`;
const db = window.firebase.firestore();
function Users() {
  const[userData, setUserData] = useState(); //[["Day", "Users"],[new Date(new Date().setHours(0,0,0,0)),0]]
  const[numberOfUsers ,setNumberOfUsers] = useState();
  const[view, setView] = useState('today');
  const[precision,setPrecision] = useState("");
  useEffect(()=>{ 
    db.collection('users').onSnapshot((data)=>{  setNumberOfUsers(data.size);setUserData([["Day", "Users"],[new Date(new Date().setHours(0,0,0,0)),Number(numberOfUsers)]])});
    
    
},[]);


function handlePeriodChange(e){
  setView(e.target.vlaue);
 setUserData([["Day", "Users"],[new Date(new Date().setHours(0,0,0,0)),0]]);
  switch(e.target.value){
  
    case "today" :
      setPrecision("Every 4 hours")
     let arr = [["Day", "Users"]];
    db.collection('users').
    where("date", ">=", new Date(new Date().setHours(0,0,0,0))).
    where("date","<=",new Date(new Date().setHours(4,0,0,0))).get().then((data)=>{return  Object.entries(data.docs).forEach((element,index) => {
   arr.push([moment(new Date (element[1].data().date.seconds *1000)).format("HH:MM"), data.size]);
   setUserData(arr);
    });});
    db.collection('users').
    where("date", ">=", new Date(new Date().setHours(0,0,0,0))).
    where("date","<=",new Date(new Date().setHours(8,0,0,0))).get().then((data)=>{return  Object.entries(data.docs).forEach((element,index) => {
   arr.push([moment(new Date (element[1].data().date.seconds *1000)).format("HH:MM"), data.size]);
   setUserData(arr);
    });});
    db.collection('users').
    where("date", ">=", new Date(new Date().setHours(0,0,0,0))).
    where("date","<=",new Date(new Date().setHours(12,0,0,0))).get().then((data)=>{return  Object.entries(data.docs).forEach((element,index) => {
   arr.push([moment(new Date (element[1].data().date.seconds *1000)).format("HH:MM"), data.size]);
   setUserData(arr);
    });});
    db.collection('users').
    where("date", ">=", new Date(new Date().setHours(0,0,0,0))).
    where("date","<=",new Date(new Date().setHours(16,0,0,0))).get().then((data)=>{return  Object.entries(data.docs).forEach((element,index) => {
   arr.push([moment(new Date (element[1].data().date.seconds *1000)).format("HH:MM"), data.size]);
   setUserData(arr);
    });});
    db.collection('users').
    where("date", ">=", new Date(new Date().setHours(0,0,0,0))).
    where("date","<=",new Date(new Date().setHours(20,0,0,0))).get().then((data)=>{return  Object.entries(data.docs).forEach((element,index) => {
   arr.push([moment(new Date (element[1].data().date.seconds *1000)).format("HH:MM"), data.size]);
   setUserData(arr);
    });});
    db.collection('users').
    where("date", ">=", new Date(new Date().setHours(0,0,0,0))).
    where("date","<=",new Date(new Date().setHours(23,59,0,0))).get().then((data)=>{return  Object.entries(data.docs).forEach((element,index) => {
   arr.push([moment(new Date (element[1].data().date.seconds *1000)).format("HH:MM"), data.size]);
   setUserData(arr);
    });});
  
    break;
    case "7day" :
      setPrecision("Every 1 day")
      let arr2 = [["Day", "Users"]];

      db.collection('users').
      where("date", ">=", new Date(Date.now() - 7 * 24 * 60 * 60 * 1000) ).
      where("date","<",new Date(Date.now() - 6 * 24 * 60 * 60 * 1000)).get().then((data)=>{return  Object.entries(data.docs).forEach((element,index) => {
     arr2.push([moment(new Date (element[1].data().date.seconds *1000)).format("DD/MM"), data.size]);
     setUserData(arr2);
      });});
      db.collection('users').
      where("date", ">=", new Date(Date.now() - 7 * 24 * 60 * 60 * 1000) ).
      where("date","<",new Date(Date.now() - 5 * 24 * 60 * 60 * 1000)).get().then((data)=>{return  Object.entries(data.docs).forEach((element,index) => {
     arr2.push([moment(new Date (element[1].data().date.seconds *1000)).format("DD/MM"), data.size]);
     setUserData(arr2);
      });});
      db.collection('users').
      where("date", ">=", new Date(Date.now() - 7 * 24 * 60 * 60 * 1000) ).
      where("date","<",new Date(Date.now() - 4 * 24 * 60 * 60 * 1000)).get().then((data)=>{return  Object.entries(data.docs).forEach((element,index) => {
     arr2.push([moment(new Date (element[1].data().date.seconds *1000)).format("DD/MM"), data.size]);
     setUserData(arr2);
      });});
      db.collection('users').
      where("date", ">=", new Date(Date.now() - 7 * 24 * 60 * 60 * 1000) ).
      where("date","<",new Date(Date.now() - 3 * 24 * 60 * 60 * 1000)).get().then((data)=>{return  Object.entries(data.docs).forEach((element,index) => {
     arr2.push([moment(new Date (element[1].data().date.seconds *1000)).format("DD/MM"), data.size]);
     setUserData(arr2);
      });});
      db.collection('users').
      where("date", ">=", new Date(Date.now() - 7 * 24 * 60 * 60 * 1000) ).
      where("date","<",new Date(Date.now() - 2 * 24 * 60 * 60 * 1000)).get().then((data)=>{return  Object.entries(data.docs).forEach((element,index) => {
     arr2.push([moment(new Date (element[1].data().date.seconds *1000)).format("DD/MM"), data.size]);
     setUserData(arr2);
      });});
      db.collection('users').
      where("date", ">=", new Date(Date.now() - 7 * 24 * 60 * 60 * 1000) ).
      where("date","<",new Date(Date.now() - 1 * 24 * 60 * 60 * 1000)).get().then((data)=>{return  Object.entries(data.docs).forEach((element,index) => {
     arr2.push([moment(new Date (element[1].data().date.seconds *1000)).format("DD/MM"), data.size]);
     setUserData(arr2);
      });});
      db.collection('users').
      where("date", ">=", new Date(Date.now() - 7 * 24 * 60 * 60 * 1000) ).
      where("date","<",new Date(Date.now())).get().then((data)=>{return  Object.entries(data.docs).forEach((element,index) => {
     arr2.push([moment(new Date (element[1].data().date.seconds *1000)).format("DD/MM"), data.size]);
     setUserData(arr2);
      });});
  
    break;
    
    case "month" : 
    setPrecision("Every week")
    let arr3 = [["Day", "Users"]];

    db.collection('users').
    where("date", ">=", new Date(Date.now() - 30 * 24 * 60 * 60 * 1000) ).
    where("date","<",new Date(Date.now() - 24 * 24 * 60 * 60 * 1000)).get().then((data)=>{return  Object.entries(data.docs).forEach((element,index) => {
   arr3.push([moment(new Date (element[1].data().date.seconds *1000)).format("DD/MM"), data.size]);
   setUserData(arr3);
    });});
    db.collection('users').
    where("date", ">=", new Date(Date.now() - 30 * 24 * 60 * 60 * 1000) ).
    where("date","<",new Date(Date.now() - 16 * 24 * 60 * 60 * 1000)).get().then((data)=>{return  Object.entries(data.docs).forEach((element,index) => {
   arr3.push([moment(new Date (element[1].data().date.seconds *1000)).format("DD/MM"), data.size]);
   setUserData(arr3);
    });});
    db.collection('users').
    where("date", ">=", new Date(Date.now() - 30 * 24 * 60 * 60 * 1000) ).
    where("date","<",new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).get().then((data)=>{return  Object.entries(data.docs).forEach((element,index) => {
   arr3.push([moment(new Date (element[1].data().date.seconds *1000)).format("DD/MM"), data.size]);
   setUserData(arr3);
    });});
    db.collection('users').
    where("date", ">=", new Date(Date.now() - 30 * 24 * 60 * 60 * 1000) ).
    where("date","<",new Date(Date.now() )).get().then((data)=>{return  Object.entries(data.docs).forEach((element,index) => {
   arr3.push([moment(new Date (element[1].data().date.seconds *1000)).format("DD/MM"), data.size]);
   setUserData(arr3);
    });});
    break;
    case "3month":
      setPrecision("Every 15 days")
      let arr4 = [["Day", "Users"]];

      db.collection('users').
      where("date", ">=", new Date(Date.now() - 90 * 24 * 60 * 60 * 1000) ).
      where("date","<",new Date(Date.now() - 75 * 24 * 60 * 60 * 1000)).get().then((data)=>{return  Object.entries(data.docs).forEach((element,index) => {
     arr4.push([moment(new Date (element[1].data().date.seconds *1000)).format("DD/MM/YYYY"), data.size]);
     setUserData(arr4);
      });});
      db.collection('users').
      where("date", ">=", new Date(Date.now() - 90 * 24 * 60 * 60 * 1000) ).
      where("date","<",new Date(Date.now() - 60 * 24 * 60 * 60 * 1000)).get().then((data)=>{return  Object.entries(data.docs).forEach((element,index) => {
     arr4.push([moment(new Date (element[1].data().date.seconds *1000)).format("DD/MM/YYYY"), data.size]);
     setUserData(arr4);
      });});
      db.collection('users').
      where("date", ">=", new Date(Date.now() - 90 * 24 * 60 * 60 * 1000) ).
      where("date","<",new Date(Date.now() - 45 * 24 * 60 * 60 * 1000)).get().then((data)=>{return  Object.entries(data.docs).forEach((element,index) => {
     arr4.push([moment(new Date (element[1].data().date.seconds *1000)).format("DD/MM/YYYY"), data.size]);
     setUserData(arr4);
    });});
     db.collection('users').
     where("date", ">=", new Date(Date.now() - 90 * 24 * 60 * 60 * 1000) ).
     where("date","<",new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)).get().then((data)=>{return  Object.entries(data.docs).forEach((element,index) => {
    arr4.push([moment(new Date (element[1].data().date.seconds *1000)).format("DD/MM/YYYY"), data.size]);
    setUserData(arr4);
      });});
      db.collection('users').
      where("date", ">=", new Date(Date.now() - 90 * 24 * 60 * 60 * 1000) ).
      where("date","<",new Date(Date.now() - 15 * 24 * 60 * 60 * 1000)).get().then((data)=>{return  Object.entries(data.docs).forEach((element,index) => {
     arr4.push([moment(new Date (element[1].data().date.seconds *1000)).format("DD/MM/YYYY"), data.size]);
     setUserData(arr4);
       });});
       db.collection('users').
       where("date", ">=", new Date(Date.now() - 90 * 24 * 60 * 60 * 1000) ).
       where("date","<",new Date(Date.now() )).get().then((data)=>{return  Object.entries(data.docs).forEach((element,index) => {
      arr4.push([moment(new Date (element[1].data().date.seconds *1000)).format("DD/MM/YYYY"), data.size]);
      setUserData(arr4);
        });});
    break;
    case "6month":
      setPrecision("Every month")
      let arr5 = [["Day", "Users"]];

      db.collection('users').
      where("date", ">=", new Date(Date.now() - 180 * 24 * 60 * 60 * 1000) ).
      where("date","<",new Date(Date.now() - 150 * 24 * 60 * 60 * 1000)).get().then((data)=>{return  Object.entries(data.docs).forEach((element,index) => {
     arr5.push([moment(new Date (element[1].data().date.seconds *1000)).format("DD/MM/YYYY"), data.size]);
     setUserData(arr5);
      });});
      db.collection('users').
      where("date", ">=", new Date(Date.now() - 180 * 24 * 60 * 60 * 1000) ).
      where("date","<",new Date(Date.now() - 120 * 24 * 60 * 60 * 1000)).get().then((data)=>{return  Object.entries(data.docs).forEach((element,index) => {
     arr5.push([moment(new Date (element[1].data().date.seconds *1000)).format("DD/MM/YYYY"), data.size]);
     setUserData(arr5);
      });});
      db.collection('users').
      where("date", ">=", new Date(Date.now() - 180 * 24 * 60 * 60 * 1000) ).
      where("date","<",new Date(Date.now() - 90 * 24 * 60 * 60 * 1000)).get().then((data)=>{return  Object.entries(data.docs).forEach((element,index) => {
     arr5.push([moment(new Date (element[1].data().date.seconds *1000)).format("DD/MM/YYYY"), data.size]);
     setUserData(arr5);
      });});
      db.collection('users').
      where("date", ">=", new Date(Date.now() - 180 * 24 * 60 * 60 * 1000) ).
      where("date","<",new Date(Date.now() - 60 * 24 * 60 * 60 * 1000)).get().then((data)=>{return  Object.entries(data.docs).forEach((element,index) => {
     arr5.push([moment(new Date (element[1].data().date.seconds *1000)).format("DD/MM/YYYY"), data.size]);
     setUserData(arr5);
      });});
      db.collection('users').
      where("date", ">=", new Date(Date.now() - 180 * 24 * 60 * 60 * 1000) ).
      where("date","<",new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)).get().then((data)=>{return  Object.entries(data.docs).forEach((element,index) => {
     arr5.push([moment(new Date (element[1].data().date.seconds *1000)).format("DD/MM/YYYY"), data.size]);
     setUserData(arr5);
      });});
      db.collection('users').
      where("date", ">=", new Date(Date.now() - 180 * 24 * 60 * 60 * 1000) ).
      where("date","<",new Date(Date.now() )).get().then((data)=>{return  Object.entries(data.docs).forEach((element,index) => {
     arr5.push([moment(new Date (element[1].data().date.seconds *1000)).format("DD/MM/YYYY"), data.size]);
     setUserData(arr5);
      });});
    break;
    case "year":
      setPrecision("Every 2 months")
      let arr6 = [["Day", "Users"]];

      db.collection('users').
      where("date", ">=", new Date(Date.now() - 360 * 24 * 60 * 60 * 1000) ).
      where("date","<",new Date(Date.now() - 270 * 24 * 60 * 60 * 1000)).get().then((data)=>{return  Object.entries(data.docs).forEach((element,index) => {
     arr6.push([moment(new Date (element[1].data().date.seconds *1000)).format("DD/MM/YYYY"), data.size]);
     setUserData(arr6);
      });});
      db.collection('users').
      where("date", ">=", new Date(Date.now() - 360 * 24 * 60 * 60 * 1000) ).
      where("date","<",new Date(Date.now() - 180 * 24 * 60 * 60 * 1000)).get().then((data)=>{return  Object.entries(data.docs).forEach((element,index) => {
     arr6.push([moment(new Date (element[1].data().date.seconds *1000)).format("DD/MM/YYYY"), data.size]);
     setUserData(arr6);
      });});
      db.collection('users').
      where("date", ">=", new Date(Date.now() - 360 * 24 * 60 * 60 * 1000) ).
      where("date","<",new Date(Date.now() - 90 * 24 * 60 * 60 * 1000)).get().then((data)=>{return  Object.entries(data.docs).forEach((element,index) => {
     arr6.push([moment(new Date (element[1].data().date.seconds *1000)).format("DD/MM/YYYY"), data.size]);
     setUserData(arr6);
      });});
      db.collection('users').
      where("date", ">=", new Date(Date.now() - 360 * 24 * 60 * 60 * 1000) ).
      where("date","<",new Date(Date.now() )).get().then((data)=>{return  Object.entries(data.docs).forEach((element,index) => {
     arr6.push([moment(new Date (element[1].data().date.seconds *1000)).format("DD/MM/YYYY"), data.size]);
     setUserData(arr6);
      });});
    break;
    default:
     
  }
}

const options = {

  curveType: "function",
  legend: { position: "top" },
};

  return (
   <Item>
  
      <NewUserContainer>
      <Header>Users</Header>
      {"Total registred : "+numberOfUsers}
      <InputContainer className="form-group">
            <label for="tech" className="col-sm-3 control-label">Period</label>
            <div className="col-sm-4">
              <select className="form-control"
                name="status"
                value={view}
                onChange={(e)=>{ handlePeriodChange(e)}}
              >
                <option value="today">Today</option>
                <option value="7day">7 Days</option>
                <option value="month">Month</option>
                <option value="3month">3 Month</option>
                <option value="6month">6 Month</option>
                <option value="year">Year</option>
              </select>
            </div>
            <Text>Cycle : {precision}</Text>
          </InputContainer>
          <Div>
          {userData ? <Chart
         chartType="LineChart"
         width="auto"
         height="90%"
         data={userData}
         options={options}
    />: "Loading..."}
          </Div>
    
      </NewUserContainer>
      </Item>
  )
}

export default Users
