import React from "react";
import styled from "styled-components";
import { useState } from "react";
import Autocomplete from "react-google-autocomplete";
import { OrderBtn } from "../Styles/colors";
import { useEffect } from "react";
import useForm from "../Hooks/useForm";
import {T} from "../Language/translate";
const db = window.firebase.firestore();
const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: auto;
  margin: 20px;
`;
export const UserFooter = styled.div`
  height: 60px;
  display: flex;

  margin-top: 10px;
  justify-content: center;
`;
export const ConfirmButton = styled.div`
  margin: 10px;
  color: white;
  height: 40px;
  width: 200px;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  background-color: ${OrderBtn};
  opacity: 0.7;
  z-index: 4;
  ${({ disabled }) =>
    disabled &&
    `
opacity: .5;
background-color: grey;
pointer-events: none;
`}
`;

const Notice = styled.span`
  color: red;
  font-size: 12px;
`;
const ExtraItem = styled.div`
position:relative;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
width:auto;
height: 40px;
text-align:center;
font-size:20px;
margin: 5px;
transition-property: box-shadow margin-top filter;
transition-duration: .2s;
border:1px solid;
border-color: lightgray; 
border-radius : 10px;
box-shadow : 0px 0px 2px 0px grey;
padding-bottom: 10x;

`;
const ExtraContent = styled.div`
display:flex;
flex-direction:row;
font-size:16px;

`;
const ExtraGrid = styled.div`
display: grid;
grid-template-columns: 1fr  ;
gap: 10px;

`;
const Div = styled.div`
margin:1px;
`;

const X = styled.div`
height: 30px;
width: 30px;
margin:5px;
 &:hover{
    cursor: pointer;
    opacity:0.7;
 }
`;
const AddressTxt = styled.div`

width:300px;
margin:auto;
`;
const HContainer= styled.div`
display:grid;
grid-template-columns:auto 200px;
gap:10px;
margin:5px;
`;
function phoneValid(value){
  return !isNaN(value) && (value != null && value.trim().length > 0)
}
function Required(value){
  return value != null && value.trim().length > 0
}

export function UserInfoForm({ userDetails,setUserDetails,setUserInfo ,prop,lang,setDeliveryFee}) {
  

  const [zip,setZip] = useState();
  const [street,setStreet]= useState("");
  const [city,setCity]= useState("");
  const [selected,setSelected] = useState(false);
  const [phone,setPhone] = useState();
  const [hNumber, setHnumber] = useState();
  const [tags, setTags] = useState([]);
 //Form validation 
 const initialState = { name:'',address: '' ,floor: '',  door: '', };
 const validations = [

 ];

 const { values, isValid, errors, changeHandler} = useForm(initialState, validations, prop);


 

  





const [Err,setError] = useState("");
const [addressErr,setAddressErr] = useState("");
const [zipErr, setZipErr] = useState("");
const [phoneErr, setPhoneErr] = useState("");
const [HnumErr,setHnumErr] = useState("");
const [zipNumber,setZipNumber] = useState();
const [nameErr,setNameErr] = useState("");


function showError(){
  console.log("errorss")
  if(isNaN(zip)||isNaN(zipNumber)){
    setZipErr("Helyes irányítószám szükséges");
  }
  if((errors.address || errors.zip || errors.phone)){
      setError(T("52",lang));
  }

  phoneValid(phone)? setPhoneErr(""): setPhoneErr(T("48",lang));
  
  Required(hNumber) ? setHnumErr(""): setHnumErr(T("139",lang));
  Required(values.name) ? setNameErr(""): setNameErr(T("96",lang));
   tags.length != 0 ? setAddressErr("") :setAddressErr(T("59",lang));
    
  
}

function handleDelete(i) {
  setTags(tags.filter((tag, index) => index !== i));
  setAddressErr(T("59",lang));
  setError('');
  setZip();

};

function handleAddition(e,place) {
  console.log(place);
   place.address_components.filter((item)=>item.types[0]=='postal_code' ? setZip(Number(item.long_name)):setZip());
   place.address_components.filter((item)=>item.types[0]=='route' ? setStreet(item.long_name):"");
   place.address_components.filter((item)=>item.types[0]=='locality' ? setCity(item.long_name):"");
   
  
   setTags([ e ]);
  setAddressErr("");
  setError('');

};

 



 
 useEffect(()=>{ 
 

},[]);

  
  const handleSubmit = () => {

    if(userDetails){
        setUserInfo();
       
    return;
    }
  
    db.collection('zip').doc(zip?String(zip):userDetails.zipCode ?String(userDetails.zipCode):String(zipNumber)).get().then((doc)=>{
        
        if(doc.exists){
      setDeliveryFee(doc.data().price?doc.data().price:0);
        const docRef = db.collection("phoneUsers").doc(String(phone));
        const obj =  {
            street: street,
            zipCode:zip?zip: zipNumber,
            city:city,
            houseNumber:hNumber,
            email: "Phone Call",
            name: values.name,
            phone: phone,
            floor: values.floor,
            door : values.door,  
            date: new Date(),
            status:true
        } 
        setUserDetails(obj);   
  
        
          docRef.set(obj);
       
      

      setUserInfo();
    }  else {

      setAddressErr(T("50",lang) ) ;
      
    } })
  };

  return (
   
     <>
      <Notice>{!( errors.address||errors.zip || errors.phone) ? Err : addressErr}</Notice>
      <ExtraGrid >
              {tags.length != 0 ? tags.map((item, index) => {
                return (
                  <ExtraItem>
                    <ExtraContent>
                      <Div>{item}</Div>
                      <X onClick={() => { handleDelete(index);setZip() }} >❌</X>
                    </ExtraContent>

                  </ExtraItem>
                )

              }) : ""}

            </ExtraGrid>
            <Notice>{phoneErr}</Notice>
     <HContainer>
     <label>
       {T("54",lang)} 
      </label>
      <input
          className="form-control"
          placeholder={T("147",lang)}
          name="phone"
          type="tel"
          value={phone}
          onChange={(e)=>{setPhone(e.target.value);
        if(e.target.value.length===10||e.target.value.length===12){
            db.collection('phoneUsers').doc(String(e.target.value)).get().then((doc)=>{
        
                if(doc.exists){
                    console.log("found :))")
                    setUserDetails(doc.data())
                    setTags([ "Found"]);
                }})
        }
        else{
            setUserDetails();
            setTags([]);
        }
        }}
        />
     </HContainer>
           { console.log(tags)}
           <label className="label">
           <AddressTxt>{T("142",lang)}</AddressTxt>
           <Notice>{addressErr}</Notice>
        <Autocomplete
          className="form-control"
          value={userDetails ? (userDetails.city+" "+userDetails.street+" "+userDetails.zipCode):values.address}
          name="address"
          onBlur={changeHandler}
          onChange={(e)=>{changeHandler(e);setSelected(false);}}
          placeholder={T("146",lang)}
          apiKey={"AIzaSyC_NsDrw_bCL2e5UQEiknoOFhwWdKadKtQ"}
          style={{ width: "fit-content(20em)" }}
          onPlaceSelected={(place) => {
           
          
            setSelected(true);
            handleAddition(place.formatted_address,place);
      
          }}
          options={{
            types: ["address"],
            componentRestrictions: { country: "fr" },
          
            strictBounds: false,
          }}
      
        />
      </label>
      <Notice>{zipErr}</Notice>
      <HContainer>
      <label>
        {T("37",lang)}
      
      </label>
      {zip!=null || tags.length ==0 ?<input
          className="form-control"
          name="zip"
          type="text"
          value={userDetails ? userDetails.zipCode:zip}
          disabled
        />:
        <input
        className="form-control"
        name="ZipType"
        type="number"
        value={userDetails ? userDetails.zipCode:zipNumber}
        onChange={(e)=>{setZipNumber(e.target.value)}}
      />
        
        }
      </HContainer>
    
      <Notice>{HnumErr}</Notice>
      <HContainer>
      <label>
       {T("138",lang)}
        </label>
        <input
          className="form-control"
          name="Hnumber"
          type="text"
          value={userDetails ? userDetails.houseNumber:hNumber}
          onChange={(e)=>setHnumber(e.target.value)}
        />
      </HContainer>
    
     <HContainer>
     <label>
       {T("39",lang)}
      </label>
      <input
          className="form-control"
          name="floor"
          type="text"
          value={userDetails ? userDetails.floor:values.floor}
          onChange={changeHandler}
        />
     </HContainer>
   <HContainer>
   <label>
       {T("40",lang)}
      </label>
      <input
          className="form-control"
          type="text"
          name="door"
          value={userDetails ? userDetails.door:values.door}
          onChange={changeHandler}
        />
   </HContainer>
 
     
     <Notice>{nameErr}</Notice>
     <HContainer>
     <label>
       {T("34",lang)}
      </label> 
      <input
          className="form-control"
          name="name"
          type="text"
          value={userDetails ? userDetails.name:values.name}
          onChange={changeHandler}
        />
     </HContainer>
   

      
      <UserFooter>
        
       {userDetails ? <ConfirmButton onClick={()=>{ handleSubmit()}}>{ T("130",lang)}</ConfirmButton>:<ConfirmButton onClick={()=>{!(tags.length != 0&& (!isNaN(zip)||!isNaN(zipNumber))&&phoneValid(phone)&&Required(hNumber)&& Required(values.name))?showError(): handleSubmit()}}>{ T("130",lang)}</ConfirmButton>}
        

       
      </UserFooter>
      </> 
   
  );
}
