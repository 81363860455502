import React from "react";
import styled from 'styled-components';
import { useState } from "react";
import { useEffect } from "react";
import {T} from "../Language/translate";
var _ = require('lodash');
const Dialog = styled.div`
position: fixed;
  width: 500px;
  height:auto;
  background-color: white;
  margin-bottom:50px;
  z-index: 57 !important;
  margin:auto;
  padding:10px;
  display: flex;
  flex-direction: column;
  border-radius:5px;
  // Add this part
  @media (max-width: 400px) {
    
    overflow-y: scroll;
    height:auto;
    width: auto;
 
    
  }
`;

 const Wrapper = styled.div`
z-index: 57 !important;
position:fixed;
width:100%;
height:100%;
left:0;
bottom:0;
  display: flex;
  justify-content: center;
  align-items: center;

`;

const DialogShadow = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  left:0px;
  background-color: black;
  opacity: 0.7;
  z-index: 56 !important;
`;
const ProfileContainer = styled.div`
width: 80%;
height:300px;
overflow-y: scroll;
margin-left:auto;
margin-right:auto;
margin-top:20px;
border-radius:5px;
border:1px solid;
border-color:gray;
display :flex;
overflow:auto;
flex-direction:column; 

`;
 const UserHeader = styled.div`
margin-top: 10px;
margin-bottom: 10px;
display:flex;
text-align:center;
align-items: center;
justify-content: center;

`;
 const Close = styled.div`
height: 30px;
width: 30px;
display :flex;
align-items:center;
justify-element:center;
position:absolute;
background-image:url("/img/close.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border: none; 
margin:10px;
top:0;
right:0;
font-size: 18px;
font-weight:bold;
 &:hover{
    cursor: pointer;
    opacity:0.7;
 }
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top:10px;
  margin-left:auto;
  margin-right:auto;
  @media(max-width: 700px){ 
    margin-top:10px;
    margin-left:-1px;
    
   }
  
`;




const Div = styled.div`
display:none;
`;
const TextArea = styled.textarea `
resize: none;
border-radius:10px;

@media(max-width: 700px){ 
  
   
  }
`;
const ConfirmButton = styled.div`
margin: 10px;
color : white;
height:40px;
width:100%;
border-radius: 5px;
padding: 10px;
text-align: center;
cursor: pointer;
background-color: blue;
opacity:0.8;
z-index : 4;
${({disabled})=>disabled && `
opacity: .5;
background-color: grey;
pointer-events: none;
`}
`;
const Box = styled.div`
height:auto;
width:${props => props.width};
background-color: ${props => props.color};
border-radius:8px;
margin-left:5px;
margin-top:10px;
margin-right:5px;
padding:2px 5px;
`;
const MsgContainer = styled.div`
display:flex;
margin-left: ${props => props.margin};
height: 50px;
flex-direction:column;

`;
const Time = styled.div`
margin-left:${props => props.margin};
font-size:12px;
`;
// Get a database reference

const db = window.firebase.firestore();

function SupportDialogContainer({supportPanel, setSupportPanel, loggedIn,lang,messages,setMessages}){
   
    const [text,setText] = useState("");
    const handleChange = event => {
        setText(event.target.value);
        console.log(text);
      };
      
      useEffect(()=>{
        db.collection('support').where('email','==',loggedIn.email).onSnapshot((x)=>{ return x.docs[0] ?  setMessages(x.docs[0].data().messages):setMessages([])});
      },[])

      function sendMessage(){
         var x = [...messages,{value:text,date:new Date().getMonth()+"/"+new Date().getDate()+" "+new Date().getHours()+":"+new Date().getMinutes(),sender:"user"}];
       
        const docRef = db.collection("support").doc(loggedIn.email);
            docRef.set({
                     email: loggedIn.email,
                     messages : x
            })
            setText("");
        
     
      
      }
    return (
        <>
        <DialogShadow  />
        <Wrapper>
        <Dialog>
        <UserHeader>{T("33",lang)+supportPanel}</UserHeader>
        <Close onClick={()=>{setSupportPanel()}}/>
    
       { messages && <ProfileContainer>   
          {messages.map((t)=>{
            return (
              <MsgContainer margin= {t.sender ==="user" ? "10px":"30%"} >
              <Box color={t.sender ==="user" ? "lightgray":"lightblue"} width={t.sender ==="user" ? "70%":"95%"}>{t.value}</Box>
               <Time margin={t.sender ==="user" ? "50%":"-15%"}>{t.date}</Time>
              </MsgContainer>
            )
          })}
         </ProfileContainer>} 
         <InputContainer>
       
   
       <TextArea type="text" className="form-control" name="ingredient" placeholder="Message"
         onChange={handleChange} 
         value={text}
         />
  
    <Div>{text}</Div>
   </InputContainer>
   <InputContainer>
   <ConfirmButton disabled={text.length===0} onClick={()=>{sendMessage()}} >Send</ConfirmButton>
   </InputContainer>
        </Dialog>
        </Wrapper>
        
        </>
    )

}



export function SupportDialog(props){
    if (!props.supportPanel) return null;
    return <SupportDialogContainer {...props}/>
}