import React from "react";
import styled from "styled-components";
import CatNav from "../../CategoryNav/CatNav";
import { BodyImage } from "../../Styles/images";
import { Id } from '../../ProjectId';
import { Token } from '../../StorageToken'; 
const MenuStyled = styled.div`
z-index:0;
background-image:  url("https://firebasestorage.googleapis.com/v0/b/${Id}.appspot.com/o/${"Body"}%2F${BodyImage}?alt=media&token=${Token}");
background-position: center;
background-repeat: repeat-y;
background-size:100% auto;
position:relative;
width : calc(100% - 340px);
height: ${props => props.height} ;
padding-bottom:10px;
 @media(max-width: 700px){ 
  overflow-x: hidden;
    width: 100%; 
    margin: 0px; 
  }

`


export function Menu({admins,loggedIn,...Language}){
  const isAdmin = loggedIn&& admins.some(u=>u.email ===loggedIn.email&&(u.role==="admin"||u.role==="pos"));
    return (
    
    <MenuStyled height={ "100%"}>
         <CatNav {...Language}/>  
    </MenuStyled>)
}
