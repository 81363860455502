import React,{useState,useEffect} from 'react';
import styled from "styled-components";
import { OrderBtn } from "../Styles/colors";
const Dialog = styled.div`
position: fixed;
  width:600px;
  height:auto;
  opacity:0.9;
  border: 2px solid white;
  background-color: ${OrderBtn};;
  margin:auto;
  z-index: 55;
padding:5px;
  display: flex;
  flex-direction: column;
  border-radius:5px;
  // Add this part
  @media (max-width: 700px) {
    
    overflow-y: scroll;
    height:auto;
    width: 90%;
 
    z-index: 55;
  }

`;
 const DialogShadow = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
 top:0;
 left:0;
  background-color: black;
  opacity: 0.8;
  z-index: 50;
`;
const Wrapper = styled.div`
z-index: 55;
position:fixed;
width:100%;
height:100%;
left:0; 
  display: flex;
  justify-content: center;
  align-items: center;

`;
const Close = styled.div`
height: 30px;
width: 30px;
display :flex;
align-items:center;
justify-element:center;
position:absolute;
background-image:url("/img/close.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border: none; 
margin:0px;
top:0;
right:0;
font-size: 18px;
font-weight:bold;
z-index:50;
 &:hover{
    cursor: pointer;
    opacity:0.7;
 }
`;
const Container = styled.div`
display:flex;
flex-direction:column;
`;
const Header = styled.div`
font-size:24px;
font-weight:bold;
text-align:center;
color:white;
margin:10px 5px;
`;
const Paragraph = styled.div`
font-size:16px;
text-align:center;
color:white;
`;
const Greeting = styled.div`
font-size:16px;
font-weight:bold;
text-align:center;
color:white;
`;
const ConfirmButton = styled.div`
margin: 10px;
color : white;
height:40px;
width:100%;
border-radius: 5px;
padding: 10px;
text-align: center;
cursor: pointer;
background-color: black;
opacity:0.9;
z-index : 4;
&:hover {
  cursor: pointer;
  opacity:0.7;
}
`;
const Div = styled.div`
display:flex;
fle-direction:row;
`;
var session = window.sessionStorage;
function WelcomeContainer({welcomePanel,setWelcomePanel,takeOrDelivery,setTakeOrDelivery}) {
    const db = window.firebase.firestore();
    const [data , setData] = useState();
    const canShowText = data && true;
 
    useEffect(()=>{ 
      session.setItem("GuestOk",false);
      db.collection('welcome').onSnapshot((data)=>{return  setData(data.docs[0])});
      
      },[]);
    function close(){
           setWelcomePanel();
    }
    return (
        <>
        <DialogShadow onClick={()=>close()} />
        <Wrapper>
        <Dialog>
           
            {canShowText &&<Container>
            <Header>{data.data().Title}</Header>
            <Paragraph>{data.data().Paragraph}</Paragraph>
            <Div>
              <ConfirmButton onClick={()=>{setTakeOrDelivery("A importer");close()}}> A importer </ConfirmButton>
              <ConfirmButton onClick={()=>{setTakeOrDelivery("Livraison");close()}}>Livraison</ConfirmButton>
            </Div>
            <Greeting>{data.data().Greeting}</Greeting>
            </Container>}
        </Dialog>
        </Wrapper>
        </>
       
    );
}

export function Welcome(props){
    if (!props.welcomePanel) return null;
    return <WelcomeContainer {...props}/>
}