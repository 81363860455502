import React from "react";
import styled from "styled-components";
import { Wrapper,UpContainer,Label ,Dialog, ButtonStyled, DialogShadow ,DialogHeader,Notice 
  ,Header,H2, InputContainer ,H4R ,H4G ,Percent} from "./Items/AddItem";

const Close = styled.div`
height: 30px;
width: 30px;
display :flex;
align-items:center;
justify-element:center;
position:absolute;
background-image:url("/img/close.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border: none; 
margin:10px;
top:0;
right:0;
font-size: 18px;
font-weight:bold;
 &:hover{
    cursor: pointer;
    opacity:0.7;
 }
`;
const UserHeader = styled.div`
padding:5px;
margin-top: 30px;
display:flex;
text-align:center;
align-items: center;
justify-content: center;

`;

const db = window.firebase.firestore();
function ConfirmDeleteContainer({ openDelete, collection ,setOpenDelete}) {

    function del(){
        db.collection(collection).doc(openDelete.name).delete().then(() => {
            console.log("Document successfully deleted!");
        }).catch((error) => {
            console.error("Error removing document: ", error);
        });
    }
    return (
        <>
         <DialogShadow />
         <Wrapper>
      <Dialog>
      <UserHeader>{'Are you sure about deleting '+openDelete.name+' ?'}</UserHeader>
        <Close onClick={()=>{setOpenDelete()}}/>
        <ButtonStyled  className="btn btn-danger"  onClick={()=>{setOpenDelete();del()}} >
                Yes !
              </ButtonStyled>
      </Dialog>
      </Wrapper>
        </>
    )

}



export function ConfirmDelete(props){
    if (!props.openDelete) return null;
    return <ConfirmDeleteContainer {...props}/>
}