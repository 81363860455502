import React,{useState} from "react";
import {Link} from 'react-router-dom';
import styled from "styled-components";
import { Ticker } from "../Banner/Ticker";
import { FooterImage } from "../Styles/images";
import { NavbarBackgroundColor } from "../Styles/colors";
import {T} from "../Language/translate";
import { Id } from '../ProjectId';
import { Token } from '../StorageToken'; 
import "../app.css";
const Container = styled.div`
position:fixed;
bottom:0;
display:flex;
flex-direction:row;
margin-top:-5px;
box-shadow: rgba(50, 50, 93, 0.25) -12px 30px 60px 0px inset, rgba(0, 0, 0, 0.3) -18px 18px 36px -0px inset;
background-color:${NavbarBackgroundColor};
height: 55px;
width : calc(100% - 340px);
@media(max-width: 700px){ 
  height:60px;
    width: 100%; 
    margin: 0px; 
  }

`;
const MediaContainer = styled.div`
display: flex;
flex-direction:row;
justify-content:center;
align-items:center;
color : #F8F1DE;
`;


const NavContainer = styled.div`
display: flex;
flex-direction:row;
width:100%;

justify-content:start;
align-items:center;
margin-left:10px;

@media(max-width: 1110px){ 
  margin-left:5px;
  }
@media(max-width: 1040px){ 
  flex-direction:column;
 
  }
  @media(max-width: 700px){ 
  
    margin-left:auto;
    margin-right:auto;
    }
`;

const LangContainer = styled.div`
display: flex;
width:100px;
flex-direction:row;
justify-content:center;
align-items:center;
color : #F8F1DE;
margin-left:10px;
margin-right:10px;
`;


const Banner = styled.div`
position: absolute;
z-index:0;
height: 230px;
width:calc(100% - 280px);
background-image:  url("https://firebasestorage.googleapis.com/v0/b/${Id}.appspot.com/o/${"Footer"}%2F${FooterImage}?alt=media&token=${Token}");
background-position: bottom;
background-repeat: no-repeat;
background-size: cover 100%;
@media(max-width: 700px){ 
  width:100%;
  }

`;
export function Footer({lang,setLang}) {
 
  


    return (
      <> 
    
   
     
 
     < Ticker />
      <Banner />
      <Container>
      <MediaContainer>
    {/*<Link className="insta" to="/insta" target="_blank" ></Link>*/}  
      <Link className="face" to="/face" target="_blank"></Link>
      </MediaContainer>
      <NavContainer>
        {/*<Link className="text" to="/link1" target="_blank">{T("1",lang)}</Link>*/}
        <Link className="text" to="/link2" target="_blank">{T("2",lang)}</Link>
        <Link className="text" to="/link3" target="_blank">{T("8",lang)}</Link>
      </NavContainer>
      <LangContainer>
      <select className="form-control" style={{"backgroundColor":"transparent","color":"white"}}
           
           value={lang}
           onChange={e=>{setLang(e.target.value); }}
           >
               
                      <option value='Hu' style={{"color":"black"}}> Fr</option>
                      <option value='En'  style={{"color":"black"}}> En</option>
                   
               
              </select>
      </LangContainer>
      </Container>
      </>
   )
 }
