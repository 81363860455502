import React from "react";
import styled from 'styled-components';
import { UserHeader } from "../userDialog/userDialog";
import { useState } from "react";
import { useEffect } from "react";
import { Div } from "../AdminPanelComponents/Pages/Orders/Orders";
import { FoodContainer } from "../AdminPanelComponents/Pages/Orders/Orders";
import { Food } from "../AdminPanelComponents/Pages/Orders/Orders";
import { Quantity } from "../AdminPanelComponents/Pages/Orders/Orders";
import { Toppings } from "../AdminPanelComponents/Pages/Orders/Orders";
import { ToppingContainer } from "../AdminPanelComponents/Pages/Orders/Orders";
import {T} from "../Language/translate";
import moment from "moment";
const database = window.firebase.database();
var _ = require('lodash');

const Dialog = styled.div`
position: fixed;
  width: 350px;
  height:350px;
  overflow-y: scroll;
  padding:20px;
  background-color: white;
  margin-bottom:50px;
  z-index: 57 !important;
  margin:auto;
  padding:10px;
  display: flex;
  flex-direction: column;
  border-radius:5px;
  // Add this part
  @media (max-width: 700px) {
    
    overflow-y: scroll;
    overflow-x:hidden;
  
    width: 90%;
 
    
  }
`;
const OrdersLabel = styled.div`
background-color: rgba(255, 255, 255, .8);
padding: 5px;
@media(max-width: 400px){ 
    font-size 14px; 
  } `;
 const Wrapper = styled.div`
z-index: 57 !important;
position:fixed;
width:100%;
height:98%;
left:0;
bottom:0;
  display: flex;
  justify-content: center;
  align-items: center;

`;

const DialogShadow = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  left:0px;
  background-color: black;
  opacity: 0.7;
  z-index: 56 !important;
`;
export const OrderContainer = styled.div`
@import url('https://fonts.googleapis.com/css2?family=Maven+Pro&display=swap');
font-family: 'Maven Pro', sans-serif;
position: relative;
height:  ${props => props.height}; 
width: 95%;
font-size:20px;
padding : 10px ; 
margin: 5px;
border:1px solid;
border-color: lightgray; 
border-radius:10px;
transition-property: box-shadow margin-top ;
transition-duration: .2s;
box-shadow : 0px 0px 2px 0px grey;
padding-bottom: 10x;
`;

export const Close = styled.div`
height: 30px;
width: 30px;

display :flex;
align-items:center;
justify-element:center;
position:absolute;
background-image:url("/img/close.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border: none; 
margin:10px;
top:0;
right:0;
font-size: 18px;
font-weight:bold;
 &:hover{
    cursor: pointer;
    opacity:0.7;
 }

`;
const Mini = styled.img`
height: 20px;
width: 20px;

display :flex;
align-items:center;
justify-element:center;
position:absolute;
background-position: center;
background-repeat: no-repeat;
background-size: cover;

margin:3px;
top:0;
left:0;
font-size: 18px;
font-weight:bold;
 &:hover{
    cursor: pointer;
    opacity:0.7;
 }
 
`;
const Btn = styled.button`
height: 30px;
width: auto;
top:10px;
font-size:12px;
right:5px;
display :flex;
align-items:center;
justify-element:center;
position:absolute;
`;

const Status = styled.span`
position:absolute;
font-size:15px;
font-weight:bold;
top:0;
right:10px;
min-width: 50px;
color:green;
margin: 10px ; 
transition-property: box-shadow margin-top filter;
transition-duration: .2s;
border-radius : 20%;
text-align:center;
padding: 5px;


`;

function ProfileOrdersContainer({orders, setOrders, loggedIn,lang}){
     
    const ref = database.ref('orders');
  const [foods, setFoods] = useState(null);
  const[H,setH]=useState("50px");
  const[Img,setImg]=useState("/img/plus.png");
  const[ind,setInd]=useState();
  const isOrdersEmpty = foods && foods.length ===0;
  const canShowOrders = foods && foods.length >0;
  var session = window.sessionStorage;
  function PhoneNumber(input) {
    // Regular expression to match only numbers or a plus sign
    var regex = /^[0-9+]+$/;
    
    // Test the input against the regular expression
    return regex.test(input);
  }
   useEffect(()=>{

    PhoneNumber(orders)? ref.orderByChild('phone').equalTo(String(orders)).on('value', (snapshot) => {          
           const data = _.toArray(snapshot.val());
           console.log(data);
          
           setFoods(data);
    }): ref.orderByChild('email').equalTo(orders).on('value', (snapshot) => {          
      const data = _.toArray(snapshot.val());
      setFoods(data);
});
console.log(orders);
console.log(loggedIn)
    return ()=> {
      ref.off('value');
    }
   
  },[]
   )
function HeightFunc(){
  H!="auto" ?setH("auto"):setH("50px");
  H!="auto" ?setImg("/img/mini.png"):setImg("/img/plus.png");
}
    return (
        <>
        <DialogShadow  />
        <Wrapper>
        <Dialog>
        <Close onClick={()=>{setOrders()}}/>
        <UserHeader>{T("60",lang)}  </UserHeader>
       
        {isOrdersEmpty && <OrdersLabel>{T("61",lang)}  </OrdersLabel>}
 {canShowOrders && foods.map((item, index)=> {
  return(
    <>
    {foods ? "":""}
    { item ? 
 <OrderContainer  key={index} height ={index == ind ?H : "60px"}>
  <Mini src={index == ind ?Img:"/img/plus.png"} onClick={()=>{HeightFunc();setInd(index)}}/>
      <Div>{"#"+item.Id}</Div>
      {item.timeToready ? <Div>{"Temps : "+item.timeToready+" Min"}</Div>: <Div>{"Temps : En cours"}</Div> } 
      
     {(H == "auto" &&index == ind)&&<Div>{moment.unix(item.date).format("DD-MM-YY HH:mm")}</Div>} 
      <Status>{item.status =="sent" ?T("62",lang):item.status =="preparing"? T("63",lang):item.status =="ready"? T("64",lang):item.status =="delivery"?T("140",lang):T("65",lang)}</Status>
      {/*<Btn className="btn btn-primary">Reorder</Btn>*/ }
      { item.order.map((food , index2)=> {
      return (
        <>
        {(H == "auto" &&index == ind)&&<div key={index2}>
      <FoodContainer>
        
      <Quantity>{food.Qu}</Quantity>
      <Quantity>X</Quantity>
      <Food>{food.name}</Food>
      </FoodContainer>
      <Toppings>
      {food.toppings && food.toppings.map((topping, ind)=>{
        return (
            <ToppingContainer key={ind}>{topping}</ToppingContainer>
        )
      })
      }
      </Toppings>
      {food.foodType &&<ToppingContainer>{food.foodType}</ToppingContainer>}   
                  {food.category === "Tacos" && <ToppingContainer>{food.viande }</ToppingContainer>} 
                  {food.selectedChoice &&<ToppingContainer>{food.selectedChoice}</ToppingContainer>} 
                  {food.drinks &&<ToppingContainer>{food.drinks}</ToppingContainer>} 
                  {food.sauces &&<ToppingContainer>{food.sauces}</ToppingContainer>} 
      </div>}
      </>
      )})
      }
      </OrderContainer> : ""}

      </>
    
  )} 
 )}
           </Dialog>
           </Wrapper>
        </>
    )

}



export function ProfileOrders(props){
    if (!props.orders) return null;
    return <ProfileOrdersContainer {...props}/>
}