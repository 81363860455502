import React from "react";
import { Router } from "react-router-dom";
import styled from "styled-components";
import MenuBar from "../AdminPanelComponents/Nav";
import {  Routes, Route } from "react-router-dom";
import Orders from "../AdminPanelComponents/Pages/Orders/Orders";
import Schedule from "../AdminPanelComponents/Pages/Schedule/Schedule";
import Performance from "../AdminPanelComponents/Pages/Performance/Performance";
import Frontend from "../AdminPanelComponents/Pages/Frontend/Frontend";
import Accounts from "../AdminPanelComponents/Pages/Accounts/Accounts";

import { History } from "../AdminPanelComponents/Pages/History/History";
import { LanguagePanel } from "../AdminPanelComponents/Pages/Language/Language";
import Support from "../AdminPanelComponents/Pages/Support/Support";
import {Howl, Howler} from 'howler';
import beepSfx from '../Sound/ring.mp3';
import { Footer } from "./Footer";
import { usePanelOpen } from "../Hooks/usePanelOpen";
import { usePanelActive } from "../Hooks/usePanelActive";
import Items from "../AdminPanelComponents/Pages/Products/pages/Items/Items";
import Categories from "../AdminPanelComponents/Pages/Products/pages/Categories/Categories";
import Options from "../AdminPanelComponents/Pages/Products/pages/Options/Options";
import Zip from "../AdminPanelComponents/Pages/Products/pages/Delivery/Zip";
import Fees from "../AdminPanelComponents/Pages/Products/pages/Fees/Fees";
import Sales from "../AdminPanelComponents/Pages/Products/pages/Sales/Sales";
import { Percentage } from "../AdminPanelComponents/Pages/Coupons/pages/Percentage/Percentage";
import { Deliveries } from "../AdminPanelComponents/Pages/Coupons/pages/Delivery/Deliveries";
import { XY } from "../AdminPanelComponents/Pages/Coupons/pages/XY/xy";
import ManageAdmins from "../AdminPanelComponents/Pages/Admins/MangeAdmins";
var _ = require('lodash');
var sound = new Howl({
  src: beepSfx,
  loop: true,
  volume: 0.5,
  onend: function() {

  }
});
// Get a database reference
const database = window.firebase.database();
const Div= styled.div`
margin:auto;
font-size:32px;
font-weight:700;
width:fit-content;
`;

const Wrapper = styled.div`
display:flex;

height:100vh;
`;
 export function AdminPage({loggedIn,messages,Language,admins,NotifyMsg}) {
  const panelOpen = usePanelOpen();
  const panelActive = usePanelActive();
  const ref = database.ref('orders');
  var data;
    ref.orderByChild('status').equalTo('sent').on('value', (snapshot) => {          
           data = _.toArray(snapshot.val());
         
          if(data.length === 1){
            sound.play();
          }
          
       
    });
  
    const isAdmin = loggedIn&& admins.some(u=>u.email ===loggedIn.email&&u.role==="admin");



   return (
     <> 
     
  {loggedIn && isAdmin ?
  <>
 
     <MenuBar {...Language}{...panelOpen} {...panelActive}{...NotifyMsg}/>
    
     <Routes>
      <Route  exact path='orders'  element={<Orders sound={sound} {...Language}{...panelActive}{...panelOpen} />}  />
      <Route path='products//*' element={<Items {...Language}{...panelActive}{...panelOpen}/>}  />

      <Route  exact path='items'  element={<Items {...Language}{...panelActive}{...panelOpen}/>}  />
     <Route path='categories' element={<Categories{...Language}{...panelActive}{...panelOpen}/>} />
     <Route path='options' element={<Options{...Language}{...panelActive}{...panelOpen}/>} />
     <Route path='zip' element={<Zip{...Language}{...panelActive}{...panelOpen}/>} />
     <Route path='fees'element={<Fees {...Language}{...panelActive}{...panelOpen}/>} />
     <Route path='sales'   element={<Sales{...Language}{...panelActive}{...panelOpen}/>} />

      <Route path='schedule' element={<Schedule {...Language}{...panelActive}{...panelOpen}/>} />
      <Route path='performance' element={<Performance {...Language}{...panelActive}{...panelOpen}/>} />
      <Route path='frontend' element={<Frontend {...Language}{...panelActive}{...panelOpen}/>} />
      <Route path='accounts' element={<Accounts {...Language}{...panelActive}{...panelOpen}/>} />
      <Route path='coupons//*' element={<Percentage {...Language}{...panelActive}{...panelOpen}/>} />

      <Route  exact path='percentage'  element={<Percentage {...panelActive}{...panelOpen}/>}  />
     <Route path='deliveries' element={<Deliveries {...panelActive}{...panelOpen}/>} />
     <Route path='xy' element={<XY {...panelActive}{...panelOpen}/>} />

      <Route path='history' element={<History {...Language}{...panelActive}{...panelOpen}/>} />
      <Route path='language' element={<LanguagePanel {...panelActive}{...panelOpen}/>} />
      <Route path='support' element={<Support {...messages} {...Language}{...panelActive}{...panelOpen}/>} />

      <Route path='admins' element={<ManageAdmins {...Language}{...panelActive}{...panelOpen}/>} />
     </Routes>
     <Footer {...Language}/>
     </>
                : <Wrapper><Div>Not accessible for you 😏</Div></Wrapper>}

    
      
    </>
   )
 }
 