import styled from "styled-components";
import { Title } from "../Styles/title";

export const FoodGrid = styled.div`
display: grid;
align-items: center;
justify-items: center;
grid-template-columns: ${props => props.grid} ;
gap: 30px;
padding-bottom:40px;
min-height:200px;
width: 100%;
margin-top:auto;
@media(max-width: 1700px){ 
  grid-template-columns: ${props => props.grid} 
}
@media(max-width: 1390px){ 
gap: 20px;
  grid-template-columns:${props => props.grid} ; 
}
@media(max-width: 1350px){ 
  grid-template-columns: ${props => props.grid}; 
}
@media(max-width: 1160px){ 
  grid-template-columns: 1fr ; 
}
@media(max-width: 780px){ 
  
  margin-top:-5px;
    grid-template-columns: 1fr ; 
   
  }
  @media(max-width: 400px){ 
   

    }
`;
export const FoodLabel = styled(Title)`
width:min-content;

border-radius:0px 5px 5px 0px;
position:absolute;
background-color: rgba(255, 255, 255, .8);
padding: 5px;
@media(max-width: 400px){ 
    font-size 8px; 
  } `;

export const Food = styled.div`
position:relative;
width:200px;
height: 200px;
text-align:center;
font-size:20px;
transition-property: box-shadow margin-top filter;
transition-duration: .2s;
border:1px solid;
border-color: lightgray; 
border-bottom-right-radius: 30px;
box-shadow : 0px 0px 2px 0px grey;
padding-bottom: 10x;
background-image: ${({img})=> `url(${img});`}
background-position: center;
background-size: contain ;
background-repeat: no-repeat;
@media(max-width: 1390px){ 
  width:130px;
  height: 180px;
}
@media(max-width: 1160px){ 
  width:150px;
  height: 200px;}
@media(max-width: 780px){ 
width:150px;
height: 150px;
}
@media(max-width: 700px){ 
width:160px;
height: 180px;
}
@media(max-width: 450px){ 
  width:160px;
  height: 180px;
  }
@media(max-width: 380px){ 
width:100px;
height: 120px;
}


`;


