import React,{useState,useEffect} from 'react';
import { OrderBtn } from '../Styles/colors';
import styled from "styled-components";
import {T} from "../Language/translate";
const QuantityInputStyled = styled.input`
font-size: 18px;
width : 24px;
text-align: center;
border: none;
outline: none;
`;
const IncreamentContainer = styled.div`
display: flex;
height: 24px;
margin-top:18px;
margin-bottom:10px;
margin-left:auto;
margin-right:auto;
  @media(max-width: 700px){ 
    margin-top:0px;
  
    
   }
`;
const UserHeader = styled.div`
margin-top: 10px;
margin-bottom: 10px;
font-weight:bold;
display:flex;
text-align:center;
align-items: center;
justify-content: center;


`;
const IncreamentButton = styled.div`
width:23px;
color: ${OrderBtn};
font-size: 23px;
text-align: center;
cursor: pointer;
line-height: 23px;
margin: 0px 10px;
border: 1px solid ${OrderBtn};
${({disabled})=> disabled &&
`opacity: 0.5;
pointer-events: none;`}
&:hover {background-color: #ffe3e3;
}
`;
const Dialog = styled.div`
position: fixed;
  width:auto;
  height:auto;
  background-color: white;
  margin:auto;
  z-index: 55;
padding:5px;

  border-radius:5px;
  // Add this part
  @media (max-width: 700px) {
    
  
    height:auto;
    width: 90%;
 
    z-index: 55;
  }
  @media (max-height: 670px) {
  
  }
`;
 const DialogShadow = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
 top:0;
 left:0;
  background-color: black;
  opacity: 0.8;
  z-index: 50;
`;
const Btn = styled.button`
width:250px;
margin:10px;
background-color:${OrderBtn};
color:white;
white-space: initial;
text-align:center;
font-size: 16px;
font-weight:bold;
 &:hover{
    cursor: pointer;
    opacity:0.7;
 }
 @media (max-width: 700px) {
  width:130px;
  font-size:14px;
}
`;

const Wrapper = styled.div`
z-index: 55;
position:fixed;
width:100%;
height:100%;
left:0; 
  display: flex;
  justify-content: center;
  align-items: center;

`;
const ItemContainer = styled.div`
display:flex;
align-items: center;
margin:auto;
justify-content: center;
flex-direction:column;
border-radius : 10px;
border: ${props=>props.border};
border-color:${props=>props.borderColor};
&:hover{
  cursor: pointer;
  opacity: 0.9;
  transform: scale(1.05);

}
@media (max-width: 700px) {
  width:124px;
}

`;
const BtnContainer = styled.div`
bottom:0;
display:flex;
flex-direction:row;
margin:auto;
@media (max-width: 700px) {
  flex-direction:row;
 
}

`;
const Item = styled.div`
width:145px;
height:150px;
background-image: ${({img})=> `url(${img});`}
background-position: center;
background-size: cover ;
border-radius : 5px 5px 0 0;
cursor: pointer;
@media (max-width: 700px) {
  width:100px;
height:90px;
}
@media (max-height: 670px) {
  width:120px;
  height:90px;
}
`;

const Text = styled.div`
font-size:16px;
font-weight:bolder;
color:gray;
text-align:center;
`;
const FoodGrid = styled.div`
display: grid;
grid-template-columns: repeat(3, 150px);
gap:5px;
margin-top:5px;
padding-bottom:20px;
min-height:200px;
width: 100%;
margin-top:auto;
justify-content: space-around;
@media(max-width: 1390px){ 


gap: 20px;
  grid-template-columns: repeat(3, 150px); ; 
}

@media(max-width: 780px){ 
  
  margin-top:-5px;
    grid-template-columns: repeat(3, 150px); ; 
   
  }
  @media(max-width: 500px){ 
    overflow-y: scroll;
    height:300px;
    grid-template-columns: repeat(2, 150px); ; 

    }
`;

const Footer = styled.div`
height: 150px;
margin-top:10px;
display: flex;
flex-direction:column;
justify-content: center;
border-radius:5px;
@media (max-height: 670px) {

`;
const db = window.firebase.firestore();
function CrossSellContainer({crossSell,setCrossSell,lang,orders,setOpenOrderDialog,setOrders,total,setTotal}) {
    function close() {
      console.log("clicked to close")
        setCrossSell();
 }
  const [items,setItems]=useState([]);
  const [quantity,setQuantity]=useState(1);
  const [selected,setSelected]=useState(0);
  const[selectedItem,setSelectedItem]=useState();
  const canShowItems = items && items.length !==0 && true;
  
  useEffect(()=>{
    setItems([]);
    db.collection('sales').doc('sales1').get().then((doc)=>{
      return doc.data().items.map((t)=>{
        console.log("this is happening ::: "+t)
        db.collection('items').where('name','==',t).get().then((doc)=>{return   setItems(items=>[...items,doc.docs[0]])});
      })
     
     })
     
  },[]);
  const order = {
    ...selectedItem,
    Qu:quantity,
    toppings: []
};
  function addToOrder(){

    console.log("inside add "+orders);
    setOrders([...orders, order]);
    setTotal(total+(order.price*order.Qu));
    /******/
    const docRef = db.collection("items").where("name","==",order.name);

    docRef.get().then((querySnapshot)=>{
     querySnapshot.forEach((doc)=>{
      doc.ref.update({
        quantity: doc.data().quantity >0 ?  doc.data().quantity - order.Qu : 0
      })
     })
    });
     /*****/
   
   
}
    return (
        <>
       
        <DialogShadow onClick={()=>close()} />
        <Wrapper>
        <Dialog>
       <UserHeader>{T("143",lang)}</UserHeader>
       

    <FoodGrid>
    {canShowItems && items.map((item,index)=>{
      
    
      return(
        
        <ItemContainer onClick={()=>{setSelected(index);setSelectedItem(item.data());}}
         borderColor={selected===index? OrderBtn:"lightgray"} border={selected===index? "4px solid": "1px solid"}>  
        {console.log(items)}
           <Item img={"https://firebasestorage.googleapis.com/v0/b/aziz-adf05.appspot.com/o/files%2F"+item.data().imgUrl+"?alt=media&token=d02a0ad4-0554-4bc0-be12-d549339e4fdb"}/>
           <Text>{item.data().name}</Text>
           <Text>{item.data().price+" Ft"}</Text>
        </ItemContainer>
      
 
       
        )
       
    })}
  
  
  </FoodGrid>
  <Footer>
  {selected || selected===0 ?<IncreamentContainer>
  
    <div>{T("70",lang)}</div>
    <IncreamentButton onClick={()=> {setQuantity(quantity-1);}} disabled ={quantity===1 }> - </IncreamentButton>
    <QuantityInputStyled value={quantity}/>
    <IncreamentButton onClick={()=> {setQuantity(quantity+1);}} > + </IncreamentButton>
    </IncreamentContainer>:""}
    {selectedItem!=null && <Text>
      {quantity+" x "+selectedItem.name}
    </Text> }
  <BtnContainer>
  <Btn className="btn" disabled={!(selected || selected===0)} onClick={addToOrder} >{T("144",lang)}</Btn>
  <Btn className="btn" onClick={()=>{close();setOpenOrderDialog(true);}}>{T("145",lang)}</Btn>
  </BtnContainer>
  </Footer>
        </Dialog>
        </Wrapper>
        </>
    );
};

export function CrossSell(props){
    if (!props.crossSell) return null;
    return <CrossSellContainer {...props}/>
}