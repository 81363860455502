import React from "react";
import styled from "styled-components";
import { useState } from "react";
import ToggleButton from "react-toggle-button";
import TimePicker from 'react-time-picker';
import { Dialog,DialogShadow ,Wrapper } from "../../../Products/pages/Items/AddItem";


const AccountsDiv = styled.div`
 
  width: 100%;
  height: auto;
  
  margin-left:auto;
  margin-right:auto;
  @media screen and (max-width: 1200px) {
    width: 80%;
  }
  @media screen and (max-width: 1050px) {
    width: 90%;
  }
`;
const AccountsRows = styled.div`
  display: flex;
  flex-direction: column;
`;
const AccountGrid = styled.div`
display:flex;
justify-content: center;
align-items: center;
text-align: center;
  height: 80px;
  border: solid 1px;
  border-color: gray;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1.2fr 1fr 0.7fr 0.2fr 0.01fr;
  gap: 15px 15px;
  margin: 10px;
  @media (max-width: 630px) {
    height: 180px;
    grid-template-columns: 1fr ;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  margin-left:auto;
  margin-right:auto;

`;
const H = styled.h3`
text-align:center;
`;
const Input = styled.input`
width:80px;
`;
const ButtonStyled = styled.button`
max-height:40px;
text-decoration: none;
margin: 10px;
&:hover{
    opacity: 1.2;
    padding-top: 3px;
}
`;
export const ButtonContainer = styled.div`
padding-top:30px;
margin-right:20px;
margin-left: auto;
display:flex;
flex-direction:row;
@media (max-width: 600px) {
  margin-right:auto;
  margin-left: auto;
}
`;

const db = window.firebase.firestore();



function EditDeliveryContainer({ openItem, prop,setData,setOpenItem}) {



//use state for toggle 
const [toggle, setToggle] = useState(openItem.free);
//use state for times 
const [startTime, setStartTime] = useState(openItem.startTime);
const [endTime,setEndTime] = useState(openItem.endTime);
//use state for percentage 
const [percentage,setPercentage] = useState(openItem.percentage);
  function handleSubmit ()  {
    console.log('handel submit');
    const delivery = db.collection("delivery").doc("0");

    delivery.set({
        percentage: percentage,
        free: toggle,
        startTime: startTime, 
        endTime: endTime,
        name : "delivery"
      
       });

     setOpenItem();
  }
  
    return (
<>
        <DialogShadow />
        <Wrapper>
        <Dialog>
        <ButtonContainer>
         <ButtonStyled className="btn btn-primary"  onClick={()=>handleSubmit()} >
           Save 
         </ButtonStyled>
         <ButtonStyled
           className="btn btn-light"
           onClick={() =>setOpenItem()}
         >
           Cancel
         </ButtonStyled>
       </ButtonContainer>
       
        <AccountsDiv>
          <H>Set delivery rules  </H>
          <AccountsRows>
     
         
                    <>
                      <AccountGrid>
                     
                        <Item>
                          
                        <TimePicker
                      value={startTime} 
                      onChange={(e)=>setStartTime(e)}
                       disableClock='true'/>
                
                 </Item>
                        <Item>
                           <TimePicker
                       value={endTime} 
                       onChange={(e)=>setEndTime(e)}
                       disableClock='true'/>
                       </Item>
                       <Item>
                        <ToggleButton
                          name="test"
                          value={toggle}
                          onToggle={()=>!toggle ? setToggle(true):setToggle(false)}
                          
                          colors={{
                            active: {
                              base: "#1578F1",
                            },
                            inactive: {
                              base: "gray",
                            },
                          }}
                        />
                        </Item>
                         <Item>
                         
                          <Input  type="number" 
                          value={percentage}
            
                          onChange={(e)=>setPercentage(e.target.value)}
                          />
                          
                          </Item>
                      </AccountGrid>
                    </>
              
               
            
          </AccountsRows>
        </AccountsDiv>
      
      </Dialog>
      </Wrapper>
      </>

    );
}


    export function EditDelivery(props){
        if (!props.openItem) return null;
        return <EditDeliveryContainer {...props}/>
    }
    
