

const db = window.firebase.firestore();
var Languages = null
db.collection('Language').get().then((data) => { return Languages = data.docs[0].data() });
export function T(text,lang){
 


if( Languages == null){
 
  setTimeout(T, 1000)
}
else{

  if (lang == 'En'){
  
    return  Languages.En[text] 
 
     } 
 else 
 {
 
   return  Languages.Hu[text] 
   }
}


}
