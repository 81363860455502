import React from "react";
import ReactLoading from "react-loading";
import styled from 'styled-components';
 const Shadow = styled.div`
position : absolute ;
height : 40px ;
width : 100%;
top : 0px;
left:0px;
background-color : #F8F1DE ; 
z-index : 50;

`;
const Container =styled.div`
z-index : 55;
position:fixed;
left:30%;

`;
export default function NavLoading() {
  return (
    <>
    <Shadow>
    <Container>
      <ReactLoading type="bars" color="#f44336"
        height={50} width={50} />
    </Container>
    </Shadow>
   
      
   

    </>
  );
}