import React from "react";
import styled from 'styled-components';
import { NavbarBackgroundColor } from "../Styles/colors";
import { UserForm } from "./userForm";
const DialogShadow = styled.div`
position: fixed;
height: 100%;
width: 100%;
top:0;
left:0;
background-color: black;
opacity: 0.7;
z-index: 50;

`;

 export const SignupDialog = styled.div`
 position: fixed;
 width: auto;
 height:auto;
 background-color: white;
 margin-bottom:50px;

 z-index: 55;
margin-left:auto;
margin-right:auto;
margin-top:-80px;
padding:10px;
 display: flex;
 flex-direction: column;
 border-radius:5px;
 // Add this part
 @media (max-width: 400px) {
   
   overflow-y: scroll;
   height:100%;
   width: 100%;

   z-index: 55;
 }
`;
 const UserHeader = styled.div`
margin-top: 10px;
display:flex;
text-align:center;
align-items: center;
justify-content: center;

`;

 const UserFooter = styled.div`
  height: 60px;
  display: flex;

  margin-top: 50px;
  justify-content: center;
`;
const ConfirmButton = styled.div`
  margin: 10px;
  color: white;
  height: 40px;
  width: 200px;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  background-color: ${NavbarBackgroundColor};
  opacity: 0.7;
  z-index: 4;
  ${({ disabled }) =>
    disabled &&
    `
opacity: .5;
background-color: grey;
pointer-events: none;
`}
`;
const Wrapper = styled.div`
z-index: 55;
position:fixed;
width:100%;
height:100%;
left:0;
  display: flex;
  justify-content: center;
  align-items: center;

`;

const db = window.firebase.firestore();
function BannerDialogContainer({bannerUser, setBannerUser, loggedIn,logout}){
    function SignOUt(){
        logout();
        setTimeout(function() {
          setBannerUser();
          },500)
         
      
       }
      
    return (
        <>
        <DialogShadow  />
        <Wrapper>
        <SignupDialog>
        <UserHeader>{'Hello '+bannerUser}</UserHeader>
        <UserHeader>Your Account is Banned :/</UserHeader>
        <UserFooter>
        
        <ConfirmButton onClick={SignOUt}>Log out</ConfirmButton>
      </UserFooter>
       
        </SignupDialog>
        </Wrapper>
     
        </>
    )

}



export function BannerDialog(props){
    if (!props.bannerUser) return null;
    return <BannerDialogContainer {...props}/>
}