import React from "react";
import styled from "styled-components";
import { TickerBackgroundColor } from "../Styles/colors";
import { TickerLyric } from "../Styles/colors";
import { useState ,useEffect } from 'react';
import ModelLoading from "../Loading/ModelLoading";
import "../app.css"


const Wrapper = styled.div`
position:relative;

box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
width : ${props=>props.width};
overflow: hidden; 
color:${TickerLyric};
background:  ${TickerBackgroundColor};
@media (max-width: 1200px) {
 
}
@media (max-width: 1115px) {
 
  width :100% ;
}
@media (max-width: 1098px) {

  width :100% ;
}
@media (max-width: 1000px) {
 
  width :100% ;
}
@media (max-width: 986px) {
  
  width :100% ;
}
@media (max-width: 955px) {
 
  width :100% ;
}
@media (max-width: 880px) {
 
  width :100% ;
}
@media (max-width: 700px) {

    width :100% ;
}
  
`;

export function Ticker(){
    const db = window.firebase.firestore();
    const [data , setData] = useState();
    const canShowText = data && true;
 
    useEffect(()=>{ 
      db.collection('ticker').onSnapshot((data)=>{return  setData(data.docs)});
      
      },[]);
    return (
      <>
{!canShowText && <ModelLoading />}
<Wrapper width={canShowText?"100%":"calc(100% - 340px)"}>
    <div className="hmove">
    { canShowText ? data.map((item,index)=>{
        return(
  <div className="hitem" key={index}>{item.data().text}</div>
  )
})
  : "Loading ..."}

</div>
</Wrapper>
</>

    )

}