import React, { useEffect, useState } from 'react';
import styled from "styled-components";
var _ = require('lodash');
const database = window.firebase.database();
const ref = database.ref('orders');

const Item = styled.div`
  display: flex;
  flex-direction: row;
  width:300px;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  margin-left:10px;
  margin-right:10px;
  @media screen and (max-width: 470px) {
    text-align:left;
  }
  
`;
function OrdersSumWrapper({ item }) {
  const [loading, setLoading] = useState(true);
  const [orderCount, setOrderCount] = useState(null);

  useEffect(() => {

    async function fetchOrders(props) {
        return new Promise((resolve, reject) => {
          ref.orderByChild("email").equalTo(props.email).once('value', (snap) => {
            const data = snap.val();
            resolve(data);
          }, (error) => {
            reject(error);
          });
        });
      }
    async function fetchData() {
      try {
        // Simultaneously fetch data and set loading state
        setLoading(true);
        const response = await fetchOrders({ email: item.data().email });
        
        if (response) {
          const data2 = _.toArray(response);
          setOrderCount(data2.length);
        }
        
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    }

    fetchData();
  }, [item]);

  return (
    <Item>
      orders : {loading ? "Loading..." : orderCount !== null ? <>{orderCount}</> : "No Data"}
    </Item>
  );
}

export default OrdersSumWrapper;