import React from 'react';
import styled from 'styled-components';
import { OrderBtn } from '../Styles/colors';
import { Title } from '../Styles/title';
import {T} from "../Language/translate";
const QuantityInputStyled = styled.input`
font-size: 18px;
width : 24px;
text-align: center;
border: none;
outline: none;
`;
const IncreamentContainer = styled(Title)`
display: flex;
height: 24px;
margin-top:18px;
margin-bottom:10px;
margin-left:auto;
margin-right:auto;
  @media(max-width: 700px){ 
    margin-top:0px;
  
    
   }
`;

const IncreamentButton = styled.div`
width:23px;
color: ${OrderBtn};
font-size: 23px;
text-align: center;
cursor: pointer;
line-height: 23px;
margin: 0px 10px;
border: 1px solid ${OrderBtn};
${({disabled})=> disabled &&
`opacity: 0.5;
pointer-events: none;`}
&:hover {background-color: #ffe3e3;
}
`;
export function QuantityInput({quantity,openFood,lang,applied}){
   const isEditing = openFood.index > -1;
    return(
 <IncreamentContainer>
  
    <div>{T("70",lang)}</div>
    <IncreamentButton onClick={()=> {quantity.setValue(quantity.value-1);}} disabled ={quantity.value===1||(isEditing&&!applied) }> - </IncreamentButton>
    <QuantityInputStyled {...quantity}/>
    <IncreamentButton onClick={()=> {quantity.setValue(quantity.value+1);}} disabled ={quantity.value===Number(openFood.quantity)}> + </IncreamentButton>
    </IncreamentContainer>
    );
}