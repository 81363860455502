import React, { useRef } from "react";
import styled from 'styled-components';




const DialogShadow = styled.div`
position : fixed ;
height : 100% ;
width : 100%;
top : 0px;
left:0px;
background-color : black ; 
opacity : 0.9;
z-index : 55;

`;
const Dialog = styled.div`
position: relative;
display: flex;
flex-direction: column;
align-items:center;
justify-content: center;
top:180px;
margin-left:auto;
margin-right:auto;
height: auto;
width: auto;

 background-color: white;
 border-radius : 7px;
 z-index: 60;


 

 // Add this part
 @media(max-width: 400px){ 
    top:80px;
   width: 100%; 
   left: 0px; 
   z-index: 55; 
 }
`;
const Button = styled.button`
  margin: 10px;
  height: 40px;
  width: 150px;
  text-align: center;

`;
const Container = styled.div `
display:flex;
flex-direction:row
margin-left:auto;
margin-right:auto;
`;
const H5 = styled.h5`
margin-top:10px;
text-align:center;
`;


export function ConfirmPanel({setConfirmPanel,UpdateStatus,setToggel}){

    return (
        <>
        <DialogShadow />
        <Dialog >
            <H5>Are you sure to Cancel ?</H5>
        <Container>
            <Button className="btn btn-info" onClick={()=>{UpdateStatus("canceled");setConfirmPanel(false);setToggel(false)}}>Yes</Button>
            <Button className="btn btn-danger" onClick={()=>setConfirmPanel(false)}>No</Button>
        </Container>
        </Dialog>
    
        </>
    )
}