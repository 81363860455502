import React from 'react';
import styled from "styled-components";
import { useState } from "react";
import { useEffect } from "react";
import {CreateZip} from "./AddZip"
import { AddHeader, ButtonContainer,ButtonStyled,Bar} from '../Items/Items';
import { ConfirmDelete } from './Delete';
import { EditZip } from './EditZip';
const Container = styled.div`
position:fixed;
top:100px;
right:0;
width:${(props) => props.width};
font-size : 20px;
@media screen and (max-width: 995px) {
    margin-left:10px;
}
`;
const ItemGrid = styled.div`
display: grid;
position:fixed;
height:65%;
width:100%;
overflow-y: scroll;
grid-template-columns: 310px 310px 310px ;
gap: 5px;
@media screen and (max-width: 1370px) {
grid-template-columns: 1fr 1fr  ;
gap: 8px;
}

@media screen and (max-width: 925px) {
    display: grid;
    grid-template-columns: 1fr ;
    gap: 5px;
  }
  @media screen and (max-width: 400px) {
    display: grid;
    grid-template-columns: 1fr  ;
    gap: 5px;
        
    }


`;
 const Item = styled.div`
 width:300px;
display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr ;
height: 60px;
text-align:center;
font-size:20px;
background-position: center;
background-size: cover ;
padding: 10px;
margin: 5px;
transition-property: box-shadow margin-top filter;
transition-duration: .2s;
border:1px solid;
border-color: lightgray; 
border-radius : 10px;
box-shadow : 0px 0px 2px 0px grey;
padding-bottom: 10x;
white-space: nowrap;

&:hover{
    cursor: pointer;
    opacity:0.7;
    margin-top: 0px;
    margin-bottom : 5px;
    box-shadow : 0px 0px 10px 0px grey;
}
@media screen and (max-width: 400px) {
    width:300px;
        
    }
    @media screen and (max-width: 350px) {
      
        width:280px;
     }
`;
const Edit = styled.div`
height: 38px;
width: 38px;
display :flex;
align-items:center;
justify-element:center;
background-image:url("/img/Edit.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border: none; 
margin: auto;
font-size: 18px;
font-weight:bold;
 &:hover{
    cursor: pointer;
    opacity: 1.5;
    background-color:lightgray;
    border-radius : 7px;
 }
`;
const Remove = styled.div`
height: 40px;
width: 40px;
display :flex;
align-items:center;
justify-element:center;
background-image:url("/img/Remove.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border: none; 
margin: auto;
font-size: 18px;
font-weight:bold;
 &:hover{
    cursor: pointer;
    opacity: 1.5;
    background-color:lightgray;
    border-radius : 7px;
 }
`;
const Div = styled.div`
     padding-top:2px;
     display:flex;
     text-align:center;
     
`;

const H2 = styled.h2`
@media screen and (max-width: 995px) {
   
    margin-top:20px;
}

`;
const db = window.firebase.firestore();

function Zip({active}) {
  //use state to edit data 
  const [openItem, setOpenItem] = useState();
//use sate to open panel for adding item 
const [openPanel, setOpenPanel ] = useState(false);
//use state to read data 
const [data , setData] = useState();
///
const [openDelete,setOpenDelete]=useState();
const isZipEmpty = data && data.length ===0;
const canShowZip = data && true;
useEffect(()=>{ 
db.collection('zip').onSnapshot((data)=>{return  setData(data.docs)});

},[]);



    return (
        <Container width={ active ? "calc(100% - 220px)" : "calc(100% - 40px)"}>
            <EditZip openItem={openItem} setData={setData} setOpenItem={setOpenItem} />
            {openPanel && <CreateZip openPanel={openPanel} setOpenPanel={setOpenPanel}  setData={setData}/>}
            <ConfirmDelete openDelete={openDelete} setData={setData} setOpenDelete={setOpenDelete} collection="zip" />
            <AddHeader>
            <H2>Zip Codes</H2>  
            <ButtonContainer>   
        <ButtonStyled className='btn btn-primary' onClick={()=>openPanel? setOpenPanel(false): setOpenPanel(true)} >ADD</ButtonStyled>
    </ButtonContainer>
            </AddHeader>
            <Bar/>
          
            <ItemGrid>
            {isZipEmpty  && <Div>No Zip code to display</Div>}
            { canShowZip ? data.map((item)=>{
                return (
               <>
                    <Item>
                    <Remove onClick={()=>setOpenDelete(item.data())}/>
                        <Div>{item.data().code}</Div>
                        <Div>{item.data().price+" Ft"}</Div>
                    <Edit onClick={()=>setOpenItem(item.data())} />
                    </Item>
                    </>
                )} 
                ) : <Item><div>Loading ...</div></Item>}
            
            </ItemGrid>
          
      
        </Container>
    );
}

export default Zip;