import React,{useState} from "react";

import styled from "styled-components";


import { NavbarBackgroundColor } from "../Styles/colors";
import "../app.css";
const Container = styled.div`
position:fixed;
bottom:0;
display:flex;
flex-direction:row;
margin-top:-5px;
background-color: #060b26;
height: 40px;
width : 100% ;
@media(max-width: 700px){ 
  height:60px;
    width: 100%; 
    margin: 0px; 
  }
z-index:5;
`;
const LangContainer = styled.div`
display: flex;
width:100px;
flex-direction:row;
justify-content:center;
align-items:center;
color : #F8F1DE;
margin-left:auto;
margin-right:10px;
`;



export function Footer({lang,setLang}) {
 
  


    return (
      <> 
    
   
     
 
     
      <Container>
      <LangContainer>
      <select className="form-control" style={{"backgroundColor":"transparent","color":"white"}}
           
           value={lang}
           onChange={e=>{setLang(e.target.value); }}
           >
               
                      <option value='Hu' style={{"color":"black"}}> Fr</option>
                      <option value='En'  style={{"color":"black"}}> En</option>
                   
               
              </select>
      </LangContainer>
      </Container>
      </>
   )
 }
