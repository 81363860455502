import React from 'react'
import styled from 'styled-components'
const Label = styled.label`
position:fixed;

z-index:30;
flex-direction:column;
width:25px;
height:30px;
cursor:pointer;
`;
const Div = styled.div`
width: 25px;
height: 3px;
background-color: white;
margin: 6px 5px;
`;
export default function HamButton({active,set}) {
  return (
    
    <Label for="check"  onClick={()=>{ set(!active);console.log(active)}}>

<Div></Div>
<Div></Div>
<Div></Div>
  </Label>

  )
}
