import React from "react";
import styled from "styled-components";
import ToggleButton from "react-toggle-button";
import { useState, useEffect } from "react";
import useForm from "../../../Products/pages/Hooks/useForm";
import moment from "moment";
import { Bar , ButtonContainer } from "../../../Products/pages/Items/Items";
import { Dialog ,DialogShadow, Wrapper,Label,DialogHeader,Header,ButtonStyled,H2 ,H4R,H4G ,Notice 
  ,InputContainer } from "../../../Products/pages/Items/AddItem";







// function for required fields 
function isRequired(value) {
  return value != null && value.trim().length > 0;
}
function DateisRequired(value) {
  return value != null ;
}
function NumericalValue(value) {
  return !isNaN(value);
}

function ValueInterval(value) {
   
    return !(value <0 || value>100);

  } 




export function CreatePerQuantity({ openPerQuantity, setOpenPerQuantity, prop, setData }) {

  


  //db
  const db = window.firebase.firestore();
  //use state for categories 
  const [items, setItems] = useState([]);
  const [checked, setChecked] = useState(false);
//use state for toggle 
const [toggle, setToggle] = useState(false);
 //use state for categories 
 const [categories, setCategory] = useState();
 const canShowCategories = categories && true;  
 useEffect(() => {
    db.collection('Category').get().then((data) => { return setCategory(data.docs) });
  
  }, []);

 

  const [codeUnique, setCodeUnique] = useState(true);
  const [nameUnique, setNameUnique] = useState(true);
  

 
  //Form validation 
  const initialState = {name: '',code: toggle? '':'global', category: 'DESSZERTEK', startDate: moment().format('YYYY-MM-DDTHH:MM:SS'), endDate: moment().format('YYYY-MM-DDTHH:MM:SS'), value: '' ,quantity:'',limit:''};
  const validations = [
    ({ name }) => isRequired(name) || { name: 'Name is required' },
    ({ category }) => isRequired(category) || { category: 'Category is required' },
    ({ code }) => toggle ? isRequired(code) || { code: 'Code is required' } : true,
    ({ startDate }) => DateisRequired(startDate) || { startDate: 'Date is required' },
    ({ endDate }) => DateisRequired(endDate) || { endDate: 'Date is required' },
    ({ quantity }) => isRequired(quantity) || { quantity: 'Quantity is required' },
    ({ limit }) => isRequired(limit) || { limit: 'Limit is required' },
    ({ limit }) => NumericalValue(limit) || { limit: 'Limit is a number' },
    ({ quantity}) => NumericalValue(quantity) || { quantity: 'Quantity is a number' },
    ({ value }) => NumericalValue(value) || { value: 'value is a number' },
    ({ value }) => isRequired(value) || { value: 'Value is required' },
    ({ value }) => ValueInterval(value) || { value: 'Value is between 0 -100' },
    () => toggle ? codeUnique || { code: 'This code already exist ' } : true ,
    () => nameUnique || {name: 'This name already exist '}
  ];

  const { values, isValid, errors, changeHandler} = useForm(initialState, validations, prop);

/// check if name is already exist 
if (values.name != "") db.collection('offer').where("name", "==", values.name).get().then((doc) => { return doc.size ? setNameUnique(false) : setNameUnique(true) })
// check if code is already exist 
if (values.code != "" || toggle) db.collection('offer').where("code", "==", values.code).get().then((doc) => { return doc.size ? setCodeUnique(false) : setCodeUnique(true) })



 


  const handleSubmit = (event) => {
    event.preventDefault();
  console.log(values.code)

    const docRef = db.collection("offer").doc(values.name);
    
   
      docRef.set({
        name: values.name,
        code: values.code,
        category: values.category,
        value: values.value,
        type: 'perquantity',
        quantity: values.quantity,
        limit: values.limit,
        startDate: values.startDate,
        endDate: values.endDate,
        recrusive: checked,
        proType:'per'
      });
    
    
    


    setOpenPerQuantity(false);

  }


  return (
    <>
    {console.log(values.code)}
      <DialogShadow />
      <Wrapper>
      <Dialog>
          <DialogHeader>
            <Header>
              <H2>Create an Offer</H2>
              {(!isValid ) ? <H4R>Fill required fields to add</H4R> : <H4G>You can save the product now</H4G>}
            </Header>
            <ButtonContainer>
              <ButtonStyled disabled={!isValid  } className="btn btn-primary" onClick={handleSubmit}>
                Save
              </ButtonStyled>
              <ButtonStyled
                className="btn btn-light"
                onClick={() =>
                  openPerQuantity ? setOpenPerQuantity(false) : setOpenPerQuantity(true)
                }
              >
                Cancel
              </ButtonStyled>
            </ButtonContainer>
          </DialogHeader>
          <Bar />

         
          <InputContainer className="form-group">
            <label for="name" className="col-sm-2 control-label">Name  </label>
            
              <input type="text" className="form-control" name="name" placeholder="Name"
                value={values.name}
                onBlur={changeHandler}
                onChange={changeHandler}
              />
          

            {errors.name && <Notice>{errors.name}</Notice>}
          </InputContainer>

          <InputContainer className="form-group">
           
            <label for="code" className="col-sm-2 control-label">Code  </label>
            {toggle ?  
              <input type="text" className="form-control" name="code" placeholder="Code"
                value={values.code}
                onBlur={changeHandler}
                onChange={changeHandler}
              />
           : " " } 
            <ToggleButton
                          name="global"
                          value={toggle}
                          onToggle={()=>!toggle ? setToggle(true):setToggle(false)}
                          
                          colors={{
                            active: {
                              base: "#1578F1",
                            },
                            inactive: {
                              base: "gray",
                            },
                          }}
                        />
            {errors.code && toggle && <Notice>{errors.code}</Notice>}
          </InputContainer>

          
          <InputContainer className="form-group">
            <label className="col-sm-3 control-label">Category</label>
            <div >
              <select className="form-control"
              name="category"
                value={values.category}
                onChange={changeHandler}
              >
                {canShowCategories && categories.map((item) => {
                  return (
                    <>
                      <option value={item.data().name}>

                        {item.data().name}
                      </option>
                    </>
                  )
                }
                )}


              </select>
            </div>
          </InputContainer>




          <InputContainer className="form-group">
            <label for="value" className="col-sm-2 control-label">Value  </label>
    
              <input type="number" className="form-control" name="value" placeholder="Value"
                value={values.value}
                onBlur={changeHandler}
                onChange={changeHandler}
              />
           
            {errors.value && <Notice>{errors.value}</Notice>}
          </InputContainer>

          <InputContainer className="form-group">
            <label for="price" className="col-sm-2 control-label">Quantity</label>
      
              <input type="number" className="form-control" name="quantity"  placeholder="Quantity"
               value={values.quantity} 
               onChange={changeHandler} />
         
            {errors.quantity && <Notice>{errors.quantity}</Notice>}
          </InputContainer>
          <InputContainer className="form-group">
          <Label  className="control-label">Limit  </Label>
              <input type="number" className="form-control" name="limit"  placeholder="Limit"
               value={values.limit} 
               onChange={changeHandler} />
           
            {errors.limit && <Notice>{errors.limit}</Notice>}
          </InputContainer>
          <InputContainer className="form-group">
            <label for="date" className="col-sm-2 control-label">Start </label>
          
              {console.log(values.startDate)}
            <input type="datetime-local" 
              name="startDate"               
              value={values.startDate} 
               onChange={changeHandler}
               min={moment().format('YYYY-MM-DDTHH:MM:SS')} max="2024-06-14T00:00"/>
        
            {errors.startDate && <Notice>{errors.startDate}</Notice>}
          </InputContainer>

          <InputContainer className="form-group">
            <label for="date" className="col-sm-2 control-label">End </label>
     
            <input type="datetime-local" 
              name="endDate"               
              value={values.endDate} 
               onChange={changeHandler}
               min={values.startDate} max="2024-06-14T00:00"/>
          
            {errors.endDate && <Notice>{errors.endDate}</Notice>}
          </InputContainer>
          <InputContainer className="form-group">
            <Label  className=" control-label">Recrusive ? </Label>
           
            <input
             type="checkbox"
             checked={checked}
             onChange={e => setChecked(e.target.checked)}
               />
       
          </InputContainer>
      </Dialog>
      </Wrapper>
    </>
  );
}