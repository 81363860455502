import React from "react";
import styled from "styled-components";
import { Bar } from "../Items/Items";
import { useState, useEffect } from "react";
import { Dialog,Wrapper, DialogShadow,DialogHeader,H2,ButtonStyled,InputContainer,Notice,Label } from "../Items/AddItem";
import useForm from "../Hooks/useForm";
import { ButtonContainer } from "../Items/Items";
import { Close } from "../../../Orders/OrderDetails";



// function for required fields 
function isRequired(value) {
  return value != null && value.trim().length > 0;
}
function ZipFormat(value){
    return value.length === 5 ;
}

export function CreateZip({ openPanel, setOpenPanel,prop,setData }) {
  
  //db
  const db = window.firebase.firestore();




  const initialState = { zipCode: '',price:''};
  const validations = [
    ({zipCode}) => isRequired(zipCode) || {zipCode: 'Zip is required'},
    ({price}) => isRequired(price) || {price: 'Price is required'},
    ()=> codeUnique || {zipCode: 'Zip code already exist '},
    ({zipCode})=> ZipFormat(zipCode) || {zipCode: 'zip Code is 5 numbers'}
  ];

  const [codeUnique,setCodeUnique] = useState(true);

  const {values, isValid, errors, changeHandler} = useForm(initialState, validations, prop);

  if(values.zipCode !="") db.collection('zip').doc(values.zipCode).get().then((doc)=>{ return doc.exists? setCodeUnique(false) : setCodeUnique(true) })




  const handleSubmit = () => {
    
   
      
    const docRef = db.collection("zip").doc(values.zipCode);
    try {
   
      docRef.set({
       code: Number(values.zipCode),
       price: Number(values.price)
     
      });
      console.log("submited :=)")
     
    } catch (e) {
      console.log(e);
    }
   
  db.collection('zip').get().then((data)=>{return  setData(data.docs)});
        
  
  setOpenPanel(false);
  
  
  
  }
  const [Err, setError] = useState("");
   const [codeErr,setCodeErr] = useState("");
   const [priceErr, setPriceErr] = useState("");
  
  function showError(){
    if(!(errors.code || errors.price )){
        setError("Code  and price are required");
    }
    console.log('called show error after')
       setCodeErr(errors.zipCode);
       console.log('called show error after' +errors.zipCode)
       setPriceErr(errors.price);
    
    
 }



    return (
        <>
         <DialogShadow />
         <Wrapper>
      <Dialog>
          <Close      onClick={() =>
                  openPanel ? setOpenPanel(false) : setOpenPanel(true)
                }/>
          <DialogHeader>
            <H2> Add Zip Code </H2>
   
          </DialogHeader>
          {!(errors.code || errors.price)  &&<Notice>{Err}</Notice>}
          <Bar />
          <Notice>{codeErr}</Notice>
          <InputContainer className="form-group">
      <Label  className="control-label">Code </Label>
     
      <input type="number" className="form-control" name="zipCode"  placeholder="Zip Code" 
      value={values.zipCode}
      onBlur={changeHandler}
      onChange={changeHandler}/>
    
   
   </InputContainer> 
       <Notice>{priceErr}</Notice>
   <InputContainer className="form-group">
      <Label  className="control-label">Price </Label>
      
      <input type="number" className="form-control" name="price"  placeholder="Price" 
      value={values.price}
      onBlur={changeHandler}
      onChange={changeHandler}/>
    
  
   </InputContainer> 
  <Bar />
   <ButtonContainer>
              <ButtonStyled className="btn btn-primary"  onClick={()=>{!isValid  ?showError():handleSubmit()}}>
                Save
              </ButtonStyled>
              <ButtonStyled
                className="btn btn-light"
                onClick={() =>
                  openPanel ? setOpenPanel(false) : setOpenPanel(true)
                }
              >
                Cancel
              </ButtonStyled>
            </ButtonContainer>
  
      </Dialog>
      </Wrapper>
        </>

    );

}