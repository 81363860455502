import React from "react";
import styled from "styled-components";

const Dialog = styled.div`
position: fixed;
  width: auto;
  height:auto;
  background-color: white;
  margin-bottom:50px;
 
  z-index: 12;
 margin:auto;
padding:10px;
  display: flex;
  flex-direction: column;
  border-radius:5px;
  // Add this part
  @media (max-width: 400px) {
    
    overflow-y: scroll;
    height:auto;
    width: auto;
 
    z-index: 12;
  }
`;

 const Wrapper = styled.div`
z-index: 12;
position:fixed;
width:100%;
height:100%;
left:0;
top:0;
  display: flex;
  justify-content: center;
  align-items: center;

`;


 const DialogShadow = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
 top:0;
 left:0;
  background-color: black;
  opacity: 0.7;
  z-index: 11;
`;
  const ButtonStyled = styled.button`
text-decoration: none;
margin: 10px;
&:hover{
    opacity: 1.2;
    padding-top: 3px;
}
`;

const Close = styled.div`
height: 30px;
width: 30px;
display :flex;
align-items:center;
justify-element:center;
position:absolute;
background-image:url("/img/close.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border: none; 
margin:10px;
top:0;
right:0;
font-size: 18px;
font-weight:bold;
 &:hover{
    cursor: pointer;
    opacity:0.7;
 }
`;
const UserHeader = styled.div`
padding:5px;
margin-top: 30px;
display:flex;
text-align:center;
align-items: center;
justify-content: center;

`;

const db = window.firebase.firestore();
function ConfirmDeleteContainer({ openDelete, collection ,setOpenDelete}) {
    
    function del(){
        db.collection(collection).doc(openDelete.name).delete().then(() => {
            console.log("Document successfully deleted!");
        }).catch((error) => {
            console.error("Error removing document: ", error);
        });
    }
    return (
        <>
         <DialogShadow />
         <Wrapper>
      <Dialog>
      <UserHeader>{'Are you sure about deleting '+openDelete.name+"?"}</UserHeader>
        <Close onClick={()=>{setOpenDelete()}}/>
        <ButtonStyled  className="btn btn-danger"  onClick={()=>{setOpenDelete();del();}} >
                Yes !
              </ButtonStyled>
      </Dialog>
      </Wrapper>
        </>
    )

}



export function ConfirmDelete(props){
    if (!props.openDelete) return null;
    return <ConfirmDeleteContainer {...props}/>
}