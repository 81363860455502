import React from "react";
import styled from "styled-components";
import { useState } from "react";
import { useEffect } from "react";

import { EditDelivery } from "./EditDelivery";
const Container = styled.div`
margin-left:150px;
position:fixed;
height:80%;
font-size : 20px;
top:10px;
right:0;
width:${(props) => props.width};
@media screen and (max-width: 995px) {
    margin-left:10px;
}
`;
const AccountsDiv = styled.div`
 
  width: 80%;
  height: auto;
  

  @media screen and (max-width: 1200px) {
    width: 80%;
  }
  @media screen and (max-width: 1050px) {
    width: 90%;
  }
`;
const AccountsRows = styled.div`
  display: flex;
  flex-direction: column;
`;
const AccountGrid = styled.div`
  height: 80px;
  border: solid 1px;
  border-color: gray;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1.2fr 1fr 0.7fr 0.4fr 0.01fr;
  gap: 15px 15px;
  margin: 10px;
`;
const AccountGridHeader = styled.div`

  display: grid;
  grid-template-columns: 1fr 0.8fr 0.7fr 0.5fr 0.001fr;
  gap: 15px 10px;
  margin: 30px;
`;
const Header = styled.div`
  display: flex;
  font-weight: bold;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
`;
const Search = styled.input`
width: 300px;
margin-left:10px;
border-radius:7px;
border-color:gray;
`;
const SearchContainer = styled.div`
display:flex;
flex-direction:row;
margin-left:auto;
margin-right:5px;
`;
const H = styled.h3`
text-align:center;
`;
const Input = styled.input`
width:80px;
`;
const YesLogo = styled.div`
 height: 40px;
 width: 40px;
 margin-left:10px;
 margin-top:20px;
 background-image:url("/img/yes.png");
 background-position: center;
 background-repeat: no-repeat;
 background-size: cover;
 border: none; 
`;
const NoLogo = styled.div`
 height: 40px;
 width: 40px;
 margin-left:10px;
 margin-top:20px;
 background-image:url("/img/no.png");
 background-position: center;
 background-repeat: no-repeat;
 background-size: cover;
 border: none; 
`;
const ButtonStyled = styled.button`
max-height:40px;
min-width:150px;
text-decoration: none;
margin: 10px;
&:hover{
    opacity: 1.5;
    border:1px solid;
    border-color: lightgray; 
    border-radius : 3px;
}
`;
const X = styled.div`
display:flex;
flex-direction:row
align-items:center;
justify-content:center;
`;

const db = window.firebase.firestore();


export function Deliveries({active}) {
  const [data, setData] = useState();
  const canShowUsers = data && true;
   //use state to edit data 
 const [openItem, setOpenItem] = useState();
  useEffect(() => {
    
    db.collection("delivery").doc('0').onSnapshot((doc) => {
        return setData(doc.data()) 
      });
  
   
    
    
  }, []);

 
  function ToggleUser(id,state) {

    const docRef = db.collection("users").doc(id);
  
 
    docRef.update({
     status: !state
   
    });
    db.collection("users")
    .get()
    .then((data) => {
      return setData(data.docs);
    });

  console.log("updated :=)")
 
  }
function changeHandler(){

}

  return (
    <Container width={ active ? "calc(100% - 220px)" : "calc(100% - 40px)"}>
         <EditDelivery openItem={openItem} setData={setData} setOpenItem={setOpenItem}/>
      <AccountsDiv>
        <H>Set delivery rules  </H>
        <AccountsRows>
        <AccountGridHeader>
            <Header>Start Time</Header>
            <Header>End Time</Header>
            <Header>Free</Header>
            <Header>%Off</Header>
          </AccountGridHeader>
          {canShowUsers  ?  
                  <>
                    <AccountGrid>
                   
                      <Item> 
                     {data.startTime} 
               </Item>
                      <Item>
                    {data.endTime} 
                   
                     </Item>
                     
                      
                       {data.free  ? <YesLogo />: <NoLogo />}
                        
              
                       <Item>
                       
                       {data.percentage+"%"}
                        
                        
                        </Item>
                    </AccountGrid>
                  </>
            
             
            : "Loading ..."}
        </AccountsRows>
      </AccountsDiv>
      <X>
          <ButtonStyled className='btn btn-primary' onClick={()=>setOpenItem(data)} >Edit</ButtonStyled>
         </X>
    </Container>
  );
}

